import React, { useEffect } from 'react';
import styled from 'styled-components';

import TickerLine from '@/components/TickerLine';
import Subscription from '@/components/Subscription';
import useCurrentPage from '@/hooks/useCurrentPage';
import { HypermarketsPageType } from '@/typings/model';
import { useTypedSelector } from '@/store/store';
import { selectMenuItemListByAlias } from '@/store/reducers/tager/menus';
import { useLayout } from '@/components/Layout/Layout.hooks';
import useCurrentNewsList from '@/hooks/useCurrentNewsList';

import News from './components/News';
import Questions from './components/Questions';
import Hero from './components/Hero';
import Notifications from './components/Notification';
import FindSection from './components/FindSection';
import TickerLineSection from './components/TickerLineSection';
import ProductsSection from './components/ProductsSection';

function Hypermarkets() {
  const page = useCurrentPage<HypermarketsPageType>();
  const { setThemePage, setActiveStoreName, setMenuStore } = useLayout();

  const { data: newsList } = useCurrentNewsList('hypermarket');

  const headerMenuHypermarkets =
    useTypedSelector((state) =>
      selectMenuItemListByAlias(state, 'hypermarket')
    ) ?? [];

  useEffect(() => {
    setThemePage('');

    setActiveStoreName({
      value: 'Гипермаркет',
      label: 'Гипермаркет',
    });
    setMenuStore(headerMenuHypermarkets);

    return () => {
      setActiveStoreName({
        value: '',
        label: '',
      });
      setMenuStore([]);
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [headerMenuHypermarkets]);

  return (
    <Wrapper>
      {page ? (
        <>
          <Hero page={page} />
          <TickerLine
            backgroundTicket={
              page.templateFields.tickerTopBackgroundFirst ?? ''
            }
            colorTicket={page.templateFields.tickerTopColorFirst ?? ''}
            labelTicket={page.templateFields.tickerTopTextFirst ?? ''}
            linkTicket={page.templateFields.tickerTopLinkFirst ?? ''}
          />
          <ProductsSection page={page} />
          <Notifications page={page} />
          <TickerLineSection tickers={page.templateFields.tickers} />
          {newsList.length > 0 && <News newsList={newsList} />}
          <FindSection page={page} />
          <TickerLine
            backgroundTicket={
              page.templateFields.tickerBottomBackgroundFirst ?? ''
            }
            colorTicket={page.templateFields.tickerBottomColorFirst ?? ''}
            labelTicket={page.templateFields.tickerBottomTextFirst ?? ''}
            linkTicket={page.templateFields.tickerBottomLinkFirst ?? ''}
          />
          <Questions page={page} />
        </>
      ) : null}
      <Subscription />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  overflow: hidden;
`;

export default Hypermarkets;
