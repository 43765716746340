import React from 'react';
import styled, { css } from 'styled-components';

import Picture from '@/components/Picture';
import mapSecondUrl from '@/assets/images/map/map-second.png';
import mapSecondUrl2x from '@/assets/images/map/map-second@2x.png';
import mapSecondUrlWebp from '@/assets/images/map/map-second.webp';
import mapSecondUrlWebp2x from '@/assets/images/map/map-second@2x.webp';
import { media } from '@/utils/mixin';

function Map() {
  return (
    <Component>
      <Inner>
        <Picture
          laptop={{
            src: mapSecondUrl,
            src2x: mapSecondUrl2x,
            webp: mapSecondUrlWebp,
            webp2x: mapSecondUrlWebp2x,
          }}
        />
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  position: absolute;
  top: 120px;
  right: -7.25%;

  ${media.laptop(css`
    max-width: 900px;
    right: 0px;
    top: 40px;
  `)}

  ${media.tablet(css`
    max-width: 700px;
    right: 0px;
    top: 40px;
  `)}
  
  ${media.mobile(css`
    max-width: 474px;
    right: 0px;
    top: 260px;
  `)}
`;

const Inner = styled.div`
  div,
  picture,
  img {
    width: 100%;
    height: 100%;
  }
`;

export default Map;
