import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

interface Props {
  label: string;
  text: string;
}

function InfoItem({ label, text }: Props) {
  return (
    <Component>
      <Label>{label}</Label>
      <Text dangerouslySetInnerHTML={{ __html: text ?? '' }} />
    </Component>
  );
}

const Component = styled.div`
  margin-bottom: 30px;

  ${media.laptop(css`
    margin-bottom: 16px;
  `)}

  ${media.mobile(css`
    margin-bottom: 30px;
  `)}

  &:last-child {
    margin-bottom: 0;
  }
`;

const Label = styled.div`
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: ${colors.white};

  ${media.laptop(css`
    margin-bottom: 4px;
    font-size: 16px;
    line-height: 24px;
  `)}

  ${media.tablet(css`
    font-size: 16px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 6px;
  `)}
`;

const Text = styled.p`
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  color: ${colors.white};

  a {
    transition: all 0.3s linear;

    &:hover {
      color: ${colors.orange};
    }
  }

  ${media.laptop(css`
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    font-size: 20px;
    line-height: 24px;
  `)}
  
  ${media.mobile(css`
    font-size: 16px;
    line-height: 20px;
  `)}
`;

export default InfoItem;
