import React from 'react';
import styled, { css } from 'styled-components';

import {
  convertThumbnailToPictureImage,
  ThumbnailType,
} from '@tager/web-modules';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import { StringField } from '@/typings/model';
import Picture from '@/components/Picture';

interface Props {
  text: StringField;
  image?: ThumbnailType | null;
  isSecondary?: boolean;
}

function Hero({ text, image, isSecondary }: Props) {
  return (
    <Component isSecondary={isSecondary}>
      <Inner>
        <Left>
          <Logo>
            {image && (
              <Picture mobileSmall={convertThumbnailToPictureImage(image)} />
            )}
          </Logo>
        </Left>
        {text && (
          <Right>
            <Text>{text}</Text>
          </Right>
        )}
      </Inner>
    </Component>
  );
}

const Inner = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.tablet(css`
    align-items: flex-end;
  `)}

  ${media.mobile(css`
    display: block;
  `)}
`;

const Left = styled.div`
  flex: 1 1 784px;
  max-width: 784px;
  margin-right: 30px;

  ${media.laptop(css`
    flex: 1 1 586px;
    max-width: 586px;
  `)}

  ${media.tablet(css`
    flex: 0 0 346px;
    max-width: 346px;
    margin-right: 36px;
  `)}
  
  ${media.mobile(css`
    margin: 0 0 30px;
  `)}
`;

const Right = styled.div`
  flex: 1 1 784px;
  max-width: 784px;

  display: flex;
  align-items: flex-end;

  ${media.laptop(css`
    flex: 1 1 486px;
    max-width: 486px;
  `)}
`;

const Logo = styled.div`
  div {
    justify-content: flex-start;
  }

  svg,
  img {
    height: 320px;

    ${media.laptop(css`
      height: 234px;
    `)}

    ${media.tablet(css`
      height: 170px;
    `)}
    
    ${media.mobile(css`
      height: 152px;
    `)}
  }
`;

const Text = styled.p`
  font-size: 30px;
  line-height: 36px;
  color: ${colors.black};

  ${media.laptop(css`
    font-size: 20px;
    line-height: 26px;
  `)}

  ${media.tablet(css`
    font-size: 18px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    font-size: 14px;
    line-height: 20px;
  `)}
`;

const Component = styled.div<{ isSecondary?: boolean }>`
  max-width: 1680px;
  padding: 92px 40px 22px;
  margin: 0 auto 98px;

  ${media.laptop(css`
    padding: 68px 40px 3px;
    margin-bottom: 78px;
  `)};

  ${media.tablet(css`
    padding: 64px 20px 0;
    margin-bottom: 50px;
  `)}

  ${media.mobile(css`
    padding: 52px 20px 0;
  `)}

  ${(props) =>
    props.isSecondary
      ? css`
          ${Logo} {
              svg,img {
              height: auto !important; 
          }

          ${Right} {
            flex: 1 1 648px;
            max-width: 648px;

            ${media.laptop(css`
              flex: 1 1 486px;
              max-width: 486px;
            `)}
            
              ${media.tablet(css`
                flex: 1 1 auto;
                max-width: 100%;
              `)}
          }
        `
      : ''}
`;

export default Hero;
