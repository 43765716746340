import React, { RefObject, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { useModal } from '@tager/web-components';

import Button from '@/components/Button';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { VacanciesShortItem, VacancyStoresItemType } from '@/typings/model';
import ContactDataModal from '@/components/modals/ContactDataModal';
import useWindowSize from '@/hooks/useWindowSize';

import VacanciesItem from './components/VacanciesItem';

type Props = {
  vacanciesList: Array<VacancyStoresItemType>;
  contentRef: RefObject<HTMLDivElement>;
  activeVacancies: number;
  setActiveVacancies: (count: number) => void;
  valueShop: string;
};

function VacanciesList({
  vacanciesList,
  contentRef,
  activeVacancies,
  setActiveVacancies,
  valueShop,
}: Props) {
  const [updateList, setUpdateList] = useState<number>(0);

  const openModal = useModal();

  const onTapContact = (data: {
    contact_name: string;
    contact_email: string;
    contact_phone: string;
  }) => {
    openModal(ContactDataModal, {
      phones: data.contact_phone.split(';'),
      email: data.contact_email,
      title: data.contact_name,
      isDropDown: false,
    });
  };

  function sortVacancies(list: Array<VacancyStoresItemType>) {
    const vacancyList: Array<VacanciesShortItem> = [];
    list.forEach((listItem, index) => {
      listItem.vacancies.forEach((vacancyItem, index) => {
        vacancyList.push(vacancyItem);
      });
    });

    if (valueShop) {
      const vacancyByStore = vacancyList.filter(
        (vacancy) => vacancy.store === valueShop
      );
      return vacancyByStore;
    } else {
      return vacancyList;
    }
  }

  function setVacanciesList(
    list: Array<VacancyStoresItemType>,
    activeVacancies: number
  ) {
    return sortVacancies(list).slice(0, activeVacancies);
  }

  function getVacanciesLength(list: Array<VacancyStoresItemType>) {
    return sortVacancies(list).length;
  }

  function addMore(count: number) {
    setActiveVacancies(count + 12);
  }

  useEffect(() => {
    setUpdateList(updateList + 1);
  }, [vacanciesList]);

  return (
    <Component>
      <Inner ref={contentRef}>
        {setVacanciesList(vacanciesList, activeVacancies).map(
          (vacancyItem: VacanciesShortItem, index: number) => {
            return (
              <VacanciesItem
                key={index}
                onTapContact={onTapContact}
                vacancyItem={vacancyItem}
                updateList={updateList}
              />
            );
          }
        )}
      </Inner>
      <ButtonWrapper
        isHidden={getVacanciesLength(vacanciesList) <= activeVacancies}
      >
        <Button variant="gray" onClick={() => addMore(activeVacancies)}>
          Показать еще
        </Button>
      </ButtonWrapper>
      {getVacanciesLength(vacanciesList) === 0 ? (
        <NotFoundText>В данный момент подходящих вакансий нет!</NotFoundText>
      ) : null}
    </Component>
  );
}

const Component = styled.div``;

const Inner = styled.div``;

const ButtonWrapper = styled.div<{ isHidden?: boolean }>`
  margin-top: 50px;

  ${(props) =>
    props.isHidden
      ? css`
          display: none;
        `
      : ''}

  button {
    width: 100%;
    padding: 31px 0;
    border-radius: 2px;
    font-weight: bold;
    font-size: 36px;
    line-height: 48px;
    transition: all 0.3s ease-in-out;
  }

  ${media.laptop(css`
    margin-top: 16px;

    button {
      padding: 24px 0;
      font-size: 24px;
      line-height: 32px;
    }
  `)}

  ${media.tablet(css`
    margin-top: 50px;

    button {
      padding: 17px 0;
      font-size: 20px;
      line-height: 26px;
    }
  `)}
  
  ${media.mobile(css`
    margin-top: 30px;

    button {
      padding: 15px 0;
      font-size: 16px;
      line-height: 20px;
    }
  `)}
`;

const NotFoundText = styled.span`
  display: flex;
  align-items: center;
  min-height: 398px;

  font-weight: 500;
  font-size: 54px;
  line-height: 64px;
  text-align: center;
  color: ${colors.red};

  ${media.laptop(css`
    min-height: 378px;
    font-size: 40px;
    line-height: 48px;
  `)}
`;

export default VacanciesList;
