import React from 'react';
import styled, { css } from 'styled-components';

import InformationLabel from '@/components/InformationLabel';
import { media } from '@/utils/mixin';
import { StringField } from '@/typings/model';

interface Props {
  options: Array<{
    optionLabel: StringField;
  }>;
}

function ServicesSection({ options }: Props) {
  return (
    <Component>
      <List>
        {options?.map((option) => {
          return (
            <Cell key={option.optionLabel}>
              <InformationLabel label={option.optionLabel} />
            </Cell>
          );
        })}
      </List>
    </Component>
  );
}

const Component = styled.div`
  max-width: 1680px;
  margin: 80px auto;
  padding: 0 40px;

  ${media.tablet(css`
    margin: 50px auto;
    padding: 0 20px;
  `)}

  ${media.mobile(css`
    margin: 30px auto;
  `)}
`;

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -12px;

  ${media.laptop(css`
    margin: -8px;
  `)}
`;

const Cell = styled.div`
  padding: 12px;

  ${media.laptop(css`
    padding: 8px;
  `)}
`;

export default ServicesSection;
