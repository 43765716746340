import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import SalesBtn from '@/modules/Home/components/Hero/components/SalesBtn';
import BannerCard from '@/modules/Home/components/BannerCard';
import Picture from '@/components/Picture';
import rightBackgroundUrl from '@/assets/images/banner/banner-bg.png';
import rightBackgroundUrl2x from '@/assets/images/banner/banner-bg@2x.png';
import rightBackgroundUrlWebp from '@/assets/images/banner/banner-bg.webp';
import rightBackgroundUrlWebp2x from '@/assets/images/banner/banner-bg@2x.webp';
import { colors } from '@/constants/theme';
import { HypermarketsPageType } from '@/typings/model';

import HeroSlider from './components/HeroSlider';

interface Props {
  page: HypermarketsPageType;
}

function Hero({ page }: Props) {
  const {
    firstBlockCircleLabel,
    firstBlockCircleLink,
    firstBlockCenterImage,
    firstBlockCenterLabel,
    firstBlockCenterLink,
    firstBlockBottomImage,
    firstBlockBottomLabel,
    firstBlockBottomLink,
    firstBlockRightLabel,
    firstBlockRightElements,
  } = page.templateFields;

  return (
    <Components>
      <HeroContainer>
        <Inner>
          <Left>
            <LeftTop>
              <LeftTopSales>
                <SalesBtn
                  label={firstBlockCircleLabel ?? ''}
                  link={firstBlockCircleLink ?? ''}
                  isTabletCircle={true}
                />
              </LeftTopSales>
              <LeftTopBanner>
                <BannerCard
                  image={firstBlockCenterImage}
                  text={firstBlockCenterLabel ?? ''}
                  link={firstBlockCenterLink ?? ''}
                  isLink={!!firstBlockCenterLink}
                />
              </LeftTopBanner>
            </LeftTop>
            <LeftBottom>
              <BannerCard
                image={firstBlockBottomImage}
                text={firstBlockBottomLabel ?? ''}
                link={firstBlockBottomLink ?? ''}
                isLink={!!firstBlockBottomLink}
              />
            </LeftBottom>
          </Left>
          <Right>
            <RightTitle>{firstBlockRightLabel}</RightTitle>
            <RightBackground>
              <Picture
                mobileSmall={{
                  src: rightBackgroundUrl,
                  src2x: rightBackgroundUrl2x,
                  webp: rightBackgroundUrlWebp,
                  webp2x: rightBackgroundUrlWebp2x,
                }}
              />
            </RightBackground>
            <HeroSlider sliderList={firstBlockRightElements} />
          </Right>
        </Inner>
      </HeroContainer>
    </Components>
  );
}

const Components = styled.div`
  margin-top: 38px;

  ${media.laptop(css`
    margin-top: 48px;
  `)}

  ${media.tablet(css`
    margin-top: 54px;
  `)}
  
  ${media.mobile(css`
    margin-top: 0;
  `)}
`;

const HeroContainer = styled.div`
  max-width: 1920px;
  margin: 0 auto;
`;

const Inner = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.mobile(css`
    flex-direction: column-reverse;
  `)}
`;

const Left = styled.div`
  flex: 1 1 976px;
  max-width: 976px;
  width: 100%;

  display: flex;
  flex-direction: column;

  ${media.laptop(css`
    flex: 1 1 644px;
    max-width: 644px;
  `)}

  ${media.tabletSmall(css`
    flex: 1 1 248px;
    max-width: 100%;
  `)}
  
  ${media.mobile(css`
    flex: 1 1 auto;
  `)}
`;

const Right = styled.div`
  position: relative;
  flex: 1 1 944px;
  max-width: 944px;

  ${media.laptop(css`
    flex: 1 1 636px;
    max-height: 644px;
  `)}

  ${media.tablet(css`
    flex: 1 1 560px;
    max-height: 560px;
  `)}

  ${media.tabletSmall(css`
    flex: 1 1 520px;
    max-height: 498px;
  `)}
  
  ${media.mobile(css`
    flex: 1 1 auto;
    max-height: 100%;
    height: calc(100vw / 100 * 70);
  `)}
  
  ${media.mobileLarge(css`
    height: calc(var(--vh, 1vh) * 100 - 136px);
  `)}
`;

const RightTitle = styled.span`
  position: absolute;
  z-index: 4;
  top: 30px;
  left: 30px;
  display: block;
  max-width: 618px;
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.black};

  ${media.laptop(css`
    top: 20px;
    left: 20px;
    max-width: 440px;
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    font-size: 48px;
    line-height: 56px;
  `)}
  
  ${media.mobile(css`
    top: 52px;
    font-size: 34px;
    line-height: 42px;
  `)}
`;

const RightBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  div,
  picture,
  img {
    width: 100%;
    height: 100%;
  }
`;

const LeftTop = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LeftBottom = styled.div`
  position: relative;
  flex: 1 1 449px;
  background: #7ed9da;

  ${media.laptop(css`
    flex: 1 1 322px;
  `)}

  ${media.tablet(css`
    flex: 1 1 280px;
  `)}

  ${media.tabletSmall(css`
    flex: 1 1 248px;
  `)}
  
  ${media.mobile(css`
    display: none;
  `)}
`;

const LeftTopSales = styled.div`
  position: relative;
  flex: 1 1 494px;
  max-width: 494px;

  ${media.laptop(css`
    flex: 1 1 322px;
    max-width: 322px;
  `)}

  ${media.tabletSmall(css`
    flex: 1 1 248px;
    max-width: 100%;
  `)}
`;

const LeftTopBanner = styled.div`
  flex: 1 1 482px;
  max-width: 482px;
  background: #2f5d99;

  ${media.laptop(css`
    flex: 1 1 322px;
    max-width: 322px;
  `)}

  ${media.tabletSmall(css`
    display: none;
  `)}
`;

export default Hero;
