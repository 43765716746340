import React from 'react';
import styled, { css } from 'styled-components';

import { StringField } from '@/typings/model';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

type Props = {
  socialItem: {
    title: StringField;
    text: StringField;
  };
};

function SocialPackageCard({ socialItem }: Props) {
  const { title, text } = socialItem;

  return (
    <Component>
      <Inner>
        <Title>{title}</Title>
        <Text>{text}</Text>
      </Inner>
    </Component>
  );
}

const Component = styled.div``;
const Inner = styled.div``;

const Title = styled.span`
  display: block;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  color: ${colors.white};

  ${media.laptop(css`
    font-size: 20px;
    line-height: 26px;
  `)}

  ${media.tablet(css`
    font-size: 18px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    font-size: 14px;
    line-height: 20px;
  `)}
`;

const Text = styled.p`
  margin-top: 16px;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: ${colors.grayLight};

  ${media.laptop(css`
    font-size: 16px;
    line-height: 24px;
  `)}

  ${media.tablet(css`
    margin-top: 20px;
    font-size: 16px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    margin-top: 10px;
    font-size: 12px;
    line-height: 20px;
  `)}
`;

export default SocialPackageCard;
