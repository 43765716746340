import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { ImageThumbnail, StringField } from '@/typings/model';
import Offers from '@/modules/Home/components/SpecialsOffers/components/Offers';

import PiggyBankBlock from './components/PiggyBankBlock';

type Props = {
  discountTitle: StringField;
  discountButtonLabel: StringField;
  discountButtonUrl: StringField;
  discountBlockquote: StringField;
  discountImage?: ImageThumbnail;
  discountStickerLabel: StringField;
  discountStickerUrl: StringField;
  specialOffers?: Array<{
    image: {
      fifth: ImageThumbnail;
      first: ImageThumbnail;
      fourth: ImageThumbnail;
      second: ImageThumbnail;
      sixth: ImageThumbnail;
      third: ImageThumbnail;
    };
    title: StringField;
    link: StringField;
    design: StringField;
  }>;
};

function DiscountTablet({
  specialOffers,
  discountTitle,
  discountImage,
  discountButtonUrl,
  discountButtonLabel,
  discountBlockquote,
}: Props) {
  return (
    <Component>
      <Top>
        {specialOffers && specialOffers.length > 1 && (
          <List>
            <Cell isBig={true}>
              <Offers
                image={
                  specialOffers[1].image
                    ? specialOffers[1].image.third
                      ? specialOffers[1].image.third
                      : null
                    : null
                }
                title={specialOffers[1].title ?? ''}
                link={specialOffers[1].link ?? '#'}
                design="big"
              />
            </Cell>
            <Cell>
              <Offers
                image={
                  specialOffers[2].image
                    ? specialOffers[2].image.third
                      ? specialOffers[2].image.third
                      : null
                    : null
                }
                title={specialOffers[2].title ?? ''}
                link={specialOffers[2].link ?? '#'}
                design="middle"
              />
            </Cell>
            <Cell>
              <Offers
                image={
                  specialOffers[3].image
                    ? specialOffers[3].image.third
                      ? specialOffers[3].image.third
                      : null
                    : null
                }
                title={specialOffers[3].title ?? ''}
                link={specialOffers[3].link ?? '#'}
                design="small"
              />
            </Cell>
            <Cell isBig={true}>
              <Offers
                image={
                  specialOffers[4].image
                    ? specialOffers[4].image.third
                      ? specialOffers[4].image.third
                      : null
                    : null
                }
                title={specialOffers[4].title ?? ''}
                link={specialOffers[4].link ?? '#'}
                design="big"
              />
            </Cell>
          </List>
        )}
      </Top>
      <Bottom>
        <PiggyBankBlock
          discountTitle={discountTitle}
          discountButtonLabel={discountButtonLabel}
          discountButtonUrl={discountButtonUrl}
          discountBlockquote={discountBlockquote}
          discountImage={discountImage}
        />
      </Bottom>
    </Component>
  );
}

const Component = styled.div``;

const Top = styled.div`
  margin-top: -170px;

  ${media.mobile(css`
    margin-top: 83px;
  `)}
`;

const Bottom = styled.div``;

const List = styled.div``;

const Cell = styled.div<{ isBig?: boolean }>`
  display: flex;
  justify-content: flex-start;
  margin-top: 30px;

  &:nth-child(odd) {
    justify-content: flex-end;
  }

  ${(props) =>
    props.isBig
      ? css`
          margin: 30px -20px 0;
          & > div {
            width: 460px;
          }
        `
      : css`
          & > div {
            width: 442px;
          }
        `}

  ${media.mobileLarge(css`
    margin: 30px -20px 0;
    & > div {
      width: calc(100vw - 20px);
    }
  `)}
`;

export default DiscountTablet;
