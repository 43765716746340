import React from 'react';
import styled from 'styled-components';

import CareersNotFound from '@/components/CareersNotFound';
import Breadcrumbs from '@/components/Breadcrumbs';
import useCurrentPage from '@/hooks/useCurrentPage';
import { JobsPageType } from '@/typings/model';
import { useLayout } from '@/components/Layout/Layout.hooks';
import { BreadCrumbs } from '@/typings/common';
import { setBreadCrumbsList } from '@/utils/common';

import Hero from './components/Hero';
import JobOpportunity from './components/JobOpportunity';

function Jobs() {
  const page = useCurrentPage<JobsPageType>();
  const { setThemePage } = useLayout();
  setThemePage('');

  const BREADCRUMB_LIST: Array<BreadCrumbs> = setBreadCrumbsList(
    {
      title: page?.title ?? '',
      path: page?.path ?? '',
    },
    page?.parent
  );

  return (
    <Wrapper>
      <Breadcrumbs breadcrumbs={BREADCRUMB_LIST} />
      <Hero
        title={page?.templateFields.title ?? ''}
        text={page?.templateFields.text ?? ''}
        stickerLabel={page?.templateFields.stickerLabel ?? ''}
        stickerUrl={page?.templateFields.stickerUrl ?? ''}
      />
      <JobOpportunity />
      <CareersNotFound />
    </Wrapper>
  );
}

const Wrapper = styled.div``;

export default Jobs;
