import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { ImageThumbnail, StringField } from '@/typings/model';
import { media } from '@/utils/mixin';

import TableRow from './components/TableRow';
import More from './components/More';

type Props = {
  offlineCertificateTitle: StringField;
  offlineCertificateButtonLabel: StringField;
  offlineCertificateItems?: Array<{
    image: ImageThumbnail;
    name: StringField;
    phone: StringField;
    link: StringField;
  }>;
};

function OfflineCertificate({
  offlineCertificateTitle,
  offlineCertificateItems,
  offlineCertificateButtonLabel,
}: Props) {
  const [shouldDisplayAll, setDisplayAll] = useState(false);
  const isLargeList = offlineCertificateItems?.length ?? 0 > 5;

  function toggleVacancy() {
    if (shouldDisplayAll) {
      setDisplayAll(false);
    } else {
      setDisplayAll(true);
    }
  }

  function getDisplayedCardList() {
    if (shouldDisplayAll) {
      return offlineCertificateItems;
    }

    return offlineCertificateItems?.slice(0, 7);
  }

  return (
    <Component>
      <Inner>
        <Top>
          <Title
            dangerouslySetInnerHTML={{ __html: offlineCertificateTitle ?? '' }}
          />
        </Top>
        <Table>
          <TableRow
            offlineCertificateItem={{
              name: 'Торговые центры',
              phone: 'Тел. номер',
            }}
            isHeader={true}
          />
          {getDisplayedCardList()?.map((offlineCertificateItem, index) => {
            return (
              <TableRow
                key={index}
                offlineCertificateItem={offlineCertificateItem}
              />
            );
          })}
        </Table>
        {isLargeList ? (
          <More
            shouldDisplayAll={shouldDisplayAll}
            onClick={() => toggleVacancy()}
            offlineCertificateButtonLabel={offlineCertificateButtonLabel}
          />
        ) : null}
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  padding: 244px 0 152px;

  ${media.laptop(css`
    padding: 125px 0 120px;
  `)}

  ${media.tablet(css`
    padding: 80px 0 40px;
  `)}
  
  ${media.mobile(css`
    padding: 50px 0 30px;
  `)}
`;

const Inner = styled.div`
  max-width: 1680px;
  margin: 0 auto;
  padding: 0 40px;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

const Top = styled.div``;

const Title = styled.h2`
  max-width: 1056px;
  margin: 0;
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.white};

  ${media.laptop(css`
    max-width: 800px;
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    font-size: 48px;
    line-height: 56px;
  `)}
  
  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
  `)}

  b {
    font-weight: 500;
    color: ${colors.orange};
  }
`;

const Table = styled.div`
  margin-top: 80px;

  ${media.laptop(css`
    margin: 60px -40px 0;
  `)}

  ${media.tablet(css`
    margin: 50px -20px 0;
  `)}
  
  ${media.mobile(css`
    margin-top: 30px;
  `)}
`;

export default OfflineCertificate;
