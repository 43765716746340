import React from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import PromotionCard from '@/modules/Promotions/components/PromotionCard';
import { PromotionProductsItem } from '@/typings/model';
import { breakpoints } from '@/constants/theme';
import { media } from '@/utils/mixin';

interface Props {
  activeProducts: Array<PromotionProductsItem>;
}

function PromotionsDesktop({ activeProducts }: Props) {
  const isMobile = useMedia(`(max-width: ${breakpoints.tabletSmall - 1}px)`);

  return (
    <Component>
      {activeProducts.map((product, index) => {
        if (isMobile && product.displaySize === 'BIG') return null;
        return (
          <PromotionCard
            key={index}
            product={product}
            size={product.displaySize ? product.displaySize : 'small'}
            index={index}
            activeProducts={activeProducts}
          />
        );
      })}
    </Component>
  );
}

const Component = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -35px -16px;
  width: 100%;

  ${media.laptop(css`
    margin: -28px -12px;
  `)}

  ${media.tablet(css`
    margin: -25px 0;
  `)}
  
  ${media.mobile(css`
    margin: -25px 0;
  `)}
`;

export default PromotionsDesktop;
