import React from 'react';
import styled, { css } from 'styled-components';

import { StringField } from '@/typings/model';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

type Props = {
  useCorrectlyItem: {
    text: StringField;
  };
};

function UseCorrectlyCard({ useCorrectlyItem }: Props) {
  return (
    <Component>
      <Inner>
        <Text>{useCorrectlyItem.text}</Text>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  border-top: 1px solid ${colors.black};

  ${media.laptop(css`
    border-top: 1px solid ${colors.grayLight};
  `)}
`;

const Inner = styled.div`
  padding-top: 32px;

  ${media.tablet(css`
    padding-top: 20px;
  `)}
`;

const Text = styled.p`
  font-size: 30px;
  line-height: 36px;

  ${media.laptop(css`
    font-size: 20px;
    line-height: 26px;
  `)}

  ${media.tablet(css`
    font-size: 18px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    font-size: 14px;
    line-height: 20px;
  `)}
`;

export default UseCorrectlyCard;
