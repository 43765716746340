import React from 'react';
import styled, { css } from 'styled-components';

import { StringField } from '@/typings/model';
import { colors } from '@/constants/theme';
import { ReactComponent as DiscountIcon1 } from '@/assets/svg/discount-1.svg';
import { ReactComponent as DiscountIcon2 } from '@/assets/svg/discount-2.svg';
import { ReactComponent as DiscountIcon3 } from '@/assets/svg/discount-3.svg';
import { media } from '@/utils/mixin';

import UseCorrectlyCard from './components/UseCorrectlyCard';

type Props = {
  useCorrectlyTitle: StringField;
  useCorrectlyItems?: Array<{
    text: StringField;
  }>;
};

function UseCorrectly({ useCorrectlyTitle, useCorrectlyItems }: Props) {
  return (
    <Component>
      <Inner>
        <Top>
          <Title>{useCorrectlyTitle}</Title>
          <IconWrapper>
            <IconInner>
              <DiscountIcon1 />
              <DiscountIcon2 />
              <DiscountIcon3 />
            </IconInner>
          </IconWrapper>
        </Top>
        <Bottom>
          <List>
            {useCorrectlyItems?.map((useCorrectlyItem, index) => {
              return (
                <Cell key={index}>
                  <UseCorrectlyCard useCorrectlyItem={useCorrectlyItem} />
                </Cell>
              );
            })}
          </List>
        </Bottom>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  background: ${colors.white};
  padding: 240px 0;

  ${media.laptop(css`
    padding: 178px 0 160px;
  `)}

  ${media.tablet(css`
    padding: 80px 0;
  `)}
`;
const Inner = styled.div`
  max-width: 1680px;
  padding: 0 40px;
  margin: 0 auto;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

const Top = styled.div`
  max-width: 996px;
  position: relative;

  svg {
    width: 72px;
    height: 72px;

    ${media.tablet(css`
      width: 56px;
      height: 56px;
    `)}

    ${media.mobile(css`
      width: 42px;
      height: 42px;
    `)}
  }

  ${media.laptop(css`
    max-width: 700px;
  `)}

  ${media.tablet(css`
    max-width: 480px;
  `)}
`;

const Bottom = styled.div`
  margin-top: 78px;

  ${media.laptop(css`
    margin-top: 53px;
  `)}

  ${media.tablet(css`
    margin-top: 50px;
  `)}
  
  ${media.mobile(css`
    margin-top: 30px;
  `)}
`;

const Title = styled.h2`
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  display: inline;

  ${media.laptop(css`
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    font-size: 48px;
    line-height: 56px;
  `)}
  
  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
  `)}
`;

const IconWrapper = styled.span`
  display: inline;
  position: relative;
  width: 240px;

  ${media.mobile(css`
    display: block;
    height: 42px;
    margin-top: 20px;
  `)}
`;

const IconInner = styled.span`
  padding-left: 16px;
  position: absolute;
  content: '';
  top: -20%;
  transform: translateY(-50%);

  display: inline-flex;

  ${media.tablet(css`
    top: -10%;
  `)}

  ${media.mobile(css`
    top: 15%;
    padding-left: 0;
  `)}
`;

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -39px -16px;

  ${media.laptop(css`
    margin: -28px -12px;
  `)}

  ${media.tablet(css`
    margin: -25px -18px;
  `)}
  
  ${media.mobile(css`
    margin: -10px;
  `)}
`;

const Cell = styled.div`
  padding: 39px 16px;
  flex: 0 0 33.33%;

  ${media.laptop(css`
    padding: 28px 12px;
  `)}

  ${media.tablet(css`
    flex: 0 0 50%;
    padding: 25px 18px;
  `)}
  
  ${media.mobile(css`
    flex: 1 1 100%;
    padding: 10px;
  `)}
`;

export default UseCorrectly;
