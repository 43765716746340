import React from 'react';
import styled, { css } from 'styled-components';

import { StringField } from '@/typings/model';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

type Props = {
  aboutItem: {
    title: StringField;
    text: StringField;
  };
};

function AboutItem({ aboutItem }: Props) {
  const { title, text } = aboutItem;
  return (
    <Component>
      <Inner>
        <Title dangerouslySetInnerHTML={{ __html: title ?? '' }} />
        <Text>{text}</Text>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  margin-bottom: 50px;

  ${media.laptop(css`
    margin-bottom: 40px;
  `)}

  ${media.tablet(css`
    margin-bottom: 30px;
  `)}

  &:last-child {
    margin-bottom: 0;
  }
`;

const Inner = styled.div``;

const Title = styled.span`
  display: block;
  font-weight: bold;
  font-size: 82px;
  line-height: 90px;
  letter-spacing: -1px;
  color: ${colors.black};

  b {
    color: ${colors.orange};
    margin-right: -10px;
  }

  ${media.laptop(css`
    font-size: 72px;
    line-height: 80px;

    b {
      margin-right: -6px;
    }
  `)}

  ${media.tablet(css`
    font-size: 56px;
    line-height: 62px;
  `)}
  
  ${media.mobile(css`
    font-size: 36px;
    line-height: 42px;

    b {
      margin-right: 3px;
    }
  `)}
`;

const Text = styled.p`
  display: block;
  margin-top: 20px;
  font-size: 30px;
  line-height: 36px;
  color: ${colors.black};

  ${media.laptop(css`
    margin-top: 16px;
    font-size: 20px;
    line-height: 26px;
  `)}

  ${media.tablet(css`
    font-size: 18px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    margin-top: 10px;
    font-size: 14px;
    line-height: 20px;
  `)}
`;

export default AboutItem;
