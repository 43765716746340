import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import TickerLine from '@/components/TickerLine';
import { media } from '@/utils/mixin';
import { TickerLineItemType } from '@/typings/model';
import { breakpoints } from '@/constants/theme';

interface Props {
  tickers: Array<TickerLineItemType>;
  isSecondary?: boolean;
}

function TickerLineSection({ tickers, isSecondary }: Props) {
  const isMobile = useMedia(`(max-width: ${breakpoints.tabletSmall - 1}px)`);
  const [isChange, setChange] = useState<boolean>(true);

  const rotateList: Array<string> = isSecondary ? ['1', '-2'] : ['-3', '4'];
  const rotateListMobile: Array<string> = isSecondary
    ? ['-3', '3']
    : ['-3', '3'];

  useEffect(() => {
    setChange(false);

    setTimeout(() => {
      setChange(true);
    }, 0);
  }, [tickers]);

  return (
    <Wrapper>
      {isChange &&
        tickers?.map((ticker, index) => {
          return (
            <LineCell key={index} isSecondary={isSecondary}>
              <TickerLine
                backgroundTicket={ticker.background}
                colorTicket={ticker.color}
                labelTicket={ticker.text}
                linkTicket={ticker.link}
                rotateTicket={
                  isMobile ? rotateListMobile[index] : rotateList[index]
                }
              />
            </LineCell>
          );
        })}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 152px 0 30px;
  z-index: 3;

  ${media.laptop(css`
    padding: 87px 0 30px;
  `)}

  ${media.tablet(css`
    padding: 58px 0 14px;
  `)}
  
  ${media.mobile(css`
    padding: 79px 0 0;
  `)}
`;

const LineCell = styled.div<{ isSecondary?: boolean }>`
  position: relative;

  &:first-child {
    z-index: 5;
  }

  &:nth-child(2) {
    position: relative;
    top: -46px;

    ${media.laptop(css`
      top: -35px;
    `)}

    ${media.tabletOnly(css`
      top: -22px;
    `)}
    
    ${media.mobile(css`
      top: -74px;
    `)}
  }

  ${(props) =>
    props.isSecondary
      ? css`
          &:nth-child(2) {
            position: relative;
            top: -5px;

            ${media.laptop(css`
              top: -10px;
            `)}

            ${media.tabletOnly(css`
              top: -2px;
            `)}
            
            ${media.mobile(css`
              top: -3px;
            `)}
          }
        `
      : ''}

  ${media.tablet(css`
    & > div {
      height: 35px;

      & li {
        font-size: 14px !important;
        line-height: 16px !important;
      }
    }
  `)}
  
  ${media.mobile(css`
    & > div {
      height: 38px;

      & li {
        font-size: 12px !important;
        line-height: 18px !important;
      }
    }
  `)}
`;

export default TickerLineSection;
