import React from 'react';
import styled, { css } from 'styled-components';

import ShopCard from '@/modules/Home/components/Shops/components/ShopCard';
import { colors } from '@/constants/theme';
import Cross from '@/components/Sticker/Cross';
import { media } from '@/utils/mixin';
import { ImageThumbnail, StringField } from '@/typings/model';
import Link from '@/components/Link';

interface Props {
  image: ImageThumbnail;
  text: StringField;
  title: StringField;
  link?: StringField;
  showCross?: boolean;
  isStickerUp?: boolean;
}

function InformationBlock({
  image,
  title,
  text,
  link,
  isStickerUp,
  showCross = true,
}: Props) {
  return (
    <Component>
      <BlockWrapper>
        <ShopCard image={image}>
          <BlockContent>
            <BlockContentInner>
              <BlockTitle>{title}</BlockTitle>
              <BlockText dangerouslySetInnerHTML={{ __html: text ?? '' }} />
            </BlockContentInner>
          </BlockContent>
        </ShopCard>
      </BlockWrapper>
      {showCross && (
        <CrossWrapper isStickerUp={isStickerUp}>
          <Cross isSecondary={true} />
        </CrossWrapper>
      )}
      {link ? <ComponentLink to={link}>Подробнее</ComponentLink> : null}
    </Component>
  );
}

const Component = styled.div`
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
`;

const ComponentLink = styled(Link)`
  position: absolute;
  bottom: 60px;
  left: 60px;
  z-index: 5;
  width: 360px;
  height: 360px;
  background: ${colors.white};
  overflow: hidden;
  border-radius: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(-15deg);
  transition: all 0.3s linear;

  font-weight: 600;
  font-size: 36px;
  line-height: 48px;
  color: ${colors.black};
  text-transform: uppercase;

  &:hover {
    background: ${colors.gray40};
  }

  ${media.laptop(css`
    bottom: 40px;
    left: 40px;
    width: 240px;
    height: 240px;

    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    bottom: 20px;
    left: 20px;
    width: 162px;
    height: 162px;

    font-size: 20px;
    line-height: 26px;
  `)}
  
  ${media.mobile(css`
    bottom: 6px;
    left: 6px;
    width: 164px;
    height: 164px;

    font-size: 16px;
    line-height: 20px;
  `)}
`;

const BlockWrapper = styled.div``;

const BlockContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.tablet(css`
    justify-content: flex-end;
  `)}
`;

const BlockContentInner = styled.div`
  max-width: 760px;
  color: ${colors.white};

  position: relative;
  top: -18px;

  ${media.laptop(css`
    top: 0;
    max-width: 480px;
  `)}

  ${media.tablet(css`
    padding: 0 20px 0 36px;
  `)}
  
  ${media.mobile(css`
    padding: 0 20px;
  `)}
`;

const BlockTitle = styled.h3`
  font-weight: 500;
  font-size: 50px;
  line-height: 58px;

  margin: 0 0 36px;

  ${media.laptop(css`
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 24px;
  `)}

  ${media.tablet(css`
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 10px;
  `)}
  
  ${media.mobile(css`
    font-size: 28px;
    line-height: 34px;
  `)}
`;

const BlockText = styled.p`
  font-size: 30px;
  line-height: 36px;

  ${media.laptop(css`
    font-size: 20px;
    line-height: 26px;
  `)}
`;

const CrossWrapper = styled.div<{ isStickerUp?: boolean }>`
  position: absolute;
  bottom: -124px;
  right: -60px;
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.6));
  height: 290px;
  width: 302px;

  ${media.laptop(css`
    bottom: -126px;
    right: -68px;
    height: 260px;
    width: 255px;
  `)}

  ${media.tablet(css`
    height: 170px;
    width: 170px;
    bottom: -69px;
    right: -82px;
  `)}
  
  ${media.mobile(css`
    height: 140px;
    width: 140px;

    bottom: auto;
    top: -73px;
    right: -38px;
    transform: rotate(26deg);
  `)}

  ${(props) =>
    props.isStickerUp
      ? css`
          right: -120px;
          top: -100px;

          ${media.tablet(css`
            right: -75px;
            top: -64px;
            transform: rotate(25deg);
          `)}

          ${media.tablet(css`
            right: -42px;
            top: -70px;
          `)}
        `
      : ''}
`;

export default InformationBlock;
