import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { media } from '@/utils/mixin';
import SectionPreview from '@/modules/Home/components/SectionPreview';
import Picture from '@/components/Picture';
import phonePreviewUrl from '@/assets/images/special-offers/phone.png';
import phonePreviewUrl2x from '@/assets/images/special-offers/phone@2x.png';
import phonePreviewUrlWebp from '@/assets/images/special-offers/phone.webp';
import phonePreviewUrlWebp2x from '@/assets/images/special-offers/phone@2x.webp';
import quoteUrl from '@/assets/images/special-offers/quote.png';
import quoteUrl2x from '@/assets/images/special-offers/quote@2x.png';
import quoteUrlWebp from '@/assets/images/special-offers/quote.webp';
import quoteUrlWebp2x from '@/assets/images/special-offers/quote@2x.webp';
import { colors } from '@/constants/theme';
import { ImageThumbnail, StringField } from '@/typings/model';

type Props = {
  discountTitle: StringField;
  discountButtonLabel: StringField;
  discountButtonUrl: StringField;
  discountBlockquote: StringField;
  discountImage?: ImageThumbnail;
};

function PiggyBankBlock({
  discountButtonLabel,
  discountButtonUrl,
  discountImage,
  discountTitle,
  discountBlockquote,
}: Props) {
  return (
    <Block>
      <Left>
        <SectionPreview
          title={discountTitle}
          buttonLabel={discountButtonLabel}
          buttonUrl={discountButtonUrl}
        />
      </Left>
      <Right>
        <RightInner>
          <RightBackground>
            <Picture
              mobileSmall={{
                src: phonePreviewUrl,
                src2x: phonePreviewUrl2x,
                webp: phonePreviewUrlWebp,
                webp2x: phonePreviewUrlWebp2x,
              }}
            />
          </RightBackground>
          <Quote>
            <QuoteInner>
              <QuoteImage>
                <Picture
                  mobileSmall={{
                    src: quoteUrl,
                    src2x: quoteUrl2x,
                    webp: quoteUrlWebp,
                    webp2x: quoteUrlWebp2x,
                  }}
                />
              </QuoteImage>
              <QuoteContent
                dangerouslySetInnerHTML={{
                  __html: discountBlockquote ?? '',
                }}
              />
            </QuoteInner>
          </Quote>
        </RightInner>
        <PiggyBank>
          {discountImage && (
            <PiggyBankImage>
              <Picture
                mobileSmall={convertThumbnailToPictureImage(discountImage)}
              />
            </PiggyBankImage>
          )}
        </PiggyBank>
      </Right>
    </Block>
  );
}

const Block = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  ${media.mobile(css`
    display: block;
  `)}
`;

const Left = styled.div`
  margin-top: 120px;
  max-width: 45%;

  ${media.mobile(css`
    margin-top: 50px;
    max-width: 100%;
  `)}
`;

const Right = styled.div`
  margin-right: 6px;
  position: relative;
  flex: 0 0 442px;

  ${media.mobile(css`
    margin: 0;
  `)}
`;

const RightInner = styled.div`
  position: relative;
  padding-top: 100px;
  margin: 0 -7px;

  ${media.mobile(css`
    padding-top: 80px;
    margin: 0 -34px;
  `)}
`;
const RightBackground = styled.div`
  position: relative;
  left: -20px;

  ${media.mobile(css`
    left: 0;
  `)}
`;

const PiggyBank = styled.div`
  position: absolute;
  content: '';
  bottom: 28px;
  left: 6px;
  width: 340px;

  ${media.mobile(css`
    bottom: 16px;
    left: -19px;
    width: 264px;
  `)}
`;

const PiggyBankImage = styled.div`
  ${media.laptop(css`
    img {
      max-width: 120%;
    }
  `)}
`;

const Quote = styled.div`
  position: absolute;
  content: '';
  top: 30px;
  right: -10px;

  ${media.mobile(css`
    right: 30px;
  `)}
`;
const QuoteInner = styled.div`
  position: relative;
  padding: 35px 20px 32px;
  min-width: 210px;
  height: 143px;

  ${media.mobile(css`
    min-width: 160px;
    height: 112px;
    padding: 20px 28px;
  `)}
`;
const QuoteImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;
const QuoteContent = styled.div`
  position: absolute;
  top: 30%;
  left: 40px;
  width: 100%;
  z-index: 2;
  max-width: 120px;
  text-align: center;

  display: flex;
  justify-content: center;

  font-size: 16px;
  line-height: 19px;
  color: ${colors.white};
  max-height: 100%;

  ${media.mobile(css`
    top: 24%;
    max-width: 90px;
    font-size: 14px;
    line-height: 18px;
  `)}
`;

export default PiggyBankBlock;
