import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { isBrowser, useMedia } from '@tager/web-core';

import { ImageThumbnail, StringField } from '@/typings/model';
import { ReactComponent as PrevIcon } from '@/assets/svg/slider-prev.svg';
import { ReactComponent as NextIcon } from '@/assets/svg/slider-next.svg';
import { breakpoints, colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

import ProductsCard from './ProductsCard';

interface Props {
  products: Array<{
    design: StringField;
    image: ImageThumbnail;
    link: StringField;
    title: StringField;
  }>;
}

function ProductsSlider({ products }: Props) {
  const [count, setCount] = useState<number>(0);
  const [nextHidden, setNextHidden] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const isTablet = useMedia(`(max-width: ${breakpoints.laptop - 1}px)`);
  const isMobile = useMedia(`(max-width: ${breakpoints.tabletSmall - 1}px)`);

  function nextSlide() {
    setCount(count + 1);
  }

  function prevSlide() {
    setCount(count - 1);
  }

  useEffect(() => {
    if (!containerRef.current || !wrapperRef.current) return;
    const innerWidth = isBrowser() && window.innerWidth;
    if (
      Math.floor(containerRef.current?.scrollWidth) -
        Math.floor(innerWidth || wrapperRef?.current?.clientWidth + 38) -
        10 >
      (Math.floor(innerWidth || wrapperRef?.current?.clientWidth + 38) /
        (isMobile ? 100 / 70 : isTablet ? 3 : 2)) *
        count
    ) {
      setNextHidden(false);
    } else {
      setNextHidden(true);
    }
  }, [count, isTablet, isMobile]);

  useEffect(() => {
    if (!containerRef.current || !wrapperRef.current) return;

    containerRef.current.style.transform = isMobile
      ? nextHidden
        ? `translateX(calc(-70vw * ${count} + 30vw))`
        : `translateX(calc(-70vw * ${count}))`
      : isTablet
      ? `translateX(calc(-30vw * ${count}))`
      : `translateX(calc(-50vw * ${count}))`;
    if (
      Math.floor(wrapperRef.current.getBoundingClientRect().width) ===
      Math.floor(containerRef.current?.scrollWidth)
    ) {
      setNextHidden(true);
    }
  }, [count, isTablet, isMobile, nextHidden]);

  return (
    <Component ref={wrapperRef}>
      <Inner ref={containerRef} nextBtnHidden={nextHidden}>
        {products.map((product, index) => {
          if (!product.image) return false;
          return <ProductsCard product={product} key={index} />;
        })}
      </Inner>
      <ButtonPrev onClick={prevSlide} isHidden={count === 0}>
        <PrevIcon />
      </ButtonPrev>
      <ButtonNext onClick={nextSlide} isHidden={nextHidden}>
        <NextIcon />
      </ButtonNext>
    </Component>
  );
}

const Component = styled.div`
  overflow: hidden;
  position: relative;

  ${media.laptop(
    css`
      margin-top: 36px;
    `
  )}
`;

const Inner = styled.div<{ nextBtnHidden?: boolean }>`
  display: grid;
  grid-template-rows: repeat(2, 25vw);
  grid-auto-columns: 25vw;
  grid-auto-flow: column dense;
  transition: all 0.5s linear;

  ${media.tablet(css`
    grid-template-rows: repeat(2, 32.4vw);
    grid-auto-columns: 33.3vw;
  `)}

  ${media.mobile(css`
    grid-template-rows: repeat(2, 69.45vw);
    grid-auto-columns: 70vw;
  `)}

  ${(props) =>
    props.nextBtnHidden
      ? css`
          ${media.mobile(css`
            transition: all 0.3s linear;
          `)}
        `
      : ''}
`;

const ButtonPrev = styled.div<{ isHidden?: boolean }>`
  position: absolute;
  top: calc(50% - 42px);
  left: 30px;
  z-index: 2;
  cursor: pointer;
  will-change: transform;
  transition: all 0.3s linear;

  width: 84px;
  height: 84px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 100%;
  background: ${colors.white};

  svg {
    position: relative;
    left: -2px;
    width: 36px;
    height: 36px;
    pointer-events: none;
    will-change: transform;
  }

  ${media.laptop(css`
    bottom: 20px;
    left: 19px;

    width: 56px;
    height: 56px;

    svg {
      left: -1px;
      width: 24px;
      height: 24px;
    }
  `)}

  ${media.tablet(css`
    top: calc(50% - 20px);
    width: 40px;
    height: 40px;

    svg {
      width: 18px;
      height: 18px;
    }
  `)}

  ${media.laptopUp(css`
    &:hover {
      transform: scale(1.1);
    }
  `)}

  ${(props) =>
    props.isHidden
      ? css`
          opacity: 0;
          pointer-events: none;
        `
      : ''}
`;

const ButtonNext = styled.div<{ isHidden?: boolean }>`
  position: absolute;
  top: calc(50% - 42px);
  right: 30px;
  z-index: 2;
  cursor: pointer;
  will-change: transform;
  transition: all 0.3s linear;

  width: 84px;
  height: 84px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 100%;
  background: ${colors.white};

  svg {
    position: relative;
    right: -2px;
    width: 36px;
    height: 36px;
    pointer-events: none;
    will-change: transform;
  }

  ${media.laptop(css`
    bottom: 20px;
    right: 19px;

    width: 56px;
    height: 56px;

    svg {
      right: -1px;
      width: 24px;
      height: 24px;
    }
  `)}

  ${media.tablet(css`
    top: calc(50% - 20px);
    width: 40px;
    height: 40px;

    svg {
      width: 18px;
      height: 18px;
    }
  `)}

  ${media.laptopUp(css`
    &:hover {
      transform: scale(1.1);
    }
  `)}

  ${(props) =>
    props.isHidden
      ? css`
          opacity: 0;
          pointer-events: none;
        `
      : ''}
`;

export default ProductsSlider;
