import React from 'react';
import styled, { css } from 'styled-components';

import QuestionCard from '@/modules/Home/components/Questions/components/QuestionCard';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { HypermarketsPageType, StringField } from '@/typings/model';

interface Props {
  page: HypermarketsPageType;
}

function Questions({ page }: Props) {
  let { cards, bottomText } = page.templateFields;

  bottomText = null;

  return (
    <Components>
      <QuestionsContainer>
        <Inner>
          {cards.map((cardsItem) => {
            return (
              <Cell
                backgroundCard={cardsItem.backgroundCard}
                key={cardsItem.title}
              >
                <QuestionCard
                  title={cardsItem.title}
                  text={cardsItem.description}
                  buttonLabel={cardsItem.button.label}
                  buttonUrl={cardsItem.button.link ?? '#'}
                  colorCard={cardsItem.colorText}
                  isHypermarket={true}
                />
              </Cell>
            );
          })}
        </Inner>
        {bottomText && <SeoDescription>{bottomText}</SeoDescription>}
      </QuestionsContainer>
    </Components>
  );
}

const Components = styled.div`
  background: ${colors.black};
  position: relative;
`;

const QuestionsContainer = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  min-height: 842px;
  clip-path: polygon(0% 0%, 100% 0, 100% 100%, 0% 100%);

  ${media.laptop(
    css`
      min-height: 638px;
    `
  )}

  ${media.tablet(
    css`
      min-height: 540px;
    `
  )}
`;

const Inner = styled.div`
  display: flex;
  align-items: stretch;

  ${media.mobile(css`
    display: block;
  `)}
`;

const Cell = styled.div<{ backgroundCard: StringField }>`
  flex: 1 1 33.33%;
  max-width: 33.33%;
  position: relative;
  // padding-bottom: 300px;

  ${media.tablet(css`
    padding-bottom: 200px;
  `)}

  ${media.mobile(css`
    max-width: 100%;
    padding-bottom: 0;
  `)}

  &:before {
    position: absolute;
    content: '';
    width: 100%;
    top: -500px;
    bottom: -500px;
    background: ${(props) =>
      props.backgroundCard ? `${props.backgroundCard}` : `${colors.black}`};

    ${media.mobile(css`
      display: none;
    `)}
  }

  ${(props) =>
    props.backgroundCard
      ? css`
          ${media.mobile(css`
            background: ${props.backgroundCard};
          `)};
        `
      : css`
          ${media.mobile(css`
            background: ${colors.black};
          `)};
        `}

  ${media.mobile(css`
    &:last-child {
      padding-bottom: 248px;
    }
  `)}
`;

const SeoDescription = styled.p`
  position: absolute;

  bottom: 182px;
  left: 50%;
  transform: translateX(-50%);

  max-width: 1680px;
  width: 100%;
  padding: 0 40px;
  z-index: 2;

  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${colors.white50};

  ${media.laptop(css`
    bottom: 200px;
    font-size: 10px;
    line-height: 12px;
  `)}

  ${media.tablet(css`
    padding: 0 20px;
    bottom: 70px;
    font-size: 8px;
    line-height: 10px;
  `)}
  
  ${media.mobile(css`
    bottom: 120px;
    font-size: 6px;
    line-height: 8px;
    color: ${colors.black50};
  `)}
`;

export default Questions;
