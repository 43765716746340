import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { ReactComponent as ArrowRight } from '@/assets/svg/arrow-right.svg';

interface Props {
  paginationList: Array<number>;
  ActiveItem: number;
  setActiveItem: (value: number) => void;
  onClick: (page: number) => void;
}

function Pagination({
  paginationList,
  ActiveItem,
  setActiveItem,
  onClick,
}: Props) {
  const [isStartEllipsis, setStartEllipsis] = useState<boolean>(false);
  const [isEndEllipsis, setEndEllipsis] = useState<boolean>(false);
  const [finalList, setFinalList] = useState<Array<number>>();

  function changeActiveItem(index: number) {
    setActiveItem(index);
    onClick(index);
  }

  function nextItem() {
    setActiveItem(ActiveItem + 1);
    onClick(ActiveItem + 1);
  }

  function prevItem() {
    setActiveItem(ActiveItem - 1);
    onClick(ActiveItem - 1);
  }

  function renderList(paginationList: Array<number>) {
    if (paginationList.length < 7) {
      setStartEllipsis(false);
      setEndEllipsis(false);
      return paginationList.slice(1, paginationList.length - 1);
    } else if (ActiveItem < 5) {
      setStartEllipsis(false);
      setEndEllipsis(true);
      return paginationList.slice(1, 5);
    } else if (ActiveItem > paginationList.length - 4) {
      setStartEllipsis(true);
      setEndEllipsis(false);
      return paginationList.slice(
        paginationList.length - 5,
        paginationList.length - 1
      );
    } else {
      setStartEllipsis(true);
      setEndEllipsis(true);
      return paginationList.slice(ActiveItem - 3, ActiveItem + 2);
    }
  }

  useEffect(() => {
    setFinalList(renderList(paginationList));
  }, [ActiveItem, paginationList]);

  return (
    <Wrapper>
      <PrevBtn isDisabled={ActiveItem === 1} onClick={() => prevItem()}>
        <ArrowRight />
        <BtnText>Назад</BtnText>
      </PrevBtn>
      <List>
        <Item isActive={ActiveItem === 1} onClick={() => changeActiveItem(1)}>
          1
        </Item>
        <Item
          isMore={true}
          onClick={() => changeActiveItem(1)}
          isDisplay={!isStartEllipsis}
        >
          ...
        </Item>
        {finalList?.map((paginationItem) => {
          return (
            <Item
              key={paginationItem}
              isActive={ActiveItem === paginationItem}
              onClick={() => changeActiveItem(paginationItem)}
            >
              {paginationItem}
            </Item>
          );
        })}
        <Item
          isMore={true}
          onClick={() => changeActiveItem(paginationList.length)}
          isDisplay={!isEndEllipsis}
        >
          ...
        </Item>
        <Item
          isActive={ActiveItem === paginationList.length}
          onClick={() => changeActiveItem(paginationList.length)}
        >
          {paginationList.length}
        </Item>
      </List>
      <NextBtn
        isDisabled={ActiveItem === paginationList.length}
        onClick={() => nextItem()}
      >
        <BtnText>Дальше</BtnText>
        <ArrowRight />
      </NextBtn>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const List = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 0 -3px;

  ${media.laptop(css`
    margin: 0 -2px;
  `)}

  ${media.mobile(css`
    margin: 0;
  `)}
`;

const Item = styled.div<{
  isActive?: boolean;
  isMore?: boolean;
  isDisplay?: boolean;
}>`
  margin: 0 3px;
  cursor: pointer;
  padding: 8px;
  width: 40px;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: ${colors.black};
  transition: all 0.3s linear;

  ${(props) =>
    props.isActive
      ? css`
          background: ${colors.orange};
          color: ${colors.white};
          pointer-events: none;
        `
      : ''}

  ${(props) =>
    props.isMore
      ? css`
          pointer-events: none;
        `
      : ''}
  
  ${(props) =>
    props.isDisplay
      ? css`
          display: none;
        `
      : ''}

  ${media.laptop(css`
    margin: 0 2px;
    font-size: 16px;
    line-height: 24px;
  `)}
  
  ${media.laptop(css`
    width: 36px;
    font-size: 16px;
    line-height: 22px;
    padding: 6px;
  `)}
  
  ${media.mobile(css`
    width: 32px;
    font-size: 12px;
    line-height: 20px;
    padding: 8px;
  `)}
  
    ${media.laptopUp(css`
    &:hover {
      background: ${colors.yellow100};
    }
  `)}
`;

const PrevBtn = styled.div<{ isDisabled: boolean }>`
  position: relative;
  margin-right: 30px;
  padding: 12px 16px 12px 14px;
  cursor: pointer;
  min-width: 133px;

  font-weight: 500;
  font-size: 20px;
  line-height: 26px;

  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${colors.yellow100};
    transform: skew(-1deg, 3deg);
    transition: all 0.3s linear;
  }

  svg {
    position: relative;
    margin-right: 10px;
    width: 18px;
    height: 15px;
    transform: scale(-1, -1);

    ${media.laptop(css`
      margin-right: 8px;
      width: 18px;
      height: 15px;
    `)}

    ${media.laptop(css`
      width: 16px;
      height: 13px;
    `)}
    
    ${media.tablet(css`
      margin-right: 7px;
      width: 19px;
      height: 15px;
    `)}
    
    ${media.mobile(css`
      margin-right: 9px;
      width: 10px;
      height: 12px;
    `)}
  }

  ${media.laptopUp(css`
    &:hover {
      &:before {
        background: ${colors.yellowHover100};
      }
    }
  `)}

  ${media.laptop(css`
    min-width: 112px;
    padding: 12px 14px 12px 12px;
    margin-left: 24px;
    font-size: 16px;
    line-height: 24px;

    &:before {
      transform: skew(-1deg, 4deg);
    }
  `)}

  ${media.tablet(css`
    min-width: 108px;
    padding: 12px 10px 8px 12px;
    margin-left: 18px;
    line-height: 22px;

    &:before {
      transform: skew(0deg, 4deg);
    }
  `)}
  
  ${media.mobile(css`
    display: none;
    min-width: 80px;
    padding: 13px 10px 13px 8px;
    margin-right: 6px;
    font-size: 12px;
    line-height: 20px;
  `)}

  ${(props) =>
    props.isDisabled
      ? css`
          opacity: 0;
          visibility: hidden;
          pointer-events: none;
        `
      : ''}
`;

const NextBtn = styled.div<{ isDisabled: boolean }>`
  position: relative;
  margin-left: 30px;
  padding: 12px 16px 12px 14px;
  cursor: pointer;

  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  transition: all 0.3s linear;

  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${colors.yellow100};
    transform: skew(1deg, -3deg);
    transition: all 0.3s linear;
  }

  svg {
    position: relative;
    margin-left: 10px;
    width: 18px;
    height: 15px;

    ${media.laptop(css`
      margin-left: 8px;
      width: 18px;
      height: 15px;
    `)}

    ${media.tablet(css`
      margin-left: 7px;
      width: 19px;
      height: 15px;
    `)}
    
    ${media.mobile(css`
      margin-left: 9px;
      width: 10px;
      height: 12px;
    `)}
  }

  ${media.laptopUp(css`
    &:hover {
      &:before {
        background: ${colors.yellowHover100};
      }
    }
  `)}

  ${media.laptop(css`
    padding: 12px 14px 12px 12px;
    margin-left: 24px;
    font-size: 16px;
    line-height: 24px;

    &:before {
      transform: skew(1deg, -4deg);
    }
  `)}

  ${media.tablet(css`
    padding: 12px 12px 8px 10px;
    margin-left: 18px;
    line-height: 22px;

    &:before {
      transform: skew(0deg, -4deg);
    }
  `)}
  
  ${media.mobile(css`
    padding: 13px 10px 13px 8px;
    margin-left: 6px;
    font-size: 12px;
    line-height: 20px;
  `)}

  ${(props) =>
    props.isDisabled
      ? css`
          opacity: 0;
          visibility: hidden;
          pointer-events: none;
        `
      : ''}
`;

const BtnText = styled.span`
  position: relative;
`;

export default Pagination;
