import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { ImageThumbnail, StringField } from '@/typings/model';
import ShopPreview from '@/components/ShopPreview';

interface Props {
  shops: Array<{
    image: ImageThumbnail;
    link: StringField;
    title: StringField;
  }>;
}

function ShopsSection({ shops }: Props) {
  return (
    <Component>
      <List>
        {shops?.map((shop) => {
          return (
            <Cell key={shop.title}>
              <ShopPreview
                link={shop.link ?? ''}
                label={shop.title ?? ''}
                image={shop.image}
              />
            </Cell>
          );
        })}
      </List>
    </Component>
  );
}

const Component = styled.div``;

const List = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${media.mobile(css`
    display: block;
  `)}
`;

const Cell = styled.div`
  flex: 1 1 50%;
  max-width: 50%;

  ${media.mobile(css`
    max-width: 100%;
  `)}
`;

export default ShopsSection;
