import React from 'react';
import styled, { css } from 'styled-components';

import Products from '@/modules/Home/components/Products';
import { ButtonLink } from '@/components/Button';
import { media } from '@/utils/mixin';
import { HypermarketsPageType } from '@/typings/model';

interface Props {
  page: HypermarketsPageType;
}

function ProductsSection({ page }: Props) {
  const { productsButton } = page.templateFields;

  return (
    <Components>
      <Top>
        <Products isWhite={true} />
      </Top>
      <Bottom>
        <BtnWrapper>
          <MoreBtn
            href={productsButton?.link ?? ''}
            variant="yellow"
            target={productsButton?.isNewTab ? '_blank' : '_self'}
          >
            {productsButton?.label}
          </MoreBtn>
        </BtnWrapper>
      </Bottom>
    </Components>
  );
}

const Components = styled.div`
  margin: -20px 0 80px;

  ${media.laptop(css`
    margin: 40px 0 80px;
  `)}

  ${media.tablet(css`
    margin: 30px 0 50px;
  `)}
  
  ${media.mobile(css`
    margin: 30px 0;
  `)}
`;

const Top = styled.div`
  margin-bottom: 16px;

  ${media.laptop(css`
    margin-bottom: 60px;
  `)}

  ${media.tablet(css`
    margin-bottom: 30px;
  `)}
  
  ${media.mobile(css`
    margin-bottom: 20px;
  `)}
`;

const Bottom = styled.div`
  max-width: 1680px;
  margin: 0 auto;
  padding: 0 40px;
  z-index: 10;
  position: relative;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

const BtnWrapper = styled.div``;

const MoreBtn = styled(ButtonLink)`
  display: block;
  width: 100%;
  padding: 21px 0;
  border-radius: 2px;
  font-weight: bold;
  font-size: 36px;
  line-height: 48px;
  transition: all 0.3s linear;

  ${media.laptop(css`
    padding: 24px 0;
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    padding: 17px 0;
    font-size: 20px;
    line-height: 26px;
  `)}
  
  ${media.mobile(css`
    font-size: 18px;
    line-height: 23px;
  `)}
`;

export default ProductsSection;
