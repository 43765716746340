import React from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

import useCurrentPage from '@/hooks/useCurrentPage';
import { AboutPageType } from '@/typings/model';
import { colors } from '@/constants/theme';
import Subscription from '@/components/Subscription';
import Breadcrumbs from '@/components/Breadcrumbs';
import { useLayout } from '@/components/Layout/Layout.hooks';
import { BreadCrumbs } from '@/typings/common';
import { setBreadCrumbsList } from '@/utils/common';

import Hero from './components/Hero';
import History from './components/History';
import Professionalism from './components/Professionalism';
import Today from './components/Today';
import Vacancies from './components/Vacancies';

gsap.registerPlugin(ScrollTrigger);

function About() {
  const page = useCurrentPage<AboutPageType>();
  const { setThemePage } = useLayout();

  const BREADCRUMB_LIST: Array<BreadCrumbs> = setBreadCrumbsList(
    {
      title: page?.title ?? '',
      path: page?.path ?? '',
    },
    page?.parent
  );

  setThemePage('');

  return (
    <Wrapper>
      <Breadcrumbs breadcrumbs={BREADCRUMB_LIST} isWhite={true} />
      <Hero
        title={page?.templateFields.title ?? ''}
        text={page?.templateFields.text ?? ''}
      />
      <History
        historyItems={page?.templateFields.historyItems}
        historyTitle={page?.templateFields.historyTitle ?? ''}
      />
      <Professionalism
        text={page?.templateFields.textInner ?? ''}
        image1={page?.templateFields.image1}
        image2={page?.templateFields.image2}
        backgroundImage={page?.templateFields.backgroundImage}
        tickerTextFirst={page?.templateFields.tickerTextFirst ?? ''}
        tickerLinkFirst={page?.templateFields.tickerLinkFirst ?? ''}
        tickerBackgroundFirst={page?.templateFields.tickerBackgroundFirst ?? ''}
        tickerColorFirst={page?.templateFields.tickerColorFirst ?? ''}
        tickerTextSecond={page?.templateFields.tickerTextSecond ?? ''}
        tickerLinkSecond={page?.templateFields.tickerLinkSecond ?? ''}
        tickerBackgroundSecond={
          page?.templateFields.tickerBackgroundSecond ?? ''
        }
        tickerColorSecond={page?.templateFields.tickerColorSecond ?? ''}
      />
      <Today
        todayItems={page?.templateFields.todayItems}
        todayTitle={page?.templateFields.todayTitle ?? ''}
      />
      <Vacancies
        careersLink={page?.templateFields.careersLink ?? ''}
        careersTitle={page?.templateFields.careersTitle ?? ''}
      />
      <Subscription />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background: ${colors.black};
  overflow: hidden;
`;

export default About;
