import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { StringField } from '@/typings/model';
import Cross from '@/components/Sticker/Cross';
import Star from '@/components/Sticker/Star';

import QuestionCard from './components/QuestionCard';

type Props = {
  questionList?: Array<{
    title: StringField;
    text: StringField;
    buttonLabel: StringField;
    buttonUrl: StringField;
  }>;
  bottomText: StringField;
};

function Questions({ questionList, bottomText }: Props) {
  const starRef = useRef<HTMLDivElement | null>(null);
  const starTween = useRef<gsap.core.Tween>();

  useEffect(() => {
    if (!starRef.current) {
      return;
    }
    const delayedCall = gsap.delayedCall(0, () => {
      ScrollTrigger.matchMedia({
        '(min-width: 1680px)': () => {
          starTween.current = gsap.to(starRef.current, {
            y: -70,
            rotate: 0,
            paused: true,
          });
        },
        '(max-width: 1680px)': () => {
          starTween.current = gsap.to(starRef.current, {
            y: -50,
            rotate: 0,
            paused: true,
          });
        },
      });
    });

    return () => {
      starTween.current?.kill();
      delayedCall.kill();
    };
  }, []);

  function animateIn() {
    starTween.current?.play();
  }

  function animateOut() {
    starTween.current?.reverse();
  }

  bottomText = null;

  return (
    <Components>
      <QuestionsContainer>
        <Inner>
          <Cell backgroundCard={'#F8C845'}>
            {questionList ? (
              <QuestionCard
                title={questionList[0].title}
                text={questionList[0].text}
                buttonLabel={questionList[0].buttonLabel}
                buttonUrl={questionList[0].buttonUrl}
                colorCard={'#020202'}
              />
            ) : null}
          </Cell>
          <Cell backgroundCard={'#020202'}>
            {questionList ? (
              <QuestionCard
                title={questionList[1].title}
                text={questionList[1].text}
                buttonLabel={questionList[1].buttonLabel}
                buttonUrl={questionList[1].buttonUrl}
                colorCard={'#FFFFFF'}
              />
            ) : null}
          </Cell>
        </Inner>
        {bottomText && <SeoDescription>{bottomText}</SeoDescription>}
      </QuestionsContainer>
    </Components>
  );
}

const Components = styled.div`
  background: ${colors.black};
  position: relative;
`;

const QuestionsContainer = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  min-height: 1048px;
  clip-path: polygon(0% 0%, 100% 0, 100% 100%, 0% 100%);

  ${media.laptop(css`
    min-height: 778px;
  `)}

  ${media.tablet(css`
    min-height: 654px;
  `)}
`;

const Inner = styled.div`
  display: flex;
  align-items: stretch;

  ${media.mobile(css`
    display: block;
  `)}
`;

const Cell = styled.div<{ backgroundCard: StringField }>`
  flex: 1 1 1px;
  position: relative;

  &:before {
    position: absolute;
    content: '';
    width: 100%;
    top: -500px;
    bottom: -500px;
    background: ${(props) =>
      props.backgroundCard ? `${props.backgroundCard}` : `${colors.black}`};

    ${media.mobile(css`
      display: none;
    `)}
  }

  ${(props) =>
    props.backgroundCard
      ? css`
          ${media.mobile(css`
            background: ${props.backgroundCard};
          `)};
        `
      : css`
          ${media.mobile(css`
            background: ${colors.black};
          `)};
        `}
`;

const SeoDescription = styled.p`
  position: absolute;

  bottom: 182px;
  left: 50%;
  transform: translateX(-50%);

  max-width: 1680px;
  width: 100%;
  padding: 0 40px;
  z-index: 2;

  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${colors.white50};

  ${media.laptop(css`
    bottom: 200px;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
  `)}

  ${media.tablet(css`
    bottom: 97px;
    font-size: 8px;
    line-height: 10px;
    padding: 0 20px;
  `)}
  
  ${media.mobile(css`
    bottom: 117px;
    font-size: 6px;
    line-height: 8px;
  `)}
`;

const CrossWrapper = styled.div`
  position: absolute;
  top: -192px;
  right: -118px;
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.6));
  height: 314px;
  width: 386px;

  ${media.laptop(css`
    top: -136px;
    right: -70px;
    height: 210px;
    width: 258px;
  `)}

  ${media.tablet(css`
    display: none;
  `)}
`;

const StarWrapper = styled.div`
  position: absolute;
  transform: rotate(-18deg);
  bottom: -104px;
  left: 102px;
  z-index: 2;
  will-change: transform;

  width: 316px;
  height: 316px;

  ${media.laptop(css`
    bottom: -2px;
    left: 41px;

    width: 240px;
    height: 240px;
  `)};

  ${media.tablet(css`
    bottom: -100px;
  `)};

  ${media.mobile(css`
    bottom: -79px;
    left: -4px;

    width: 164px;
    height: 164px;
  `)};
`;

export default Questions;
