import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import InformationLabel from '@/components/InformationLabel';
import { media } from '@/utils/mixin';
import { StringField } from '@/typings/model';

import InformationRow from './components/InformationRow';

interface Props {
  address: StringField;
  // mapIcon?: string | null
  workingTime: StringField;
  phone?: StringField;
  brands?: Array<{
    brandTitle: StringField;
  }>;
  contacts?: Array<{
    contactsItem: StringField;
  }>;
  categories?: Array<{ rangeLabel: StringField }>;
}

function InformationSection({
  workingTime,
  brands,
  address,
  phone,
  categories,
  contacts,
}: Props) {
  return (
    <Component>
      <Inner>
        <Top>
          <Left>
            <Address>{address}</Address>
          </Left>
          <Right>
            {workingTime && (
              <InformationRow label={'Время работы'}>
                <InformationRowItem>{workingTime}</InformationRowItem>
              </InformationRow>
            )}
            {phone && (
              <InformationRow label={'Телефон'}>
                <InformationRowItem>{phone}</InformationRowItem>
              </InformationRow>
            )}
            {brands && brands?.length > 0 && (
              <InformationRow label={'Бренды'}>
                {brands.map((brand) => {
                  return (
                    <InformationRowItem key={brand.brandTitle}>
                      {brand.brandTitle}
                    </InformationRowItem>
                  );
                })}
              </InformationRow>
            )}
            {contacts && contacts?.length > 0 && (
              <InformationRow label={'Контакты'} isBlock={true}>
                {contacts.map((contact) => {
                  return (
                    <InformationRowItem key={contact.contactsItem}>
                      {contact.contactsItem}
                    </InformationRowItem>
                  );
                })}
              </InformationRow>
            )}
          </Right>
        </Top>
        {categories && categories.length > 0 && (
          <Bottom>
            <BottomList>
              {categories?.map((category) => {
                return (
                  <BottomCell key={category.rangeLabel}>
                    <InformationLabel
                      label={category.rangeLabel}
                      isMedium={true}
                    />
                  </BottomCell>
                );
              })}
            </BottomList>
          </Bottom>
        )}
      </Inner>
    </Component>
  );
}

const Component = styled.div``;
const Inner = styled.div`
  max-width: 1680px;
  margin: 0 auto;
  padding: 0 40px;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  ${media.laptop(css`
    margin-bottom: 31px;
  `)}

  ${media.tablet(css`
    margin-bottom: 50px;
  `)}

  ${media.mobile(css`
    display: block;
  `)}
`;

const Bottom = styled.div``;

const Left = styled.div`
  flex: 1 1 917px;
  max-width: 917px;

  display: flex;
  align-items: center;

  ${media.laptop(css`
    flex: 1 1 690px;
    max-width: 690px;
  `)}

  ${media.tablet(css`
    flex: 1 1 442px;
    max-width: 442px;
    margin-right: 30px;
  `)}
  
  ${media.mobile(css`
    max-width: 100%;
    margin: 0 0 20px;
  `)}
`;

const Right = styled.div`
  flex: 1 1 512px;
  max-width: 512px;

  ${media.laptop(css`
    flex: 1 1 384px;
    max-width: 384px;
  `)}

  ${media.tablet(css`
    flex: 1 1 250px;
    max-width: 250px;
  `)}
  
  ${media.mobile(css`
    max-width: 100%;
  `)}
`;

const Address = styled.address`
  font-style: normal;
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.black};

  ${media.laptop(css`
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    font-size: 48px;
    line-height: 56px;
  `)}
  
  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
  `)}
`;

const BottomList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -12px;

  ${media.laptop(css`
    margin: -8px;
  `)}
`;

const BottomCell = styled.div`
  padding: 12px;

  ${media.laptop(css`
    padding: 8px;
  `)}
`;

const InformationRowItem = styled.div`
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  color: ${colors.black};
  padding: 10px;

  ${media.laptop(css`
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    font-size: 20px;
    line-height: 24px;
  `)}
  
  ${media.mobile(css`
    font-size: 16px;
    line-height: 20px;
  `)}
`;

export default InformationSection;
