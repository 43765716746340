import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { ImageThumbnail } from '@/typings/model';

import GalleryItem from './components/GalleryItem';

interface Props {
  gallery: Array<ImageThumbnail>;
}

function GalleryBlock({ gallery }: Props) {
  return (
    <Component>
      <List>
        {gallery?.slice(0, 3).map((galleryItem, index) => {
          return (
            <Cell key={index}>
              <GalleryItem galleryItem={galleryItem} />
            </Cell>
          );
        })}
      </List>
    </Component>
  );
}

const Component = styled.div`
  margin: 70px auto 0;

  ${media.laptop(css`
    margin-top: 24px;
  `)}

  ${media.tablet(css`
    margin-top: 40px;
  `)}
  
  ${media.mobile(css`
    margin-top: 76px;
  `)}
`;

const List = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 -16px;

  ${media.laptop(css`
    margin: 0 -12px;
  `)}

  ${media.mobile(css`
    margin: 0 -20px;
  `)}
`;

const Cell = styled.div`
  flex: 1 1 33.33%;
  max-width: 33.33%;
  padding: 0 16px;

  ${media.laptop(css`
    padding: 0 12px;
  `)}

  ${media.mobile(css`
    padding: 0;
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  `)}
`;

export default GalleryBlock;
