import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { MenuItemType, MenuRowType, StringField } from '@/typings/model';
import { colors } from '@/constants/theme';
import { ReactComponent as ArrowDown } from '@/assets/svg/arrow-down-small.svg';
import { media } from '@/utils/mixin';

import MenuPositionRow from '../MenuPositionRow';

interface Props {
  title: StringField;
  positions: Array<MenuItemType>;
  menu: Array<MenuRowType>;
}

function MenuAccordsItem({ title, positions, menu }: Props) {
  const [isActive, setActive] = useState(false);
  const [currentHeight, setHeight] = useState<number>(0);
  const ref = useRef<HTMLDivElement>(null);

  function onClick() {
    if (!isActive && ref.current) {
      const maxHeight = ref.current.scrollHeight ?? 0;
      setActive(true);
      setHeight(maxHeight);
    } else {
      setActive(false);
      setHeight(0);
    }
  }

  useEffect(() => {
    setActive(false);
    setHeight(0);

    if (menu.length === 1 && ref.current) {
      const maxHeight = ref.current.scrollHeight ?? 0;
      setActive(true);
      setHeight(maxHeight);
    }
  }, [menu]);

  return (
    <Wrapper isActive={isActive}>
      <Top onClick={onClick}>
        <Title>{title}</Title>
        <Icon>
          <ArrowDown fill={'transparent'} />
        </Icon>
      </Top>
      <Content ref={ref} currentHeight={currentHeight}>
        <ContentInner>
          {positions?.map((positionsItem, index) => {
            if (!positionsItem.name) return false;
            return <MenuPositionRow key={index} menuItem={positionsItem} />;
          })}
        </ContentInner>
      </Content>
    </Wrapper>
  );
}

const Top = styled.div`
  padding: 42px 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  ${media.laptop(css`
    padding: 32px 50px 32px 40px;
  `)}

  ${media.tablet(css`
    padding: 26px 40px 26px 30px;
  `)}
  
  ${media.mobile(css`
    padding: 24px 36px 24px 32px;
  `)}
`;

const Content = styled.div<{ currentHeight: number }>`
  max-height: ${(props) => props.currentHeight}px;
  overflow: hidden;
  transition: all 0.2s linear;
`;

const ContentInner = styled.div`
  padding: 0 60px 50px;

  ${media.laptop(css`
    padding: 0 40px 50px;
  `)}

  ${media.tablet(css`
    padding: 4px 30px 40px;
  `)}
  
  ${media.mobile(css`
    padding: 6px 20px 30px;
  `)}
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  color: ${colors.black};
  margin-right: 30px;
  transition: all 0.3s linear, font-size 0s linear;

  ${media.laptop(css`
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    font-size: 20px;
    line-height: 24px;
  `)}
  
  ${media.mobile(css`
    font-size: 16px;
    line-height: 20px;
  `)}
`;

const Icon = styled.div`
  color: ${colors.black};
  transition: all 0.3s linear;

  ${media.laptop(css`
    svg {
      width: 22px;
      height: 20px;
    }
  `)}

  ${media.mobile(css`
    svg {
      width: 14px;
      height: 10px;
    }
  `)}
`;

const Wrapper = styled.div<{ isActive: boolean }>`
  background: ${colors.gray50};
  border-radius: 50px;
  margin: 5px 0;
  transition: all 0.3s linear;

  ${media.laptop(css`
    margin: 4px 0;
    border-radius: 40px;
  `)}

  ${media.tablet(css`
    margin: 3px 0;
  `)}
  
  ${media.laptopUp(css`
    &:hover {
      background: ${colors.black};
      ${Title} {
        color: ${colors.white};
      }
      ${Icon} {
        color: ${colors.white};
      }
    }
  `)}



  ${(props) =>
    props.isActive
      ? css`
          background: ${colors.black};

          ${Title} {
            font-size: 54px;
            line-height: 64px;
            color: ${colors.white};

            ${media.laptop(css`
              font-size: 40px;
              line-height: 48px;
            `)}

            ${media.tablet(css`
              font-size: 28px;
              line-height: 34px;
            `)}
            
            ${media.mobile(css`
              font-size: 16px;
              line-height: 20px;
            `)}
          }

          ${Icon} {
            color: ${colors.white};
            transform: rotate(-180deg);
          }
        `
      : ''};
`;

export default MenuAccordsItem;
