import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import DropDownSelect from '@/components/DropDownSelect';
import { OptionType } from '@/components/DropDownSelect/DropDownSelect';
import { PromotionListTypeItem } from '@/typings/model';
import { useTypedSelector } from '@/store/store';
import { selectPromotionsSubCategoriesList } from '@/store/reducers/promotionSubcategories';

interface Props {
  setActiveSubCategory: (value: number) => void;
  selected: number;
}

function SubCategoriesSelect({ setActiveSubCategory, selected }: Props) {
  const promotionCategories = useTypedSelector(
    selectPromotionsSubCategoriesList
  );

  const [categoriesOptions, setCategoriesOptions] = useState<Array<OptionType>>(
    createCategoriesOptions(promotionCategories)
  );
  const [selectedCategories, setSelectedCategories] = useState<OptionType>();

  function createCategoriesOptions(list: Array<PromotionListTypeItem>) {
    const optionList = [{ label: 'Все подкатегории', value: '0' }];

    list.forEach((listItem) => {
      let newItem: OptionType = { label: '', value: '' };
      newItem.label = listItem.name ?? '';
      newItem.value = String(listItem.id) ?? '';
      optionList.push(newItem);
    });

    return optionList;
  }

  const sortCategoriesValue = useMemo<OptionType>(
    () =>
      categoriesOptions.find(
        (option) => option.value === selectedCategories?.value
      ) ?? categoriesOptions[0],
    [selectedCategories, categoriesOptions]
  );

  function handlesCategoriesChange(selectedOption: OptionType | null): void {
    if (selectedOption) {
      setSelectedCategories(selectedOption);

      if (selectedOption.value === '0') {
        setActiveSubCategory(0);
      } else {
        promotionCategories.forEach((category) => {
          if (category.id === Number(selectedOption.value)) {
            setActiveSubCategory(category.id);
          }
        });
      }
    }
  }

  useEffect(() => {
    setCategoriesOptions(createCategoriesOptions(promotionCategories));
  }, [promotionCategories]);

  useEffect(() => {
    categoriesOptions.forEach((category) => {
      if (category.value === String(selected)) {
        setSelectedCategories(category);
      }
    });
  }, [selected]);

  return (
    <Component>
      <DropDownSelect
        placeholder="Выбрать..."
        options={categoriesOptions}
        value={sortCategoriesValue}
        onChange={handlesCategoriesChange}
      />
    </Component>
  );
}

const Component = styled.div``;

export default SubCategoriesSelect;
