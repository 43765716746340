import starsYellowUrl from '@/assets/images/sticker/stars-yellow-big.png';
import starsYellowUrl2x from '@/assets/images/sticker/stars-yellow-big@2x.png';
import starsYellowUrlWebp from '@/assets/images/sticker/stars-yellow-big.webp';
import starsYellowUrlWebp2x from '@/assets/images/sticker/stars-yellow-big@2x.webp';
import starsOrangeUrl from '@/assets/images/sticker/stars-orange-big.png';
import starsOrangeUrl2x from '@/assets/images/sticker/stars-orange-big@2x.png';
import starsOrangeUrlWebp from '@/assets/images/sticker/stars-orange-big.webp';
import starsOrangeUrlWebp2x from '@/assets/images/sticker/stars-orange-big@2x.webp';
import starsImageUrl from '@/assets/images/sticker/stars-image-big.png';
import starsImageUrl2x from '@/assets/images/sticker/stars-image-big@2x.png';
import starsImageUrlWebp from '@/assets/images/sticker/stars-image-big.webp';
import starsImageUrlWebp2x from '@/assets/images/sticker/stars-image-big@2x.webp';
import starsYellowMiddleUrl from '@/assets/images/sticker/stars-yellow-middle.png';
import starsYellowMiddleUrl2x from '@/assets/images/sticker/stars-yellow-middle@2x.png';
import starsYellowMiddleUrlWebp from '@/assets/images/sticker/stars-yellow-middle.webp';
import starsYellowMiddleUrlWebp2x from '@/assets/images/sticker/stars-yellow-middle@2x.webp';

export type BackgroundType = {
  url: string;
  url_2x: string;
  url_webp: string;
  url_webp_2x: string;
};

export const STARS_YELLOW_BACKGROUND: BackgroundType = {
  url: starsYellowUrl,
  url_2x: starsYellowUrl2x,
  url_webp: starsYellowUrlWebp,
  url_webp_2x: starsYellowUrlWebp2x,
};

export const STARS_ORANGE_BACKGROUND: BackgroundType = {
  url: starsOrangeUrl,
  url_2x: starsOrangeUrl2x,
  url_webp: starsOrangeUrlWebp,
  url_webp_2x: starsOrangeUrlWebp2x,
};

export const STARS_IMAGE_BACKGROUND: BackgroundType = {
  url: starsImageUrl,
  url_2x: starsImageUrl2x,
  url_webp: starsImageUrlWebp,
  url_webp_2x: starsImageUrlWebp2x,
};

export const STARS_YELLOW_BACKGROUND_MIDDLE: BackgroundType = {
  url: starsYellowMiddleUrl,
  url_2x: starsYellowMiddleUrl2x,
  url_webp: starsYellowMiddleUrlWebp,
  url_webp_2x: starsYellowMiddleUrlWebp2x,
};
