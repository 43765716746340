import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { colors } from '@/constants/theme';
import { ImageThumbnail, StringField } from '@/typings/model';
import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';

type Props = {
  appreciateTitle: StringField;
  appreciateText: StringField;
  appreciateImage?: ImageThumbnail;
};

function Appreciate({
  appreciateImage,
  appreciateText,
  appreciateTitle,
}: Props) {
  return (
    <Component>
      <AppreciateContainer>
        <Inner>
          <Left>
            <Title
              dangerouslySetInnerHTML={{ __html: appreciateTitle ?? '' }}
            />
            <Text>{appreciateText}</Text>
          </Left>
          <Right>
            {appreciateImage ? (
              <Image>
                <ImageInner>
                  <Picture
                    mobileSmall={convertThumbnailToPictureImage(
                      appreciateImage
                    )}
                  />
                </ImageInner>
              </Image>
            ) : null}
          </Right>
        </Inner>
      </AppreciateContainer>
    </Component>
  );
}

const Component = styled.div`
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
`;

const AppreciateContainer = styled.div`
  max-width: 1680px;
  margin: 0 auto;
  padding: 0 40px;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

const Inner = styled.div`
  display: flex;
  padding: 256px 0 310px;

  ${media.laptop(css`
    padding: 92px 0 122px;
  `)}

  ${media.mobile(css`
    padding: 233px 0 20px;
  `)}
`;

const Left = styled.div`
  flex: 1 1 644px;
  max-width: 644px;

  ${media.laptop(css`
    flex: 1 1 500px;
    max-width: 500px;
  `)}

  ${media.tablet(css`
    flex: 1 1 49%;
    max-width: 49%;
  `)}
  
  ${media.mobile(css`
    flex: auto;
    max-width: none;
  `)}
`;
const Right = styled.div``;

const Title = styled.h2`
  display: block;
  margin: 0 0 54px;

  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.black};

  b {
    font-weight: 500;
    color: ${colors.orange};
  }

  ${media.laptop(css`
    margin-bottom: 40px;
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    margin-bottom: 30px;
    font-size: 48px;
    line-height: 56px;
  `)}
  
  ${media.mobile(css`
    margin-bottom: 20px;
    font-size: 34px;
    line-height: 42px;
  `)}
`;

const Text = styled.p`
  max-width: 580px;
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  color: ${colors.black};

  ${media.laptop(css`
    max-width: 440px;
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    font-size: 20px;
    line-height: 24px;
  `)}
  
  ${media.mobile(css`
    max-width: none;
    font-size: 16px;
    line-height: 20px;
  `)}
`;

const Image = styled.div`
  position: absolute;
  top: -200px;
  right: 0;
  max-width: 49.1vw;
  width: 100%;

  @media (min-width: 1921px) {
    max-width: 949px;
  }

  ${media.laptop(css`
    top: -80px;
  `)}

  ${media.tablet(css`
    max-width: 519px;
    right: -150px;
  `)}
  
  ${media.mobile(css`
    max-width: 354px;
    top: -287px;
    right: -90px;
  `)}
`;

const ImageInner = styled.div`
  position: relative;
  width: 100%;

  &:before {
    display: block;
    content: '';
    padding-bottom: 132.55%;
  }

  div {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  picture,
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export default Appreciate;
