import React from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

import useCurrentPage from '@/hooks/useCurrentPage';
import { CareersPageType } from '@/typings/model';
import CareersNotFound from '@/components/CareersNotFound';
import CareerAdvancement from '@/modules/Careers/components/CareerAdvancement';
import Breadcrumbs from '@/components/Breadcrumbs';
import { useLayout } from '@/components/Layout/Layout.hooks';
import { BreadCrumbs } from '@/typings/common';
import { setBreadCrumbsList } from '@/utils/common';

import Hero from './components/Hero';
import About from './components/About';
import SocialPackage from './components/SocialPackage/SocialPackage';
import Appreciate from './components/Appreciate';

gsap.registerPlugin(ScrollTrigger);

function Careers() {
  const page = useCurrentPage<CareersPageType>();
  const { setThemePage } = useLayout();

  setThemePage('');

  const BREADCRUMB_LIST: Array<BreadCrumbs> = setBreadCrumbsList(
    {
      title: page?.title ?? '',
      path: page?.path ?? '',
    },
    page?.parent
  );

  return (
    <Wrapper>
      <Breadcrumbs breadcrumbs={BREADCRUMB_LIST} />
      <Hero
        title={page?.templateFields.title ?? ''}
        text={page?.templateFields.text ?? ''}
        statisticsTitle={page?.templateFields.statisticsTitle ?? ''}
        cardText={page?.templateFields.cardText ?? ''}
      />
      <CareersNotFound isCareers={true} isTitles={false} />
      <About
        aboutTitle={page?.templateFields.aboutTitle ?? ''}
        aboutItems={page?.templateFields.aboutItems}
      />
      <CareerAdvancement
        progressTitle={page?.templateFields.progressTitle ?? ''}
        progressText={page?.templateFields.progressText ?? ''}
        progressImage={page?.templateFields.progressImage}
        tickerTextFirst={page?.templateFields.tickerTextFirst ?? ''}
        tickerLinkFirst={page?.templateFields.tickerLinkFirst ?? ''}
        tickerBackgroundFirst={page?.templateFields.tickerBackgroundFirst ?? ''}
        tickerColorFirst={page?.templateFields.tickerColorFirst ?? ''}
        tickerTextSecond={page?.templateFields.tickerTextSecond ?? ''}
        tickerLinkSecond={page?.templateFields.tickerLinkSecond ?? ''}
        tickerBackgroundSecond={
          page?.templateFields.tickerBackgroundSecond ?? ''
        }
        tickerColorSecond={page?.templateFields.tickerColorSecond ?? ''}
      />
      <SocialPackage
        socialTitle={page?.templateFields.socialTitle ?? ''}
        socialItems={page?.templateFields.socialItems}
      />
      <Appreciate
        appreciateTitle={page?.templateFields.appreciateTitle ?? ''}
        appreciateText={page?.templateFields.appreciateText ?? ''}
        appreciateImage={page?.templateFields.appreciateImage}
      />
      <CareersNotFound isCareers={true} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  overflow: hidden;
`;

export default Careers;
