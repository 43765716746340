import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import BannerCard from '@/modules/Home/components/BannerCard';
import SalesSlider from '@/modules/Home/components/Hero/components/SalesSlider';
import { ImageThumbnail, RestaurantsItem, StringField } from '@/typings/model';

import RestaurantsList from '../RestaurantsList';

interface Props {
  newsBlockLeftBlocks?: Array<{
    image: ImageThumbnail;
    title: StringField;
    link: StringField;
  }>;

  newsBlockRightTopImage: ImageThumbnail;
  newsBlockRightTopText: StringField;
  newsBlockRightTopLink: StringField;

  newsBlockRightBottomImage: ImageThumbnail;
  newsBlockRightBottomText: StringField;
  newsBlockRightBottomLink: StringField;

  restaurantsList: Array<RestaurantsItem>;
}

function BannerSection({
  newsBlockLeftBlocks,
  newsBlockRightBottomImage,
  newsBlockRightBottomLink,
  newsBlockRightBottomText,
  newsBlockRightTopImage,
  newsBlockRightTopLink,
  newsBlockRightTopText,
  restaurantsList,
}: Props) {
  function getListLength(
    list: Array<{
      image: ImageThumbnail;
      link: StringField;
      title: StringField;
    }>
  ) {
    let listLength = 0;
    list.forEach((listItem) => {
      if (!listItem.image && !listItem.title && !listItem.link) return;
      listLength += 1;
    });
    return listLength;
  }

  return (
    <Component>
      <Inner>
        {getListLength(newsBlockLeftBlocks ?? []) !== 0 && (
          <Left>
            {getListLength(newsBlockLeftBlocks ?? []) <= 1 ? (
              newsBlockLeftBlocks?.map((salesItem) => {
                return (
                  <BannerCard
                    image={salesItem.image}
                    text={salesItem.title}
                    link={salesItem.link}
                  />
                );
              })
            ) : (
              <SalesSlider salesList={newsBlockLeftBlocks} />
            )}
          </Left>
        )}
        {newsBlockRightTopImage || newsBlockRightBottomImage ? (
          <Center>
            {newsBlockRightTopImage && (
              <CenterCell>
                <CenterCellInner>
                  <BannerCard
                    image={newsBlockRightTopImage}
                    text={newsBlockRightTopText}
                    link={newsBlockRightTopLink}
                    isLink={true}
                  />
                </CenterCellInner>
              </CenterCell>
            )}
            {newsBlockRightBottomImage && (
              <CenterCell>
                <CenterCellInner>
                  <BannerCard
                    image={newsBlockRightBottomImage}
                    text={newsBlockRightBottomText}
                    link={newsBlockRightBottomLink}
                    isLink={true}
                  />
                </CenterCellInner>
              </CenterCell>
            )}
          </Center>
        ) : null}
        <Right>
          <RestaurantsList restaurantsList={restaurantsList} />
        </Right>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  max-width: 1920px;
  margin: 0 auto;
`;

const Inner = styled.div`
  display: flex;

  ${media.tablet(css`
    justify-content: flex-end;
  `)}

  ${media.mobile(css`
    display: block;
  `)}
`;

const Left = styled.div`
  flex: 1 1 536px;
  max-width: 536px;

  ${media.desktopOnly(css`
    flex: 1 1 20%;
    max-width: 20%;
  `)}

  ${media.laptop(css`
    flex: 1 1 322px;
    max-width: 322px;
  `)}

  ${media.tablet(css`
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 248px;
  `)}
  
  ${media.mobile(css`
    position: static;
    max-width: 100%;
    height: 162vw;
    border-radius: 24px;
    overflow: hidden;
  `)}
`;

const Center = styled.div`
  flex: 1 1 483px;
  max-width: 483px;

  ${media.desktopOnly(css`
    flex: 1 1 20%;
    max-width: 20%;
  `)}

  ${media.laptop(css`
    flex: 1 1 322px;
    max-width: 322px;
  `)}

  ${media.tablet(css`
    display: none;
  `)}
`;

const Right = styled.div`
  flex: 1 1 581px;
  padding: 0 40px 0 80px;

  ${media.desktopOnly(css`
    flex: 1 1 60%;
    padding: 0 20px 0 40px;
  `)}

  ${media.laptop(css`
    flex: 1 1 636px;
    padding: 0 20px 0 40px;
  `)}

  ${media.tablet(css`
    max-width: calc(100% - 284px);
    width: 100%;
    padding: 0 20px 0 0;
  `)}
  
  ${media.mobile(css`
    margin-top: 30px;
    max-width: 100%;
    padding: 0 20px;
  `)}
`;

const CenterCell = styled.div`
  position: relative;

  &:before {
    display: block;
    content: '';
    padding-bottom: 102%;

    ${media.laptop(css`
      padding-bottom: 100%;
    `)}
  }
`;

const CenterCellInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export default BannerSection;
