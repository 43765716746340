import { useEffect, useState } from 'react';

import { News, NewsListItem } from '@/typings/model';
import { getNewsList } from '@/services/requests';

function useCurrentNewsList(category: string) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<Array<NewsListItem>>([]);

  useEffect(() => {
    function getCurrentNewsList(news: Array<NewsListItem>, category: string) {
      if (category === '/') {
        return news;
      }

      return news.filter(({ categories }) =>
        categories.some(({ urlAlias }) => urlAlias === category)
      );
    }

    setLoading(true);

    getNewsList()
      .then(({ data }) => {
        setData(getCurrentNewsList(data, category));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [category]);

  return { isLoading, data };
}

export default useCurrentNewsList;
