import React from 'react';
import styled, { css } from 'styled-components';

import Button from '@/components/Button';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { ShopsCategoriesItemType } from '@/typings/model';

interface Props {
  categoryItem: ShopsCategoriesItemType;
  isActive: boolean;
  onChange: (categoryItem: ShopsCategoriesItemType) => void;
  count: number;
}

function CategoriesItem({ categoryItem, count, onChange, isActive }: Props) {
  return (
    <Component>
      <CategoryButton
        isActive={isActive}
        onClick={() => {
          onChange(categoryItem);
        }}
      >
        {categoryItem.name}
        <CategoryItemCount>{count}</CategoryItemCount>
      </CategoryButton>
    </Component>
  );
}

const Component = styled.div`
  padding-right: 26px;

  ${media.laptop(css`
    padding-right: 16px;
  `)}

  &:last-child {
    padding-right: 0;
  }
`;

const CategoryButton = styled(Button)<{ isActive?: boolean }>`
  display: block;
  position: relative;
  padding: 0 26px 0 0 !important;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: ${colors.black32};
  transition: all 0.4s linear;

  &:hover {
    color: ${colors.black};
  }

  ${(props) =>
    props.isActive
      ? css`
          color: ${colors.black};
        `
      : ''}

  ${media.laptop(css`
    padding-right: 20px !important;
    font-size: 16px;
    line-height: 24px;
  `)}
  
  ${media.tablet(css`
    font-size: 16px;
    line-height: 22px;
  `)}
`;

const CategoryItemCount = styled.span`
  position: absolute;
  content: '';
  top: -1px;
  right: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  ${media.laptop(css`
    font-size: 12px;
  `)}

  ${media.tablet(css`
    right: 8px;
    font-size: 10px;
    line-height: 16px;
  `)}
`;

export default CategoriesItem;
