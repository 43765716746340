import React, { useState } from 'react';
import { Formik, FormikErrors, FormikHelpers } from 'formik';

import { convertRequestErrorToMap } from '@tager/web-core';

import { FeedbackFormPayload, sendFeedbackForm } from '@/services/requests';
import { FilesType, FormCheckbox } from '@/typings/model';

import FeedbackForm, { FeedbackFormValues } from './FeedbackForm';

type Props = {
  formTitle: string;
  messageSuccess: string;
  buttonLabel: string;
  formCheckboxes: Array<FormCheckbox>;
};

function FeedbackFormContainer({
  formTitle,
  buttonLabel,
  messageSuccess,
  formCheckboxes,
}: Props) {
  const [isSentSuccess, setSentSuccess] = useState(false);
  const [fileId, setFileId] = useState<FilesType>([]);
  const [storeId, setStoreId] = useState<number>(0);
  const [subjectId, setSubjectId] = useState<number | null>(null);
  const [isClearValue, setClearValue] = useState<boolean>(false);
  const [isCityClear, setCityClear] = useState<boolean>(false);
  const [isDisabled, setDisabled] = useState<boolean>(true);
  const [isDisabledSubject, setDisabledSubject] = useState<boolean>(true);

  function handleSubmit(
    values: FeedbackFormValues,
    formikHelpers: FormikHelpers<FeedbackFormValues>
  ) {
    const payload: FeedbackFormPayload = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      body: values.body,
      storeId: storeId,
      subjectId: subjectId ?? null,
      files: fileId ?? null,
    };

    sendFeedbackForm(payload)
      .then(() => {
        setSentSuccess(true);
        formikHelpers.resetForm();
        setFileId([]);
        setSubjectId(null);
        setStoreId(0);
        setClearValue(true);
        setCityClear(true);
        setDisabled(true);
        setDisabledSubject(true);
        setTimeout(() => {
          setClearValue(false);
          setCityClear(false);
        }, 100);
      })
      .catch((error) => {
        const errorMap = convertRequestErrorToMap(
          error
        ) as FormikErrors<FeedbackFormPayload>;
        formikHelpers.setErrors(errorMap);
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  }

  return (
    <Formik<FeedbackFormValues>
      onSubmit={handleSubmit}
      initialValues={{
        name: '',
        email: '',
        phone: '',
        body: '',
        storeId: null,
        subjectId: null,
        files: [],
      }}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {(formProps) => (
        <FeedbackForm
          {...formProps}
          isSentSuccess={isSentSuccess}
          fileId={fileId}
          setFileId={(value: FilesType) => setFileId(value)}
          storeId={storeId}
          setStoreId={(value: number) => setStoreId(value)}
          subjectId={subjectId}
          setSubjectId={(value: number | null) => setSubjectId(value)}
          setSentSuccess={(value: boolean) => setSentSuccess(value)}
          isClearValue={isClearValue}
          setClearValue={(value: boolean) => setClearValue(value)}
          isCityClear={isCityClear}
          formTitle={formTitle}
          buttonLabel={buttonLabel}
          messageSuccess={messageSuccess}
          isDisabled={isDisabled}
          setDisabled={(value: boolean) => setDisabled(value)}
          isDisabledSubject={isDisabledSubject}
          setDisabledSubject={(value: boolean) => setDisabledSubject(value)}
          formCheckboxes={formCheckboxes}
        />
      )}
    </Formik>
  );
}

export default FeedbackFormContainer;
