import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

import { convertThumbnailToPictureImage } from '@tager/web-modules';
import { useMedia } from '@tager/web-core';

import { breakpoints, colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { ImageThumbnail, StringField } from '@/typings/model';
import Picture from '@/components/Picture';
import TickerLine from '@/components/TickerLine';

interface Props {
  text: StringField;
  image1?: ImageThumbnail;
  image2?: ImageThumbnail;
  backgroundImage?: ImageThumbnail;

  tickerTextFirst: StringField;
  tickerLinkFirst: StringField;
  tickerBackgroundFirst: StringField;
  tickerColorFirst: StringField;

  tickerTextSecond: StringField;
  tickerLinkSecond: StringField;
  tickerBackgroundSecond: StringField;
  tickerColorSecond: StringField;
}

function Professionalism({
  text,
  image1,
  image2,
  backgroundImage,
  tickerBackgroundFirst,
  tickerBackgroundSecond,
  tickerColorFirst,
  tickerColorSecond,
  tickerLinkFirst,
  tickerLinkSecond,
  tickerTextFirst,
  tickerTextSecond,
}: Props) {
  const topRef = useRef<HTMLDivElement | null>(null);
  const textRef = useRef<HTMLParagraphElement | null>(null);
  const imageLeftRef = useRef<HTMLDivElement | null>(null);
  const imageRightRef = useRef<HTMLDivElement | null>(null);

  const isTablet = useMedia(`(max-width: ${breakpoints.laptop - 1}px)`);

  useEffect(() => {
    if (
      !topRef.current ||
      !imageLeftRef.current ||
      !imageRightRef.current ||
      isTablet
    ) {
      return;
    }
    let tween: gsap.core.Tween;
    const set = gsap.set([imageLeftRef.current, imageRightRef.current], {
      y: -180,
    });

    const delayedCall = gsap.delayedCall(0, () => {
      if (!topRef.current) {
        return;
      }
      ScrollTrigger.matchMedia({
        '(min-width: 1600px)': () => {
          if (!topRef.current) {
            return;
          }
          tween = gsap.to([imageLeftRef.current, imageRightRef.current], {
            y: 180,
            ease: 'linear',
            scrollTrigger: {
              trigger: topRef.current,
              scrub: true,
            },
          });
        },
        '(max-width: 1600px)': () => {
          if (!topRef.current) {
            return;
          }
          tween = gsap.to([imageLeftRef.current, imageRightRef.current], {
            y: 130,
            ease: 'linear',
            scrollTrigger: {
              trigger: topRef.current,
              scrub: true,
            },
          });
        },
        '(max-width: 1259px)': () => {
          if (!topRef.current) {
            return;
          }
          tween = gsap.to([imageLeftRef.current, imageRightRef.current], {
            y: 0,
            ease: 'linear',
            scrollTrigger: {
              trigger: topRef.current,
              scrub: true,
            },
          });
        },
      });
    });

    return () => {
      set.kill();
      tween?.kill();
      delayedCall.kill();
    };
  }, [isTablet]);

  useEffect(() => {
    if (!topRef.current || !textRef.current || isTablet) {
      return;
    }
    let tween: gsap.core.Tween;
    let set: gsap.core.Tween;

    ScrollTrigger.matchMedia({
      '(min-width: 1600px)': () => {
        set = gsap.set(textRef.current, { y: -160 });
      },
      '(max-width: 1600px)': () => {
        set = gsap.set(textRef.current, { y: -130 });
      },
      '(max-width: 1259px)': () => {
        set = gsap.set(textRef.current, { y: 0 });
      },
    });

    const delayedCall = gsap.delayedCall(0, () => {
      if (!topRef.current) {
        return;
      }
      ScrollTrigger.matchMedia({
        '(min-width: 1600px)': () => {
          if (!topRef.current) {
            return;
          }
          tween = gsap.to(textRef.current, {
            y: 160,
            ease: 'linear',
            scrollTrigger: {
              trigger: topRef.current,
              scrub: true,
            },
          });
        },
        '(max-width: 1600px)': () => {
          if (!topRef.current) {
            return;
          }
          tween = gsap.to(textRef.current, {
            y: 130,
            ease: 'linear',
            scrollTrigger: {
              trigger: topRef.current,
              scrub: true,
            },
          });
        },
        '(max-width: 1259px)': () => {
          if (!topRef.current) {
            return;
          }
          tween = gsap.to(textRef.current, {
            y: 0,
            ease: 'linear',
            scrollTrigger: {
              trigger: topRef.current,
              scrub: true,
            },
          });
        },
      });
    });

    return () => {
      set?.kill();
      tween?.kill();
      delayedCall.kill();
    };
  }, [isTablet]);

  return (
    <Component>
      {tickerTextFirst && (
        <TickerLine
          backgroundTicket={tickerBackgroundFirst}
          colorTicket={tickerColorFirst}
          labelTicket={tickerTextFirst ?? ''}
          linkTicket={tickerLinkFirst}
          rotateTicket={'-10'}
          sizeTicket={'middle'}
        />
      )}
      <Top ref={topRef}>
        <Content>
          <Text ref={textRef}>{text}</Text>
          <Image>
            {image1 && (
              <ImageLeft ref={imageLeftRef}>
                <Picture mobileSmall={convertThumbnailToPictureImage(image1)} />
              </ImageLeft>
            )}
            {image2 && (
              <ImageRight ref={imageRightRef}>
                <Picture mobileSmall={convertThumbnailToPictureImage(image2)} />
              </ImageRight>
            )}
          </Image>
        </Content>
      </Top>
      {tickerTextSecond && (
        <TickerLine
          backgroundTicket={tickerBackgroundSecond}
          colorTicket={tickerColorSecond}
          labelTicket={tickerTextSecond ?? ''}
          linkTicket={tickerLinkSecond}
          rotateTicket={'10'}
          sizeTicket={'middle'}
        />
      )}
      <Bottom>
        {backgroundImage && (
          <BottomBackground>
            <Picture
              mobileSmall={convertThumbnailToPictureImage(backgroundImage)}
            />
          </BottomBackground>
        )}
      </Bottom>
    </Component>
  );
}

const Component = styled.div``;

const Top = styled.div`
  padding: 180px 0;

  ${media.laptop(css`
    padding: 140px 0 127px;
  `)}

  ${media.tablet(css`
    padding: 82px 0 30px;
  `)}
  
    ${media.mobile(css`
    padding: 53px 0 104px;
  `)}
`;
const Bottom = styled.div`
  max-width: 1920px;
  margin: -776px auto 0;

  ${media.laptop(css`
    margin-top: -544px;
  `)}

  ${media.tablet(css`
    margin-top: -375px;
  `)}
  
  ${media.mobile(css`
    margin-top: -196px;
  `)}
`;

const Content = styled.div`
  max-width: 1328px;
  margin: 0 auto;

  ${media.laptop(css`
    max-width: 996px;
  `)}

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

const Text = styled.p`
  display: block;
  margin-bottom: 120px;

  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.white};

  ${media.laptop(css`
    margin-bottom: 118px;
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    font-size: 48px;
    line-height: 56px;
    margin: 0 0 258px 60px;
  `)}
  
  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
    margin: 0 0 56px;
  `)}
`;

const Image = styled.div`
  display: flex;
  justify-content: space-between;
  z-index: 3;

  ${media.mobile(css`
    justify-content: center;
    overflow: hidden;
    margin: 0 -20px;
  `)}
`;

const ImageLeft = styled.div`
  flex: 1 1 512px;
  max-width: 512px;
  z-index: 3;

  ${media.laptop(css`
    flex: 1 1 384px;
    max-width: 384px;
  `)}

  ${media.tablet(css`
    flex: 1 1 250px;
    max-width: 250px;
    margin-left: 96px;
  `)}
  
  ${media.mobile(css`
    flex: 0 0 244px;
    margin-left: 0;
    transform: translate(0, 0) !important;
  `)}

  picture {
    width: 100%;
  }
`;
const ImageRight = styled.div`
  margin-top: 300px;
  flex: 1 1 648px;
  max-width: 648px;
  z-index: 3;

  ${media.laptop(css`
    margin-top: 176px;
    flex: 1 1 486px;
    max-width: 486px;
  `)}

  ${media.tablet(css`
    margin-top: 183px;
    flex: 1 1 250px;
    max-width: 250px;
  `)}
  
  ${media.mobile(css`
    flex: 0 0 254px;
    margin: 152px 0 0 20px;
    transform: translate(0, 0) !important;
  `)}

  picture, img {
    width: 100%;
    object-fit: contain;
  }
`;

const BottomBackground = styled.div`
  position: relative;
  &:before {
    display: block;
    content: '';
    padding-bottom: 87.5%;

    ${media.tablet(css`
      padding-bottom: 84.6%;
    `)}

    ${media.mobile(css`
      padding-bottom: 111.11%;
    `)}
  }

  div {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  picture {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export default Professionalism;
