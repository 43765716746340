import React from 'react';
import styled, { css } from 'styled-components';

import {
  convertThumbnailToPictureImage,
  ThumbnailType,
} from '@tager/web-modules';
import { useMedia } from '@tager/web-core';

import StoreItem from '@/modules/ShoppingCenterSingle/components/Hero/components/StoreItem';
import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';
import { breakpoints, colors } from '@/constants/theme';
import { MenuCategoriesItemType, StringField } from '@/typings/model';

interface Props {
  name: StringField;
  address: StringField;
  city: StringField;
  image?: ThumbnailType | null;
  menuLinks: Array<MenuCategoriesItemType>;
  menuUrl: StringField;
  workingTime: StringField;
}

function Hero({
  name,
  image,
  address,
  city,
  menuLinks,
  menuUrl,
  workingTime,
}: Props) {
  const isTablet = useMedia(`(max-width: ${breakpoints.laptop - 1}px)`);

  return (
    <Component>
      <Inner>
        <Left>
          <Title>{name}</Title>
          <Address>
            {city}, {address}
          </Address>
          {isTablet && workingTime && (
            <WorkingTime>Время работы: {workingTime}</WorkingTime>
          )}
        </Left>
        <Right>
          <StoreList>
            {menuLinks?.map((menu, index) => {
              return (
                <StoreCell key={index}>
                  <StoreItem
                    label={menu.name}
                    url={`${menuUrl}?menu=${menu.name}`}
                    hover={'red'}
                    isSecondary={true}
                  />
                </StoreCell>
              );
            })}
          </StoreList>
          {image && (
            <Image>
              <Picture mobileSmall={convertThumbnailToPictureImage(image)} />
            </Image>
          )}
        </Right>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
`;

const Inner = styled.div`
  max-width: 1680px;
  margin: 0 auto;
  padding: 97px 40px 63px;
  display: flex;

  ${media.laptop(css`
    padding: 51px 40px 28px;
  `)}

  ${media.tablet(css`
    padding: 51px 20px 2px;
    display: block;
  `)}
  
  ${media.mobile(css`
    padding: 20px 20px 0;
  `)}
`;

const Left = styled.div`
  flex: 0 0 512px;
  max-width: 512px;
  margin-right: 32px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  ${media.laptop(css`
    flex: 0 0 384px;
    max-width: 384px;
  `)}

  ${media.tablet(css`
    max-width: 100%;
    margin-bottom: 50px;
  `)}
`;

const Right = styled.div`
  flex: 1 1 auto;

  ${media.tablet(css`
    position: relative;
    min-height: 350px;
    margin: 0 -20px;
  `)}

  ${media.mobile(css`
    min-height: 133vw;

    display: flex;
    align-items: flex-end;

    border-radius: 24px;
    overflow: hidden;
  `)}
`;

const Title = styled.h2`
  margin: 0 0 32px;
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.black};

  word-wrap: break-word;

  ${media.laptop(css`
    margin-bottom: 15px;
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    margin-bottom: 20px;
    font-size: 48px;
    line-height: 56px;
  `)}
  
  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
  `)}
`;

const Address = styled.address`
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  color: ${colors.black};

  ${media.laptop(css`
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    font-size: 20px;
    line-height: 24px;
  `)}
  
  ${media.mobile(css`
    font-size: 16px;
    line-height: 20px;
  `)}
`;

const WorkingTime = styled.div`
  margin-top: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  ${media.mobile(css`
    font-size: 12px;
    line-height: 18px;
  `)}
`;

const StoreList = styled.div`
  position: relative;
  z-index: 2;
  padding: 0 60px;
  margin: -11px;

  display: inline-flex;
  flex-wrap: wrap;

  ${media.laptop(css`
    margin: -8px;
    padding: 0 20px;
  `)}

  ${media.tablet(css`
    padding: 47px 20px;
  `)}
  
  ${media.mobile(css`
    padding: 30px 20px;
  `)}
`;

const StoreCell = styled.div`
  display: inline-block;
  padding: 11px;

  ${media.laptop(css`
    padding: 8px;
  `)}
`;

const Image = styled.div`
  position: absolute;
  content: '';
  top: 32px;
  bottom: 0;
  right: 0;
  width: 100%;
  max-width: calc((100vw - 1600px) / 2 + 1056px);
  border-radius: 36px;
  background: ${colors.gray50};

  ${media.desktopLarge(css`
    max-width: 1216px !important;
  `)}

  ${media.laptop(css`
    top: 22px;
    max-width: calc(100vw - 456px);
    border-radius: 24px;
  `)}
  
  ${media.tablet(css`
    top: 0;
    max-width: 100%;
    width: 100%;
    border-radius: 0;
  `)}

  div, picture,img {
    width: 100%;
    height: 100%;

    ${media.tablet(css`
      object-fit: cover;
    `)}
  }
`;

export default Hero;
