import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Navigation,
  Autoplay,
  Pagination,
  EffectFade,
} from 'swiper';

import { ReactComponent as PrevIcon } from '@/assets/svg/slider-prev.svg';
import { ReactComponent as NextIcon } from '@/assets/svg/slider-next.svg';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { ImageThumbnail, StringField } from '@/typings/model';

import ProductsSlide from './components/ProductsSlide';

SwiperCore.use([Navigation, Autoplay, Pagination, EffectFade]);

interface Props {
  products: Array<{
    image: ImageThumbnail;
    link: StringField;
    title: StringField;
  }>;
}

function ProductsSlider({ products }: Props) {
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);

  return (
    <Component>
      <SliderContainer>
        <Swiper
          initialSlide={0}
          slidesPerView={'auto'}
          speed={500}
          autoplay={{ delay: 4000, disableOnInteraction: false }}
          loop={true}
          navigation={{
            nextEl: '.swiper-next',
            prevEl: '.swiper-prev',
          }}
          onSlideChange={(swiper) => setActiveSlideIndex(swiper.activeIndex)}
          breakpoints={{
            1260: { slidesPerView: 4 },
          }}
        >
          {products?.map((product, index) => {
            if (!product.image || !product.title) return false;
            return (
              <SwiperSlide key={index}>
                <ProductsSlide product={product} />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <ButtonPrev className="swiper-prev">
          <PrevIcon />
        </ButtonPrev>
        <ButtonNext className="swiper-next">
          <NextIcon />
        </ButtonNext>
      </SliderContainer>
    </Component>
  );
}

const Component = styled.div`
  position: relative;
`;

const SliderContainer = styled.div`
  .swiper-slide {
    ${media.tablet(css`
      width: 322px !important;
    `)}

    ${media.mobile(css`
      width: 255px !important;
    `)}
  }
`;

const ButtonPrev = styled.div`
  position: absolute;
  top: calc(50% - 42px);
  left: 30px;
  z-index: 2;
  cursor: pointer;
  will-change: transform;
  transition: all 0.3s linear;

  width: 84px;
  height: 84px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 100%;
  background: ${colors.white};

  svg {
    position: relative;
    left: -2px;
    width: 36px;
    height: 36px;
    pointer-events: none;
    will-change: transform;
  }

  ${media.laptop(css`
    bottom: 20px;
    left: 19px;

    width: 56px;
    height: 56px;

    svg {
      left: -1px;
      width: 24px;
      height: 24px;
    }
  `)}

  ${media.tablet(css`
    display: none;
  `)}

  &:hover {
    transform: scale(1.1);
  }
`;

const ButtonNext = styled.div`
  position: absolute;
  top: calc(50% - 42px);
  right: 30px;
  z-index: 2;
  cursor: pointer;
  will-change: transform;
  transition: all 0.3s linear;

  width: 84px;
  height: 84px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 100%;
  background: ${colors.white};

  svg {
    position: relative;
    right: -2px;
    width: 36px;
    height: 36px;
    pointer-events: none;
    will-change: transform;
  }

  ${media.laptop(css`
    bottom: 20px;
    right: 19px;

    width: 56px;
    height: 56px;

    svg {
      right: -1px;
      width: 24px;
      height: 24px;
    }
  `)}

  ${media.tablet(css`
    display: none;
  `)}

  &:hover {
    transform: scale(1.1);
  }
`;

export default ProductsSlider;
