import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';
import { useMedia } from '@tager/web-core';

import Star from '@/components/Sticker/Star';
import { breakpoints, colors } from '@/constants/theme';
import { ImageThumbnail, StringField } from '@/typings/model';
import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';

import CertificateType from './components/CertificateType';

type Props = {
  firstBlockTitle: StringField;
  firstBlockText: StringField;
  firstBlockImage?: ImageThumbnail;
  firstBlockItems?: Array<{
    cost: StringField;
  }>;
};

function Hero({
  firstBlockTitle,
  firstBlockText,
  firstBlockImage,
  firstBlockItems,
}: Props) {
  const isTablet = useMedia(`(max-width: ${breakpoints.laptop - 1}px)`);

  return (
    <Component>
      <Inner>
        <Left>
          <Title dangerouslySetInnerHTML={{ __html: firstBlockTitle ?? '' }} />
          {firstBlockText && isTablet && <Text>{firstBlockText}</Text>}
          {firstBlockImage ? (
            <ImageCard>
              <Picture
                mobileSmall={convertThumbnailToPictureImage(firstBlockImage)}
              />
            </ImageCard>
          ) : null}
        </Left>
        <Right>
          <StarWrapper>
            <Star
              label={'ПОДАРОЧНЫЙ СЕРТИФИКАТ'}
              size={'big'}
              color={'lime'}
              isDiscount={true}
              isCertificate={true}
              rotate={'5deg'}
            />
          </StarWrapper>
          {firstBlockText && !isTablet && <Text>{firstBlockText}</Text>}
          <CertificateType firstBlockItems={firstBlockItems} />
        </Right>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  max-width: 1680px;
  padding: 0 40px;
  margin: 0 auto;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 278px;

  ${media.laptop(css`
    padding-bottom: 142px;
  `)}

  ${media.tablet(css`
    padding-bottom: 104px;
  `)}
  
  ${media.mobile(css`
    display: block;
    position: relative;
    padding-bottom: 70px;
  `)}
`;

const Left = styled.div`
  margin-top: 178px;
  flex: 1 1 784px;
  max-width: 784px;

  ${media.laptop(css`
    margin-top: 104px;
    flex: 1 1 618px;
    max-width: 618px;
  `)}

  ${media.tablet(css`
    flex: 1 1 442px;
    max-width: 100%;
    margin: 50px 36px 0 0;
  `)}
  
  ${media.mobile(css`
    margin-right: 0;
  `)}
`;

const Right = styled.div`
  margin-top: 73px;
  flex: 1 1 648px;
  max-width: 648px;

  ${media.laptop(css`
    margin-top: 0;
    flex: 1 1 486px;
    max-width: 486px;
  `)}

  ${media.tablet(css`
    margin-top: 110px;
    flex: 1 1 250px;
    max-width: 250px;
  `)}
  
  ${media.mobile(css`
    margin-top: 0;
    max-width: 100%;
  `)}
`;

const Title = styled.h2`
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.white};

  ${media.laptop(css`
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    font-size: 48px;
    line-height: 56px;
  `)}
  
  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
    z-index: 2;
    position: relative;
  `)}

  b {
    font-weight: 500;
    color: ${colors.orange};
  }
`;

const ImageCard = styled.div`
  position: relative;
  margin-top: 84px;

  ${media.laptop(css`
    margin-top: 190px;
  `)}

  ${media.tablet(css`
    margin-top: 106px;
  `)}
  
  ${media.mobile(css`
    position: absolute;
    width: 339px;
    bottom: 24px;
    right: 170px;
    z-index: 1;
  `)}

  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: linear-gradient(180deg, rgba(2, 2, 2, 0) 7.29%, #020202 93.7%);

    ${media.mobile(css`
      background: linear-gradient(
        180deg,
        rgba(2, 2, 2, 0) 7.29%,
        #020202 93.7%
      );
    `)}
  }

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-bottom: 150%;
  }

  div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    ${media.laptop(css`
      left: -15px;
    `)}

    ${media.tablet(css`
      left: 0;
    `)}
  }
  picture {
    width: 100%;
    height: 100%;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const StarWrapper = styled.div`
  position: relative;
  left: -5px;
  transform: rotate(-45deg);
  width: 470px;
  height: 470px;

  ${media.laptop(css`
    top: -1px;
    left: -24px;
    transform: rotate(-35.42deg);
    width: 400px;
    height: 400px;
  `)}

  ${media.tablet(css`
    top: 0;
    left: 0;
    width: 240px;
    height: 240px;
    transform: rotate(-30deg);
  `)}
  
  ${media.mobile(css`
    position: absolute;
    content: '';
    top: 102px;
    left: auto;
    right: -18px;

    width: 210px;
    height: 210px;
    transform: rotate(15deg);
    z-index: 2;
  `)}
`;

const Text = styled.p`
  margin-top: 106px;
  font-size: 30px;
  line-height: 36px;
  color: ${colors.white};

  ${media.laptop(css`
    margin-top: 127px;
    font-size: 20px;
    line-height: 26px;
  `)}

  ${media.tablet(css`
    margin-top: 30px;
    font-size: 18px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    max-width: 235px;
    margin-top: 223px;
    font-size: 14px;
    line-height: 20px;
    z-index: 2;
    position: relative;
  `)}
`;

export default Hero;
