import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

type Props = {
  title: string;
  description: string;
};

function Hero({ title, description }: Props) {
  return (
    <Component>
      <Title>{title}</Title>
      <Text>{description}</Text>
    </Component>
  );
}

const Component = styled.div`
  padding-top: 92px;
  max-width: 1056px;
  margin: 0 auto;

  ${media.laptop(css`
    max-width: 792px;
    padding-top: 64px;
  `)}

  ${media.tablet(css`
    padding-top: 31px;
  `)}
  
  ${media.mobile(css`
    padding-top: 20px;
  `)}
`;

const Title = styled.h2`
  margin: 0 0 60px;
  font-weight: 500;
  font-size: 64px;
  line-height: 72px;
  color: ${colors.black};

  ${media.laptop(css`
    margin-bottom: 40px;
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    margin-bottom: 30px;
    font-size: 48px;
    line-height: 56px;
  `)}
  
  ${media.mobile(css`
    margin-bottom: 20px;
    font-size: 34px;
    line-height: 42px;
  `)}
`;

const Text = styled.p`
  font-size: 30px;
  line-height: 36px;
  color: ${colors.black};

  ${media.laptop(css`
    font-size: 20px;
    line-height: 26px;
  `)}

  ${media.tablet(css`
    font-size: 18px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    font-size: 14px;
    line-height: 20px;
  `)}
`;

export default Hero;
