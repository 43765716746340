import React from 'react';
import styled from 'styled-components';

import Picture from '@/components/Picture';
import crossUrl from '@/assets/images/sticker/cross.png';
import crossUrl2x from '@/assets/images/sticker/cross@2x.png';
import crossUrlWebp from '@/assets/images/sticker/cross.webp';
import crossUrlWebp2x from '@/assets/images/sticker/cross@2x.webp';
import crossSecondaryUrl from '@/assets/images/sticker/cross-secondary.png';
import crossSecondaryUrl2x from '@/assets/images/sticker/cross-secondary@2x.png';
import crossSecondaryUrlWebp from '@/assets/images/sticker/cross-secondary.webp';
import crossSecondaryUrlWebp2x from '@/assets/images/sticker/cross-secondary@2x.webp';
import crossDiscountUrl from '@/assets/images/sticker/cross-discount.png';
import crossDiscountUrl2x from '@/assets/images/sticker/cross-discount@2x.png';
import crossDiscountUrlWebp from '@/assets/images/sticker/cross-discount.webp';
import crossDiscountUrlWebp2x from '@/assets/images/sticker/cross-discount@2x.webp';

type Props = {
  isSecondary?: boolean;
  isDiscount?: boolean;
};

function Cross({ isSecondary, isDiscount }: Props) {
  return (
    <Component>
      <Inner>
        <Image>
          {isSecondary ? (
            <Picture
              mobileSmall={{
                src: crossSecondaryUrl,
                src2x: crossSecondaryUrl2x,
                webp: crossSecondaryUrlWebp,
                webp2x: crossSecondaryUrlWebp2x,
              }}
            />
          ) : isDiscount ? (
            <Picture
              mobileSmall={{
                src: crossDiscountUrl,
                src2x: crossDiscountUrl2x,
                webp: crossDiscountUrlWebp,
                webp2x: crossDiscountUrlWebp2x,
              }}
            />
          ) : (
            <Picture
              mobileSmall={{
                src: crossUrl,
                src2x: crossUrl2x,
                webp: crossUrlWebp,
                webp2x: crossUrlWebp2x,
              }}
            />
          )}
        </Image>
      </Inner>
    </Component>
  );
}

const Component = styled.div``;
const Inner = styled.div``;
const Image = styled.div`
  width: 100%;
  height: 100%;
  div,
  picture,
  img {
    width: 100%;
    height: 100%;
  }
`;

export default Cross;
