import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { colors } from '@/constants/theme';
import Link from '@/components/Link';
import { media } from '@/utils/mixin';
import { ImageThumbnail, StringField } from '@/typings/model';
import Picture from '@/components/Picture';

interface Props {
  product: {
    title: StringField;
    link: StringField;
    image: ImageThumbnail;
  };
  isProduct?: boolean;
}

function ProductsSlide({ product, isProduct }: Props) {
  return (
    <Component isProduct={isProduct}>
      {product.link && <ComponentLink to={product.link} />}
      <Inner>
        <Image>
          {product?.image ? (
            <Picture
              mobileSmall={convertThumbnailToPictureImage(product.image)}
            />
          ) : null}
        </Image>
        <Label>{product.title}</Label>
      </Inner>
    </Component>
  );
}

const ComponentLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
`;

const Inner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${colors.gray50};
  padding: 26px 30px;
  display: flex;
  align-items: flex-end;
  border-radius: 36px;
  overflow: hidden;

  ${media.laptop(css`
    border-radius: 24px;
    padding: 24px;
  `)}

  ${media.tablet(css`
    padding: 30px;
  `)}
  
  ${media.mobile(css`
    padding: 30px 20px;
  `)}
`;

const Label = styled.span`
  border-radius: 50px;
  overflow: hidden;
  padding: 40px 54px;
  background: ${colors.white};
  position: relative;

  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  color: ${colors.black};
  transition: all 0.3s linear;

  ${media.laptop(css`
    border-radius: 35px;
    padding: 24px 32px;

    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    padding: 14px 22px;

    font-size: 20px;
    line-height: 24px;
  `)}
  
  ${media.mobile(css`
    border-radius: 29px;
    padding: 12px 20px;
    font-size: 16px;
    line-height: 20px;
  `)}
`;

const Image = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  div,
  picture {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Component = styled.div<{ isProduct?: boolean }>`
  position: relative;

  &:before {
    display: block;
    content: '';
    padding-bottom: ${(props) => (props.isProduct ? '150%' : '200%')};
    
    ${media.tablet(css`
      padding-bottom: 200%;
    `)}
  }

  &:hover {
    ${Label} {
     background: ${colors.orange};
     color: ${colors.white};
  }
`;

export default ProductsSlide;
