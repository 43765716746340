import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import { StringField } from '@/typings/model';
import { ReactComponent as ArrowIcon } from '@/assets/svg/arrow-procedure.svg';
import { breakpoints, colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

type Props = {
  procedureItem: {
    title: StringField;
    text: StringField;
  };
};

const onresize = (elem: HTMLDivElement, callback: () => void) => {
  const resizeObserver = new ResizeObserver(() => callback());
  resizeObserver.observe(elem);
};

function ProcedureItem({ procedureItem }: Props) {
  const { title, text } = procedureItem;

  // const [isActive, setActive] = useState(false);
  const [maxHeight, setMaxHeight] = useState<number>(0);

  const componentRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLParagraphElement>(null);
  const prevRefOffset = useRef<number>(0);

  const isDesktop = useMedia(`(min-width: ${breakpoints.desktop}px)`);
  const isLaptop = useMedia(`(min-width: ${breakpoints.laptop}px)`);
  const isTablet = useMedia(`(max-width: ${breakpoints.laptop - 1}px)`);

  useEffect(() => {
    if (!textRef.current) return;
    const textHeight = textRef.current.offsetHeight;
    if (textHeight > 144 && isDesktop) {
      setMaxHeight(textHeight - 165);
    } else if (textHeight > 104 && isLaptop) {
      setMaxHeight(textHeight - 130);
    } else if (isTablet) {
      setMaxHeight(textHeight - 30);
    } else {
      setMaxHeight(0);
    }
    prevRefOffset.current = textHeight;
  }, [isDesktop, isLaptop, isTablet]);

  useEffect(() => {
    const textNode = textRef.current;
    if (!textNode) {
      return;
    }
    const resizeObserver = new ResizeObserver(() => {
      if (prevRefOffset.current !== textRef.current?.offsetHeight) {
        setMaxHeight(textRef.current?.offsetHeight ?? 0);
      }
      return;
    });
    resizeObserver.observe(textNode);

    return () => {
      resizeObserver.unobserve(textNode);
    };
  }, []);

  return (
    <Component ref={componentRef} maxHeight={maxHeight}>
      <TextHidden ref={textRef}>{text}</TextHidden>
      <Inner>
        <Top>
          <Icon>
            <ArrowIcon />
          </Icon>
        </Top>
        <Bottom>
          <Title>{title}</Title>
          <TextWrapper>
            <TextInner>
              <Text>{text}</Text>
            </TextInner>
          </TextWrapper>
        </Bottom>
      </Inner>
    </Component>
  );
}

const Inner = styled.div`
  padding-top: 30px;
  position: relative;

  ${media.laptop(css`
    padding-top: 25px;
  `)}
`;

const Top = styled.div`
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
  min-height: 70px;
  position: relative;
  transition: all 0.3s linear;

  &:before {
    position: absolute;
    counter-increment: list;
    content: counter(list) '';
    top: 0;
    left: 0;
    font-size: 30px;
    line-height: 36px;
    color: rgba(255, 255, 255, 0.5);
    transition: all 0.3s linear;

    ${media.laptop(css`
      font-size: 20px;
      line-height: 26px;
    `)}

    ${media.tablet(css`
      font-size: 18px;
      line-height: 22px;
    `)}
  }

  ${media.laptop(css`
    min-height: 53px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  `)}

  ${media.tablet(css`
    min-height: 32px;
  `)}
`;

const Bottom = styled.div`
  margin-top: 35px;

  ${media.laptop(css`
    margin-top: 23px;
  `)}

  ${media.tablet(css`
    margin-top: 14px;
  `)}
`;

const Title = styled.h3`
  margin: 0;
  font-weight: 500;
  font-size: 54px;
  line-height: 64px;
  color: rgba(255, 255, 255, 0.8);
  transition: all 0.3s linear;

  ${media.laptop(css`
    font-size: 40px;
    line-height: 48px;
  `)}

  ${media.tablet(css`
    font-size: 28px;
    line-height: 34px;
  `)}
`;

const Icon = styled.div`
  position: absolute;
  top: 5px;
  right: 8px;
  opacity: 0.5;
  transition: all 0.3s linear;

  ${media.laptop(css`
    right: 4px;

    svg {
      width: 21px;
      height: 18px;
    }
  `)}

  ${media.tablet(css`
    top: 2px;
    right: 2px;
  `)}
`;

const TextWrapper = styled.div`
  margin-top: 25px;
  font-size: 30px;
  line-height: 36px;
  color: rgba(255, 255, 255, 0.5);
  transition: all 0.3s linear;
  position: relative;
  min-height: 144px;

  ${media.laptop(css`
    margin-top: 20px;
    font-size: 20px;
    line-height: 26px;
    min-height: 104px;
  `)}

  ${media.tablet(css`
    min-height: 0;
    font-size: 18px;
    line-height: 22px;
  `)}
`;

const TextInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

const Text = styled.p`
  position: relative;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  transition: all 0.3s linear;
  z-index: 1;

  ${media.tablet(css`
    -webkit-line-clamp: 1;
  `)}
`;

const TextHidden = styled.div`
  position: absolute;
  top: 0;
  width: calc(100% - 58px);
  left: -10000px;
  visibility: hidden;
  z-index: -100;
  pointer-events: none;
  opacity: 0;

  font-size: 30px;
  line-height: 36px;

  ${media.laptop(css`
    width: calc(100% - 58px);
    font-size: 20px;
    line-height: 26px;
  `)}

  ${media.tablet(css`
    width: calc(100% - 40px);
    font-size: 18px;
    line-height: 22px;
  `)}
`;

const Component = styled.div<{ maxHeight: number }>`
  flex: 1 1 33.33%;
  max-width: 33.33%;
  padding: 33.5px 36px;
  cursor: pointer;
  position: relative;

  ${media.laptop(css`
    padding: 23px 29px;
  `)}
  ${media.tablet(css`
    flex: 1 1 50%;
    max-width: 50%;
    padding: 20px;
    cursor: default;
  `)}
  &:nth-child(-n + 9) {
    ${Top} {
      &:before {
        counter-increment: list;
        content: '0' counter(list);
      }
    }
  }

  &:before {
    position: absolute;
    content: '';
    top: 33.5px;
    left: 0;
    right: 0;
    height: ${(props) =>
      props.maxHeight ? `calc(100% + ${props.maxHeight}px)` : `100%`};
    background: transparent;
    transition: background-color 0.3s linear;

    ${(props) =>
      props.maxHeight
        ? css`
            ${media.tablet(css`
              max-height: calc(100% + ${props.maxHeight}px);
            `)}
          `
        : css`
            ${media.tablet(css`
              max-height: 100%;
            `)}
          `}
  }

  &:hover {
    z-index: 2;

    &:before {
      background: ${colors.orange};
    }

    ${Top} {
      border-bottom: 2px solid ${colors.white};

      &:before {
        color: ${colors.white};
      }
    }

    ${Title} {
      color: ${colors.white};
    }

    ${Icon} {
      opacity: 1;
    }

    ${Text} {
      color: ${colors.white};
      display: -webkit-box;
      -webkit-line-clamp: 100;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
`;

export default ProcedureItem;
