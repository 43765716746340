import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import { StringField } from '@/typings/model';

import TableRow from './components/TableRow';

interface Props {
  title: StringField;
  list: Array<{
    text: StringField;
    title: StringField;
  }>;
}

function TableSection({ title, list }: Props) {
  return (
    <Component>
      <Inner>
        <Top>
          <Title>{title}</Title>
        </Top>
        <Content>
          {list?.map((listItem, index) => {
            return <TableRow key={index} listItem={listItem} />;
          })}
        </Content>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  max-width: 1680px;
  margin: 120px auto 0;
  padding: 0 40px;

  ${media.laptop(css`
    margin-top: 80px;
  `)}

  ${media.tablet(css`
    margin-top: 70px;
    padding: 0 20px;
  `)}
`;

const Inner = styled.div``;

const Top = styled.div`
  margin-bottom: 110px;

  ${media.laptop(css`
    margin-bottom: 56px;
  `)}

  ${media.tablet(css`
    margin-bottom: 50px;
  `)}
`;
const Content = styled.div``;

const Title = styled.h2`
  max-width: 784px;
  margin: 0;
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.black1000};

  ${media.laptop(css`
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    font-size: 48px;
    line-height: 56px;
  `)}
  
  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
  `)}
`;

export default TableSection;
