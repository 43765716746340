import React from 'react';
import styled from 'styled-components';

import useCurrentPage from '@/hooks/useCurrentPage';
import { RecipesPageType } from '@/typings/model';
import Breadcrumbs from '@/components/Breadcrumbs';
import { useLayout } from '@/components/Layout/Layout.hooks';
import useCurrentChildrenPages from '@/hooks/useCurrentChildrenPages';
import { BreadCrumbs } from '@/typings/common';
import { setBreadCrumbsList } from '@/utils/common';

import Main from './components/Main';

function Recipes() {
  const page = useCurrentPage<RecipesPageType>();
  const { data: recipesList } = useCurrentChildrenPages('recipes-single');
  const { setThemePage } = useLayout();
  setThemePage('');

  const BREADCRUMB_LIST: Array<BreadCrumbs> = setBreadCrumbsList(
    {
      title: page?.title ?? '',
      path: page?.path ?? '',
    },
    page?.parent
  );

  return (
    <Wrapper>
      <Breadcrumbs breadcrumbs={BREADCRUMB_LIST} />
      <Main
        title={'Рецепты'}
        list={recipesList}
        bannerImage={page?.templateFields.image}
        bannerLink={page?.templateFields.link ?? ''}
        bannerTitle={page?.templateFields.title ?? ''}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div``;

export default Recipes;
