import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import { ImageThumbnail, StringField } from '@/typings/model';
import Picture from '@/components/Picture';

interface Props {
  product: {
    image: ImageThumbnail;
    name: StringField;
    variety: StringField;
  };
}

function GalleryActiveField({ product }: Props) {
  return (
    <Component>
      <Inner>
        <Image>
          {product.image ? (
            <Picture
              mobileSmall={convertThumbnailToPictureImage(product.image)}
            />
          ) : null}
        </Image>
        <Label>
          {product.name}
          <Variety>{product.variety}</Variety>
        </Label>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  position: relative;
  border-radius: 32px;
  overflow: hidden;
  background: ${colors.gray50};

  ${media.laptop(css`
    border-radius: 24px;
  `)}

  ${media.tabletOnly(css`
    border-radius: 14px;
  `)}

  &:before {
    display: block;
    content: '';
    padding-bottom: 95.15%;

    ${media.laptop(css`
      padding-bottom: 95.24%;
    `)}

    ${media.mobile(css`
      padding-bottom: 100%;
    `)}
  }
`;

const Inner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 30px;

  display: flex;
  align-items: flex-end;

  ${media.laptop(css`
    padding: 24px;
  `)}

  ${media.tablet(css`
    padding: 10px;
  `)}
  
  ${media.mobile(css`
    padding: 30px 20px;
  `)}
`;

const Image = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  div,
  picture {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const Label = styled.div`
  position: relative;
  padding: 40px 54px;
  border-radius: 50px;
  background: ${colors.white};
  overflow: hidden;

  display: flex;
  align-items: flex-start;

  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  color: ${colors.black};

  ${media.laptop(css`
    padding: 24px 32px;
    border-radius: 35px;

    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    padding: 14px 22px;
    font-size: 20px;
    line-height: 24px;
  `)}
  
  ${media.mobile(css`
    padding: 12px 20px;
    font-size: 16px;
    line-height: 20px;
  `)}
`;

const Variety = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin-left: 8px;

  ${media.laptop(css`
    font-size: 12px;
    line-height: 18px;
    margin-left: 6px;
  `)}

  ${media.tablet(css`
    font-size: 10px;
    line-height: 16px;
    margin-left: 4px;
  `)}
  
  ${media.mobile(css`
    font-size: 8px;
    line-height: 10px;
  `)}
`;

export default GalleryActiveField;
