import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';
import { useMedia } from '@tager/web-core';

import { breakpoints, colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { ImageThumbnail, StringField } from '@/typings/model';
import Picture from '@/components/Picture';

type Props = {
  historyItem: {
    image: ImageThumbnail;
    text: StringField;
    title: StringField;
    year: StringField;
  };
  isActive?: boolean;
  index: number;
};

function HistoryCard({ historyItem, index }: Props) {
  const { image, text, title, year } = historyItem;
  const cardRef = useRef<HTMLDivElement>(null);
  const [isActive, setActive] = useState<boolean>(false);

  function handleScroll() {
    if (!cardRef.current) return;

    if (
      cardRef.current.getBoundingClientRect().top > -100 &&
      cardRef.current.getBoundingClientRect().top <= 600
    ) {
      setActive(true);
    } else {
      setActive(false);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, []);

  return (
    <Components ref={cardRef}>
      <Inner>
        {image ? (
          <Image isActive={isActive}>
            <ImageInner>
              <Picture mobileSmall={convertThumbnailToPictureImage(image)} />
            </ImageInner>
          </Image>
        ) : null}
        <Content>
          <Year>{year}</Year>
          <Title>{title}</Title>
          <Text>{text}</Text>
        </Content>
      </Inner>
    </Components>
  );
}

const Inner = styled.div``;

const Content = styled.div``;

const Image = styled.div<{ isActive?: boolean }>`
  position: absolute;
  top: -340px;
  right: 0;

  max-width: 400px;
  width: 100%;

  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s linear, opacity 0.5s linear;

  ${(props) =>
    props.isActive
      ? css`
          visibility: visible;
          opacity: 1;
        `
      : ''}

  ${media.laptop(css`
    top: -152px;
    max-width: 234px;
  `)}
`;

const ImageInner = styled.div`
  width: 100%;
  position: relative;

  &:before {
    display: block;
    content: '';
    padding-bottom: 150.5%;

    ${media.laptop(css`
      padding-bottom: 170.9%;
    `)}
  }

  div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  picture,
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Year = styled.span`
  display: block;
  margin-bottom: 42px;
  font-weight: 500;
  font-size: 54px;
  line-height: 64px;
  color: ${colors.yellow100};

  ${media.laptop(css`
    margin-bottom: 32px;
    font-size: 40px;
    line-height: 48px;
  `)}
`;

const Title = styled.span`
  display: block;
  margin-bottom: 42px;
  max-width: 648px;
  font-weight: 500;
  font-size: 50px;
  line-height: 58px;
  color: ${colors.white};

  ${media.laptop(css`
    max-width: 588px;
    margin-bottom: 32px;
    font-size: 48px;
    line-height: 56px;
  `)}
`;

const Text = styled.p`
  max-width: 784px;
  font-size: 30px;
  line-height: 36px;
  color: ${colors.grayLight};

  ${media.laptop(css`
    max-width: 588px;
    font-size: 20px;
    line-height: 26px;
  `)}
`;

const Components = styled.div<{ isActive?: boolean }>`
  position: relative;

  ${(props) =>
    props.isActive
      ? css`
          ${Year} {
            font-size: 40px;
            line-height: 48px;
          }
        `
      : ''}
`;

export default HistoryCard;
