import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Navigation,
  Autoplay,
  Pagination,
  EffectFade,
} from 'swiper';

import { ModalProps } from '@tager/web-components';

import { media } from '@/utils/mixin';
import Picture from '@/components/Picture';
import productsUrl from '@/assets/images/products/products-modal.png';
import productsUrl2x from '@/assets/images/products/products-modal@2x.png';
import productsUrlWebp from '@/assets/images/products/products-modal.webp';
import productsUrlWebp2x from '@/assets/images/products/products-modal@2x.webp';
import { ReactComponent as SliderRight } from '@/assets/svg/slider-right.svg';
import { ReactComponent as SliderLeft } from '@/assets/svg/slider-left.svg';
import { PromotionProductsItem } from '@/typings/model';
import PromotionCardModel from '@/modules/Promotions/components/PromotionCardModal';

import CloseButton from '../shared/CloseButton';

SwiperCore.use([Navigation, Autoplay, Pagination, EffectFade]);

type Props = ModalProps<{
  activeSlide: number;
  products: Array<PromotionProductsItem>;
}>;

function PromotionsModal({ innerProps, closeModal }: Props) {
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);
  const NextElement = useRef<HTMLButtonElement>(null);
  const PrevElement = useRef<HTMLButtonElement>(null);

  const { activeSlide, products } = innerProps;

  function handleProductsChange(index: number) {
    setActiveSlideIndex(index);
  }

  return (
    <Wrapper>
      <CloseButton onClick={closeModal} />
      <Background>
        <Picture
          mobileSmall={{
            src: productsUrl,
            src2x: productsUrl2x,
            webp: productsUrlWebp,
            webp2x: productsUrlWebp2x,
          }}
        />
      </Background>
      <Inner>
        <SliderContainer>
          <Swiper
            slidesPerView={1}
            speed={500}
            navigation={{
              nextEl: '.swiper-next',
              prevEl: '.swiper-prev',
            }}
            onInit={(swiper) => {
              swiper.slideToLoop(activeSlide);
              setActiveSlideIndex(activeSlide);
            }}
            onSlideChange={(swiper) => setActiveSlideIndex(swiper.activeIndex)}
          >
            {products?.map((productItem, index) => {
              return (
                <SwiperSlide key={index}>
                  <SwiperSlideInner>
                    <PromotionCardModel product={productItem} />
                  </SwiperSlideInner>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </SliderContainer>
        <NextSlide className="swiper-next" ref={NextElement}>
          <SliderRight />
        </NextSlide>
        <PrevSlide className="swiper-prev" ref={PrevElement}>
          <SliderLeft />
        </PrevSlide>
      </Inner>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  padding: 100px 90px;
  position: relative;
  z-index: 1000;

  ${media.laptop(css`
    padding: 50px 90px;
  `)}

  ${media.tablet(css`
    padding: 50px 20px;
  `)}
`;
const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  div,
  img,
  picture {
    width: 100%;
    height: 100%;
  }
`;

const Inner = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const SliderContainer = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  position: relative;
  .swiper-container {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .swiper-wrapper {
    transform: translate3d(0px, 0, 0) !important;
  }

  .swiper-slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    transition: all 0.35s ease;

    &-active {
      z-index: 1;
      opacity: 1;
      visibility: visible;
      transition: all 0.4s ease;
    }
  }

  ${media.laptop(css`
    max-width: 950px;
  `)}
`;

const SwiperSlideInner = styled.div`
  width: 100%;
  height: 100%;
`;

const NextSlide = styled.button`
  position: absolute;
  content: '';
  width: 80px;
  height: 80px;
  top: 50%;
  transform: translateY(-50%);
  right: 60px;
  border-radius: 100%;
  transition: background-color 0.1s;
  padding: 20px;
  z-index: 40;

  ${media.laptopUp(css`
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  `)}

  svg {
    pointer-events: none;
  }

  @media (max-width: 1750px) {
    right: -60px;
  }

  ${media.laptop(css`
    right: 0;
    width: 60px;
    height: 60px;
    padding: 0 10px;

    svg {
      width: 28px;
      height: 24px;
    }
  `)}

  ${media.tablet(css`
    top: 47.5%;
    right: -10px;

    svg {
      width: 30px;
      height: 30px;
    }
  `)}
  
  ${media.mobile(css`
    top: 102px;
    right: -10px;
  `)}

  &.swiper-button-disabled {
    pointer-events: none;
    opacity: 0.3;
  }
`;

const PrevSlide = styled.button`
  position: absolute;
  content: '';
  width: 80px;
  height: 80px;
  top: 50%;
  transform: translateY(-50%);
  left: 60px;
  border-radius: 100%;
  transition: background-color 0.1s;
  padding: 20px;
  z-index: 40;

  ${media.laptopUp(css`
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  `)}

  svg {
    pointer-events: none;
  }

  @media (max-width: 1750px) {
    left: -60px;
  }

  ${media.laptop(css`
    left: 0;
    width: 60px;
    height: 60px;
    padding: 0 10px;

    svg {
      width: 28px;
      height: 24px;
    }
  `)}

  ${media.tablet(css`
    top: 47.5%;
    left: -10px;

    svg {
      width: 30px;
      height: 30px;
    }
  `)}
  
  ${media.mobile(css`
    top: 102px;
    left: -10px;
  `)}

  &.swiper-button-disabled {
    pointer-events: none;
    opacity: 0.3;
  }
`;

export default PromotionsModal;
