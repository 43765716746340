import React from 'react';
import styled from 'styled-components';

import { ImageThumbnail, StringField } from '@/typings/model';
import NewsCard from '@/modules/Home/components/News/components/NewsCard';
import { colors } from '@/constants/theme';

type Props = {
  newsList?: Array<{
    image: {
      landscape: ImageThumbnail;
      portrait: ImageThumbnail;
    };
    title: StringField;
    link: StringField;
  }>;
};

function NewsListMobile({ newsList }: Props) {
  return (
    <Block>
      <List>
        {newsList?.map((news, index) => {
          return (
            <Cell key={index}>
              <NewsCard newsItem={news} />
            </Cell>
          );
        })}
      </List>
    </Block>
  );
}

const Block = styled.div`
  margin: 0 -20px;
  padding: 0 20px;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: 4px;

    &-track {
      background-color: transparent;
    }

    &-thumb {
      background-color: transparent;
    }
  }

  &:after {
    display: block;
    content: '';
    width: 20px;
    height: 100%;
  }
`;

const List = styled.div`
  display: grid;
  grid-template-rows: repeat(2, calc(100vw / 100 * 145 / 2));
  grid-template-columns: repeat(2, 65vw);
  grid-auto-columns: 65vw;
  grid-gap: 20px;
  grid-auto-flow: column dense;
`;

const Cell = styled.div`
  & > div {
    height: 100%;
  }

  &:first-child {
    grid-row-end: span 2;
  }
`;

export default NewsListMobile;
