import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { PageByTemplateType } from '@/typings/model';
import Picture from '@/components/Picture';
import Link from '@/components/Link';

type Props = {
  magazinesItem: PageByTemplateType;
};

function MagazinesCard({ magazinesItem }: Props) {
  const { datetime, excerpt, image, title, path } = magazinesItem;
  return (
    <Component>
      {path ? <ComponentLink to={`${path}`} /> : null}
      <Inner>
        <Left>
          <ImageWrapper>
            {image ? (
              <Picture mobileSmall={convertThumbnailToPictureImage(image)} />
            ) : null}
          </ImageWrapper>
        </Left>
        <Right>
          <Title>{title}</Title>
          {datetime ? (
            <Date>
              {datetime
                .substring(0, datetime.indexOf(' '))
                .replace(/(\d+)-(\d+)-(\d+)/, '$3-$2-$1')
                .replace(/-/g, '.')}
            </Date>
          ) : null}
          <Text>{excerpt}</Text>
        </Right>
      </Inner>
    </Component>
  );
}

const ComponentLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

const Inner = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.mobile(css`
    flex-direction: column;
    align-items: center;
  `)}
`;

const Left = styled.div`
  overflow: hidden;
  margin-right: 32px;
  flex: 0 0 375px;
  max-width: 376px;

  ${media.laptop(css`
    margin-right: 24px;
    flex: 0 0 282px;
    max-width: 282px;
  `)}

  ${media.tablet(css`
    margin-right: 37px;
    flex: 0 0 250px;
    max-width: 250px;
  `)}
  
  ${media.mobile(css`
    flex: auto;
    max-width: 320px;
    margin-right: 0;
  `)}
`;

const Right = styled.div`
  flex: 1 1 auto;

  ${media.mobile(css`
    margin-top: 20px;
  `)}
`;

const ImageWrapper = styled.div`
  background: ${colors.grayLight};
  width: 100%;
  height: 490px;
  border-radius: 32px;
  overflow: hidden;

  ${media.laptop(css`
    border-radius: 24px;
    height: 368px;
  `)}

  ${media.tablet(css`
    height: 326px;
  `)}
  
  ${media.mobile(css`
    height: 420px;
  `)}

  div, picture {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top left;
    max-height: 100%;

    ${media.mobile(css`
      object-position: center center;
    `)}
  }
`;

const Title = styled.h3`
  margin: 0 0 32px;
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.black400};
  transition: all 0.3s linear;

  ${media.laptop(css`
    margin-bottom: 24px;
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    margin-bottom: 20px;
    font-size: 44px;
    line-height: 54px;
  `)}
  
  ${media.mobile(css`
    margin-bottom: 10px;
    font-size: 34px;
    line-height: 42px;
  `)}
  
  @media (max-width: 359px) {
    font-size: 30px;
  }
`;

const Date = styled.span`
  display: block;
  margin-bottom: 52px;

  font-size: 30px;
  line-height: 36px;
  color: ${colors.black500};

  ${media.laptop(css`
    margin-bottom: 40px;
    font-size: 20px;
    line-height: 26px;
  `)}

  ${media.tablet(css`
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 20px;
  `)}
`;

const Text = styled.p`
  max-width: 784px;
  font-size: 30px;
  line-height: 36px;
  color: ${colors.black500};

  ${media.laptop(css`
    max-width: 588px;
    font-size: 20px;
    line-height: 26px;
  `)}

  ${media.tablet(css`
    font-size: 18px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    max-width: none;
    font-size: 14px;
    line-height: 20px;
  `)}
`;

const Component = styled.div`
  position: relative;
  padding: 52px 0;
  border-bottom: 2px solid ${colors.grayLight};

  ${media.laptop(css`
    border-bottom: 1px solid ${colors.grayLight};
    padding: 40px 0;
  `)}

  ${media.tablet(css`
    padding: 30px 0;
  `)}

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }

  &:hover {
    ${Title} {
      color: ${colors.orange};
    }
  }
`;

export default MagazinesCard;
