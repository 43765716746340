import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Nullish } from '@tager/web-core';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { StringField, VacancyStoresItemType } from '@/typings/model';
import SelectComponent from '@/components/Select';
import { useTypedSelector } from '@/store/store';
import { selectVacancyByCitiesList } from '@/store/reducers/vacanciesCities';
import { getVacanciesByStore } from '@/services/requests';

import Badge from './components/Badge';

type Props = {
  title: StringField;
  statisticsTitle: StringField;
  cardText: StringField;
  text: StringField;
};

function Hero({ title, text, statisticsTitle, cardText }: Props) {
  const vacancyByCitiesList = useTypedSelector(selectVacancyByCitiesList);

  const [isActiveCity, setActiveCity] = useState<number | null>(null);
  const [isDisabled, setDisabled] = useState<boolean>(true);
  const [valueShop, setValueShop] = useState<string>('');
  const [valueShopId, setValueShopId] = useState<Nullish<number>>();
  const [valueCity, setValueCity] = useState<string>('');
  const [valuePosition, setValuePosition] = useState<string>('');

  const [isClearValue, setClearValue] = useState<boolean>(false);

  const [isActiveStore, setActiveStore] = useState<
    Array<VacancyStoresItemType>
  >([]);

  const [vacanciesBySelectedStore, setVacanciesBySelectedStore] = useState<
    Array<VacancyStoresItemType>
  >([]);

  function handleChangeShop(value: string, valueId?: number) {
    setValueShop(value);
    setValueShopId(valueId);
    setVacanciesBySelectedStore(
      isActiveStore.filter((store) => store.id === valueId)
    );
  }

  useEffect(() => {
    if (!isActiveCity) return;

    setDisabled(false);
    getVacanciesByStore(isActiveCity ?? 0).then((response) => {
      setActiveStore(response.data);
    });

    vacancyByCitiesList.forEach((item) => {
      if (item.id === isActiveCity) {
        setValueCity(item.name);
        setValueShop('');
        setValuePosition('');
        setClearValue(true);
        setTimeout(() => setClearValue(false), 100);
      }
    });
  }, [isActiveCity]);

  return (
    <Component>
      <Inner>
        <Left>
          <Top>
            <Title dangerouslySetInnerHTML={{ __html: title ?? '' }} />
            <Text>{text}</Text>
          </Top>
          <Bottom>
            {statisticsTitle ? (
              <SubTitle
                dangerouslySetInnerHTML={{ __html: statisticsTitle ?? '' }}
              />
            ) : null}
            <SelectList>
              <SelectCell>
                <SelectComponent
                  optionList={vacancyByCitiesList}
                  activeValue={isActiveCity ?? 0}
                  placeholder={'Город'}
                  onChange={(value: number) => setActiveCity(value)}
                />
              </SelectCell>
              <SelectCell>
                <SelectComponent
                  optionList={isActiveStore}
                  placeholder={'Магазин'}
                  onChange={handleChangeShop}
                  isLabel={true}
                  isDisabled={isDisabled}
                  isClearValue={isClearValue}
                />
              </SelectCell>
              <SelectCell>
                <SelectComponent
                  optionList={vacanciesBySelectedStore}
                  placeholder={'Должность'}
                  isVacancies={true}
                  onChange={(value: string) => setValuePosition(value)}
                  isLabel={true}
                  isDisabled={isDisabled}
                  isClearValue={isClearValue}
                  activeValue={valueShopId ?? ''}
                />
              </SelectCell>
            </SelectList>
          </Bottom>
        </Left>
        <Right>
          <Badge
            position={valuePosition}
            city={valueCity}
            shop={valueShop}
            vacancyList={isActiveStore}
            defaultText={cardText}
          />
        </Right>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  padding-top: 152px;

  ${media.laptop(css`
    padding-top: 104px;
  `)}

  ${media.tablet(css`
    padding-top: 51px;
  `)}

  ${media.mobile(css`
    padding-top: 30px;
  `)}
`;
const Inner = styled.div`
  max-width: 1680px;
  padding: 0 40px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  ${media.tablet(css`
    padding: 0 20px;
    flex-direction: column;
  `)}
`;

const Left = styled.div`
  flex: 1 1 834px;
  max-width: 834px;

  ${media.laptopOnly(css`
    flex: 1 1 764px;
    max-width: 764px;
  `)}

  ${media.tablet(css`
    flex: auto;
    max-width: none;
  `)}
`;

const Right = styled.div`
  flex: 1 1 auto;
  margin-top: -336px;
  min-height: 1637px;

  ${media.laptop(css`
    margin-top: -244px;
    min-height: 1226px;
  `)}

  ${media.tablet(css`
    flex: auto;
    margin-top: 30px;
    min-height: auto;
  `)}
`;

const Top = styled.div`
  margin-bottom: 192px;

  ${media.laptop(css`
    margin-bottom: 160px;
  `)}

  ${media.tablet(css`
    margin-bottom: 61px;
  `)}

  ${media.mobile(css`
    margin-bottom: 50px;
  `)}
`;
const Bottom = styled.div``;

const Title = styled.h1`
  margin: 0;
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.black};

  b {
    font-weight: 500;
    color: ${colors.orange};

    ${media.tablet(css`
      color: ${colors.black};
    `)}
  }

  ${media.laptop(css`
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    font-size: 48px;
    line-height: 56px;
  `)}

  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
  `)}
`;

const SubTitle = styled.h2`
  max-width: 740px;
  margin: 0;
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.black};

  b {
    font-weight: 500;
    color: ${colors.orange};
  }

  ${media.laptop(css`
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    max-width: none;
    font-size: 48px;
    line-height: 56px;
  `)}

  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
  `)}
`;

const Text = styled.p`
  display: block;
  max-width: 648px;
  margin-top: 50px;
  font-size: 30px;
  line-height: 36px;
  color: ${colors.black};

  ${media.laptop(css`
    max-width: 486px;
    margin-top: 23px;
    font-size: 20px;
    line-height: 26px;
  `)}

  ${media.tablet(css`
    margin-top: 30px;
    font-size: 18px;
    line-height: 22px;
  `)}

  ${media.mobile(css`
    font-size: 14px;
    line-height: 20px;
    max-width: none;
  `)}
`;

const SelectList = styled.div`
  margin-top: 164px;

  ${media.laptop(css`
    margin-top: 58px;
  `)}

  ${media.tablet(css`
    margin-top: 57px;
  `)}

  ${media.mobile(css`
    margin-top: 30px;
  `)}
`;

const SelectCell = styled.div`
  margin-bottom: 54px;

  ${media.laptop(css`
    margin-bottom: 50px;
  `)}
  ${media.tablet(css`
    margin-bottom: 37px;
  `)}
  &:last-child {
    margin-bottom: 0;
  }
`;

export default Hero;
