import React from 'react';
import styled, { css } from 'styled-components';

import Breadcrumbs from '@/components/Breadcrumbs';
import Hero from '@/modules/Post/components/Hero';
import { media } from '@/utils/mixin';
import { useLayout } from '@/components/Layout/Layout.hooks';
import useCurrentPage from '@/hooks/useCurrentPage';
import { JournalPageType } from '@/typings/model';
import useCurrentChildrenPages from '@/hooks/useCurrentChildrenPages';
import { BreadCrumbs } from '@/typings/common';
import { setBreadCrumbsList } from '@/utils/common';

import Static from '../Post/components/Main/components/Static';

import CalameloWidget from './components/CalameloWidget';
import PastIssues from './components/PastIssues';

function Journal() {
  const page = useCurrentPage<JournalPageType>();

  const { data: magazinesList } = useCurrentChildrenPages('magazines-single');
  const { setThemePage } = useLayout();
  setThemePage('');

  const pageFields = page?.templateFields;
  if (!pageFields) return null;

  const { code, date } = pageFields;

  const BREADCRUMB_LIST: Array<BreadCrumbs> = setBreadCrumbsList(
    {
      title: `Выпуск ${getJournalNumber(page?.title ?? '')}`,
      path: page?.path,
    },
    [
      { id: 2, title: 'Гипермаркет', path: '/hypermarkets' },
      { id: 3, title: `${page?.parent?.title}`, path: `${page?.parent?.path}` },
    ]
  );

  function getJournalNumber(journalTitle: string) {
    const indexNumber = journalTitle.indexOf('№');
    return journalTitle.slice(indexNumber);
  }

  function setJournalLink(journalCode: string) {
    return (
      '//v.calameo.com/?bkcode=' +
      journalCode +
      '&mode=mini&clickto=view&clicktarget=_self'
    );
  }

  return (
    <Wrapper>
      <Breadcrumbs breadcrumbs={BREADCRUMB_LIST} />
      <Content>
        <Hero title={page?.title ?? ''} date={date} />
        <Main>
          <Static body={page?.body ?? ''} />
          {code ? (
            <CalameloWrapper>
              <CalameloWidget journalLink={setJournalLink(code)} />
            </CalameloWrapper>
          ) : null}
        </Main>
        <PastIssuesWrapper>
          <PastIssues magazinesList={magazinesList} title={page?.title ?? ''} />
        </PastIssuesWrapper>
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  overflow: hidden;
`;

const Content = styled.div`
  margin-bottom: 242px;

  ${media.laptop(css`
    margin-bottom: 180px;
  `)}

  ${media.tabletSmall(css`
    margin-bottom: 120px;
  `)}
`;

const Main = styled.div`
  max-width: 1056px;
  margin: 60px auto 0;

  .table {
    margin: 0 !important;

    td {
      padding-right: 15px;

      ${media.tabletSmall(css`
        padding-right: 10px;
      `)}
    }
  }

  ${media.laptop(css`
    max-width: 792px;
    margin: 40px auto 0;
  `)}

  ${media.tablet(css`
    margin: 50px auto 0;
  `)}

  ${media.tabletSmall(css`
    max-width: 538px;
  `)}

  ${media.mobile(css`
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    & > div:first-child {
      order: 2;
    }
  `)}
`;

const CalameloWrapper = styled.div`
  text-align: center;
  margin-top: 60px;

  ${media.laptop(css`
    margin-top: 40px;
  `)}

  ${media.tablet(css`
    margin-top: 50px;
  `)}

  ${media.mobile(css`
    order: 1;
    margin-top: 0;
    margin-bottom: 30px;
  `)}
`;

const PastIssuesWrapper = styled.div`
  max-width: 1056px;
  margin: 80px auto 0;

  ${media.laptop(css`
    max-width: 792px;
    margin-top: 120px;
  `)}

  ${media.tablet(css`
    margin-top: 70px;
  `)}

  ${media.tabletSmall(css`
    max-width: 100%;
    padding: 0 20px;
  `)}

  ${media.mobile(css`
    order: 3;
    margin-top: 39px;
  `)}
`;

export default Journal;
