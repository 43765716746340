import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { StringField } from '@/typings/model';

import StarsVacancies from './components/StarsVacancies';
import {
  STARS_IMAGE_BACKGROUND,
  STARS_ORANGE_BACKGROUND,
  STARS_YELLOW_BACKGROUND,
} from './Vacancies.constants';

type Props = {
  careersLink: StringField;
  careersTitle: StringField;
};

function Vacancies({ careersTitle, careersLink }: Props) {
  return (
    <Component>
      <Inner>
        <StarsVacancies image={STARS_ORANGE_BACKGROUND} />
        <StarsVacancies image={STARS_IMAGE_BACKGROUND} />
        <StarsVacancies
          careersLink={careersLink}
          careersTitle={careersTitle}
          image={STARS_YELLOW_BACKGROUND}
          isSvg={true}
        />
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  padding: 138px 0 318px;

  ${media.laptop(css`
    padding: 118px 0 281px;
  `)}

  ${media.tablet(css`
    padding: 80px 0 108px;
  `)}
  
  ${media.mobile(css`
    padding: 50px 0 84px;
  `)}
`;
const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;

  max-width: 1680px;
  padding: 0 40px;
  margin: 0 auto;

  ${media.tablet(css`
    justify-content: center;
    padding: 0;
    margin: 0 -20px;
  `)}
`;

export default Vacancies;
