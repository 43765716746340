import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import SelectComponent from '@/components/Select';
import { useTypedSelector } from '@/store/store';
import { selectVacancyByCitiesList } from '@/store/reducers/vacanciesCities';
import { getVacanciesByStore, getVacancyCategories } from '@/services/requests';
import {
  VacanciesShortItem,
  VacancyCategoriesItemType,
  VacancyStoresItemType,
} from '@/typings/model';
import { selectVacancyByCategoriesList } from '@/store/reducers/vacanciesCategories';

import CategoryList from './components/CategoryList';
import VacanciesList from './components/VacanciesList';

function JobOpportunity() {
  const contentRef = useRef<HTMLDivElement>(null);
  const componentRef = useRef<HTMLDivElement>(null);

  const vacancyByCitiesList = useTypedSelector(selectVacancyByCitiesList);
  const vacancyByCategoriesList = useTypedSelector(
    selectVacancyByCategoriesList
  );

  const [isActiveCity, setActiveCity] = useState(-1);
  const [isActiveIndex, setActiveIndex] = useState(0 ?? {});
  const [activeVacancies, setActiveVacancies] = useState<number>(12);
  const [isFirstState, setFirstState] = useState<boolean>(false);

  const [isDisabled, setDisabled] = useState<boolean>(true);
  const [valueShop, setValueShop] = useState<string>('');
  const [isClearValue, setClearValue] = useState<boolean>(false);

  const [isActiveCategories, setActiveCategories] = useState<
    Array<VacancyCategoriesItemType>
  >(vacancyByCategoriesList);

  const [isActiveStore, setActiveStore] = useState<
    Array<VacancyStoresItemType>
  >([]);

  const [isActiveList, setActiveList] = useState<
    Array<VacancyCategoriesItemType>
  >(vacancyByCategoriesList);

  function scrollTo(section: any) {
    const targetElement = section;
    if (targetElement) {
      const y =
        targetElement.getBoundingClientRect().top + window.pageYOffset - 50;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }

  function getFullVacancyList(list: Array<VacancyCategoriesItemType>) {
    const vacancyList: Array<VacanciesShortItem> = [];

    list.map((listItem, index) => {
      listItem.vacancies.map((vacancyItem, index) => {
        vacancyList.push(vacancyItem);
      });
    });

    if (valueShop) {
      const vacancyByStore = vacancyList.filter(
        (vacancy) => vacancy.store === valueShop
      );
      return vacancyByStore.length;
    } else {
      return vacancyList.length;
    }
  }

  useEffect(() => {
    setActiveCategories(vacancyByCategoriesList);
    setActiveList(vacancyByCategoriesList);
  }, [vacancyByCategoriesList]);

  useEffect(() => {
    if (isActiveCity === -1) return;

    getVacancyCategories(isActiveCity).then((response) => {
      scrollTo(componentRef.current);

      setTimeout(() => {
        setActiveIndex(0);
        setActiveVacancies(12);
        setActiveCategories(response.data);
        setActiveList(response.data);
      }, 400);
    });

    setDisabled(false);
    getVacanciesByStore(isActiveCity ?? 0).then((response) => {
      setActiveStore(response.data);
    });
    vacancyByCitiesList.forEach((item) => {
      if (item.id === isActiveCity) {
        setValueShop('');
        setClearValue(true);
        setTimeout(() => setClearValue(false), 100);
      }
    });
  }, [isActiveCity]);

  useEffect(() => {
    if (!isFirstState) {
      setFirstState(true);
      return;
    }
    scrollTo(componentRef.current);
    setTimeout(() => {
      if (isActiveIndex === 0) {
        setActiveList(isActiveCategories);
      } else {
        setActiveList([isActiveCategories[isActiveIndex - 1]]);
      }
    }, 400);
    setActiveVacancies(12);
  }, [isActiveIndex]);

  useEffect(() => {
    if (valueShop === '') return;

    scrollTo(componentRef.current);
    setTimeout(() => {
      setActiveIndex(0);
      setActiveVacancies(12);
    }, 400);
  }, [valueShop]);

  return (
    <Component ref={componentRef}>
      <Inner>
        <Left>
          <LeftInner>
            <Title>Найдите нужную вакансию</Title>
            <SelectWrapper>
              <SelectComponent
                optionList={vacancyByCitiesList}
                placeholder={'Город'}
                onChange={(value: number) => {
                  setActiveCity(value);
                }}
                activeValue={isActiveCity}
              />
            </SelectWrapper>
            <SelectWrapper>
              <SelectComponent
                optionList={isActiveStore}
                placeholder={'Магазин'}
                onChange={(value: string) => setValueShop(value)}
                isLabel={true}
                isDisabled={isDisabled}
                isClearValue={isClearValue}
              />
            </SelectWrapper>
            <CategoryList
              categoryList={isActiveCategories}
              vacanciesLength={getFullVacancyList(isActiveCategories)}
              isActiveIndex={isActiveIndex}
              setActiveIndex={(value: number) => setActiveIndex(value)}
              valueShop={valueShop}
            />
          </LeftInner>
        </Left>
        <Right>
          <VacanciesList
            contentRef={contentRef}
            vacanciesList={isActiveList}
            activeVacancies={activeVacancies}
            setActiveVacancies={(count: number) => setActiveVacancies(count)}
            valueShop={valueShop}
          />
        </Right>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  max-width: 1680px;
  margin: 160px auto 194px;
  padding: 0 40px;

  ${media.laptop(css`
    margin: 120px auto 180px;
  `)}

  ${media.tablet(css`
    padding: 0 20px;
    margin: 50px auto 10px;
  `)}
  
  ${media.mobile(css`
    margin: 50px auto 0;
  `)}
`;

const Inner = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.tablet(css`
    flex-direction: column;
  `)}
`;

const Left = styled.div`
  flex: 1 1 648px;
  max-width: 648px;
  position: relative;

  ${media.laptop(css`
    flex: 1 1 488px;
    max-width: 488px;
  `)}

  ${media.tablet(css`
    flex: auto;
    max-width: none;
  `)}
`;

const Right = styled.div`
  flex: 1 1 784px;
  max-width: 784px;

  ${media.laptop(css`
    flex: 1 1 588px;
    max-width: 588px;
  `)}

  ${media.tablet(css`
    margin-top: 70px;
    flex: auto;
    max-width: none;
  `)}
  
   ${media.mobile(css`
    margin-top: 50px;
  `)}
`;

const LeftInner = styled.div`
  width: 100%;
  position: sticky;
  top: 20px;
  min-height: 398px;

  ${media.laptop(css`
    min-height: 378px;
  `)}

  ${media.tablet(css`
    min-height: auto;
  `)}
`;

const Title = styled.h2`
  font-weight: 500;
  font-size: 54px;
  line-height: 64px;
  color: ${colors.black};

  ${media.laptop(css`
    font-size: 40px;
    line-height: 48px;
  `)}

  ${media.tablet(css`
    font-size: 28px;
    line-height: 34px;
  `)}
  
  ${media.mobile(css`
    font-size: 22px;
    line-height: 28px;
  `)}
`;

const SelectWrapper = styled.div`
  margin: 0 0 35px;

  ${media.laptop(css`
    margin: 0 0 25px;
  `)}

  ${media.tablet(css`
    max-width: 65%;
  `)}
  
  ${media.mobile(css`
    max-width: none;
  `)}

  &:nth-child(2) {
    margin-top: 12px;

    ${media.laptop(css`
      margin-top: 40px;
    `)}

    ${media.tablet(css`
      margin-top: 36px;
    `)}
    
     ${media.mobile(css`
      margin-top: 31px;
    `)}
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export default JobOpportunity;
