import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import {
  ImageThumbnail,
  PageByTemplateType,
  StringField,
} from '@/typings/model';
import Button from '@/components/Button';
import NewsBanner from '@/modules/News/components/NewsBanner';

import RecipesCard from '../RecipesCard';

interface Props {
  title: StringField;
  list: Array<PageByTemplateType>;
  bannerImage?: ImageThumbnail;
  bannerLink: StringField;
  bannerTitle: StringField;
}

function Main({ title, list, bannerLink, bannerImage, bannerTitle }: Props) {
  const [activeRecipe, setActiveRecipe] = useState<number>(8);

  const tabletMedia = useMedia('(max-width: 1259.9px)');

  function setRecipeList(
    list: Array<PageByTemplateType>,
    activeRecipe: number
  ) {
    return list.slice(0, activeRecipe);
  }

  function getRecipeLength(list: Array<PageByTemplateType>) {
    return list.length;
  }

  function addMore(count: number) {
    setActiveRecipe(count + 8);
  }

  return (
    <Component>
      <Inner>
        <Top>
          <Title>{title}</Title>
        </Top>
        <Top>
          <Left>
            <List>
              {!tabletMedia &&
                setRecipeList(list, activeRecipe)?.map((recipe, index) => {
                  return <RecipesCard recipe={recipe} key={index} />;
                })}
              {tabletMedia &&
                setRecipeList(list, activeRecipe)?.map((recipe, index) => {
                  return index === 3 && tabletMedia ? (
                    <>
                      <BannerWrapper>
                        <NewsBanner
                          bannerImage={bannerImage}
                          bannerLink={bannerLink ?? ''}
                          bannerTitle={bannerTitle ?? ''}
                        />
                      </BannerWrapper>
                      <RecipesCard recipe={recipe} key={index} />
                    </>
                  ) : (
                    <RecipesCard recipe={recipe} key={index} />
                  );
                })}
            </List>
          </Left>
          {!tabletMedia && (
            <Right>
              <BannerWrapper>
                {bannerImage && (
                  <NewsBanner
                    bannerImage={bannerImage}
                    bannerLink={bannerLink}
                    bannerTitle={bannerTitle}
                  />
                )}
              </BannerWrapper>
            </Right>
          )}
        </Top>
        <Bottom>
          <ButtonWrapper isHidden={getRecipeLength(list) <= activeRecipe}>
            <Button variant="orange" onClick={() => addMore(activeRecipe)}>
              Показать еще
            </Button>
          </ButtonWrapper>
        </Bottom>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  max-width: 1680px;
  padding: 0 40px;
  margin: 0 auto;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

const Inner = styled.div`
  padding: 92px 0 240px;

  ${media.laptop(css`
    padding: 64px 0 180px;
  `)}

  ${media.tablet(css`
    padding: 30px 0 150px;
  `)}
  
  ${media.mobile(css`
    padding: 20px 0 130px;
  `)}
`;

const Top = styled.div`
  display: flex;
  margin-bottom: 29px;

  ${media.laptop(css`
    margin-bottom: 24px;
  `)}

  ${media.tablet(css`
    flex-direction: column;
  `)}
  
  ${media.mobile(css`
    margin-bottom: 30px;
  `)}
`;

const Title = styled.h2`
  margin: 0 20px 0 0;
  font-weight: 500;
  font-size: 50px;
  line-height: 58px;
  color: ${colors.black};

  ${media.laptop(css`
    font-size: 48px;
    line-height: 56px;
    margin: 0 16px 0 0;
  `)}

  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
  `)}
`;

const Bottom = styled.div``;

const Left = styled.div`
  flex: 1 1 auto;
  margin-right: 32px;

  ${media.laptop(css`
    margin-right: 24px;
  `)}

  ${media.tablet(css`
    margin-right: 0;
  `)}
`;

const Right = styled.div`
  flex: 0 0 376px;
  max-width: 376px;

  ${media.laptop(css`
    flex: 0 0 384px;
    max-width: 384px;
  `)}
`;

const List = styled.div``;

const ButtonWrapper = styled.div<{ isHidden?: boolean }>`
  margin-top: 100px;

  ${(props) =>
    props.isHidden
      ? css`
          display: none;
        `
      : ''}

  button {
    width: 100%;
    padding: 21px 0;
    border-radius: 2px;
    font-weight: bold;
    font-size: 36px;
    line-height: 48px;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
  }

  ${media.laptop(css`
    margin-top: 80px;

    button {
      padding: 24px 0;
      font-size: 24px;
      line-height: 32px;
    }
  `)}

  ${media.tablet(css`
    margin-top: 30px;

    button {
      padding: 17px 0;
      font-size: 20px;
      line-height: 26px;
    }
  `)}
  
  ${media.mobile(css`
    margin-top: 50px;

    button {
      padding: 15px 0;
      font-size: 16px;
      line-height: 20px;
    }
  `)}
`;

const BannerWrapper = styled.div`
  margin-top: -40px;
  position: sticky;
  top: 60px;

  ${media.laptop(css`
    top: 40px;
  `)}

  ${media.tablet(css`
    position: static;
    margin: 30px 0;
  `)}
  
  ${media.mobile(css`
    margin: 20px 0;
  `)}
`;

export default Main;
