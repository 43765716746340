import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

interface Props {
  body?: string;
}

function About({ body }: Props) {
  return (
    <Component>
      <Inner>
        <Static dangerouslySetInnerHTML={{ __html: body ?? '' }} />
      </Inner>
    </Component>
  );
}

const Component = styled.div``;
const Inner = styled.div`
  max-width: 1328px;
  margin: 0 auto;

  ${media.laptop(css`
    max-width: 996px;
  `)}
`;

const Static = styled.div`
  h2 {
    margin: 0 0 42px;
    font-weight: 500;
    font-size: 72px;
    line-height: 80px;

    ${media.laptop(css`
      margin-bottom: 32px;
      font-size: 64px;
      line-height: 72px;
    `)}
  }

  h3 {
    font-weight: 500;
    font-size: 50px;
    line-height: 58px;
    margin: 3px 0 34px;

    ${media.laptop(css`
      margin: 0 0 16px;
      font-size: 48px;
      line-height: 56px;
    `)}
  }

  h4 {
    margin: 84px 0 27px;
    font-weight: 500;
    font-size: 32px;
    line-height: 42px;

    ${media.laptop(css`
      margin: 56px 0 16px;
      font-size: 24px;
      line-height: 32px;
    `)}
  }

  p {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 34px;

    ${media.laptop(css`
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 26px;
    `)}

    ${media.tablet(css`
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 22px;
    `)}
    
    ${media.mobile(css`
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 15px;
    `)}
  }

  table {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 34px;

    ${media.laptop(css`
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 26px;
    `)}
  }

  b,
  strong {
    font-weight: 700;
  }

  a {
    color: ${colors.orange};
    text-decoration: underline;
    text-decoration-color: ${colors.orange};
    transition: all 0.3s linear;

    &:hover {
      text-decoration-color: transparent;
    }
  }

  img {
    margin: 40px auto 20px;
    max-width: 100%;
    object-fit: cover;
    border-radius: 36px;
    overflow: hidden;

    ${media.laptop(css`
      margin: 24px auto 16px;
      border-radius: 24px;
    `)}

    &+figcaption {
      margin-top: 20px;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      color: ${colors.grayLight};

      ${media.laptop(css`
        margin-top: 16px;
        font-size: 16px;
        line-height: 24px;
      `)}
    }
  }

  .image-style-side {
    float: left;
    margin: 0 20px 20px 0;

    img {
      margin: 0;
      width: auto;
      border-radius: 0;
    }
  }

  figure {
    margin-bottom: 34px;
    ${media.laptop(css`
      margin-bottom: 26px;
    `)}
  }

  ul,
  ol {
    margin-bottom: 84px;

    ${media.laptop(css`
      margin-bottom: 56px;
    `)}
  }

  ul {
    li {
      position: relative;
      text-indent: 40px;
      font-weight: normal;
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 24px;

      &:before {
        position: absolute;
        content: '';
        top: 12px;
        left: 0;
        width: 12px;
        height: 12px;
        border-radius: 100%;
        background: ${colors.orange};
      }

      ${media.laptop(css`
        margin-bottom: 0;
        text-indent: 28px;
        font-size: 20px;
        line-height: 40px;

        &:before {
          top: 16px;
          width: 8px;
          height: 8px;
        }
      `)}

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ol {
    counter-reset: list;
    li {
      position: relative;
      text-indent: 40px;
      font-weight: normal;
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 24px;

      &:before {
        position: absolute;
        counter-increment: list;
        content: counter(list) '.';
        font-weight: 600;
        top: 0;
        left: -38px;
        color: ${colors.orange};
      }

      ${media.laptop(css`
        margin-bottom: 0;
        text-indent: 28px;
        font-size: 20px;
        line-height: 40px;

        &:before {
          left: -28px;
        }
      `)}

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .table {
    overflow-x: auto;
    max-width: 1056px;

    ${media.laptop(css`
      max-width: 792px;
    `)}
  }

  table {
    table-layout: fixed;
    width: 100%;
    max-width: 100vw;
    margin: 56px auto 0;

    thead {
      th {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        color: ${colors.grayLight};
        padding-bottom: 48px;

        ${media.laptop(css`
          font-size: 12px;
          padding-bottom: 40px;
        `)}

        p {
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 0;
          ${media.laptop(css`
            font-size: 12px;
          `)}
        }
      }
    }

    tbody {
      tr {
        td {
          position: relative;
          padding: 32px 0;
          font-weight: 500;
          font-size: 20px;
          line-height: 26px;
          color: ${colors.black};
          vertical-align: baseline;

          a {
            text-decoration-color: transparent;

            &:hover {
              text-decoration-color: ${colors.orange};
            }
          }

          p {
            font-weight: 500;
            font-size: 20px;
            line-height: 26px;
            margin-bottom: 0;
            ${media.laptop(css`
              font-size: 16px;
              line-height: 24px;
            `)}
          }

          ${media.laptop(css`
            padding: 24px 0;
            font-size: 16px;
            line-height: 24px;
          `)}

          &:after {
            position: absolute;
            content: '';
            bottom: 0;
            left: 0;
            right: -3px;
            height: 1px;
            background: ${colors.grayLight};
          }

          &:last-child {
            &:after {
              right: 0;
            }
          }
        }

        &:first-child {
          td {
            padding-top: 0;
          }
        }

        &:last-child {
          td {
            padding-bottom: 0;
            &:after {
              display: none;
            }
          }
        }
      }
    }
  }
`;

export default About;
