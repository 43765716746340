import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

function getBorderColor(
  props: { invalid?: boolean; active?: boolean } = {}
): string {
  return props.invalid
    ? colors.red
    : props.active
    ? colors.black
    : colors.black100;
}

export const TextArea = styled.textarea<{
  invalid?: boolean;
}>`
  width: 100%;
  padding: 10px 0;
  height: 60px;
  max-height: 290px;
  background: transparent;
  resize: none;
  border: none;
  border-bottom: 2px solid ${colors.black};
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;

  color: ${colors.black};

  overflow-y: auto;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 0.375rem;

    &-track {
      background-color: transparent;
    }

    &-thumb {
      background-color: ${colors.orange};

      &:hover {
        background-color: ${colors.orange100};
      }
    }
  }

  ${media.laptop(css`
    height: 35px;
    max-height: 150px;
    border-bottom: 1px solid ${colors.black};
    font-size: 20px;
    line-height: 26px;
    padding: 4px 0;
  `)}

  ${media.tablet(css`
    font-size: 18px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    height: 30px;
    font-size: 14px;
    line-height: 20px;
  `)}

  &::placeholder {
    opacity: 0.4;
    color: ${colors.black};
    transition: all 0.4s ease-in-out;
  }

  &:focus {
    &::placeholder {
      opacity: 0;
      visibility: hidden;
    }
  }
`;
