import React from 'react';
import styled, { css } from 'styled-components';

import {
  convertThumbnailToPictureImage,
  ThumbnailType,
} from '@tager/web-modules';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import { StringField } from '@/typings/model';
import Picture from '@/components/Picture';

interface Props {
  text: StringField;
  logo: ThumbnailType | null;
}

function Hero({ text, logo }: Props) {
  return (
    <Component>
      <Inner>
        <Left>
          <Logo>
            {logo && (
              <Picture mobileSmall={convertThumbnailToPictureImage(logo)} />
            )}
          </Logo>
        </Left>
        <Right>
          <Text>{text}</Text>
        </Right>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  max-width: 1680px;
  padding: 0 40px;
  margin: 0 auto 98px;

  ${media.laptop(css`
    margin-bottom: 78px;
  `)}

  ${media.tablet(css`
    padding: 0 20px;
    margin-bottom: 30px;
  `)}
  
  ${media.mobile(css`
    margin-bottom: 56px;
  `)}
`;

const Inner = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.tablet(css`
    flex-direction: column;
    align-items: flex-end;
  `)}
`;

const Left = styled.div`
  flex: 1 1 784px;
  max-width: 784px;
  margin-right: 30px;

  ${media.laptop(css`
    flex: 1 1 586px;
    max-width: 586px;
  `)}

  ${media.tablet(css`
    flex: 1 1 auto;
    max-width: calc(100% - 264px);
    width: 100%;
    margin: 0 0 12px;
  `)}
  
  ${media.mobile(css`
    max-width: 100%;
    margin-bottom: 30px;
  `)}
`;

const Right = styled.div`
  flex: 1 1 784px;
  max-width: 784px;

  display: flex;
  align-items: flex-end;

  ${media.laptop(css`
    flex: 1 1 486px;
    max-width: 486px;
  `)}

  ${media.tablet(css`
    flex: 1 1 auto;
    max-width: calc(100% - 264px);
  `)}
  
  ${media.mobile(css`
    max-width: 100%;
  `)}
`;

const Logo = styled.div`
  div,
  picture {
    width: 100%;
    height: 100%;
  }

  svg,
  img {
    height: 190px;

    ${media.laptop(css`
      height: 142px;
    `)}

    ${media.tablet(css`
      height: 82px;
    `)}
    
    ${media.mobileLarge(css`
      height: auto;
    `)}
  }
`;

const Text = styled.p`
  font-size: 30px;
  line-height: 36px;
  color: ${colors.black};

  ${media.laptop(css`
    font-size: 20px;
    line-height: 26px;
  `)}

  ${media.tablet(css`
    font-size: 18px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    font-size: 14px;
    line-height: 20px;
  `)}
`;

export default Hero;
