import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { StringField } from '@/typings/model';

interface Props {
  place: {
    text: StringField;
  };
}

function PresentCard({ place }: Props) {
  return (
    <Component>
      <Inner>
        <Title>{place.text}</Title>
      </Inner>
    </Component>
  );
}

const Component = styled.div``;

const Inner = styled.div`
  border-top: 2px solid ${colors.grayLight};
  padding-top: 42px;

  ${media.laptop(css`
    padding-top: 32px;
    border-width: 1px;
  `)}

  ${media.tablet(css`
    padding-top: 16px;
  `)}
  
  ${media.mobile(css`
    padding-top: 30px;
  `)}
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 54px;
  line-height: 64px;
  color: ${colors.black};
  word-break: break-word;

  ${media.laptop(css`
    font-size: 40px;
    line-height: 48px;
  `)}

  ${media.tablet(css`
    font-size: 20px;
    line-height: 24px;
  `)}
  
  ${media.mobile(css`
    font-size: 22px;
    line-height: 28px;
  `)}
`;

export default PresentCard;
