import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { media } from '@/utils/mixin';
import { BackgroundType } from '@/modules/About/components/Vacancies/Vacancies.constants';
import { StringField } from '@/typings/model';
import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import { ReactComponent as StarsIcon } from '@/assets/svg/stars.svg';
import Link from '@/components/Link';

type Props = {
  image: BackgroundType;
  careersLink?: StringField;
  careersTitle?: StringField;
  isSvg?: boolean;
};

function StarsVacancies({ image, careersLink, careersTitle, isSvg }: Props) {
  return (
    <Component isLink={careersLink}>
      <Inner>
        <Background>
          {!isSvg ? (
            <Picture mobileSmall={convertThumbnailToPictureImage(image)} />
          ) : (
            <StarsIcon />
          )}
        </Background>
        {careersLink ? (
          <VacanciesLink to={careersLink}>
            <VacanciesLabel>{careersTitle}</VacanciesLabel>
          </VacanciesLink>
        ) : null}
      </Inner>
    </Component>
  );
}

const Inner = styled.div`
  position: relative;
  width: 100%;
  border-radius: 100%;

  &:before {
    display: block;
    content: '';
    padding-bottom: 100%;
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: ${colors.yellow200};
  transition: all 0.3s linear;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const Component = styled.div<{ isLink?: StringField }>`
  flex: 1 1 49%;
  min-width: 49%;
  max-width: 49%;

  ${media.tablet(css`
    flex: 1 1 460px;
    min-width: 460px;
    max-width: 460px;
  `)}

  ${media.mobile(css`
    flex: 1 1 260px;
    min-width: 260px;
    max-width: 260px;
  `)}

  ${(props) =>
    props.isLink
      ? css`
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          min-width: calc(50vw - 50px);
          max-width: calc(50vw - 50px);

          @media (min-width: 1921px) {
            min-width: 784px;
            max-width: 784px;
          }

          ${Inner} {
            &:before {
              padding-bottom: 86%;

              @media (min-width: 1921px) {
                padding-bottom: 100%;
              }
              ${media.laptop(css`
                padding-bottom: 100%;
              `)}
            }
          }
        `
      : ''}

  &:hover {
    ${Background} {
      color: ${colors.yellowHover};
    }
  }
`;

const VacanciesLabel = styled.span`
  max-width: 550px;
  position: relative;
  pointer-events: none;

  font-weight: bold;
  font-size: 96px;
  line-height: 110px;
  letter-spacing: -1px;
  text-align: center;

  text-decoration: underline;
  text-decoration-color: ${colors.black};
  transition: all 0.3s linear;

  ${media.laptop(css`
    max-width: 410px;
    font-size: 72px;
    line-height: 80px;
  `)}

  ${media.tablet(css`
    max-width: 364px;
    font-size: 56px;
    line-height: 62px;
  `)}
  
  ${media.mobile(css`
    max-width: 194px;
    font-size: 32px;
    line-height: 36px;
  `)}
`;

const VacanciesLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export default StarsVacancies;
