import React from 'react';
import styled, { css } from 'styled-components';

import { StringField } from '@/typings/model';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import Star from '@/components/Sticker/Star';

type Props = {
  title: StringField;
  text: StringField;
  stickerLabel: StringField;
  stickerUrl: StringField;
};

function Hero({ title, text, stickerLabel, stickerUrl }: Props) {
  return (
    <Component>
      <Inner>
        <Left>
          <Title dangerouslySetInnerHTML={{ __html: title ?? '' }} />
        </Left>
        <Right>
          <Top>
            <StarsWrapper>
              <Star
                color="lime"
                rotate={'3deg'}
                size="big"
                label={stickerLabel ?? ''}
                link={stickerUrl ?? ''}
              />
            </StarsWrapper>
          </Top>
          <Bottom>
            <Text>{text}</Text>
          </Bottom>
        </Right>
      </Inner>
    </Component>
  );
}

const Component = styled.div``;

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1680px;
  padding: 0 40px;
  margin: 0 auto;

  ${media.tablet(
    css`
      flex-direction: column;
      padding: 0 20px;
    `
  )}
`;

const Left = styled.div`
  flex: 1 1 50%;
  max-width: 50%;
  padding-top: 172px;

  ${media.laptop(
    css`
      padding-top: 104px;
    `
  )}

  ${media.tablet(
    css`
      flex: auto;
      max-width: none;
      padding-top: 51px;
    `
  )}
  
  ${media.mobile(
    css`
      padding-top: 30px;
    `
  )}
`;

const Right = styled.div`
  flex: 1 1 49%;
  max-width: 49%;
  position: relative;

  ${media.tablet(css`
    flex: auto;
    max-width: none;
    margin-top: 30px;
  `)}
`;

const Top = styled.div`
  display: flex;

  position: absolute;
  top: 0;
  right: 0;
`;

const Bottom = styled.div`
  margin-top: 398px;

  ${media.laptop(css`
    margin-top: 319px;
  `)}

  ${media.tablet(css`
    margin-top: 0;
  `)}
`;

const StarsWrapper = styled.div`
  transform: rotate(12deg);
  position: relative;
  top: 23px;
  left: -36px;
  width: 316px;
  height: 316px;

  ${media.laptop(css`
    top: 11px;
    left: -27px;
    width: 240px;
    height: 240px;
  `)}

  ${media.tablet(css`
    top: 0;
    left: 0;
    width: 128px;
    height: 128px;
  `)}
  
   ${media.mobile(css`
    display: none;
  `)}
`;

const Title = styled.h1`
  margin: 0;
  max-width: 648px;
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.black};

  b {
    font-weight: 500;
    color: ${colors.orange};
  }

  ${media.laptop(css`
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    max-width: none;
    font-size: 48px;
    line-height: 56px;
  `)}
  
  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
  `)}
`;

const Text = styled.p`
  font-size: 30px;
  line-height: 36px;
  color: ${colors.black};

  ${media.laptop(css`
    font-size: 20px;
    line-height: 26px;
  `)}

  ${media.tablet(css`
    max-width: 80%;
    font-size: 18px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    max-width: none;
    font-size: 14px;
    line-height: 20px;
  `)}
`;

export default Hero;
