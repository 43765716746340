import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import Button from '@/components/Button';
import { PageByTemplateType } from '@/typings/model';

import MagazinesCard from './components/MagazinesCard';

type Props = {
  magazinesList: Array<PageByTemplateType>;
};

function MagazinesList({ magazinesList }: Props) {
  const [activeMagazines, setActiveMagazines] = useState<number>(8);

  function setMagazinesList(
    list: Array<PageByTemplateType>,
    activeMagazines: number
  ) {
    return list.slice(0, activeMagazines);
  }

  function getMagazinesLength(list: Array<PageByTemplateType>) {
    return list.length;
  }

  function addMore(count: number) {
    setActiveMagazines(count + 8);
  }

  return (
    <Component>
      <Inner>
        <List>
          {setMagazinesList(magazinesList, activeMagazines)?.map(
            (magazinesItem, index) => {
              return (
                <MagazinesCard key={index} magazinesItem={magazinesItem} />
              );
            }
          )}
        </List>
        <Bottom>
          <ButtonWrapper
            isHidden={getMagazinesLength(magazinesList) <= activeMagazines}
          >
            <Button variant="yellow" onClick={() => addMore(activeMagazines)}>
              Показать еще
            </Button>
          </ButtonWrapper>
        </Bottom>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  max-width: 1680px;
  padding: 0 40px;
  margin: 0 auto;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

const Inner = styled.div`
  padding: 92px 0 240px;

  ${media.laptop(css`
    padding: 64px 0 180px;
  `)}

  ${media.tablet(css`
    padding: 51px 0 150px;
  `)}
  
  ${media.mobile(css`
    padding: 30px 0 150px;
  `)}
`;

const List = styled.div``;

const Bottom = styled.div`
  margin-top: 120px;

  ${media.laptop(css`
    margin-top: 80px;
  `)}

  ${media.tablet(css`
    margin-top: 50px;
  `)}
  
   ${media.mobile(css`
    margin-top: 30px;
  `)}
`;

const ButtonWrapper = styled.div<{ isHidden?: boolean }>`
  ${(props) =>
    props.isHidden
      ? css`
          display: none;
        `
      : ''}

  button {
    width: 100%;
    padding: 21px 0;
    border-radius: 2px;
    font-weight: bold;
    font-size: 36px;
    line-height: 48px;
    transition: all 0.3s ease-in-out;
  }

  ${media.laptop(css`
    button {
      padding: 24px 0;
      font-size: 24px;
      line-height: 32px;
    }
  `)}

  ${media.tablet(css`
    button {
      padding: 17px 0;
      font-size: 20px;
      line-height: 26px;
    }
  `)}
  
  ${media.mobile(css`
    button {
      padding: 15px 0;
      font-size: 16px;
      line-height: 20px;
    }
  `)}
`;

export default MagazinesList;
