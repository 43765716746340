import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { PageByTemplateType } from '@/typings/model';
import Picture from '@/components/Picture';
import Link from '@/components/Link';

type Props = {
  magazinesItem: PageByTemplateType;
};

function PastIssuesCard({ magazinesItem }: Props) {
  const { title, image, path } = magazinesItem;
  return (
    <Component>
      {path ? <ComponentLink to={`${path}`} /> : null}
      <Inner>
        <Top>
          <Image>
            {image ? (
              <Picture mobileSmall={convertThumbnailToPictureImage(image)} />
            ) : null}
          </Image>
        </Top>
        <Content>
          <Title>{title}</Title>
        </Content>
      </Inner>
    </Component>
  );
}

const ComponentLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

const Inner = styled.div``;

const Top = styled.div``;

const Content = styled.div`
  margin-top: 30px;

  ${media.laptop(css`
    margin-top: 20px;
  `)}
`;

const Image = styled.div`
  height: 430px;
  background-color: ${colors.gray50};
  border-radius: 32px;
  overflow: hidden;

  ${media.laptop(css`
    height: 322px;
    border-radius: 24px;
  `)}

  ${media.mobile(css`
    height: 380px;
  `)}

  div, picture, img {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
    object-position: top left;
    max-height: 100%;

    ${media.mobile(css`
      object-position: center center;
    `)}
  }
`;

const Title = styled.span`
  font-size: 30px;
  line-height: 36px;
  color: ${colors.black400};
  transition: all 0.3s linear;

  ${media.laptop(css`
    font-size: 20px;
    line-height: 26px;
  `)}

  ${media.tabletSmall(css`
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
  `)}
  
  ${media.mobile(css`
    font-size: 22px;
    line-height: 28px;
  `)}
`;

const Component = styled.div`
  position: relative;
  margin-right: 33px;
  flex: 1 1 330px;
  max-width: 330px;

  ${media.laptop(css`
    margin-right: 24px;
    flex: 1 1 248px;
    max-width: 248px;
  `)}

  ${media.tabletSmall(css`
    margin-right: 20px;
    flex: 1 1 290px;
    max-width: 290px;
  `)}
  
   ${media.mobile(css`
    margin-right: 20px;
    flex: 0 0 270px;
    max-width: none;
  `)}

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    ${Title} {
      color: ${colors.orange};
    }
  }
`;

export default PastIssuesCard;
