import React from 'react';
import styled, { css } from 'styled-components';

import Breadcrumbs from '@/components/Breadcrumbs';
import Hero from '@/modules/OwnProduction/components/Hero';
import useCurrentPage from '@/hooks/useCurrentPage';
import { CastleProductPageType } from '@/typings/model';
import Subscription from '@/components/Subscription';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import ShopsSection from '@/modules/Product/components/ShopsSection';
import InformationBlock from '@/modules/OwnProduction/components/InformationBlock';
import GallerySection from '@/modules/Product/components/GallerySection';
import { useLayout } from '@/components/Layout/Layout.hooks';
import { BreadCrumbs } from '@/typings/common';
import { setBreadCrumbsList } from '@/utils/common';

import PresentSection from './components/PresentSecton';

function CastleTmProduct() {
  const page = useCurrentPage<CastleProductPageType>();
  const { setThemePage } = useLayout();

  setThemePage('');

  const BREADCRUMB_LIST: Array<BreadCrumbs> = setBreadCrumbsList(
    {
      title: page?.title ?? '',
      path: page?.path ?? '',
    },
    page?.parent
  );

  if (!page) return <></>;

  const {
    topTitle,
    topText,
    topStickerText,
    topStickerLink,
    bannerImage,
    bannerText,
    bannerTitle,
    bannerLink,
    shopTitle,
    shopList,
    products,
    placeList,
    placeTitle,
  } = page?.templateFields;

  return (
    <Wrapper>
      <Inner>
        <Breadcrumbs breadcrumbs={BREADCRUMB_LIST} />
        <Hero
          title={topTitle}
          stickerLabel={topStickerText}
          stickerLink={topStickerLink}
          text={topText}
          isCastleTmProduct={true}
        />
        {products.length > 0 && (
          <GallerySectionWrapper>
            <GallerySection products={products} />
          </GallerySectionWrapper>
        )}
        <InformationBlock
          image={bannerImage}
          title={bannerTitle}
          text={bannerText}
          link={bannerLink}
        />
        {placeList.length > 0 && (
          <PresentSectionWrapper>
            <PresentSection title={placeTitle} list={placeList} />
          </PresentSectionWrapper>
        )}
        {shopList.length > 0 && (
          <ShopsSection title={shopTitle} list={shopList} />
        )}
      </Inner>
      <Subscription background={colors.gray50} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  overflow: hidden;
`;

const Inner = styled.div`
  margin-bottom: 245px;

  ${media.laptop(css`
    margin-bottom: 282px;
  `)}

  ${media.tablet(css`
    margin-bottom: 90px;
  `)}

  ${media.mobile(css`
    margin-bottom: 110px;
  `)}
`;

const GallerySectionWrapper = styled.div`
  margin: -60px 0 120px;

  ${media.laptop(css`
    margin: -44px 0 120px;
  `)}

  ${media.tablet(css`
    margin: -60px 0 70px;
  `)}

  ${media.mobile(css`
    margin-bottom: 50px;
  `)}
`;

const PresentSectionWrapper = styled.div`
  margin-top: 120px;

  ${media.laptop(css`
    margin-bottom: 120px;
  `)}

  ${media.tablet(css`
    margin: 70px 0;
  `)}
`;

export default CastleTmProduct;
