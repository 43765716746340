import React from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as StickerIcon } from '@/assets/svg/sticker.svg';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import PriceBlock from '@/components/PriceBlock';

type StickerColor = 'yellow' | 'black' | 'lime';

type Props = {
  color: StickerColor;
  size?: string;
  link?: string;
  rotate?: string;
  price: Number;
};

function HeroSlidePrice({ color, size, price, rotate, link }: Props) {
  function setPriceSize(price: string) {
    const pointIndex = price.indexOf('.');

    if (pointIndex > 2) {
      return 'three-digit';
    } else if (pointIndex > 1) {
      return 'double-digit';
    } else {
      return 'simple';
    }
  }

  return (
    <Wrapper color={color} link={link}>
      <Inner size={size}>
        {link ? <ComponentLink href={link} /> : null}
        <Background color={color}>
          <StickerIcon />
        </Background>
        <Text color={color} rotate={rotate}>
          <ProductPrice priceSize={setPriceSize(String(price))}>
            {String(price).split('.')[0]}
            <ProductPricePenny priceSize={setPriceSize(String(price))}>
              .{String(Number(price).toFixed(2)).split('.')[1]}
            </ProductPricePenny>
          </ProductPrice>
        </Text>
      </Inner>
    </Wrapper>
  );
}

const colorMap: Record<StickerColor, string> = {
  yellow: colors.yellow100,
  black: colors.black,
  lime: colors.yellow200,
};

const colorMapHover: Record<StickerColor, string> = {
  yellow: colors.yellowHover100,
  black: colors.black200,
  lime: colors.yellowHover,
};

const Text = styled.span<{
  color: string;
  rotate?: string;
}>`
  display: block;
  position: relative;
  text-align: center;
  pointer-events: none;
  transition: all 0.3s linear;
  color: ${(props) =>
    props.color === 'black' ? `${colors.yellow200}` : `${colors.black}`};
  transform: rotate(${(props) => (props.rotate ? `${props.rotate}` : '0')})
    translate3d(0, 0, 0);
`;

const ProductPrice = styled.div<{ priceSize?: string }>`
  display: flex;
  align-items: flex-start;

  font-weight: 500;
  font-size: 132px;
  line-height: 153px;

  ${media.laptop(css`
    font-size: 80px;
    line-height: 112px;
    letter-spacing: -2px;
  `)}

  ${media.tablet(css`
    font-size: 54px;
    line-height: 76px;
  `)}
  
  ${media.tablet(css`
    font-size: 52px;
    line-height: 52px;
  `)}

  ${(props) =>
    props.priceSize === 'simple'
      ? css`
          font-size: 132px;
          line-height: 153px;

          ${media.laptop(css`
            font-size: 80px;
            line-height: 112px;
          `)}

          ${media.tablet(css`
            font-size: 54px;
            line-height: 76px;
          `)}
          
            ${media.tablet(css`
            font-size: 52px;
            line-height: 52px;
          `)}
        `
      : props.priceSize === 'double-digit'
      ? css`
          font-size: 132px;
          line-height: 153px;

          ${media.laptop(css`
            font-size: 80px;
            line-height: 112px;
          `)}

          ${media.tablet(css`
            font-size: 54px;
            line-height: 76px;
          `)}
          
            ${media.tablet(css`
            font-size: 52px;
            line-height: 52px;
          `)}
        `
      : css`
          font-size: 80px;
          line-height: 112px;

          ${media.laptop(css`
            font-size: 55px;
            line-height: 63px;
          `)}

          ${media.tablet(css`
            font-size: 44px;
            line-height: 64px;
          `)}
        `}
`;
const ProductPricePenny = styled.div<{ priceSize?: string }>`
  font-weight: 500;
  font-size: 66px;
  line-height: 76px;
  margin-left: 4px;
  white-space: nowrap;

  ${media.laptop(css`
    font-size: 48px;
    line-height: 56px;
  `)}

  ${media.tablet(css`
    font-size: 32px;
    line-height: 38px;
  `)}
  
  ${media.mobile(css`
    font-size: 28px;
    line-height: 34px;
  `)}
  
  ${(props) =>
    props.priceSize === 'simple'
      ? css`
          font-size: 66px;
          line-height: 76px;

          ${media.laptop(css`
            font-size: 48px;
            line-height: 56px;
          `)}

          ${media.tablet(css`
            font-size: 32px;
            line-height: 38px;
          `)}
          
          ${media.mobile(css`
            font-size: 28px;
            line-height: 34px;
          `)}
        `
      : props.priceSize === 'double-digit'
      ? css`
          font-size: 66px;
          line-height: 76px;

          ${media.laptop(css`
            font-size: 48px;
            line-height: 56px;
          `)}

          ${media.tablet(css`
            font-size: 32px;
            line-height: 38px;
          `)}
          
          ${media.mobile(css`
            font-size: 28px;
            line-height: 34px;
          `)}
        `
      : css`
          font-size: 48px;
          line-height: 56px;

          ${media.laptop(css`
            font-size: 32px;
            line-height: 46px;
          `)}

          ${media.tablet(css`
            font-size: 24px;
            line-height: 28px;
          `)}
        `}
`;

const Inner = styled.div<{
  size?: string;
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 360px;
  height: 360px;

  ${media.laptop(css`
    width: 240px;
    height: 240px;
  `)}

  ${media.tablet(css`
    width: 164px;
    height: 164px;
  `)}
  
  ${media.mobile(css`
    width: 160px;
    height: 160px;
  `)}
`;

const ComponentLink = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

const Background = styled.div<{ color: StickerColor }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s linear;
  color: ${(props) => colorMap[props.color]};

  svg {
    width: 100%;
    height: 100%;
  }
`;

const Wrapper = styled.div<{ color: StickerColor; link?: string }>`
  width: 100%;
  height: 100%;

  ${(props) =>
    props.link
      ? css`
          &:hover {
            ${Background} {
              color: ${colorMapHover[props.color]};
            }
          }
        `
      : ''}
`;

export default HeroSlidePrice;
