import React from 'react';
import styled, { css } from 'styled-components';

import { ShopsCategoriesItemType, StringField } from '@/typings/model';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

import StoreLabel from '../StoreLabel';

interface Props {
  title: StringField;
  background: StringField;
  storeList: Array<ShopsCategoriesItemType>;
}

function StoreTypes({ title, storeList, background }: Props) {
  return (
    <Component background={background}>
      <Inner>
        {title && <Title>{title}</Title>}
        <List>
          {storeList?.map((storeItem, index) => {
            if (storeItem.items.length === 0) return null;
            return (
              <StoreLabel
                label={storeItem.name}
                count={storeItem.items.length}
              />
            );
          })}
        </List>
      </Inner>
    </Component>
  );
}

const Component = styled.div<{ background: StringField }>`
  height: 100%;
  padding: 133px 60px 100px;

  background: ${(props) =>
    props.background ? `${props.background}` : `${colors.orange}`};

  ${media.laptop(css`
    padding: 40px;
  `)}

  ${media.tablet(css`
    padding: 30px 20px;
  `)}
`;

const Inner = styled.div``;

const Title = styled.span`
  display: block;
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.white};
  margin-bottom: 60px;

  ${media.laptop(css`
    font-size: 64px;
    line-height: 72px;
    margin-bottom: 40px;
  `)}

  ${media.tablet(css`
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 50px;
  `)}
  
  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
    margin-bottom: 30px;
  `)}
`;

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -11px;

  ${media.laptop(css`
    margin: -8px;
  `)}

  ${media.mobile(css`
    margin: -8px -4px;
  `)}
`;

export default StoreTypes;
