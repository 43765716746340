import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import DropDownSelect from '@/components/DropDownSelect';
import { OptionType } from '@/components/DropDownSelect/DropDownSelect';
import { ShopsItemByCities, ShopsTypeItem } from '@/typings/model';
import { useTypedSelector } from '@/store/store';
import { selectShopByCitiesList } from '@/store/reducers/shopsCities';

interface Props {
  setActiveShop: (value: number) => void;
  activeCity: number;
  activeShop: number;
}

function ShopsSelect({ setActiveShop, activeCity, activeShop }: Props) {
  const cityList = useTypedSelector(selectShopByCitiesList);
  const [shops, setShops] = useState<Array<ShopsTypeItem>>(
    setShopsList(cityList)
  );
  const [shopsOptions, setShopsOptions] = useState<Array<OptionType>>(
    createShopsOptions(shops)
  );
  const [selectedShops, setSelectedShops] = useState<OptionType>();

  function setShopsList(cityList: Array<ShopsItemByCities>) {
    let shopsList: Array<ShopsTypeItem> = [];

    if (activeCity === 0) {
      cityList.forEach((city) => {
        city.shops.forEach((shop) => {
          shopsList.push(shop);
        });
      });
    } else {
      cityList.forEach((city) => {
        if (city.id === activeCity) {
          city.shops.forEach((shop) => {
            shopsList.push(shop);
          });
        }
      });
    }
    return shopsList;
  }

  function createShopsOptions(list: Array<ShopsTypeItem>) {
    const optionList = [{ label: 'Все магазины', value: '0' }];

    list.forEach((listItem) => {
      let newShopsTypeItem: OptionType = { label: '', value: '' };
      newShopsTypeItem.label = `${listItem.name} (${listItem.address})` ?? '';
      newShopsTypeItem.value = String(listItem.id) ?? '';
      optionList.push(newShopsTypeItem);
    });

    return optionList;
  }

  const sortShopsValue = useMemo<OptionType>(
    () =>
      shopsOptions.find((option) => option.value === selectedShops?.value) ??
      shopsOptions[0],
    [selectedShops, shopsOptions]
  );

  function handlesShopsChange(selectedOption: OptionType | null): void {
    if (selectedOption) {
      setSelectedShops(selectedOption);

      if (selectedOption.value === '0') {
        setActiveShop(0);
      } else {
        shops.forEach((shop) => {
          if (shop.id === Number(selectedOption.value)) {
            setActiveShop(shop.id);
          }
        });
      }
    }
  }

  useEffect(() => {
    setShops(setShopsList(cityList));
  }, [cityList, activeCity]);

  useEffect(() => {
    setShopsOptions(createShopsOptions(shops));
  }, [shops]);

  useEffect(() => {
    shopsOptions.forEach((shop) => {
      if (shop.value === String(activeShop)) {
        setSelectedShops(shop);
      }
    });
  }, [activeShop]);

  return (
    <Component>
      <DropDownSelect
        placeholder="Выбрать..."
        options={shopsOptions}
        value={sortShopsValue}
        onChange={handlesShopsChange}
      />
    </Component>
  );
}

const Component = styled.div``;

export default ShopsSelect;
