import React from 'react';
import styled, { css } from 'styled-components';

import Picture from '@/components/Picture';
import starUrl from '@/assets/images/certificate/star-small.png';
import starUrl2x from '@/assets/images/certificate/star-small@2x.png';
import starUrlWebp from '@/assets/images/certificate/star-small.webp';
import starUrlWebp2x from '@/assets/images/certificate/star-small@2x.webp';
import { media } from '@/utils/mixin';
import { StringField } from '@/typings/model';

type Props = {
  onClick: () => void;
  shouldDisplayAll: boolean;
  offlineCertificateButtonLabel: StringField;
};

function More({
  onClick,
  shouldDisplayAll,
  offlineCertificateButtonLabel,
}: Props) {
  return (
    <Component shouldDisplayAll={shouldDisplayAll}>
      <Inner>
        <Star onClick={onClick}>
          <StarBackground>
            <Picture
              mobileSmall={{
                src: starUrl,
                src2x: starUrl2x,
                webp: starUrlWebp,
                webp2x: starUrlWebp2x,
              }}
            />
          </StarBackground>
          <StarInner>
            <StarText>{offlineCertificateButtonLabel}</StarText>
          </StarInner>
        </Star>
      </Inner>
    </Component>
  );
}

const Component = styled.div<{ shouldDisplayAll: boolean }>`
  background: linear-gradient(180deg, rgba(2, 2, 2, 0) 0%, #020202 34.94%);
  min-height: 262px;
  position: relative;
  margin-top: -245px;
  z-index: 2;

  ${media.laptop(css`
    margin-top: -197px;
  `)}

  ${media.tablet(css`
    min-height: 180px;
    margin-top: -178px;
  `)}
  

  ${(props) =>
    props.shouldDisplayAll
      ? css`
          display: none;
        `
      : ''}
`;
const Inner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Star = styled.div`
  position: relative;
  width: 254px;
  transition: all 0.3s linear;
  z-index: 2;

  ${media.laptop(css`
    width: 194px;
  `)}

  ${media.tablet(css`
    width: 154px;
  `)}
  
  ${media.mobile(css`
    width: 145px;
  `)}

  &:before {
    display: block;
    content: '';
    padding-bottom: 100%;
  }

  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
`;
const StarBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const StarInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  transform: rotate(-15deg);

  ${media.laptop(css`
    font-size: 20px;
    line-height: 26px;
  `)}

  ${media.tablet(css`
    font-size: 18px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    font-size: 14px;
    line-height: 18px;
  `)}
`;

const StarText = styled.div`
  max-width: 180px;

  ${media.laptop(css`
    max-width: 128px;
  `)}

  ${media.tablet(css`
    max-width: 94px;
  `)}
`;

export default More;
