import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import Picture from '@/components/Picture';
import {
  ShoppingCenterPageType,
  TradeCenterCategoriesItemType,
  TradeCenterItemType,
} from '@/typings/model';
import useCurrentPage from '@/hooks/useCurrentPage';
import Breadcrumbs from '@/components/Breadcrumbs';
import shoppingCartUrl from '@/assets/images/map/shopping-cart.png';
import shoppingCartUrl2x from '@/assets/images/map/shopping-cart@2x.png';
import shoppingCartUrlWebp from '@/assets/images/map/shopping-cart.webp';
import shoppingCartUrlWebp2x from '@/assets/images/map/shopping-cart@2x.webp';
import { useLayout } from '@/components/Layout/Layout.hooks';
import { BreadCrumbs } from '@/typings/common';
import { setBreadCrumbsList } from '@/utils/common';

import Hero from './components/Hero';
import ShopInYourCity from './components/ShopInYourCity';
import MapBlock from './components/MapBlock';

function ShoppingCenters() {
  const page = useCurrentPage<ShoppingCenterPageType>();

  const { setActiveStoreName, setMenuStore, setThemePage } = useLayout();

  useEffect(() => {
    setActiveStoreName({
      value: '',
      label: '',
    });
    setMenuStore([]);
    setThemePage('');
  }, []);

  const mapRef = useRef<HTMLDivElement>(null);

  const [activeShop, setActiveShop] = useState<TradeCenterItemType | null>(
    null
  );

  const BREADCRUMB_LIST: Array<BreadCrumbs> = setBreadCrumbsList({
    title: page?.title ?? 'Торговые центры',
    path: page?.path ?? '',
  });

  function scrollTo(section: any) {
    const targetElement = section;
    if (targetElement) {
      const y =
        targetElement.getBoundingClientRect().top + window.pageYOffset - 50;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }

  useEffect(() => {
    if (!activeShop) return;
    scrollTo(mapRef.current);
  }, [activeShop]);

  const [activeCityItem, setActiveCityItem] = useState(
    page?.templateFields.shopsItems[0] ?? { name: '', items: [] }
  );

  useEffect(() => {
    if (!page?.templateFields.shopsItems[0]) return;
    setActiveCityItem(page?.templateFields.shopsItems[0]);
  }, [page?.templateFields.shopsItems]);

  function setPlacemarkList(list: Array<TradeCenterCategoriesItemType>) {
    const placemarkList: Array<TradeCenterItemType> = [];
    list.forEach((listItem) => {
      listItem.items.forEach((shop) => {
        placemarkList.push(shop);
      });
    });

    return placemarkList;
  }

  if (!page?.templateFields) return null;

  const { shopsTitle, shopsItems } = page?.templateFields;

  return (
    <Wrapper>
      <Breadcrumbs breadcrumbs={BREADCRUMB_LIST} />
      {page && <Hero page={page} />}
      <MapWrapper ref={mapRef}>
        <MapBlock
          activeShop={activeShop}
          placemarkList={setPlacemarkList(shopsItems)}
          isActiveCity={activeCityItem.name ?? 'Минск'}
          setActiveShop={(shop: TradeCenterItemType) => setActiveShop(shop)}
        />
        <ShoppingCart>
          <ShoppingCartInner>
            <Picture
              mobileSmall={{
                src: shoppingCartUrl,
                src2x: shoppingCartUrl2x,
                webp: shoppingCartUrlWebp,
                webp2x: shoppingCartUrlWebp2x,
              }}
            />
          </ShoppingCartInner>
        </ShoppingCart>
      </MapWrapper>
      <ShopInYourCity
        title={shopsTitle}
        setActiveShop={(shop: TradeCenterItemType | null) =>
          setActiveShop(shop)
        }
        activeCityItem={activeCityItem}
        setActiveCityItem={(listItem: TradeCenterCategoriesItemType) =>
          setActiveCityItem(listItem)
        }
        shopByCitiesList={shopsItems}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  padding-bottom: 212px;
  overflow: hidden;

  &:before {
    position: absolute;
    content: '';
    top: 1275px;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${colors.gray50};
  }

  ${media.laptop(css`
    padding-bottom: 180px;

    &:before {
      top: 902px;
    }
  `)}

  ${media.tablet(css`
    padding-bottom: 104px;

    &:before {
      top: 720px;
    }
  `)}

  ${media.mobile(css`
    padding-bottom: 112px;
  `)}
`;

const MapWrapper = styled.div`
  position: relative;
  max-width: 1920px;
  margin: 0 auto 88px;

  ${media.laptop(css`
    margin-bottom: 80px;
  `)}

  ${media.tablet(css`
    margin-bottom: 68px;
  `)}

  ${media.mobile(css`
    margin-bottom: 80px;
  `)}
`;

const ShoppingCart = styled.div`
  position: absolute;
  bottom: -284px;
  right: -106px;
  width: 568px;
  height: 580px;
  pointer-events: none;

  ${media.laptop(css`
    width: 455px;
    height: 420px;
    top: auto;
    bottom: -198px;
    right: -148px;
  `)}

  ${media.tablet(css`
    width: 364px;
    height: 308px;
    bottom: -78px;
    right: -40px;
  `)}

  ${media.mobile(css`
    width: 280px;
    height: 260px;
    bottom: -56px;
    right: -83px;
  `)}
`;

const ShoppingCartInner = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;

  &:before {
    display: block;
    content: '';
    padding-bottom: 100%;
  }

  div {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  img,
  picture {
    width: 100%;
    height: 100%;
  }
`;

export default ShoppingCenters;
