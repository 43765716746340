import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import { RestaurantsItem, StringField } from '@/typings/model';

import RestaurantsList from '../RestaurantsList';

interface Props {
  title: StringField;
  text?: StringField;
  restaurantsList: Array<RestaurantsItem>;
}

function OtherRestaurantsSection({ title, text, restaurantsList }: Props) {
  return (
    <Component>
      <Inner>
        <Top>
          <Left>
            <Title>{title}</Title>
          </Left>

          {text && (
            <Right>
              <Text>{text}</Text>
            </Right>
          )}
        </Top>
        <Bottom>
          <RestaurantsList
            restaurantsList={restaurantsList}
            isAddressLabel={true}
          />
        </Bottom>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  max-width: 1680px;
  margin: 0 auto;
  padding: 80px 40px 0;

  ${media.tablet(css`
    padding: 50px 20px 0;
  `)}

  ${media.mobile(css`
    padding-top: 70px;
  `)}
`;

const Inner = styled.div``;

const Top = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;

  ${media.laptop(css`
    margin-bottom: 80px;
  `)}

  ${media.tablet(css`
    margin-bottom: 50px;
  `)}
  
  ${media.mobile(css`
    display: block;
  `)}
`;

const Left = styled.div`
  flex: 1 1 652px;
  margin-right: 30px;

  ${media.laptop(css`
    flex: 1 1 486px;
  `)}

  ${media.tablet(css`
    margin-right: 36px;
    flex: 1 1 346px;
  `)}
  
  ${media.mobile(css`
    margin: 0 0 20px;
  `)}
`;

const Right = styled.div`
  flex: 1 1 784px;
  max-width: 784px;
  display: flex;
  align-items: flex-end;

  ${media.laptop(css`
    flex: 1 1 588px;
    max-width: 588px;
  `)}

  ${media.tablet(css`
    flex: 1 1 346px;
    max-width: 100%;
  `)}
`;

const Bottom = styled.div``;

const Title = styled.h2`
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.black};

  ${media.laptop(css`
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    font-size: 48px;
    line-height: 56px;
  `)}
  
  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
  `)}
`;

const Text = styled.p`
  font-size: 30px;
  line-height: 36px;
  color: ${colors.black};

  ${media.laptop(css`
    font-size: 20px;
    line-height: 26px;
  `)}

  ${media.tablet(css`
    font-size: 18px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    font-size: 14px;
    line-height: 20px;
  `)}
`;

export default OtherRestaurantsSection;
