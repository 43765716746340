import React from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as CloseIcon } from '@/assets/svg/close.svg';
import { media } from '@/utils/mixin';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement>;

function CloseButton(props: Props) {
  return (
    <StyledButton {...props}>
      <CloseIcon />
    </StyledButton>
  );
}

const StyledButton = styled.button`
  position: absolute;
  top: 22px;
  right: 45px;
  border-radius: 50%;
  transition: background-color 0.1s;
  padding: 20px;
  z-index: 10;

  ${media.laptop(css`
    top: 34px;
    right: 34px;
    padding: 10px;
  `)}

  ${media.tablet(css`
    top: 21px;
    right: 22px;
  `)}
  
  ${media.mobile(css`
    top: 16px;
    right: 16px;
  `)}

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  svg {
    width: 26px;
    height: 26px;
    display: block;
    fill: currentColor;

    ${media.laptop(css`
      width: 16px;
      height: 16px;
    `)}

    ${media.tablet(css`
      width: 20px;
      height: 20px;
    `)}
  }
`;
export default CloseButton;
