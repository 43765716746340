import React from 'react';
import styled, { css } from 'styled-components';

import BannerCard from '@/modules/Home/components/BannerCard';
import { ImageThumbnail, StringField } from '@/typings/model';
import { media } from '@/utils/mixin';

type Props = {
  image?: ImageThumbnail;
  title: StringField;
  link: StringField;
  design: StringField;
  isLeft?: boolean;
};

function Offers({ image, title, link, design, isLeft }: Props) {
  return (
    <Component size={design} isLeft={isLeft}>
      {image ? (
        <Inner size={design} isLeft={isLeft}>
          <BannerCard image={image} text={title} link={link} isLink={true} />
        </Inner>
      ) : null}
    </Component>
  );
}

const Component = styled.div<{ size: StringField; isLeft?: boolean }>`
  position: relative;
  margin-top: 50px;

  ${media.laptop(css`
    margin-top: 40px;
  `)}

  &:first-child {
    margin-top: 0;
  }

  ${(props) =>
    props.size === 'big'
      ? css`
          &:before {
            display: block;
            content: '';
            padding-bottom: 92%;

            ${media.laptop(css`
              padding-bottom: 88.5%;
            `)}

            ${media.mobile(css`
              padding-bottom: 133.3%;
            `)}
          }
        `
      : props.size === 'small'
      ? css`
          &:before {
            display: block;
            content: '';
            padding-bottom: 53.2%;

            ${media.laptop(css`
              padding-bottom: 54.4%;
            `)}

            ${media.mobile(css`
              padding-bottom: 133.3%;
            `)}
          }
        `
      : css`
          &:before {
            display: block;
            content: '';
            padding-bottom: 87.6%;

            ${media.laptop(css`
              padding-bottom: 81.6%;
            `)}

            ${media.tabletSmall(css`
              padding-bottom: 85.3%;
            `)}
            
            ${media.mobile(css`
              padding-bottom: 133.3%;
            `)}
          }
        `}

  ${(props) =>
    props.isLeft
      ? props.size === 'big'
        ? css`
            &:before {
              display: block;
              content: '';
              padding-bottom: 96%;

              ${media.laptop(css`
                padding-bottom: 88.5%;
              `)}
            }
          `
        : ''
      : ''}
`;
const Inner = styled.div<{ size: StringField; isLeft?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  ${(props) =>
    props.isLeft
      ? props.size === 'big'
        ? css`
            width: 49.2vw !important;
            left: 0 !important;
            right: auto !important;

            @media (min-width: 1921px) {
              width: 981px !important;
            }
          `
        : ''
      : ''}

  ${(props) =>
    props.size === 'big'
      ? css`
          left: auto;
          right: 0;
          width: 51vw;

          @media (min-width: 1921px) {
            width: 979px;
          }

          ${media.tablet(css`
            width: 100%;
          `)}
        `
      : ''}
`;
export default Offers;
