import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

import { OffersCardType } from '../Vacancies.constants';

type Props = {
  cardItem: {
    id: number;
    name: string;
    urlAlias: string;
  };
};

function OffersCard({ cardItem }: Props) {
  const { urlAlias, name } = cardItem;
  return (
    <Component>
      <OffersCardLink href={`/careers/vacancies/${urlAlias}`} />
      <OffersCardPosition>{name}</OffersCardPosition>
    </Component>
  );
}

const Component = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  padding: 20px 32px;
  background: ${colors.black};
  color: ${colors.white};

  transition: all 0.3s linear;

  ${media.tablet(css`
    padding: 18px 28px;
  `)}

  ${media.mobile(css`
    padding: 20px 32px;
  `)}

  &:hover {
    color: ${colors.orange};
  }
`;

const OffersCardLink = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

const OffersCardPosition = styled.span`
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  white-space: nowrap;

  ${media.laptop(css`
    font-size: 16px;
    line-height: 24px;
  `)}

  ${media.tablet(css`
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    line-height: 24px;
  `)}
`;

export default OffersCard;
