import React, { useState } from 'react';

import { cookie } from '@tager/web-core';
import { Page } from '@tager/web-components';
import { convertSeoParamsToPageProps } from '@tager/web-modules';

import ErrorPage from '@/pages/_error';
import NotFoundPage from '@/pages/404';
import { CustomApp_PageContext } from '@/typings/hocs';
import Layout from '@/components/Layout';
import {
  getPageByPathThunk,
  getPageListThunk,
} from '@/store/reducers/tager/pages';
import useCurrentPage from '@/hooks/useCurrentPage';
import { getPageModuleByTemplate } from '@/services/pageModules';
import { convertErrorToProps, convertSlugToPath } from '@/utils/common';
import { LocomotiveScroll } from '@/components/LocomotiveScroll';
import FooterPopup from '@/components/FooterPopup';
import CookieSettingsModal from '@/components/Layout/components/CookieSettingsModal/CookieSettingsModal';

type Props =
  | {
      pageType: 'DYNAMIC_PAGE';
      template: string;
    }
  | {
      pageType: 'NOT_FOUND';
    }
  | {
      pageType: 'ERROR';
      error: Error;
    };

function DynamicPage(props: Props) {
  const popupClosedCookieName = 'infoPopupClosed';
  const [showCookieModal, setShowCookieModal] = useState<boolean>(false);
  const [analyticsCookiesChecked, setAnalyticsCookiesChecked] =
    useState<boolean>(() => {
      const cookieValue = cookie.get('infoPopupClosed');

      return cookieValue ? (cookieValue === '2' ? true : false) : true;
    });
  const page = useCurrentPage();

  if (props.pageType === 'NOT_FOUND') {
    return <NotFoundPage />;
  }

  if (props.pageType === 'ERROR') {
    return <ErrorPage {...convertErrorToProps(props.error)} />;
  }

  const foundPageModule = getPageModuleByTemplate(props.template);
  const pageElement = React.createElement(foundPageModule.component);

  const handleCookiesSettingsSave = () => {
    cookie.set(
      popupClosedCookieName,
      analyticsCookiesChecked ? String(2) : String(1),
      undefined,
      365
    );

    setShowCookieModal(false);
    window.location.reload();
  };

  const handleSubmitCookie = () => {
    cookie.set(popupClosedCookieName, String(2), undefined, 365);
    window.location.reload();
  };

  const handleDeclineCookie = () => {
    cookie.set(popupClosedCookieName, String(1), undefined, 365);
    window.location.reload();
  };

  const hasCookies = Boolean(cookie.get(popupClosedCookieName));

  return (
    <Page {...convertSeoParamsToPageProps(page?.seoParams)}>
      {props.template === 'home' ? (
        <LocomotiveScroll>
          <Layout onCookiesClick={() => setShowCookieModal(true)}>
            {pageElement}
          </Layout>
        </LocomotiveScroll>
      ) : (
        <Layout onCookiesClick={() => setShowCookieModal(true)}>
          {pageElement}
        </Layout>
      )}
      <FooterPopup
        onSubimt={handleSubmitCookie}
        onDecline={handleDeclineCookie}
        onSetupCookie={() => {
          setShowCookieModal(true);
        }}
        hasCookies={hasCookies}
      />
      {showCookieModal && (
        <CookieSettingsModal
          analyticsCookiesChecked={analyticsCookiesChecked}
          onAnalyticsCookiesChange={(value) => {
            setAnalyticsCookiesChecked(value);
          }}
          onClose={() => setShowCookieModal(false)}
          onSave={handleCookiesSettingsSave}
        />
      )}
    </Page>
  );
}

DynamicPage.getInitialProps = async (
  context: CustomApp_PageContext
): Promise<Props> => {
  const { store, query } = context;
  const currentPath = convertSlugToPath(query.slug);

  try {
    const pageList = await store.dispatch(getPageListThunk());
    const foundPage =
      pageList.find((page) => page.path === currentPath) || undefined;
    const foundPageModule = getPageModuleByTemplate(foundPage?.template);

    if (!foundPage) {
      if (context.res) {
        context.res.statusCode = 404;
      }
      return { pageType: 'NOT_FOUND' };
    }

    if (foundPage.path === '/page-not-found') {
      if (context.res) {
        context.res.statusCode = 404;
      }
    }

    const requestsPromise = Promise.all([
      store.dispatch(getPageByPathThunk(foundPage.path)),
      foundPageModule.getInitialProps
        ? foundPageModule.getInitialProps(context)
        : Promise.resolve(),
    ]);

    await requestsPromise;

    return { pageType: 'DYNAMIC_PAGE', template: foundPageModule.template };
  } catch (error: unknown) {
    return { pageType: 'ERROR', error: error as Error };
  }
};

export default DynamicPage;
