import React from 'react';
import styled from 'styled-components';

import useCurrentPage from '@/hooks/useCurrentPage';
import { IdeasPageType } from '@/typings/model';
import Breadcrumbs from '@/components/Breadcrumbs';
import { useLayout } from '@/components/Layout/Layout.hooks';
import useCurrentChildrenPages from '@/hooks/useCurrentChildrenPages';
import Main from '@/modules/Recipes/components/Main';
import { BreadCrumbs } from '@/typings/common';
import { setBreadCrumbsList } from '@/utils/common';

function Ideas() {
  const page = useCurrentPage<IdeasPageType>();
  const { data: ideasList } = useCurrentChildrenPages('ideas-single');

  const { setThemePage } = useLayout();
  setThemePage('');

  const BREADCRUMB_LIST: Array<BreadCrumbs> = setBreadCrumbsList(
    {
      title: page?.title ?? '',
      path: page?.path ?? '',
    },
    page?.parent
  );

  return (
    <Wrapper>
      <Breadcrumbs breadcrumbs={BREADCRUMB_LIST} />
      <Main
        title={'Идеи для дома'}
        list={ideasList}
        bannerImage={page?.templateFields.image}
        bannerLink={page?.templateFields.link ?? ''}
        bannerTitle={page?.templateFields.title ?? ''}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div``;

export default Ideas;
