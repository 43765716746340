import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { media } from '@/utils/mixin';
import { ImageThumbnail } from '@/typings/model';
import Picture from '@/components/Picture';

interface Props {
  image: ImageThumbnail;
}

function BrandsCard({ image }: Props) {
  return (
    <Component>
      <Inner>
        <Logo>
          {image ? (
            <Picture mobileSmall={convertThumbnailToPictureImage(image)} />
          ) : null}
        </Logo>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  width: 100%;
  height: 160px;

  ${media.laptop(css`
    height: 120px;
  `)}

  ${media.tablet(css`
    height: 152px;
  `)}
  
  ${media.mobile(css`
    height: 100px;
  `)}
`;

const Inner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Logo = styled.div`
  svg,
  img {
    max-width: 100%;
    max-height: 100%;

    ${media.mobile(css`
      max-width: 125px;
    `)}
  }
`;

export default BrandsCard;
