import React from 'react';
import styled, { css } from 'styled-components';

import Star from '@/components/Sticker/Star';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { StringField } from '@/typings/model';

interface Props {
  title: StringField;
  stickerLabel: StringField;
  stickerLink: StringField;
  text?: StringField;
  isProduct?: boolean;
  isCastleTmProduct?: boolean;
}

function Hero({
  title,
  text,
  stickerLabel,
  stickerLink,
  isProduct,
  isCastleTmProduct,
}: Props) {
  return (
    <Component isProduct={isProduct}>
      <Inner>
        <Left>
          <Title>{title}</Title>
          {text && <Text>{text}</Text>}
        </Left>
        <Right>
          {stickerLabel && (
            <StarWrapper isCastleTmProduct={isCastleTmProduct}>
              <Star
                rotate={'15deg'}
                color="orange"
                label={stickerLabel ?? ''}
                link={stickerLink ?? '#'}
                size={'big'}
                isProduction={true}
              />
            </StarWrapper>
          )}
        </Right>
      </Inner>
    </Component>
  );
}

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`;

const Left = styled.div`
  max-width: 770px;
  padding: 92px 0 160px;

  ${media.laptop(css`
    max-width: 560px;
    padding: 64px 0 85px;
  `)}

  ${media.tablet(css`
    max-width: 536px;
    padding: 40px 0 13px;
  `)}
  
  ${media.mobile(css`
    padding: 7px 0 13px;
    max-width: 100%;
  `)}
`;

const Right = styled.div`
  flex: 1 1 auto;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.black1000};

  ${media.laptop(css`
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    font-size: 48px;
    line-height: 56px;
  `)}
  
  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
  `)}
`;

const Text = styled.p`
  margin-top: 36px;
  padding-left: 120px;
  font-size: 30px;
  line-height: 36px;
  color: ${colors.black};

  ${media.laptop(css`
    margin-top: 24px;
    padding-left: 100px;
    font-size: 18px;
    line-height: 24px;
  `)}

  ${media.tablet(css`
    margin-top: 20px;
    padding-left: 0;
    font-size: 18px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    font-size: 14px;
    line-height: 20px;
  `)}
`;

const StarWrapper = styled.div<{ isCastleTmProduct?: boolean }>`
  position: absolute;
  right: 72px;
  bottom: 60px;
  width: 320px;
  height: 320px;

  ${media.laptop(css`
    right: 0;
    bottom: 38px;
    width: 240px;
    height: 240px;
  `)}

  ${media.tablet(css`
    right: 41px;
    bottom: -65px;
    width: 200px;
    height: 200px;
    z-index: 2;
  `)}
  
  ${media.mobile(css`
    right: -17px;
    bottom: -167px;
    width: 160px;
    height: 160px;
  `)}
  
  ${(props) =>
    props.isCastleTmProduct &&
    css`
      ${media.mobile(css`
        right: -55px !important;
        bottom: -125px;
      `)}
    `}
`;

const Component = styled.div<{ isProduct?: boolean }>`
  max-width: 1680px;
  margin: 0 auto;
  padding: 0 40px;

  ${media.tablet(css`
    padding: 0 20px;
  `)}

  ${Left} {
    padding: 110px 0 138px;

    ${media.laptop(css`
      padding: 64px 0 55px;
    `)}

    ${media.tablet(css`
      padding: 40px 0 13px;
    `)}
    
    ${media.mobile(css`
      padding: 7px 0 13px;
    `)}
  }

  ${StarWrapper} {
    right: 0;

    ${media.tablet(css`
      right: -41px;
    `)}

    ${media.mobile(css`
      right: -17px;
    `)}
  }
`;

export default Hero;
