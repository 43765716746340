import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import Link from '@/components/Link';
import { useLayout } from '@/components/Layout/Layout.hooks';
import useCurrentPage from '@/hooks/useCurrentPage';
import useCurrentChildrenPages from '@/hooks/useCurrentChildrenPages';
import { OwnProductionPageType } from '@/typings/model';
import Breadcrumbs from '@/components/Breadcrumbs';
import Subscription from '@/components/Subscription';
import { useTypedSelector } from '@/store/store';
import { selectMenuItemListByAlias } from '@/store/reducers/tager/menus';

import Hero from './components/Hero';
import Features from './components/Features';
import InformationBlock from './components/InformationBlock';
import Cooking from './components/Cooking';
import ProductsSlider from './components/ProductsSlider';

function OwnProduction() {
  const page = useCurrentPage<OwnProductionPageType>();
  const { data: recipesList } = useCurrentChildrenPages('recipes-single');
  const { setThemePage, setActiveStoreName, setMenuStore } = useLayout();

  const headerMenuHypermarkets =
    useTypedSelector((state) =>
      selectMenuItemListByAlias(state, 'hypermarket')
    ) ?? [];

  useEffect(() => {
    setThemePage('');

    setActiveStoreName({
      value: 'Гипермаркет',
      label: 'Гипермаркет',
    });
    setMenuStore(headerMenuHypermarkets);

    return () => {
      setActiveStoreName({
        value: '',
        label: '',
      });
      setMenuStore([]);
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [headerMenuHypermarkets]);

  const BREADCRUMB_LIST: Array<{
    id: number;
    label: string;
    to: React.ComponentProps<typeof Link>['to'];
  }> = [
    { id: 1, label: 'Главная', to: '/' },
    { id: 2, label: `${page?.title}`, to: `${page?.path}` },
  ];

  if (!page) return <></>;

  const {
    topTitle,
    topStickerLink,
    topStickerText,
    products,
    features,
    featuresTitle,
    bannerImage,
    bannerText,
    bannerTitle,
  } = page?.templateFields;

  return (
    <Wrapper>
      <Breadcrumbs breadcrumbs={BREADCRUMB_LIST} />
      <Hero
        title={topTitle}
        stickerLabel={topStickerText}
        stickerLink={topStickerLink}
      />
      <ProductsSliderWrapper>
        <ProductsSlider products={products} />
      </ProductsSliderWrapper>
      {features.length > 0 && (
        <Features features={features} featuresTitle={featuresTitle} />
      )}
      <InformationBlock
        image={bannerImage}
        title={bannerTitle}
        text={bannerText}
      />
      {recipesList.length > 0 && (
        <CookingWrapper>
          <Cooking
            recipesList={recipesList}
            title={'Готовим вместе'}
            btnLink={'/our-production/recipes'}
            btnLabel={'Все рецепты'}
          />
        </CookingWrapper>
      )}
      <Subscription background={colors.gray50} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  overflow: hidden;
`;

const ProductsSliderWrapper = styled.div`
  ${media.tablet(css`
    margin-top: 40px;
  `)}
`;

const CookingWrapper = styled.div`
  ${media.tablet(css`
    margin-top: 70px;
  `)}
`;

export default OwnProduction;
