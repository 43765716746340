import React from 'react';
import styled, { css } from 'styled-components';

import { ImageThumbnail, StringField } from '@/typings/model';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

import DiscountList from './components/DiscountList';

type Props = {
  sizeTitle: StringField;
  sizeText: StringField;
  sizeItems?: Array<{
    cost: StringField;
    discount: StringField;
  }>;
};

function DiscountType({ sizeItems, sizeText, sizeTitle }: Props) {
  return (
    <Component>
      <Inner>
        <Top>
          <Title>{sizeTitle}</Title>
          <Text>{sizeText}</Text>
        </Top>
        <Bottom>
          <DiscountList sizeItems={sizeItems} />
        </Bottom>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  background: ${colors.white};
`;
const Inner = styled.div`
  max-width: 1680px;
  padding: 267px 40px;
  margin: 0 auto;

  ${media.laptop(css`
    padding: 80px 40px 202px;
  `)}

  ${media.tablet(css`
    padding: 70px 20px 47px;
  `)}
  
  ${media.mobile(css`
    padding: 50px 20px 151px;
  `)}
`;

const Top = styled.div``;

const Bottom = styled.div`
  margin-top: 58px;

  ${media.laptop(css`
    margin-top: 48px;
  `)}

  ${media.tablet(css`
    margin-top: 30px;
  `)}
`;

const Title = styled.h2`
  margin: 0;
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.black};

  ${media.laptop(css`
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tabletSmall(css`
    font-size: 48px;
    line-height: 56px;
  `)}
  
  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
  `)}
`;

const Text = styled.p`
  margin-top: 40px;
  font-size: 30px;
  line-height: 36px;

  ${media.laptop(css`
    margin-top: 24px;
    font-size: 20px;
    line-height: 26px;
  `)}

  ${media.tabletSmall(css`
    margin-top: 20px;
    font-size: 18px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    font-size: 14px;
    line-height: 20px;
  `)}
`;

export default DiscountType;
