import React from 'react';
import styled, { css } from 'styled-components';

import { nl2br } from '@tager/web-core';

import { StringField } from '@/typings/model';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

interface Props {
  listItem: {
    title: StringField;
    text: StringField;
  };
}

function TableRow({ listItem }: Props) {
  return (
    <Component>
      <Left>
        <Title>{listItem.title}</Title>
      </Left>
      <Right>
        <Text>{nl2br(listItem.text)}</Text>
      </Right>
    </Component>
  );
}

const Component = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 60px 0 58px;
  border-bottom: 2px solid ${colors.grayLight};

  ${media.laptop(css`
    padding: 40px 0 39px;
    border-bottom: 1px solid ${colors.grayLight};
  `)}

  ${media.tablet(css`
    padding: 30px 0 29px;
  `)}
  
  ${media.mobile(css`
    display: block;
  `)}

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
`;

const Left = styled.div`
  flex: 1 640px;
  max-width: 640px;
  margin-right: 30px;

  ${media.laptop(css`
    flex: 1 1 486px;
    max-width: 486px;
  `)}

  ${media.tablet(css`
    flex: 1 1 250px;
    max-width: 250px;
    margin-right: 37px;
  `)}
  
  ${media.mobile(css`
    max-width: 100%;
    margin: 0 0 20px;
  `)}
`;
const Right = styled.div`
  flex: 1 1 920px;
  max-width: 920px;

  ${media.laptop(css`
    flex: 1 1 588px;
    max-width: 588px;
  `)}

  ${media.tablet(css`
    flex: 1 1 440px;
    max-width: 100%;
  `)}
  
  ${media.mobile(css`
    max-width: 100%;
  `)}
`;

const Title = styled.h3`
  margin: 0;
  font-weight: 500;
  font-size: 54px;
  line-height: 64px;
  color: ${colors.black};

  ${media.laptop(css`
    font-size: 40px;
    line-height: 48px;
  `)}

  ${media.tablet(css`
    font-size: 28px;
    line-height: 34px;
  `)}
  
  ${media.mobile(css`
    font-size: 22px;
    line-height: 28px;
  `)}
`;

const Text = styled.p`
  font-size: 30px;
  line-height: 36px;
  color: ${colors.black};

  ${media.laptop(css`
    font-size: 20px;
    line-height: 26px;
  `)}

  ${media.tablet(css`
    font-size: 18px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    font-size: 14px;
    line-height: 20px;
  `)}
`;

export default TableRow;
