import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import SelectComponent from '@/components/Select';
import Button from '@/components/Button';
import { colors } from '@/constants/theme';
import { useTypedSelector } from '@/store/store';
import { selectShopsTypeByCities } from '@/store/reducers/shopsTypeByCities';
import { ShopsTypeItem } from '@/typings/model';

interface Props {
  setActiveShop: (shop: ShopsTypeItem) => void;
  setActivePlacemark: (list: Array<ShopsTypeItem>) => void;
  setActiveCityName: (name: string) => void;
}

function Filter({
  setActiveShop,
  setActivePlacemark,
  setActiveCityName,
}: Props) {
  const shopTypeByCitiesList = useTypedSelector(selectShopsTypeByCities);

  const [typeOption, setTypeOption] = useState<
    Array<{ id: number; name: string }>
  >([]);

  const [activeCity, setActiveCity] = useState(-1);
  const [activeType, setActiveType] = useState<string>('');
  const [activeAddress, setActiveAddress] = useState<number>(-1);

  const [isClearType, setClearType] = useState<boolean>(false);
  const [isClearAddress, setClearAddress] = useState<boolean>(false);
  const [isDisabledType, setDisabledType] = useState<boolean>(true);
  const [isDisabledAddress, setDisabledAddress] = useState<boolean>(true);
  const [isDisabledBtn, setDisabledBtn] = useState<boolean>(true);

  const [shopTypes, setShopTypes] = useState<
    Array<{
      name: string;
      shops: Array<ShopsTypeItem>;
    }>
  >([]);

  const [addressList, setAddressList] = useState<
    Array<{ name: string; id: number }>
  >([]);

  const [shopSearch, setShopSearch] = useState<ShopsTypeItem | null>(null);
  const [shopList, setShopList] = useState<Array<ShopsTypeItem>>();

  useEffect(() => {
    if (activeCity < 0) return;
    shopTypeByCitiesList.forEach((city) => {
      if (city.id === activeCity) {
        setActiveCityName(city.name);
        setShopTypes(city.shopTypes);
      }
    });

    setDisabledType(false);
    setDisabledAddress(true);
    setDisabledBtn(true);

    setActiveType('');
    setActiveAddress(-1);
    setClearType(true);
    setClearAddress(true);
    setShopSearch(null);

    setTimeout(() => {
      setClearType(false);
      setClearAddress(false);
    }, 100);
  }, [activeCity]);

  useEffect(() => {
    if (!activeType) return;
    shopTypes?.map((shopTypesItem) => {
      if (shopTypesItem.name === activeType) {
        setActivePlacemark(shopTypesItem.shops);
        setShopList(shopTypesItem.shops);

        const newAddressList: Array<{ name: string; id: number }> = [];

        shopTypesItem.shops.map((shop) => {
          const newAddress = {
            id: -1,
            name: '',
          };
          newAddress.id = shop.id;
          newAddress.name = shop.address ?? '';
          newAddressList.push(newAddress);
        });
        setAddressList(newAddressList);

        setDisabledAddress(false);
        setDisabledBtn(true);
        setActiveAddress(-1);
        setClearAddress(true);
        setShopSearch(null);

        setTimeout(() => {
          setClearAddress(false);
        }, 100);
      }
    });
  }, [activeType]);

  useEffect(() => {
    if (activeAddress < 0) {
      setShopSearch(null);
      setDisabledBtn(true);
    } else {
      shopList?.map((shop) => {
        if (shop.id === activeAddress) {
          setShopSearch(shop);
          setDisabledBtn(false);
        }
      });
      setDisabledBtn(false);
    }
  }, [activeAddress]);

  function changeShop() {
    if (shopSearch) setActiveShop(shopSearch);
  }

  function updateTypeOption(
    list: Array<{
      name: string;
      shops: Array<ShopsTypeItem>;
    }>
  ) {
    const optionList: Array<{
      id: number;
      name: string;
    }> = [];

    let newPlacemarkList: Array<ShopsTypeItem> = [];

    list.forEach((listItem, index) => {
      const option: {
        id: number;
        name: string;
      } = { id: 0, name: '' };

      option.id = index;
      option.name = listItem.name;
      optionList.push(option);

      newPlacemarkList = newPlacemarkList.concat(listItem.shops);
    });

    setTypeOption(optionList);

    return newPlacemarkList;
  }

  useEffect(() => {
    const newPlacemarks = updateTypeOption(shopTypes);
    setActivePlacemark(newPlacemarks);
  }, [shopTypes]);

  return (
    <Component>
      <Inner>
        <Cell>
          <SelectComponent
            optionList={shopTypeByCitiesList}
            activeValue={0}
            placeholder={'Город'}
            onChange={(value: number) => setActiveCity(value)}
          />
        </Cell>
        <Cell>
          <SelectComponent
            optionList={typeOption}
            activeValue={0}
            placeholder={'Тип магазина'}
            isDisabled={isDisabledType}
            onChange={(value: string) => setActiveType(value)}
            isLabel={true}
            isClearValue={isClearType}
          />
        </Cell>
        <Cell>
          <SelectComponent
            optionList={addressList}
            activeValue={0}
            placeholder={'Адрес'}
            isDisabled={isDisabledAddress}
            onChange={(value: number) => setActiveAddress(value)}
            isClearValue={isClearAddress}
          />
        </Cell>
        <Cell>
          <SearchBtn
            variant="orange"
            onClick={changeShop}
            isDisabledBtn={isDisabledBtn}
          >
            Найти
          </SearchBtn>
        </Cell>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  max-width: 1680px;
  padding: 0 40px;
  margin: 0 auto 60px;

  ${media.laptop(css`
    margin-bottom: 40px;
  `)}

  ${media.tablet(css`
    padding: 0 20px;
    margin-bottom: 50px;
  `)}
  
  ${media.mobile(css`
    margin-bottom: 48px;
  `)}
`;

const Inner = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 0 -16px;

  ${media.laptop(css`
    margin: 0 -12px;
  `)}

  ${media.tablet(css`
    flex-wrap: wrap;
    margin: -15px -11px;
  `)}
  
  ${media.mobile(css`
    margin: -17px 0;
  `)}
`;

const Cell = styled.div`
  padding: 0 16px;
  width: 100%;

  ${media.laptop(css`
    padding: 0 12px;
  `)}

  ${media.tablet(css`
    flex: 1 1 33.33%;
    padding: 15px 11px;
  `)}
  
  ${media.mobile(css`
    flex: 1 1 100%;
    padding: 17px 0;
  `)}
`;

const SearchBtn = styled(Button)<{ isDisabledBtn?: boolean }>`
  position: relative;
  display: block;
  width: 100%;
  text-align: center;
  min-height: 74px;
  padding: 16px 0;
  transition: all 0.3s linear;

  font-weight: 500;
  font-size: 32px;
  line-height: 42px;

  ${media.laptop(css`
    min-height: 56px;
    padding: 12px 0;
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    min-height: 48px;
    padding: 11px 0;
    font-size: 20px;
    line-height: 26px;
  `)}
  
  ${media.mobile(css`
    min-height: 50px;
    padding: 15px 0;
    font-size: 16px;
    line-height: 20px;
  `)}

  ${(props) =>
    props.isDisabledBtn
      ? css`
          pointer-events: none;
          background: ${colors.gray50};
          color: ${colors.black};
        `
      : ''}
`;

export default Filter;
