import React from 'react';
import styled, { css } from 'styled-components';

import Star from '@/components/Sticker/Star';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import { ShoppingCenterPageType } from '@/typings/model';
import Picture from '@/components/Picture';
import bgHero from '@/assets/images/shopping-center/shopping-bg.png';
import bgHero2x from '@/assets/images/shopping-center/shopping-bg@2x.png';
import bgHeroWebp from '@/assets/images/shopping-center/shopping-bg.webp';
import bgHeroWebp2x from '@/assets/images/shopping-center/shopping-bg@2x.webp';
import bgHeroLaptop from '@/assets/images/shopping-center/shopping-bg_laptop.png';
import bgHeroLaptop2x from '@/assets/images/shopping-center/shopping-bg_laptop@2x.png';
import bgHeroLaptopWebp from '@/assets/images/shopping-center/shopping-bg_laptop.webp';
import bgHeroLaptopWebp2x from '@/assets/images/shopping-center/shopping-bg_laptop@2x.webp';
import bgHeroTablet from '@/assets/images/shopping-center/shopping-bg_tablet.png';
import bgHeroTablet2x from '@/assets/images/shopping-center/shopping-bg_tablet@2x.png';
import bgHeroTabletWebp from '@/assets/images/shopping-center/shopping-bg_tablet.webp';
import bgHeroTabletWebp2x from '@/assets/images/shopping-center/shopping-bg_tablet@2x.webp';
import bgHeroMobile from '@/assets/images/shopping-center/shopping-bg_mobile.png';
import bgHeroMobile2x from '@/assets/images/shopping-center/shopping-bg_mobile@2x.png';
import bgHeroMobileWebp from '@/assets/images/shopping-center/shopping-bg_mobile.webp';
import bgHeroMobileWebp2x from '@/assets/images/shopping-center/shopping-bg_mobile@2x.webp';

interface Props {
  page: ShoppingCenterPageType;
}

function Hero({ page }: Props) {
  const { topTitle, topLabelText } = page.templateFields;
  return (
    <Component>
      <Background>
        <Picture
          mobileSmall={{
            src: bgHeroMobile,
            src2x: bgHeroMobile2x,
            webp: bgHeroMobileWebp,
            webp2x: bgHeroMobileWebp2x,
          }}
          tabletSmall={{
            src: bgHeroTablet,
            src2x: bgHeroTablet2x,
            webp: bgHeroTabletWebp,
            webp2x: bgHeroTabletWebp2x,
          }}
          laptop={{
            src: bgHeroLaptop,
            src2x: bgHeroLaptop2x,
            webp: bgHeroLaptopWebp,
            webp2x: bgHeroLaptopWebp2x,
          }}
          desktop={{
            src: bgHero,
            src2x: bgHero2x,
            webp: bgHeroWebp,
            webp2x: bgHeroWebp2x,
          }}
        />
      </Background>
      <Inner>
        <Left>
          <Title>{topTitle}</Title>
        </Left>
        <Right>
          <StarWrapper>
            <Star
              rotate={'18deg'}
              color="orange"
              label={topLabelText ?? 'ПОСМОТРИ, НАШЛИ БЛИЖАЙШИЙ МАГАЗИН'}
              link={''}
              size={'big'}
            />
          </StarWrapper>
        </Right>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  padding: 163px 0 245px;

  ${media.laptop(css`
    padding: 104px 0 80px;
  `)}

  ${media.tablet(css`
    padding: 65px 0 86px;
  `)}
  
  ${media.mobile(css`
    padding: 20px 0 212px;
  `)}
`;

const Inner = styled.div`
  max-width: 1680px;
  margin: 0 auto;
  padding: 0 40px;

  display: flex;
  justify-content: space-between;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

const Left = styled.div`
  flex: 1 1 784px;
  max-width: 784px;

  ${media.tablet(css`
    flex: 1 1 442px;
    max-width: 442px;
  `)}

  ${media.mobile(css`
    flex: 1 1 100%;
    max-width: 100%;
  `)}
`;

const Right = styled.div`
  flex: 1 1 auto;
  position: relative;
`;

const Title = styled.h2`
  max-width: 784px;
  margin: 0;
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.black1000};

  ${media.laptop(css`
    max-width: 588px;
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    max-width: 442px;
    font-size: 48px;
    line-height: 56px;
  `)}
  
  ${media.mobile(css`
    max-width: 100%;
    font-size: 34px;
    line-height: 42px;
  `)}
`;

const Background = styled.div`
  position: absolute;
  right: 0;
  top: -133px;

  ${media.laptop(css`
    top: -178px;
  `)}

  ${media.tablet(css`
    top: -122px;
  `)}
  
  ${media.mobile(css`
    top: 28px;
  `)}
`;

const StarWrapper = styled.div`
  position: absolute;
  top: -35px;
  right: 161px;
  transform: rotate(-3deg);

  width: 360px;
  height: 360px;

  ${media.laptop(css`
    top: -33px;
    right: 144px;

    width: 240px;
    height: 240px;
  `)}

  ${media.tablet(css`
    top: -15px;
    right: 49px;

    width: 200px;
    height: 200px;
  `)}
  
  ${media.mobile(css`
    top: 103px;
    right: -25px;

    width: 180px;
    height: 180px;
  `)}
`;

export default Hero;
