import React from 'react';
import styled from 'styled-components';

import { useLayout } from '@/components/Layout/Layout.hooks';
import Breadcrumbs from '@/components/Breadcrumbs';
import Hero from '@/modules/Post/components/Hero';
import Main from '@/modules/Post/components/Main';
import useCurrentPage from '@/hooks/useCurrentPage';
import { RecipesSinglePageType } from '@/typings/model';
import { BreadCrumbs } from '@/typings/common';
import { setBreadCrumbsList } from '@/utils/common';

function RecipesSingle() {
  const page = useCurrentPage<RecipesSinglePageType>();
  const { setThemePage } = useLayout();
  setThemePage('');

  const pageFields = page?.templateFields;
  if (!pageFields) return null;

  const { date } = pageFields;

  const BREADCRUMB_LIST: Array<BreadCrumbs> = setBreadCrumbsList(
    {
      title: page?.title ?? '',
      path: page?.path ?? '',
    },
    page?.parent
      ? [
          {
            id: 2,
            title: `Наша продукция`,
            path: `/our-production`,
          },
          page.parent,
        ]
      : [{ id: 2, title: `Наша продукция`, path: `/our-production` }]
  );

  return (
    <Wrapper>
      <Breadcrumbs breadcrumbs={BREADCRUMB_LIST} />
      <Content>
        <Hero title={page?.title ?? ''} date={date} />
        <Main body={page?.body ?? ''} />
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const Content = styled.div``;

export default RecipesSingle;
