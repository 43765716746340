import React from 'react';
import styled, { css } from 'styled-components';

import { ImageThumbnail, StringField } from '@/typings/model';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

interface Props {
  sliderItem: {
    image: ImageThumbnail;
    text: StringField;
    title: StringField;
    year: StringField;
  };
}

function HistorySlide({ sliderItem }: Props) {
  const { text, title, year } = sliderItem;
  return (
    <Slide>
      {year && <Year>{year}</Year>}
      {title && <Title>{title}</Title>}
      {text && <Text>{text}</Text>}
    </Slide>
  );
}

const Slide = styled.div``;

const Year = styled.span`
  display: block;
  margin-bottom: 20px;

  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  color: ${colors.yellow100};

  ${media.mobile(css`
    font-size: 22px;
    line-height: 28px;
  `)}
`;

const Title = styled.h3`
  margin: 0 0 20px;
  padding: 0;

  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  color: ${colors.white};

  ${media.mobile(css`
    margin-bottom: 30px;
    font-size: 28px;
    line-height: 34px;
  `)}
`;

const Text = styled.p`
  font-size: 18px;
  line-height: 22px;
  color: ${colors.grayLight};

  ${media.mobile(css`
    font-size: 14px;
    line-height: 20px;
  `)}
`;

export default HistorySlide;
