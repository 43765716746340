import React from 'react';
import styled, { css } from 'styled-components';

import { nl2br } from '@tager/web-core';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { StringField } from '@/typings/model';

interface Props {
  title: StringField;
  text: StringField;
}

function PostSection({ title, text }: Props) {
  return (
    <Component>
      <Inner>
        <Left>
          <Title>{title}</Title>
        </Left>
        <Right>
          <Text>{nl2br(text)}</Text>
        </Right>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  max-width: 1680px;
  margin: 120px auto 0;
  padding: 0 40px;

  ${media.tablet(css`
    margin-top: 70px;
    padding: 0 20px;
  `)}
`;

const Inner = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.mobile(css`
    display: block;
  `)}
`;

const Left = styled.div`
  flex: 1 1 652px;
  max-width: 652px;
  margin-right: 30px;

  ${media.laptop(css`
    flex: 1 1 548px;
    max-width: 548px;
  `)}

  ${media.tablet(css`
    flex: 1 1 250px;
    max-width: 250px;
    margin-right: 37px;
  `)}

  ${media.mobile(css`
    max-width: 100%;
    margin: 0 0 30px;
  `)}
`;

const Right = styled.div`
  flex: 1 1 920px;
  max-width: 920px;

  ${media.laptop(css`
    flex: 1 1 588px;
    max-width: 588px;
  `)}

  ${media.tablet(css`
    flex: 1 1 440px;
  `)}

  ${media.mobile(css`
    max-width: 100%;
  `)}
`;

const Title = styled.h2`
  margin: 0;
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.black};

  ${media.laptop(css`
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    font-size: 48px;
    line-height: 56px;
  `)}

  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
  `)}
`;

const Text = styled.p`
  font-size: 30px;
  line-height: 36px;
  color: ${colors.black500};

  ${media.laptop(css`
    font-size: 20px;
    line-height: 26px;
  `)}

  ${media.tablet(css`
    font-size: 18px;
    line-height: 22px;
  `)}

  ${media.mobile(css`
    font-size: 14px;
    line-height: 20px;
  `)}
`;

export default PostSection;
