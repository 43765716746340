import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import Picture from '@/components/Picture';
import { ImageThumbnail, StringField } from '@/typings/model';
import Star from '@/components/Sticker/Star';

interface Props {
  image: ImageThumbnail;
  text: StringField;
  stickerLabel: StringField;
  stickerLink: StringField;
}

function BottomSection({ image, text, stickerLink, stickerLabel }: Props) {
  return (
    <Section>
      <Inner>
        <Left>
          <Text dangerouslySetInnerHTML={{ __html: text ?? '' }} />
        </Left>
        <Right>
          <Image>
            {image && <Picture {...convertThumbnailToPictureImage(image)} />}
          </Image>
        </Right>
        {stickerLabel && (
          <StickerWrapper>
            <Star
              rotate={'18deg'}
              color="lime"
              label={stickerLabel ?? ''}
              link={stickerLink ?? ''}
              size={'big'}
            />
          </StickerWrapper>
        )}
      </Inner>
    </Section>
  );
}

const Section = styled.div`
  margin: -80px 0 26px;
  clip-path: polygon(0 10px, 100% 20px, 100% 100%, 0% 100%);

  ${media.laptop(css`
    margin: -26px 0 124px;
  `)}

  ${media.tablet(css`
    margin: -26px 0 0;
  `)}
  
  ${media.mobile(css`
    clip-path: polygon(0 0, 100% 40px, 100% 100%, 0% 100%);
  `)}
`;

const Inner = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;

  ${media.mobile(
    css`
      position: relative;
    `
  )}
`;

const Left = styled.div`
  padding: 0 32px 0 40px;
  flex: 1 1 840px;
  max-width: 840px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${media.laptop(
    css`
      flex: 1 1 550px;
      max-width: 550px;
      padding: 0 24px 0 40px;
    `
  )}

  ${media.tablet(
    css`
      flex: 1 1 307px;
      padding: 0 37px 0 20px;
    `
  )}
  
  ${media.mobile(
    css`
      position: absolute;
      bottom: 128px;
      left: 0;
      width: 100%;
      z-index: 2;
      max-width: 100%;
      padding: 0 20px;
    `
  )}
`;

const Right = styled.div`
  flex: 1 1 1080px;
  max-width: 1080px;

  ${media.laptop(
    css`
      flex: 1 1 730px;
      max-width: 730px;
    `
  )}

  ${media.tablet(
    css`
      flex: 1 1 460px;
      max-width: 460px;
    `
  )}
`;

const Image = styled.div`
  position: relative;
  width: 100%;

  &:before {
    display: block;
    content: '';
    padding-bottom: 106%;

    ${media.laptop(css`
      padding-bottom: 100.4%;
    `)}

    ${media.tablet(css`
      padding-bottom: 103.2%;
    `)}
    
    ${media.mobile(css`
      padding-bottom: 202%;
    `)}
  }

  div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  img,
  picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Text = styled.p`
  max-width: 648px;
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.black};

  ${media.laptop(css`
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    font-size: 32px;
    line-height: 38px;
  `)}
  
  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
    color: ${colors.white};
  `)}
`;

const StickerWrapper = styled.div`
  position: absolute;
  bottom: 182px;
  right: 60px;
  width: 360px;
  height: 360px;

  ${media.laptop(css`
    bottom: 100px;
    right: 40px;

    width: 240px;
    height: 240px;
  `)}

  ${media.tablet(css`
    bottom: 47px;
    right: 20px;

    width: 194px;
    height: 194px;
  `)}

  ${media.mobile(css`
    bottom: auto;
    top: 75px;
    right: -29px;

    width: 160px;
    height: 160px;
  `)}
`;

export default BottomSection;
