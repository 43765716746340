import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import { ImageThumbnail, StringField } from '@/typings/model';
import Picture from '@/components/Picture';

interface Props {
  product: {
    image: ImageThumbnail;
    name: StringField;
    variety: StringField;
  };
  setActiveProduct: (value: number) => void;
  index: number;
  isActive: boolean;
}

function GallerySlide({ product, setActiveProduct, index, isActive }: Props) {
  return (
    <Component onClick={() => setActiveProduct(index)} isActive={isActive}>
      <Image>
        {product.image ? (
          <Picture
            mobileSmall={convertThumbnailToPictureImage(product.image)}
          />
        ) : null}
      </Image>
    </Component>
  );
}

const Image = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 32px;
  background: ${colors.gray50};
  border: 2px solid transparent;
  transition: all 0.3s linear;

  &:before {
    display: block;
    content: '';
    padding-bottom: 149.17%;
  }

  div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  picture,
  img {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }

  ${media.laptop(css`
    border-radius: 24px;
    &:before {
      padding-bottom: 148.9%;
    }
  `)}

  ${media.tablet(css`
    ${media.tablet(css`
      border-radius: 14px;
    `)}
  `)}
  
  ${media.mobile(css`
    &:before {
      padding-bottom: 151%;
    }
  `)}
`;

const Component = styled.div<{ isActive: boolean }>`
  position: relative;
  cursor: pointer;

  &:hover {
    ${Image} {
      border-color: ${colors.orange};
    }
  }

  ${(props) =>
    props.isActive
      ? css`
          ${Image} {
            border-color: ${colors.orange};
          }
        `
      : ''}
`;

export default GallerySlide;
