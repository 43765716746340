import React from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as ArrowRight } from '@/assets/svg/arrow-right.svg';
import { StringField } from '@/typings/model';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import Link from '@/components/Link';

type Props = {
  careersButtonLabel: StringField;
  careersButtonUrl: StringField;
};

function More({ careersButtonUrl, careersButtonLabel }: Props) {
  return (
    <Component>
      {careersButtonUrl && <NewsLink to={careersButtonUrl ?? '#'} />}
      <Inner>
        <Right>
          {careersButtonLabel}
          <ArrowRight />
        </Right>
      </Inner>
    </Component>
  );
}

const NewsLink = styled(Link)`
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
`;

const Right = styled.div`
  display: flex;
  align-items: center;

  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  color: ${colors.white};
  transition: all 0.3s linear;

  svg {
    margin-left: 10px;
    width: 28px;
    height: 17px;
  }

  ${media.laptop(css`
    font-size: 20px;
    line-height: 26px;

    svg {
      margin-left: 0;
      width: 32px;
      height: 15px;
    }
  `)}

  ${media.tablet(css`
    font-size: 18px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    font-size: 20px;
    line-height: 40px;

    svg {
      margin-left: 8px;
      width: 32px;
      height: 20px;
    }
  `)}
`;

const Component = styled.div`
  position: relative;
  display: inline-block;

  &:hover {
    ${Right} {
      color: ${colors.black};
    }
  }
`;

export default More;
