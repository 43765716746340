import React from 'react';
import styled from 'styled-components';

import Picture from '@/components/Picture';
import circleYellowUrl from '@/assets/images/certificate/circle-yellow.png';
import circleYellowUrl2x from '@/assets/images/certificate/circle-yellow@2x.png';
import circleYellowUrlWebp from '@/assets/images/certificate/circle-yellow.webp';
import circleYellowUrlWebp2x from '@/assets/images/certificate/circle-yellow@2x.webp';
import circleOrangeUrl from '@/assets/images/certificate/circle-orange.png';
import circleOrangeUrl2x from '@/assets/images/certificate/circle-orange@2x.png';
import circleOrangeUrlWebp from '@/assets/images/certificate/circle-orange.webp';
import circleOrangeUrlWebp2x from '@/assets/images/certificate/circle-orange@2x.webp';

type Props = {
  color: string;
  size?: string;
};

function Circle({ color, size }: Props) {
  return (
    <Component size={size}>
      <Inner>
        {color === 'yellow' ? (
          <Picture
            mobileSmall={{
              src: circleYellowUrl,
              src2x: circleYellowUrl2x,
              webp: circleYellowUrlWebp,
              webp2x: circleYellowUrlWebp2x,
            }}
          />
        ) : (
          <Picture
            mobileSmall={{
              src: circleOrangeUrl,
              src2x: circleOrangeUrl2x,
              webp: circleOrangeUrlWebp,
              webp2x: circleOrangeUrlWebp2x,
            }}
          />
        )}
      </Inner>
    </Component>
  );
}

const Component = styled.div<{ size?: string }>`
  position: relative;

  &:before {
    display: block;
    content: '';
    padding-bottom: 100%;
  }
`;
const Inner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  div,
  picture,
  img {
    width: 100%;
    height: 100%;
  }
`;

export default Circle;
