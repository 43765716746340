import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { ImageThumbnail } from '@/typings/model';
import Picture from '@/components/Picture';
import bannerUrl from '@/assets/images/certificate/banner-bg-small.png';
import bannerUrl2x from '@/assets/images/certificate/banner-bg-small@2x.png';
import bannerUrlWebp from '@/assets/images/certificate/banner-bg-small.webp';
import bannerUrlWebp2x from '@/assets/images/certificate/banner-bg-small@2x.webp';
import { media } from '@/utils/mixin';

type Props = {
  freeChoiceImage?: ImageThumbnail;
};

function Banner({ freeChoiceImage }: Props) {
  return (
    <Component>
      <Background>
        <Picture
          mobileSmall={{
            src: bannerUrl,
            src2x: bannerUrl2x,
            webp: bannerUrlWebp,
            webp2x: bannerUrlWebp2x,
          }}
        />
      </Background>
      <Image>
        {freeChoiceImage ? (
          <Picture
            mobileSmall={convertThumbnailToPictureImage(freeChoiceImage)}
          />
        ) : null}
      </Image>
    </Component>
  );
}

const Component = styled.div`
  position: relative;
  padding: 109px 72px 144px;
  min-height: 980px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${media.laptop(css`
    padding: 80px 54px 110px;
    min-height: 735px;
  `)}

  ${media.tablet(css`
    padding: 127px 30px 110px;
    min-height: 680px;
  `)}
  
  ${media.mobile(css`
    padding: 40px 20px;
    min-height: 432px;
  `)}
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img,
  picture,
  div {
    width: 100%;
    height: 100%;
  }

  ${media.tablet(css`
    display: none;
  `)}
`;

const Image = styled.div`
  position: relative;
  width: 682px;
  left: -155px;

  ${media.laptop(css`
    width: 516px;
    left: -115px;
  `)}

  ${media.tablet(css`
    width: 454px;
    left: -88px;
  `)}
  
    
  ${media.mobile(css`
    width: 422px;
    left: -70px;
  `)}

  &:before {
    display: block;
    content: '';
    padding-bottom: 83%;
  }

  div {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`;

export default Banner;
