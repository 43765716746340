import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import Button from '@/components/Button';
import { media } from '@/utils/mixin';
import useSettingItem from '@/hooks/useSettingItem';
import { ReactComponent as CloseIcon } from '@/assets/svg/close.svg';

const ACCEPT_COOKIES_BUTTON = 'Принять';
const DECLINE_COOKIES_BUTTON = 'Отклонить';
const SETUP_COOKIES_LINK = 'Настроить файлы cookie.';

interface Props {
  onSubimt: () => void;
  onDecline: () => void;
  onSetupCookie: () => void;
  hasCookies: boolean;
}

const FooterPopup = ({
  onSubimt,
  onDecline,
  onSetupCookie,
  hasCookies,
}: Props) => {
  const enabled = useSettingItem('COOKIE_POPUP_ENABLED');
  const infoText = useSettingItem('COOKIE_POPUP_TEXT');

  const [popupClosed, setPopupClosed] = useState<boolean>(() => {
    return hasCookies;
  });

  useEffect(() => {
    setPopupClosed(hasCookies);
  }, [hasCookies]);

  function closePopup() {
    setPopupClosed(true);
  }

  const handleAcceptCookies = () => {
    onSubimt();
    closePopup();
  };

  const handleDeclineCookies = () => {
    onDecline();
    closePopup();
  };

  return enabled && !popupClosed ? (
    <Component>
      <Inner>
        <Info dangerouslySetInnerHTML={{ __html: infoText ?? '' }} />
        <ButtonGroup>
          <ActionButton variant="orange" onClick={handleAcceptCookies}>
            {ACCEPT_COOKIES_BUTTON}
          </ActionButton>
          <ActionButton variant="outlined" onClick={handleDeclineCookies}>
            {DECLINE_COOKIES_BUTTON}
          </ActionButton>
        </ButtonGroup>
        <ActionLink onClick={onSetupCookie}>{SETUP_COOKIES_LINK}</ActionLink>
        <CloseButton onClick={closePopup}>
          <CloseIcon />
        </CloseButton>
      </Inner>
    </Component>
  ) : null;
};

export default FooterPopup;

const Component = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${colors.white};
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 63px;
  z-index: 9000;

  ${media.mobile(
    css`
      min-height: auto;
      padding: 14px 21px;
    `
  )}

  ${media.mobile(
    css`
      padding: 11px;
    `
  )}
`;

const Inner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 90%;

  ${media.mobile(
    css`
      width: 100%;
    `
  )}

  ${media.mobile(
    css`
      flex-direction: column;
    `
  )}
`;

const Info = styled.div`
  font-size: 14px;
  margin-right: 21px;

  ${media.tablet(css`
    font-size: 12px;
    margin-right: 0;
  `)}

  ${media.mobile(css`
    order: 1;
    width: 90%;
    margin: auto;
  `)}
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: nowrap;

  ${media.mobile(css`
    order: 3;
  `)}
`;

const ActionButton = styled(Button)`
  min-width: 127px;
  font-weight: 700;
  font-size: 16px;
  line-height: 39px;
  padding: 0;

  border: 1px solid ${colors.orange};

  margin-left: ${({ variant }) => variant === 'outlined' && '22px'};
  color: ${({ variant }) =>
    variant === 'outlined' ? `${colors.orange}` : `${colors.white}`};

  &:hover {
    color: ${({ variant }) =>
      variant === 'outlined' ? `${colors.orange}` : `${colors.white}`};
    background: ${({ variant }) =>
      variant === 'outlined' ? `${colors.white}` : `${colors.orange}`};

    border: ${({ variant }) =>
      variant === 'outlined' && '1px solid transparent;'}

  ${media.tablet(css`
    min-width: 85px;
    font-size: 12px;
    line-height: 26px;
    margin-left: 14px;
  `)}

  ${media.mobile(css`
    margin-left: 10px;
  `)}

  ${media.mobile(css`
    margin-left: 0;

    &:first-child {
      margin-right: 17px;
    }
  `)}
`;

const ActionLink = styled.span`
  margin-left: 19px;
  cursor: pointer;
  color: ${colors.blue};
  font-size: 14px;
  font-family: Raleway;
  line-height: 25px;
  white-space: nowrap;

  &:hover {
    text-decoration-line: underline;
  }

  ${media.tablet(css`
    font-size: 12px;
    margin-left: 13px;
  `)}

  ${media.mobile(css`
    white-space: wrap;
    line-height: 14px;
  `)}

  ${media.mobile(css`
    order: 2;
    margin-top: 4px;
    margin-bottom: 10px;
    margin-left: 0;
  `)}
`;

const CloseButton = styled.button`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  border-radius: 50%;
  z-index: 10;
  padding: 15px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  ${media.laptop(css`
    right: 13px;
  `)}

  ${media.mobile(css`
    right: 14px;
    top: 16px;
  `)}

  svg {
    width: 20px;
    height: 20px;
    display: block;
    fill: currentColor;

    ${media.laptop(css`
      width: 14px;
      height: 14px;
    `)}
  }
`;
