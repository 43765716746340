import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import SeeAlso from '@/modules/Search/components/SeeAlso';
import { ReactComponent as CloseIcon } from '@/assets/svg/close-message.svg';

type Props = {
  isSentSuccess: boolean;
  setSentSuccess: (value: boolean) => void;
  messageSuccess: string;
};

function MessageSuccess({
  isSentSuccess,
  setSentSuccess,
  messageSuccess,
}: Props) {
  function closeMessageSuccess() {
    setSentSuccess(false);
  }

  return (
    <Message isSentSuccess={isSentSuccess}>
      <MessageWrapper>
        <CloseBtn onClick={closeMessageSuccess}>
          <CloseIcon />
        </CloseBtn>
        <MessageInner>
          <Title>Ваша заявка успешно отправлена</Title>
          <Text>{messageSuccess}</Text>
        </MessageInner>
      </MessageWrapper>
      <SeeAlsoWrapper>
        <SeeAlso />
      </SeeAlsoWrapper>
    </Message>
  );
}

const Message = styled.div<{ isSentSuccess: boolean }>`
  transition: all 0.3s ease-in-out;
  ${(props) =>
    props.isSentSuccess
      ? css`
          position: relative;
          visibility: visible;
          opacity: 1;
          z-index: 1;
        `
      : css`
          position: absolute;
          visibility: hidden;
          opacity: 0;
          z-index: -1000;
        `}
`;

const MessageWrapper = styled.div`
  position: relative;
  max-width: 1056px;
  width: 100%;
  margin: 0 auto;
  background: rgba(241, 241, 241, 0.5);

  display: flex;
  justify-content: center;

  ${media.laptop(css`
    max-width: 792px;
  `)}
`;

const MessageInner = styled.div`
  max-width: 526px;
  padding: 100px 0;

  ${media.laptop(css`
    max-width: 400px;
    padding: 80px 0;
  `)}
`;

const Title = styled.span`
  display: block;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  color: ${colors.black};

  ${media.laptop(css`
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 32px;
  `)}
`;

const Text = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: ${colors.black600};

  ${media.laptop(css`
    font-size: 16px;
    line-height: 24px;
  `)}
`;

const SeeAlsoWrapper = styled.div`
  max-width: 1680px;
  margin: 80px auto -212px;
  padding: 0 40px;

  ${media.laptop(css`
    margin-bottom: -160px;
  `)}
`;

const CloseBtn = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  transition: all 0.3s linear;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  ${media.laptop(css`
    top: 14px;
    right: 14px;
    width: 40px;
    height: 40px;
  `)}

  &:hover {
    background-color: ${colors.gray50};
  }

  svg {
    width: 32px;
    height: 32px;

    ${media.laptop(css`
      width: 24px;
      height: 24px;
    `)}
  }
`;

export default MessageSuccess;
