import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { gsap } from 'gsap';
import { chunk } from 'lodash';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Navigation,
  Autoplay,
  Pagination,
  EffectFade,
} from 'swiper';

import { useMedia } from '@tager/web-core';

import { StringField } from '@/typings/model';
import { breakpoints, colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { selectVacancyList } from '@/store/reducers/tager/vacancies';
import { useTypedSelector } from '@/store/store';
import OffersCard from '@/modules/Home/components/Vacancies/components/OffersCard';
import Star from '@/components/Sticker/Star';
import Line from '@/modules/Home/components/Vacancies/components/Line';

import CategoryItem from './components/CategoryItem';
import More from './components/More';

SwiperCore.use([Navigation, Autoplay, Pagination, EffectFade]);

type Props = {
  careersTitle: StringField;
  careersButtonLabel: StringField;
  careersButtonUrl: StringField;
};

function Vacancies({
  careersTitle,
  careersButtonLabel,
  careersButtonUrl,
}: Props) {
  const isMobile = useMedia(`(max-width: ${breakpoints.tabletSmall - 1}px)`);

  const vacancyList = useTypedSelector(selectVacancyList);

  const [isActiveItem, setActiveItem] = useState(vacancyList[0] ?? '');
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);

  const starRef = useRef<HTMLDivElement | null>(null);
  const starTween = useRef<gsap.core.Tween>();

  useEffect(() => {
    if (!starRef.current) {
      return;
    }
    const set = gsap.set(starRef.current, { rotate: isMobile ? 28 : 12 });
    const delayedCall = gsap.delayedCall(0, () => {
      starTween.current = gsap.to(starRef.current, {
        y: 50,
        rotate: 0,
        paused: true,
      });
    });

    return () => {
      set.kill();
      delayedCall.kill();
      starTween.current?.kill();
    };
  }, []);

  function handleStarMouseEnter() {
    starTween.current?.play();
  }

  function handleStarMouseLeave() {
    starTween.current?.reverse();
  }

  function getDisplayedActiveContent(category: string) {
    const vacancyListNew = vacancyList.filter((list) => list.name === category);
    if (vacancyListNew[0]) {
      return vacancyListNew[0].vacancies.filter(
        (listItem) => listItem.isDisplayOnHome !== 0
      );
    } else {
      return [];
    }
  }

  function isOdd(number: number) {
    return number % 2 === 1;
  }

  function getCountVacancies(
    list: Array<{
      id: number;
      name: string;
      urlAlias: string;
      isDisplayOnHome: number;
    }>
  ) {
    return list.filter((listItem, index) => listItem.isDisplayOnHome !== 0)
      .length;
  }

  return (
    <Component>
      <Inner>
        <VacanciesContainer>
          <Top>
            <TitleSection
              dangerouslySetInnerHTML={{ __html: careersTitle ?? '' }}
            />
          </Top>
        </VacanciesContainer>

        <Bottom>
          <VacanciesContainer>
            <Categories>
              <CategoriesList>
                <Swiper
                  slidesPerView="auto"
                  spaceBetween={20}
                  speed={500}
                  onSlideChange={(swiper) =>
                    setActiveSlideIndex(swiper.activeIndex)
                  }
                  breakpoints={{
                    320: {
                      spaceBetween: 10,
                    },
                    1260: {
                      spaceBetween: 30,
                    },
                  }}
                >
                  {vacancyList.map((categoryItem, index) => {
                    if (getCountVacancies(categoryItem.vacancies) === 0)
                      return false;
                    return (
                      <SwiperSlide key={index}>
                        <CategoryItem
                          categoryItem={categoryItem}
                          isActive={categoryItem.name === isActiveItem.name}
                          onChange={setActiveItem}
                          count={getCountVacancies(categoryItem.vacancies)}
                        />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </CategoriesList>
            </Categories>
          </VacanciesContainer>
          <Offers>
            {chunk(getDisplayedActiveContent(isActiveItem?.name), 8).map(
              (list, index) => {
                if (!list[index]) {
                  return null;
                }
                return (
                  <Line
                    scroll={isOdd(index) ? 'right' : 'left'}
                    key={list[index].id}
                  >
                    {list.map((card) => (
                      <OffersCell key={card.id}>
                        <OffersCard cardItem={card} />
                      </OffersCell>
                    ))}
                  </Line>
                );
              }
            )}
          </Offers>
        </Bottom>

        <VacanciesContainer>
          <MoreWrapper>
            <More
              careersButtonLabel={careersButtonLabel}
              careersButtonUrl={careersButtonUrl}
            />
          </MoreWrapper>
        </VacanciesContainer>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  position: relative;
  overflow: hidden;
`;
const Inner = styled.div`
  padding: 226px 0 80px;
  background: ${colors.orange};

  ${media.laptop(css`
    padding: 120px 0 48px;
  `)}

  ${media.tablet(css`
    padding: 100px 0 40px;
  `)}
  
  ${media.mobile(css`
    padding: 70px 0 50px;
  `)}
`;

const VacanciesContainer = styled.div`
  max-width: 1680px;
  padding: 0 40px;
  margin: 0 auto;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

const Top = styled.div`
  margin-bottom: 63px;

  ${media.laptop(css`
    margin-bottom: 38px;
  `)}

  ${media.tablet(css`
    margin-bottom: 21px;
  `)}
  
  ${media.mobile(css`
    margin-bottom: 19px;
  `)}
`;

const Bottom = styled.div``;

const MoreWrapper = styled.div`
  padding: 93px 0 0;

  ${media.laptop(css`
    padding: 38px 0 0;
  `)}

  ${media.tablet(css`
    padding-top: 28px;
  `)}
  
  ${media.mobile(css`
    padding-top: 15px;
    display: flex;
    justify-content: flex-end;
  `)}
`;

const TitleSection = styled.span`
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.white};

  b {
    font-weight: 500;
    color: ${colors.yellow100};
  }

  ${media.laptop(css`
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    font-size: 48px;
    line-height: 56px;
  `)}
  
  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
  `)}
`;

const Categories = styled.div`
  margin-bottom: 30px;
  width: 100%;

  ${media.laptop(css`
    margin-bottom: 21px;
  `)}

  ${media.tablet(css`
    margin-bottom: 11px;
  `)}
  
  ${media.mobile(css`
    margin-bottom: 16px;
  `)}
`;

const CategoriesList = styled.div`
  .swiper-container {
    height: 100%;
    cursor: grab;
    margin: 0 -40px;
    padding: 0 40px;
  }

  .swiper-slide {
    width: auto !important;
    &:last-child {
      padding-right: 40px !important;
    }
  }
`;

const Offers = styled.div``;

const OffersCell = styled.div`
  margin: 13px;

  ${media.laptop(css`
    margin: 8px;
  `)}

  ${media.tablet(css`
    margin: 7px 8px;
  `)}
  
  ${media.mobile(css`
    margin: 5px;
  `)}
`;

const StarWrapper = styled.div`
  position: absolute;
  right: 77px;
  top: -141px;
  transform: rotate(12deg);
  will-change: transform;

  width: 316px;
  height: 316px;

  ${media.laptop(css`
    right: 40px;
    top: -111px;

    width: 240px;
    height: 240px;
  `)}

  ${media.tabletSmall(css`
    right: 4px;
    top: -84px;

    width: 180px;
    height: 180px;
  `)}
  
  ${media.mobile(css`
    right: -1px;
    top: -87px;

    width: 164px;
    height: 164px;
    transform: rotate(10deg);
  `)}
`;

export default Vacancies;
