import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';

import Breadcrumbs from '@/components/Breadcrumbs';
import { media } from '@/utils/mixin';
import InformationLine from '@/components/InformationLine';
import SectionSlider from '@/components/SectionSlider';
import GalleryBlock from '@/components/GalleryBlock';
import Subscription from '@/components/Subscription';
import useCurrentPage from '@/hooks/useCurrentPage';
import { ImageThumbnail, SalonPageType } from '@/typings/model';
import { useLayout } from '@/components/Layout/Layout.hooks';
import { colors } from '@/constants/theme';
import { BreadCrumbs } from '@/typings/common';
import { setBreadCrumbsList } from '@/utils/common';
import { useTypedSelector } from '@/store/store';
import { selectMenuItemListByAlias } from '@/store/reducers/tager/menus';

import Hero from './components/Hero';
import ServicesSection from './components/ServicesSection';
import SalonSection from './components/SalonSection';
import About from './components/About';
import InformationSection from './components/InformationSection';

function Salon() {
  const page = useCurrentPage<SalonPageType>();
  const { setThemePage, setActiveStoreName, setMenuStore } = useLayout();

  const headerMenuFashionMoll =
    useTypedSelector((state) =>
      selectMenuItemListByAlias(state, 'fashion-moll')
    ) ?? [];
  const BREADCRUMB_LIST: Array<BreadCrumbs> = setBreadCrumbsList(
    {
      title: page?.title ?? '',
      path: page?.path ?? '',
    },
    page?.parent
  );

  function setSlider(slides: Array<ImageThumbnail> | null) {
    const newSlider: Array<string> = [];
    if (slides) {
      slides.forEach((slide) => {
        if (slide?.url) {
          newSlider.push(slide.url);
        }
      });
    }
    return newSlider;
  }

  useEffect(() => {
    if (page?.path && page?.path.includes('modnyy-moll')) {
      setThemePage('#E4FF41');
      setActiveStoreName({
        value: 'Модный молл',
        label: 'Модный молл',
      });
      setMenuStore(headerMenuFashionMoll);
    } else {
      setThemePage('');
    }
    return () => {
      setActiveStoreName({
        value: '',
        label: '',
      });
      setMenuStore([]);
    };
  }, [page?.path]);

  if (!page?.templateFields) return null;

  const { address } = page?.templateFields;

  return (
    <Wrapper>
      <Breadcrumbs breadcrumbs={BREADCRUMB_LIST} />
      <Hero
        title={page?.title ?? ''}
        address={`${address}` ?? ''}
        topImage={page?.templateFields.topImage}
        workingTime={page?.templateFields.workingTime ?? ''}
      />
      {page?.templateFields.informationLineField?.label && (
        <InformationLine
          backgroundLine={page?.templateFields.informationLineBackground ?? ''}
          colorLine={page?.templateFields.informationLineColor ?? ''}
          fieldLine={page?.templateFields.informationLineField}
        />
      )}
      <ServicesSection options={page?.templateFields.options ?? []} />
      <SalonSection title={page?.templateFields.aboutTitle ?? ''}>
        <About body={page?.templateFields.aboutText ?? ''} />
      </SalonSection>
      <SalonSection title={page?.templateFields.galleryTitle ?? ''}>
        {setSlider(page?.templateFields.gallerySlider ?? []).length !== 0 && (
          <SliderWrapper>
            <SliderInner>
              <SectionSlider
                gallery={setSlider(page?.templateFields.gallerySlider ?? [])}
              />
            </SliderInner>
          </SliderWrapper>
        )}
        <GalleryBlock gallery={page?.templateFields.galleryBottom ?? []} />
      </SalonSection>
      <InformationSectionWrapper>
        <InformationSection
          address={`${address}` ?? ''}
          workingTime={page?.templateFields.workingTime ?? ''}
          brands={page?.templateFields.brands ?? []}
          contacts={page?.templateFields.contacts ?? []}
          categories={page?.templateFields.range ?? []}
        />
      </InformationSectionWrapper>
      <Subscription
        background={
          page?.path && page?.path.includes('modnyy-moll') ? colors.gray50 : ''
        }
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  overflow: hidden;
`;

const SliderWrapper = styled.div`
  max-width: 1920px;
  margin: 72px auto 0;
  position: relative;

  &:before {
    display: block;
    content: '';
    padding-bottom: calc(100vw / 2);

    @media (min-width: 1920px) {
      padding-bottom: 960px;
    }

    ${media.tablet(css`
      padding-bottom: 62%;
    `)}

    ${media.mobile(css`
      padding-bottom: 141.67%;
    `)}
  }

  ${media.laptop(css`
    margin-top: 56px;
  `)}

  ${media.tablet(css`
    margin-top: 50px;
  `)}
`;

const SliderInner = styled.div`
  position: absolute;
  content: '';
  width: calc(100vw - 0.375rem);
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  @media (min-width: 1921px) {
    width: calc(1920px - 0.375rem);
  }

  ${media.tablet(css`
    height: 100%;
    width: 100vw;
    left: -20px;
    transform: translateX(0);
  `)}
`;

const InformationSectionWrapper = styled.div`
  margin: 95px auto 270px;

  ${media.laptop(css`
    margin-top: 86px;
  `)}

  ${media.tablet(css`
    margin: 70px auto 108px;
  `)}

  ${media.mobile(css`
    margin: 50px auto 112px;
  `)}
`;

export default Salon;
