import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { gsap } from 'gsap';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { ImageThumbnail, StringField } from '@/typings/model';
import Picture from '@/components/Picture';
import phoneUrl from '@/assets/images/discount/phone.png';
import phoneUrl2x from '@/assets/images/discount/phone@2x.png';
import phoneUrlWebp from '@/assets/images/discount/phone.webp';
import phoneUrlWebp2x from '@/assets/images/discount/phone@2x.webp';

type Props = {
  issueCardTitle: StringField;
  issueCardImage?: ImageThumbnail;
};

function IssueCard({ issueCardTitle, issueCardImage }: Props) {
  const pigRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    let timeline: gsap.core.Timeline;

    const set = gsap.set(pigRef.current, { opacity: 0 });

    const delayedCall = gsap.delayedCall(0, () => {
      if (pigRef.current) {
        timeline = gsap
          .timeline({
            scrollTrigger: {
              trigger: pigRef.current,
              scrub: true,
            },
          })
          .to(pigRef.current, { opacity: 1 })
          .to(
            pigRef.current,
            {
              y: 150,
              duration: 1.5,
            },
            '-=0.3'
          );
      }
    });

    return () => {
      set.kill();
      timeline?.kill();
      delayedCall.kill();
    };
  }, []);

  return (
    <Component>
      <Inner>
        <Left>
          {issueCardImage ? (
            <PigImage>
              <Picture
                imageRef={pigRef}
                mobileSmall={convertThumbnailToPictureImage(issueCardImage)}
              />
            </PigImage>
          ) : null}

          <PhoneImage>
            <Picture
              mobileSmall={{
                src: phoneUrl,
                src2x: phoneUrl2x,
                webp: phoneUrlWebp,
                webp2x: phoneUrlWebp2x,
              }}
            />
          </PhoneImage>
        </Left>
        <Right>
          <Title dangerouslySetInnerHTML={{ __html: issueCardTitle ?? '' }} />
        </Right>
      </Inner>
    </Component>
  );
}

const Component = styled.div``;
const Inner = styled.div`
  max-width: 1680px;
  padding: 0 40px;
  margin: 0 auto;

  display: flex;
  justify-content: space-between;

  ${media.tablet(css`
    padding: 0 20px;
  `)}

  ${media.mobile(css`
    display: block;
  `)}
`;

const Left = styled.div`
  flex: 1 1 auto;
  padding-top: 124px;
  position: relative;

  ${media.laptop(css`
    padding-top: 70px;
  `)}

  ${media.tablet(css`
    padding-top: 28px;
  `)}
  
  ${media.mobile(css`
    padding-top: 5px;
  `)}
`;

const Right = styled.div`
  padding-top: 272px;

  ${media.laptop(css`
    padding-top: 190px;
  `)}

  ${media.tablet(css`
    padding-top: 86px;
  `)}
  
  ${media.mobile(css`
    padding-top: 0;
    margin-top: -5px;
  `)}
`;

const Title = styled.h2`
  max-width: 648px;
  margin: 0;
  font-weight: 500;
  font-size: 64px;
  line-height: 72px;
  color: ${colors.white};

  b {
    font-weight: 500;
    color: ${colors.orange};
  }

  ${media.laptop(css`
    max-width: 532px;
    font-size: 48px;
    line-height: 56px;
  `)}

  ${media.tablet(css`
    font-size: 32px;
    line-height: 38px;
  `)}

  ${media.tabletSmall(css`
    max-width: 345px;
    font-size: 32px;
    line-height: 38px;
  `)}
  
  ${media.mobile(css`
    max-width: 100%;
    font-size: 28px;
    line-height: 34px;
  `)}
`;

const PhoneImage = styled.div`
  div {
    justify-content: flex-start;
  }

  ${media.laptop(css`
    width: 835px;
    margin-left: -120px;

    picture {
      width: 100%;
    }
  `)}

  ${media.tablet(css`
    width: 440px;
    margin-left: -96px;
  `)}
  
  ${media.mobile(css`
    width: 393px;
    margin-left: -42px;
  `)}
`;

const PigImage = styled.div`
  position: absolute;
  top: 124px;
  left: 0;
  z-index: 2;

  div {
    justify-content: flex-start;
  }

  ${media.laptop(css`
    top: 0;
    left: -142px;
    img {
      width: 725px;
      height: 585px;
    }
  `)}

  ${media.tablet(css`
    top: -134px;
    left: -74px;
    img {
      width: 380px;
      height: 306px;
    }
  `)}
  
  ${media.mobile(css`
    top: -91px;
    img {
      width: 372px;
      height: 300px;
    }
  `)}
`;

export default IssueCard;
