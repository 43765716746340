import React from 'react';
import styled, { css } from 'styled-components';

import NotificationsCard from '@/components/NotificationsCard';
import { media } from '@/utils/mixin';
import { HypermarketsPageType } from '@/typings/model';

interface Props {
  page: HypermarketsPageType;
}

function Notifications({ page }: Props) {
  const { bannerImage, bannerLink } = page.templateFields;
  return (
    <Component>
      <Inner>
        <NotificationsCard link={bannerLink ?? ''} image={bannerImage} />
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  margin-bottom: 152px;
  position: relative;

  ${media.laptop(css`
    margin-bottom: 87px;
  `)}

  ${media.tablet(css`
    margin-bottom: 60px;
  `)}
  
  ${media.mobile(css`
    margin-bottom: 42px;
  `)}
`;

const Inner = styled.div`
  max-width: 1680px;
  padding: 0 40px;
  margin: 0 auto;

  ${media.tablet(css`
    padding: 0 20px;
  `)}

  ${media.mobile(css`
    padding: 0 1px;
  `)}
`;

export default Notifications;
