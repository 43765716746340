import React from 'react';
import styled, { css } from 'styled-components';

import { Nullish } from '@tager/web-core';

import { HomePageType } from '@/typings/model';
import { media } from '@/utils/mixin';
import useWindowSize from '@/hooks/useWindowSize';
import { ReactComponent as ArrowRight } from '@/assets/svg/arrow-right.svg';

import SalesSlider from './components/SalesSlider';

type Props = {
  page: Nullish<HomePageType>;
};

const COLORS_CARD = ['#F4E557', '#F1F1F1', '#E4FF41'];
const COLORS_HOVER_CARD = ['#F2DF2D', '#EBE8E8', '#DCFA2A'];

function Hero({ page }: Props) {
  const size = useWindowSize();

  const data =
    size.width <= 767 && page?.templateFields.firstBlockButtons
      ? [page?.templateFields.firstBlockButtons[0]]
      : page?.templateFields.firstBlockButtons;
  return (
    <Component>
      <HeroBackground />
      <HeroContainer>
        <HeroInner>
          <HeroLeft>
            <SalesSlider
              salesList={page?.templateFields.firstBlockCenterBlocks}
            />
          </HeroLeft>

          {size.width > 1259 && page?.templateFields.firstBlockButtons && (
            <HeroCenter>
              {page?.templateFields.firstBlockButtons.map((item, index) => (
                <>
                  <BannerCard
                    background={COLORS_CARD[index]}
                    hover={COLORS_HOVER_CARD[index]}
                    href={item.link}
                  >
                    <BannerCardTitle>
                      {item.title.toUpperCase()}
                    </BannerCardTitle>
                  </BannerCard>
                </>
              ))}
            </HeroCenter>
          )}
        </HeroInner>

        {size.width <= 1259 && page?.templateFields.firstBlockButtons && (
          <BottomContent paddingTop={size.width <= 767 ? '0' : '6px'}>
            {data &&
              data.map(
                (item, index) =>
                  page.templateFields.firstBlockButtons &&
                  page.templateFields.firstBlockButtons?.length - 1 !==
                    index && (
                    <>
                      <BannerBottomCard
                        background={
                          size.width <= 767 ? 'black' : COLORS_CARD[index]
                        }
                        hover={COLORS_HOVER_CARD[index]}
                        href={item?.link}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingLeft: 12,
                          }}
                        >
                          <BannerBottomCardTitle isBorder={size.width <= 767}>
                            {item.title.toUpperCase()}
                          </BannerBottomCardTitle>
                          <ArrowRight
                            color={size.width <= 767 ? '#F4E557' : '#000000'}
                            height={26}
                            width={22}
                          />
                        </div>
                      </BannerBottomCard>
                      {page?.templateFields?.firstBlockButtons &&
                        page?.templateFields.firstBlockButtons.length !==
                          index && <div style={{ height: 6 }}></div>}
                    </>
                  )
              )}
          </BottomContent>
        )}
      </HeroContainer>
    </Component>
  );
}

const Component = styled.div`
  position: relative;
`;

const HeroContainer = styled.div`
  max-width: 1860px;
  margin: 0 auto;
`;

const BottomContent = styled.div<{ paddingTop: string }>`
  position: relative;
  background: ${(props) => props.paddingTop};
  width: 100%;
  align-items: start;
  justify-content: start;
`;

const HeroInner = styled.div`
  display: flex;
  padding-top: 42px;
  height: 843px;
  overflow: hidden;
  padding-right: 24px;
  padding-left: 24px;
  ${media.laptopOnly(css`
    height: 573px;
  `)}

  ${media.tabletOnly(css`
    height: 584px;
    padding-right: 0px;
    padding-left: 0px;
  `)}


  ${media.mobileLargeOnly(css`
    height: calc(100vh - 56px - 70px);
    padding-right: 0px;
    padding-left: 0px;
  `)}
  ${media.mobileMedium(css`
    height: calc(100vh - 56px - 70px);
    padding-right: 0px;
    padding-left: 0px;
  `)}

  
  ${media.tabletSmall(css`
    flex-wrap: wrap;
    padding-right: 0px;
    padding-left: 0px;
  `)}

  
  ${media.mobileLarge(css`
    padding-top: 0;
    padding-right: 0px;
    padding-left: 0px;
  `)}
`;

const HeroBackground = styled.div`
  position: absolute;
  top: -100px;
  bottom: 0;
  left: 0;
  width: 100%;
  background: radial-gradient(
    81.25% 28416.66% at 38.67% 18.75%,
    #ffffff 0%,
    #ffffff 100%
  );
`;

const BannerCard = styled.a<{ background: string; hover: string }>`
  max-width: 348px;
  max-height: 237px;
  height: 237px;
  background: ${(props) => props.background};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;

  ${media.laptopOnly(css`
    position: relative;
    max-width: 348px;
    max-height: 158px;
    height: 158px;
  `)}

  &:hover {
    transition: all 0.3s linear;
    cursor: pointer;
    background: ${(props) => props.hover};
  }
`;

const BannerCardTitle = styled.span`
  color: #020202;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;

  ${media.laptopOnly(css`
    font-size: 24px;
    line-height: 38px;
  `)}

  ${media.tabletOnly(css`
    font-size: 24px;
  `)}
`;

const BannerBottomCard = styled.a<{ background: string; hover: string }>`
  width: 100%;
  max-height: 70px;
  height: 70px;
  background: ${(props) => props.background};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  &:hover {
    transition: all 0.3s linear;
    cursor: pointer;
    background: ${(props) => props.hover};
  }
`;

const BannerBottomCardTitle = styled.span<{ isBorder: boolean }>`
  color: #020202;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  text-decoration: ${(props) => (props.isBorder ? 'underline' : 'none')};

  ${media.sizeSmallMobile(css`
    font-size: 22px;
    line-height: 50px;
    color: #f4e557;
  `)}

  ${media.laptop(css`
    padding: 0 4px;
    font-size: 24px;
    line-height: 30px;
  `)}

  ${media.tablet(css`
    font-size: 20px;
    line-height: 26px;
    padding: 0 2px;
  `)}

  ${media.mobile(css`
    font-size: 22px;
    line-height: 28px;
    width: 200px;
    color: #f4e557;
  `)}
`;

const HeroLeft = styled.div`
  position: relative;
  height: 100%;

  flex: 1 1 78.1%;
  max-width: 79%;

  ${media.tablet(css`
    max-width: 100%;
  `)}

  ${media.mobileMedium(css`
    flex: 1 1 100%;
    width: 100%;
    background: #7ed9da;
  `)}
`;

const HeroCenter = styled.div`
  justify-content: space-between;
  //align-items: center;
  padding-left: 34px;
  flex-direction: column;
  display: flex;
  position: relative;
  height: 100%;
  width: 20%;
  flex: 1 1 483px;
  max-width: 483px;

  ${media.mobile(css`
    height: calc(100vw / 100 * 70);
  `)}

  ${media.mobileLarge(css`
    flex: 1 1 100%;
    max-width: 450px;
    margin: 0 auto;
    height: calc(var(--vh, 1vh) * 100 - 136px);
  `)}
  ${media.mobileMedium(css`
    flex: 1 1 100%;
    max-width: 100%;
    background: #7ed9da;
  `)}
`;

export default Hero;
