import React from 'react';
import styled, { css } from 'styled-components';

import { StringField } from '@/typings/model';
import { ButtonLink } from '@/components/Button';
import Picture from '@/components/Picture';
import bannerUrl from '@/assets/images/discount/banner-bg.png';
import bannerUrl2x from '@/assets/images/discount/banner-bg@2x.png';
import bannerUrlWebp from '@/assets/images/discount/banner-bg.webp';
import bannerUrlWebp2x from '@/assets/images/discount/banner-bg@2x.webp';
import bannerUrlTablet from '@/assets/images/discount/banner-bg_tablet.png';
import bannerUrlTablet2x from '@/assets/images/discount/banner-bg_tablet@2x.png';
import bannerUrlTabletWebp from '@/assets/images/discount/banner-bg_tablet.webp';
import bannerUrlTabletWebp2x from '@/assets/images/discount/banner-bg_tablet@2x.webp';
import bannerUrlMobile from '@/assets/images/discount/banner-bg_mobile.png';
import bannerUrlMobile2x from '@/assets/images/discount/banner-bg_mobile@2x.png';
import bannerUrlMobileWebp from '@/assets/images/discount/banner-bg_mobile.webp';
import bannerUrlMobileWebp2x from '@/assets/images/discount/banner-bg_mobile@2x.webp';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import Cross from '@/components/Sticker/Cross';

type Props = {
  bannerTitle: StringField;
  bannerButtonLabel: StringField;
  bannerButtonUrl: StringField;
};

function DiscountCard({
  bannerTitle,
  bannerButtonLabel,
  bannerButtonUrl,
}: Props) {
  return (
    <Component>
      <Inner>
        <Background>
          <Picture
            laptop={{
              src: bannerUrl,
              src2x: bannerUrl2x,
              webp: bannerUrlWebp,
              webp2x: bannerUrlWebp2x,
            }}
            tabletSmall={{
              src: bannerUrlTablet,
              src2x: bannerUrlTablet2x,
              webp: bannerUrlTabletWebp,
              webp2x: bannerUrlTabletWebp2x,
            }}
            mobileSmall={{
              src: bannerUrlMobile,
              src2x: bannerUrlMobile2x,
              webp: bannerUrlMobileWebp,
              webp2x: bannerUrlMobileWebp2x,
            }}
          />
        </Background>
        <Content>
          <Title dangerouslySetInnerHTML={{ __html: bannerTitle ?? '' }} />
          {bannerButtonUrl && (
            <BtnWrapper>
              <DiscountCardBtn variant="orange" href={bannerButtonUrl ?? '#'}>
                {bannerButtonLabel ?? 'КАК ОФОРМИТЬЬ КАРТУ?'}
              </DiscountCardBtn>
            </BtnWrapper>
          )}
        </Content>
        <CrossWrapper>
          <Cross isDiscount={true} />
        </CrossWrapper>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  margin-top: 130px;
  position: relative;

  ${media.laptop(css`
    margin-top: 70px;
  `)}

  ${media.tablet(css`
    margin-top: 50px;
  `)}
  
  ${media.mobile(css`
    margin-top: 90px;
  `)}

  &:before {
    position: absolute;
    content: '';
    top: 50%;
    bottom: 0;
    left: -10000px;
    right: -10000px;
    background: ${colors.white};
  }
`;

const Inner = styled.div`
  max-width: 1680px;
  padding: 0 40px;
  margin: 0 auto;

  position: relative;

  ${media.tablet(css`
    padding: 0 20px;
  `)}

  ${media.mobile(css`
    padding: 0;
  `)}
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 40px;
  right: 40px;
  height: 100%;

  ${media.tablet(css`
    left: 20px;
    right: 20px;
  `)}

  ${media.mobile(css`
    left: 0;
    right: 0;
  `)}

  div,
  picture,
  img {
    width: 100%;
    height: 100%;
  }
`;

const Content = styled.div`
  min-height: 592px;
  padding: 120px 136px;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;

  ${media.laptop(css`
    min-height: 444px;
    padding: 90px 102px;
  `)}

  ${media.tablet(css`
    min-height: 514px;
    padding: 90px 40px;
  `)}
  
  ${media.mobile(css`
    min-height: 428px;
    padding: 90px 20px;
  `)}
`;

const Title = styled.span`
  display: block;
  max-width: 1100px;
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;

  ${media.laptop(css`
    max-width: 996px;
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    font-size: 48px;
    line-height: 56px;
  `)}
  
  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
  `)}

  a {
    display: inline-block;
    position: relative;
    transition: all 0.3s linear;

    &:before {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background: ${colors.black};
      transition: all 0.3s linear;

      ${media.laptop(css`
        bottom: 3px;
        height: 2px;
      `)}

      ${media.mobile(css`
        bottom: 1px;
      `)}
    }

    &:hover {
      color: ${colors.orange};

      &:before {
        background: ${colors.orange};
      }
    }
  }
`;

const BtnWrapper = styled.div`
  margin-top: 84px;

  ${media.laptop(css`
    margin-top: 40px;
  `)}

  ${media.tablet(css`
    margin-top: 50px;
  `)}
  
  ${media.mobile(css`
    margin-top: 30px;
  `)}
`;

const DiscountCardBtn = styled(ButtonLink)`
  display: block;
  padding: 31px 0;
  width: 100%;

  font-weight: bold;
  font-size: 36px;
  line-height: 48px;
  text-transform: uppercase;
  transition: all 0.3s linear;

  ${media.laptop(css`
    padding: 24px 0;
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    padding: 17px 0;
    font-size: 20px;
    line-height: 26px;
  `)}
  
  ${media.mobile(css`
    padding: 15px 0;
    font-size: 16px;
    line-height: 20px;
  `)}
`;

const CrossWrapper = styled.div`
  position: absolute;
  top: -156px;
  right: -121px;
  max-width: 382px;
  max-height: 314px;

  width: 100%;
  height: 100%;

  ${media.laptop(css`
    top: -104px;
    right: -53px;
    max-width: 255px;
    max-height: 208px;
    transform: rotate(-15deg);
  `)}

  ${media.tablet(css`
    top: -68px;
    right: -59px;
    max-width: 190px;
    max-height: 190px;
  `)}
  
  ${media.mobile(css`
    display: none;
  `)}
`;

export default DiscountCard;
