import React from 'react';
import styled, { css } from 'styled-components';

import { StringField } from '@/typings/model';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

type Props = {
  firstBlockItems?: Array<{
    cost: StringField;
  }>;
};

function CertificateType({ firstBlockItems }: Props) {
  return (
    <Component>
      <List>
        {firstBlockItems?.map((priceItem, index) => {
          return (
            <ListItem key={index}>
              {priceItem.cost}
              <ListItemValue>руб.</ListItemValue>
            </ListItem>
          );
        })}
      </List>
    </Component>
  );
}

const Component = styled.div`
  position: relative;
  margin-top: 118px;
  padding-left: 136px;

  ${media.laptop(css`
    margin-top: 80px;
    padding-left: 100px;
  `)}

  ${media.tablet(css`
    margin-top: 83px;
    padding-left: 52px;
  `)}
  
  ${media.mobile(css`
    margin-top: 18px;
    padding-left: 0;
    display: flex;
    justify-content: flex-end;
  `)}

  &:before {
    position: absolute;
    content: '';
    top: 58px;
    left: 0;
    width: 74px;
    height: 1px;
    background: ${colors.white};

    ${media.laptop(css`
      top: 49px;
      width: 50px;
    `)}

    ${media.tablet(css`
      top: 34px;
      width: 32px;
    `)}
    
    ${media.mobile(css`
      display: none;
    `)}
  }
`;

const List = styled.div``;

const ListItem = styled.div`
  margin-top: 20px;
  font-weight: bold;
  font-size: 82px;
  line-height: 90px;
  letter-spacing: -1px;
  color: ${colors.orange};

  ${media.laptop(css`
    margin-top: 30px;
    font-size: 72px;
    line-height: 80px;
  `)}

  ${media.tablet(css`
    margin-top: 20px;
    font-size: 48px;
    line-height: 56px;
  `)}
  
  ${media.mobile(css`
    margin-top: 6px;
    font-size: 60px;
    line-height: 70px;
  `)}

  &:first-child {
    margin-top: 0;
  }
`;

const ListItemValue = styled.span`
  margin-left: 9px;
  text-transform: uppercase;
  color: ${colors.white};

  ${media.mobile(css`
    margin-left: 12px;
    font-size: 16px;
    line-height: 46px;
  `)}
`;

export default CertificateType;
