import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

interface Props {
  title: string;
  description: string;
}

function FeaturesCard({ title, description }: Props) {
  return (
    <Component>
      <Inner>
        <Left>
          <Title>{title}</Title>
        </Left>
        <Right>
          <Description>{description}</Description>
        </Right>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  padding: 54px 0 52px;
  border-bottom: 2px solid ${colors.grayLight};

  ${media.laptop(css`
    padding: 40px 0 39px;
    border-bottom: 1px solid ${colors.grayLight};
  `)}

  ${media.tablet(css`
    padding: 30px 0 29px;
    border-bottom: 1px solid ${colors.grayLight};
  `)}

  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
`;

const Inner = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.mobile(css`
    display: block;
  `)}
`;

const Left = styled.div`
  flex: 1 1 648px;
  max-width: 648px;
  margin-right: 30px;

  ${media.laptop(css`
    flex: 1 1 486px;
    max-width: 486px;
  `)}

  ${media.tablet(css`
    flex: 1 1 300px;
    max-width: 300px;
  `)}
  
  ${media.mobile(css`
    margin: 0 0 10px;
    max-width: 100%;
  `)}
`;
const Right = styled.div`
  flex: 1 1 784px;
  max-width: 784px;

  ${media.laptop(css`
    flex: 1 1 588px;
    max-width: 588px;
  `)}

  ${media.tablet(css`
    flex: 1 1 346px;
    max-width: 346px;
  `)}
  
  ${media.mobile(css`
    max-width: 100%;
  `)}
`;

const Title = styled.h3`
  margin: 0;
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  color: ${colors.black};

  ${media.laptop(css`
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    font-size: 20px;
    line-height: 24px;
  `)}
  
  ${media.mobile(css`
    font-size: 16px;
    line-height: 20px;
  `)}
`;

const Description = styled.p`
  font-size: 30px;
  line-height: 36px;
  color: ${colors.black};

  ${media.laptop(css`
    font-size: 20px;
    line-height: 26px;
  `)}

  ${media.tablet(css`
    font-size: 18px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    font-size: 14px;
    line-height: 20px;
  `)}
`;

export default FeaturesCard;
