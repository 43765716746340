import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';

interface Props {
  children: React.ReactNode;
  scroll?: 'left' | 'right';
}

function Line({ children, scroll = 'left' }: Props) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const scrollerRef = useRef<HTMLDivElement | null>(null);
  const scrollLineRef = useRef<HTMLDivElement | null>(null);

  function getScrollLineWidth() {
    if (scrollLineRef.current) {
      return scrollLineRef.current.clientWidth;
    }
    return 0;
  }

  useEffect(() => {
    if (!scrollerRef.current || !scrollerRef.current) {
      return;
    }
    if (getScrollLineWidth() < window.innerWidth) {
      return;
    }
    let tween: gsap.core.Tween;
    const set = gsap.set(scrollerRef.current, {
      justifyContent: scroll === 'left' ? 'flex-start' : 'flex-end',
    });
    const delayedCall = gsap.delayedCall(0, () => {
      if (!containerRef.current || !scrollerRef.current) {
        return;
      }
      tween = gsap.to(scrollerRef.current, {
        duration: () => getScrollLineWidth() / 200,
        x: () => {
          const newX = getScrollLineWidth() - window.innerWidth + 20;
          return scroll === 'left' ? -newX : newX;
        },
        ease: 'linear',
        scrollTrigger: {
          trigger: containerRef.current,
          scrub: 0.7,
        },
      });
    });

    return () => {
      set.kill();
      tween?.kill();
      delayedCall.kill();
    };
  }, []);

  return (
    <Component ref={containerRef}>
      <Scroller ref={scrollerRef}>
        <ScrollLine ref={scrollLineRef}>{children}</ScrollLine>
      </Scroller>
    </Component>
  );
}

export default Line;

const Component = styled.div``;

const Scroller = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ScrollLine = styled.div`
  display: flex;
`;
