import React from 'react';
import styled, { css } from 'styled-components';

import { useModal } from '@tager/web-components';
import { useMedia } from '@tager/web-core';

import { MenuItemType, StringField } from '@/typings/model';
import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';
import { breakpoints, colors } from '@/constants/theme';
import MenuModal from '@/components/modals/MenuModal';

interface Props {
  menuItem: MenuItemType;
}

function MenuPositionRow({ menuItem }: Props) {
  const { full, thumb, price, body, name, description } = menuItem;
  const isMobile = useMedia(`(max-width: ${breakpoints.tabletSmall - 1}px)`);

  const openModal = useModal();

  function openMenuModal(imagePopup: StringField) {
    openModal(MenuModal, {
      imagePopup: imagePopup,
    });
  }

  return (
    <Wrapper>
      <Inner>
        {thumb ? (
          <Left onClick={() => openMenuModal(full ?? thumb)}>
            <Image>
              <Picture
                mobileSmall={{
                  src: thumb,
                }}
              />
            </Image>
          </Left>
        ) : null}
        <Center>
          <Title>{name}</Title>
          {isMobile && price && (
            <PriceWrapper>
              <Price>
                {String(price).split('.')[0]}
                <PricePenny>
                  .{String(price?.toFixed(2)).split('.')[1]}
                </PricePenny>
              </Price>
            </PriceWrapper>
          )}
          {description && <Description>{description}</Description>}
          {body && <Description>{body}</Description>}
        </Center>
        <Right>
          {price && (
            <PriceWrapper>
              <Price>
                {String(price).split('.')[0]}
                <PricePenny>
                  .{String(price?.toFixed(2)).split('.')[1]}
                </PricePenny>
              </Price>
            </PriceWrapper>
          )}
        </Right>
      </Inner>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 22px 0 32px;
  border-bottom: 2px solid ${colors.white};

  ${media.laptop(css`
    border-bottom: 1px solid ${colors.white};
    padding: 24px 0;
  `)}

  ${media.tablet(css`
    padding: 20px 0;
  `)}

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
`;

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.mobile(css`
    display: block;
  `)}

  ${media.mobile(css`
    position: relative;
  `)}
`;

const Left = styled.div`
  flex: 1 1 84px;
  max-width: 84px;
  margin-right: 24px;

  ${media.laptop(css`
    flex: 1 1 54px;
    max-width: 54px;
    margin-right: 16px;
  `)}

  ${media.tablet(css`
    flex: 0 0 48px;
    max-width: 48px;
    margin-right: 20px;
  `)}
  
  ${media.mobile(css`
    position: absolute;
    top: 0;
    left: 0;
    height: 84px;
    max-width: 84px;
    width: 100%;
    margin: 0;

    display: flex;
    align-items: center;
    justify-content: center;
  `)}
`;

const Center = styled.div`
  flex: 1 1 auto;
  color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${media.mobile(css`
    display: block;
    padding-top: 10px;
  `)}
`;

const Right = styled.div`
  margin-left: 24px;

  ${media.laptop(css`
    margin-left: 16px;
  `)}

  ${media.tablet(css`
    margin-left: 20px;
  `)}
  
  ${media.mobile(css`
    display: none;
  `)}
`;

const Image = styled.div`
  cursor: pointer;

  ${media.tablet(css`
    cursor: default;
  `)}

  img {
    max-width: 84px;
    max-height: 84px;

    ${media.laptop(css`
      max-width: 54px;
      max-height: 54px;
    `)}

    ${media.tablet(css`
      max-width: 48px;
      max-height: 48px;
    `)}
    
    ${media.mobile(css`
      max-width: 84px;
      max-height: 84px;
    `)}
  }
`;

const Title = styled.span`
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;

  ${media.laptop(css`
    font-size: 20px;
    line-height: 26px;
  `)}

  ${media.tablet(css`
    font-size: 18px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    display: block;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 10px 104px;
  `)}
`;
const Description = styled.p`
  margin-top: 12px;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  color: ${colors.grayLight};

  ${media.laptop(css`
    margin-top: 2px;
    font-size: 20px;
    line-height: 26px;
  `)}

  ${media.tablet(css`
    margin-top: 4px;
    font-size: 18px;
    line-height: 22px;
  `)}
   
  ${media.mobile(css`
    margin-top: 21px;
    font-size: 14px;
    line-height: 20px;
  `)}
`;

const PriceWrapper = styled.div`
  position: relative;
  padding: 0 21px 0 9px;

  ${media.laptop(css`
    padding: 0 15px 0 7px;
  `)}

  ${media.tablet(css`
    padding: 0 9px 0 5px;
  `)}
  
  ${media.mobile(css`
    display: inline-block;
    margin-left: 108px;
  `)}

  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${colors.red100};
    transform: skew(-10deg, 0);

    ${media.laptop(css`
      transform: skew(-11deg, 0);
      background: ${colors.red200};
    `)}
  }
`;

const Price = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;

  font-style: italic;
  font-weight: bold;
  font-size: 54px;
  line-height: 64px;
  letter-spacing: -6px;
  color: ${colors.white};

  ${media.laptop(css`
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -4px;
  `)}

  ${media.mobile(css`
    display: inline-block;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0;
  `)}
`;

const PricePenny = styled.span`
  font-style: italic;
  font-weight: bold;
  font-size: 32px;
  line-height: 42px;
  margin-left: 15px;
  color: ${colors.white};
  letter-spacing: 0;

  ${media.laptop(css`
    font-size: 24px;
    line-height: 32px;
    margin-left: 11px;
  `)}

  ${media.mobile(css`
    font-style: normal;
    font-size: 24px;
    line-height: 32px;
    margin-left: 0;
  `)}
`;

export default MenuPositionRow;
