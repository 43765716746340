import React from 'react';
import styled, { css } from 'styled-components';

import {
  convertThumbnailToPictureImage,
  PageFullType,
} from '@tager/web-modules';

import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import Link from '@/components/Link';
import { ImageThumbnail, StringField } from '@/typings/model';

interface Props {
  shop: {
    image: ImageThumbnail;
    city: StringField;
    text: StringField;
    link: {
      datetime: StringField;
      excerpt: StringField;
      id: Number;
      image: ImageThumbnail;
      title: StringField;
      url: StringField;
    };
  };
}

function ShopsCard({ shop }: Props) {
  const { image, city, link, text } = shop;

  return (
    <Card>
      {link.url && <ComponentLink to={link.url} />}
      <Inner>
        <Up>
          <Image>
            {image ? (
              <Picture mobileSmall={convertThumbnailToPictureImage(image)} />
            ) : null}
          </Image>
        </Up>
        <Bottom>
          <Text dangerouslySetInnerHTML={{ __html: text ?? '' }} />
        </Bottom>
        <Label>{city}</Label>
      </Inner>
    </Card>
  );
}

const ComponentLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
`;

const Inner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 28px 32px;

  display: flex;
  align-items: flex-end;

  ${media.laptop(css`
    padding: 24px;
  `)}

  ${media.tablet(css`
    padding: 20px;
  `)}

  ${media.mobile(css`
    padding: 30px 20px;
  `)}
`;

const Up = styled.div``;

const Bottom = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: 2;
  transition: all 0.3s linear;
  background: ${colors.yellow200};
  padding: 40px 30px;

  ${media.laptop(css`
    padding: 32px 24px;
  `)}

  ${media.tablet(css`
    padding: 18px 20px 16px;
    height: auto;
    visibility: visible;
    opacity: 1;
    border-radius: 14px;
  `)}
  
   ${media.mobile(css`
    padding: 20px;
    border-radius: 24px;
  `)}
`;

const Image = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 24px;
  overflow: hidden;

  ${media.tabletOnly(css`
    border-radius: 14px;
  `)}

  div,
  picture {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Label = styled.div`
  border-radius: 50px;
  overflow: hidden;
  padding: 40px 54px;
  background: ${colors.white};
  position: relative;
  z-index: 3;
  display: inline-block;

  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  color: ${colors.black};
  transition: all 0.3s linear;

  ${media.laptop(css`
    border-radius: 35px;
    padding: 24px 32px;

    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    padding: 14px 22px;
    font-size: 16px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    border-radius: 29px;
    padding: 12px 20px;
    line-height: 20px;
  `)}
`;

const Text = styled.div`
  color: ${colors.black};
  font-weight: normal;
  font-size: 30px;
  line-height: 39px;

  ${media.laptop(css`
    font-size: 20px;
    line-height: 26px;
  `)}

  ${media.tablet(css`
    font-size: 10px;
    line-height: 16px;
  `)}
  
  ${media.mobile(css`
    font-size: 14px;
    line-height: 20px;
  `)}

  b {
    display: block;
    font-weight: 500;
    font-size: 54px;
    line-height: 64px;
    margin-bottom: 24px;

    ${media.laptop(css`
      font-size: 40px;
      line-height: 48px;
      margin-bottom: 16px;
    `)}

    ${media.tablet(css`
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 2px;
    `)}
    
    ${media.mobile(css`
      font-size: 22px;
      line-height: 28px;
      margin-bottom: 10px;
    `)}
  }
`;

const Card = styled.div`
  position: relative;

  &:before {
    display: block;
    content: '';
    padding-bottom: 200%;
  }

  ${media.laptopUp(css`
    &:hover {
      ${Bottom} {
        opacity: 1;
        visibility: visible;
      }
    }
  `)}

  ${media.tablet(css`
    overflow: hidden;
    border-radius: 14px;
  `)}
  
  ${media.mobile(css`
    border-radius: 24px;
  `)}
`;

export default ShopsCard;
