import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { gsap } from 'gsap';

import { useMedia } from '@tager/web-core';

import TickerLine from '@/components/TickerLine/TickerLine';
import { breakpoints, colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { ImageThumbnail, StringField } from '@/typings/model';

import NewsMore from './components/NewsMore';
import NewsAside from './components/NewsAside';
import NewsList from './components/NewsList';
import NewsMap from './components/NewsMap';
import NewsListMobile from './components/NewsListMobile';

type Props = {
  newsList?: Array<{
    image: {
      landscape: ImageThumbnail;
      portrait: ImageThumbnail;
    };
    title: StringField;
    link: StringField;
  }>;
  backgroundTicket: StringField;
  colorTicket: StringField;
  labelTicket: StringField;
  linkTicket: StringField;
  newsButtonLabel: StringField;
  newsButtonUrl: StringField;

  shopsTitle: StringField;
  shopsButtonLabel: StringField;
  shopsButtonUrl: StringField;
};

function News({
  newsList,
  backgroundTicket,
  labelTicket,
  linkTicket,
  colorTicket,
  newsButtonLabel,
  newsButtonUrl,
  shopsTitle,
  shopsButtonLabel,
  shopsButtonUrl,
}: Props) {
  const newsRef = useRef<HTMLDivElement | null>(null);
  const newsContainerRef = useRef<HTMLDivElement | null>(null);
  const newsAsideRef = useRef<HTMLDivElement | null>(null);
  const newsListRef = useRef<HTMLDivElement | null>(null);

  const isMobile = useMedia(`(max-width: ${breakpoints.tabletSmall - 1}px)`);

  useEffect(() => {
    if (!newsContainerRef.current || !newsAsideRef.current) {
      return;
    }
    let tween: gsap.core.Tween;
    const delayedCall = gsap.delayedCall(0, () => {
      if (!newsContainerRef.current || !newsAsideRef.current) {
        return;
      }
      tween = gsap.to(newsAsideRef.current, {
        y:
          newsContainerRef.current?.getBoundingClientRect().bottom -
          newsAsideRef.current?.getBoundingClientRect().bottom -
          40,
        scrollTrigger: {
          trigger: newsContainerRef.current,
          start: 'top top',
          end: 'bottom bottom',
          scrub: true,
        },
      });
    });

    return () => {
      tween?.kill();
      delayedCall.kill();
    };
  }, []);
  return (
    <Components id="gsap-news" ref={newsRef}>
      <ComponentsContainer ref={newsContainerRef}>
        <NewsInner>
          <NewsTop>
            <NewsContainer>
              <NewsTopInner>
                {isMobile ? (
                  <>
                    <NewsMap
                      shopsTitle={shopsTitle}
                      shopsButtonLabel={shopsButtonLabel}
                      shopsButtonUrl={shopsButtonUrl}
                    />
                    <NewsListMobile newsList={newsList} />
                  </>
                ) : (
                  <>
                    <NewsAside ref={newsAsideRef} newsList={newsList} />
                    <NewsMain>
                      <NewsMap
                        shopsTitle={shopsTitle}
                        shopsButtonLabel={shopsButtonLabel}
                        shopsButtonUrl={shopsButtonUrl}
                      />
                      <NewsList ref={newsListRef} newsList={newsList} />
                    </NewsMain>
                  </>
                )}
              </NewsTopInner>
            </NewsContainer>
          </NewsTop>

          <TickerLineWrapper>
            <TickerLine
              backgroundTicket={backgroundTicket}
              colorTicket={colorTicket}
              labelTicket={labelTicket}
              linkTicket={linkTicket}
            />
          </TickerLineWrapper>

          <NewsBottom>
            <NewsContainer>
              <NewsBottomInner>
                <NewsMore
                  newsButtonLabel={newsButtonLabel}
                  newsButtonUrl={newsButtonUrl}
                />
              </NewsBottomInner>
            </NewsContainer>
          </NewsBottom>
        </NewsInner>
      </ComponentsContainer>
    </Components>
  );
}

const Components = styled.div`
  overflow: hidden;
  margin-top: -1px;
  padding-top: 69px;
  background: ${colors.white};

  ${media.laptop(css`
    padding-top: 58px;
  `)}

  ${media.tablet(css`
    padding-top: 50px;
  `)}
`;

const ComponentsContainer = styled.div`
  background: ${colors.black};
`;

const NewsInner = styled.div`
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
`;

const NewsContainer = styled.div`
  max-width: 1680px;
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

const NewsTop = styled.div``;

const NewsTopInner = styled.div`
  display: flex;
  padding: 290px 0 80px;

  ${media.laptop(css`
    padding: 160px 0 56px;
  `)}

  ${media.tabletSmall(css`
    padding: 160px 0 50px;
  `)}

  ${media.mobile(css`
    display: block;
    padding: 20px 0 30px;
  `)}
`;

const NewsBottom = styled.div`
  padding: 50px 0 80px;
  background: ${colors.black};

  ${media.laptop(css`
    padding-top: 38px;
    border-radius: 0 0 24px 24px;
  `)}

  ${media.tabletSmall(css`
    padding: 24px 0;
  `)}
`;

const NewsBottomInner = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const NewsMain = styled.div`
  flex: 1 1 auto;
`;

const TickerLineWrapper = styled.div`
  width: 140vw;

  @media (min-width: 1921px) {
    position: relative;
    left: -40vw;
  }
`;

export default News;
