import React from 'react';
import styled, { css } from 'styled-components';

import { StringField } from '@/typings/model';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

import PresentCard from './PresentCard';

interface Props {
  title: StringField;
  list: Array<{
    text: StringField;
  }>;
}

function PresentSection({ title, list }: Props) {
  return (
    <Component>
      <Top>
        <Title>{title}</Title>
      </Top>
      <List>
        {list?.map((listItem) => {
          return (
            <Cell>
              <PresentCard place={listItem} />
            </Cell>
          );
        })}
      </List>
    </Component>
  );
}

const Component = styled.div`
  max-width: 1680px;
  margin: 0 auto;
  padding: 0 40px;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

const Top = styled.div`
  margin-bottom: 68px;

  ${media.laptop(css`
    margin-bottom: 55px;
  `)}

  ${media.tablet(css`
    margin-bottom: 22px;
  `)}
  
  ${media.mobile(css`
    margin-bottom: 0;
  `)}
`;

const Title = styled.h2`
  max-width: 1300px;
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.black};

  ${media.laptop(css`
    max-width: 920px;
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    font-size: 48px;
    line-height: 56px;
  `)}
  
  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
  `)}
`;

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -16px;

  ${media.laptop(css`
    margin: -12px;
  `)}

  ${media.tablet(css`
    margin: -11px;
  `)}
  
  ${media.mobile(css`
    display: block;
    margin: -15px;
  `)}
`;

const Cell = styled.div`
  flex: 1 1 33.33%;
  padding: 16px;

  ${media.laptop(css`
    padding: 12px;
  `)}

  ${media.tablet(css`
    padding: 11px;
  `)}
  
  ${media.mobile(css`
    padding: 15px;

    &:first-child {
      div {
        border-top: none;
      }
    }
  `)}
`;

export default PresentSection;
