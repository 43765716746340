import { Nullish } from '@tager/web-core';
import { getSettingValueByKey } from '@tager/web-modules';

import { PageModuleType } from '@/typings/common';
import Home from '@/modules/Home';
import About from '@/modules/About';
import Careers from '@/modules/Careers';
import Jobs from '@/modules/Jobs';
import Discount from '@/modules/Discount';
import GiftCertificate from '@/modules/GiftCertificate';
import News from '@/modules/News';
import Magazines from '@/modules/Magazines';
import Feedback from '@/modules/Feedback';
import Corruption from '@/modules/Corruption';
import Assistance from '@/modules/Assistance';
import Hypermarkets from '@/modules/Hypermarkets';
import Promotions from '@/modules/Promotions';
import Help from '@/modules/Help';
import Shops from '@/modules/Shops';
import ShoppingCenters from '@/modules/ShoppingCenters';
import OwnProduction from '@/modules/OwnProduction';
import { getMenuItemListThunk } from '@/store/reducers/tager/menus';
import { getSettingItemListThunk } from '@/store/reducers/tager/settings';
import { getVacancyListThunk } from '@/store/reducers/tager/vacancies';
import { getVacancyByCategoriesListThunk } from '@/store/reducers/vacanciesCategories';
import { getNewsListThunk } from '@/store/reducers/newsList';
import { getPromotionsCategoriesListThunk } from '@/store/reducers/promotionCategories';
import { getShopByCitiesListThunk } from '@/store/reducers/shopsCities';
import { getShopsTypeListByCitiesThunk } from '@/store/reducers/shopsTypeByCities';
import { getShoppingCenterListByCitiesThunk } from '@/store/reducers/shoppingCenterByCities';
import { getPromotionsTypesListThunk } from '@/store/reducers/promotionTypes';
import { CustomApp_PageContext } from '@/typings/hocs';
import { getParamAsString } from '@/utils/common';
import {
  getAdditionFiltersByAlias,
  saveAdditionFiltersByAliasThunk,
} from '@/store/reducers/filter';
import { getProductListThunk } from '@/store/reducers/products';
import Salon from '@/modules/Salon';
import ShoppingCenterSingle from '@/modules/ShoppingCenterSingle';
import { getShoppingCenterListThunk } from '@/store/reducers/shoppingCenterList';
import { getSalonCategoriesThunk } from '@/store/reducers/salonsCategories';
import { getSalonListThunk } from '@/store/reducers/salonList';
import { getNewsSectionListThunk } from '@/store/reducers/newsSection';
import { getVacancyByCitiesListThunk } from '@/store/reducers/vacanciesCities';
import Product from '@/modules/Product';
import CastleTm from '@/modules/CastleTm';
import CastleTmProduct from '@/modules/CastleTmProduct';
import Recipes from '@/modules/Recipes';
import Restaurants from '@/modules/Restaurants';
import KoronaDom from '@/modules/KoronaDom';
import FashionableMoll from '@/modules/FashionableMoll';
import FashionableMollSingle from '@/modules/FashionableMollSingle';
import { getFashionMollsListThunk } from '@/store/reducers/fashionMollsList';
import RestaurantsSingle from '@/modules/RestaurantsSingle';
import { getRestaurantsListThunk } from '@/store/reducers/restaurantsList';
import RestaurantsMenu from '@/modules/RestaurantsMenu';
import Journal from '@/modules/Journal';
import RecipesSingle from '@/modules/RecipesSingle';
import Ideas from '@/modules/Ideas';
import IdeasSingle from '@/modules/IdeasSingle';
import { getCitiesListThunk } from '@/store/reducers/tager/cities';
import DefaultTemplate from '@/modules/DefaultTemplate';

const DEFAULT_PAGE_MODULE: PageModuleType = {
  component: DefaultTemplate,
  template: '',
  getInitialProps({ store }) {
    return Promise.all([store.dispatch(getMenuItemListThunk('fashion-moll'))]);
  },
};

const PAGE_MODULE_LIST: Array<PageModuleType> = [
  {
    template: 'home',
    component: Home,
    getInitialProps({ store }) {
      return Promise.all([store.dispatch(getVacancyListThunk())]);
    },
  },
  {
    template: 'about',
    component: About,
    getInitialProps({ store }) {
      return Promise.all([]);
    },
  },
  {
    template: 'careers',
    component: Careers,
    getInitialProps({ store }) {
      return Promise.all([]);
    },
  },
  {
    template: 'jobs',
    component: Jobs,
    getInitialProps({ store }) {
      return Promise.all([store.dispatch(getVacancyByCategoriesListThunk())]);
    },
  },
  {
    template: 'discount-program',
    component: Discount,
    getInitialProps({ store }) {
      return Promise.all([]);
    },
  },
  {
    template: 'gift-certificate',
    component: GiftCertificate,
    getInitialProps({ store }) {
      return Promise.all([]);
    },
  },
  {
    template: 'news',
    component: News,
    getInitialProps({ store }) {
      return Promise.all([
        store.dispatch(getNewsListThunk()),
        store.dispatch(getNewsSectionListThunk()),
      ]);
    },
  },
  {
    template: 'magazines',
    component: Magazines,
    getInitialProps({ store }) {
      return Promise.all([]);
    },
  },
  {
    template: 'magazines-single',
    component: Journal,
    getInitialProps({ store }) {
      return Promise.all([]);
    },
  },
  {
    template: 'feedback',
    component: Feedback,
    getInitialProps({ store }) {
      return Promise.all([store.dispatch(getCitiesListThunk())]);
    },
  },
  {
    template: 'corruption',
    component: Corruption,
    getInitialProps({ store }) {
      return Promise.all([]);
    },
  },
  {
    template: 'assistance',
    component: Assistance,
    getInitialProps({ store }) {
      return Promise.all([]);
    },
  },
  {
    template: 'faq',
    component: Help,
    getInitialProps({ store }) {
      return Promise.all([]);
    },
  },
  {
    template: 'hypermarket',
    component: Hypermarkets,
    getInitialProps({ store }) {
      return Promise.all([
        store.dispatch(getMenuItemListThunk('hypermarket')),
        store.dispatch(getNewsListThunk()),
        store.dispatch(getShopByCitiesListThunk()),
        store.dispatch(getShopsTypeListByCitiesThunk()),
      ]);
    },
  },
  {
    template: 'discount-products',
    component: Promotions,
    getInitialProps({ store, query }: CustomApp_PageContext) {
      const page = query.page;
      const searchQuery = query.search;

      const city = Number(query.city);
      const shop = Number(query.shop);
      const category = Number(query.category);
      const subcategory = Number(query.subcategory);
      const type = Number(query.type);

      const additionFilters = getAdditionFiltersByAlias(
        store.getState(),
        getParamAsString('/hypermarkets/promotions')
      );

      const filterQuery = {
        city: city,
        shop: shop,
        category: category,
        subcategory: subcategory,
        type: type,
      };

      return Promise.all([
        !additionFilters && filterQuery
          ? store.dispatch(
              saveAdditionFiltersByAliasThunk(
                getParamAsString('/hypermarkets/promotions'),
                filterQuery
              )
            )
          : null,
        store.dispatch(getShopByCitiesListThunk()),
        store.dispatch(getPromotionsCategoriesListThunk()),
        store.dispatch(getPromotionsTypesListThunk()),
        store.dispatch(getSettingItemListThunk()).then((settingList) => {
          const numberItemPerPage = getSettingValueByKey(
            settingList,
            'ITEMS_QUANTITY_VALUE'
          );
          return store.dispatch(
            getProductListThunk(
              Number(numberItemPerPage) ? Number(numberItemPerPage) : 15,
              getParamAsString(page),
              getParamAsString(searchQuery),
              additionFilters ? additionFilters : filterQuery
            )
          );
        }),
      ]);
    },
  },
  {
    template: 'shopping-center-and-hypermarkets',
    component: Shops,
    getInitialProps({ store }) {
      return Promise.all([
        store.dispatch(getMenuItemListThunk('hypermarket')),
        store.dispatch(getVacancyListThunk()),
        store.dispatch(getShopByCitiesListThunk()),
        store.dispatch(getShopsTypeListByCitiesThunk()),
      ]);
    },
  },
  {
    template: 'shopping-center',
    component: ShoppingCenters,
    getInitialProps({ store }) {
      return Promise.all([
        store.dispatch(getShoppingCenterListByCitiesThunk()),
      ]);
    },
  },
  {
    template: 'salon',
    component: Salon,
    getInitialProps({ store }) {
      return Promise.all([
        store.dispatch(getSettingItemListThunk()),
        store.dispatch(getShopByCitiesListThunk()),
        store.dispatch(getShopsTypeListByCitiesThunk()),
        store.dispatch(getSalonListThunk()),
        store.dispatch(getMenuItemListThunk('fashion-moll')),
      ]);
    },
  },
  {
    template: 'shopping-center-single',
    component: ShoppingCenterSingle,
    getInitialProps({ store }) {
      return Promise.all([
        store.dispatch(getSettingItemListThunk()),
        store.dispatch(getShopByCitiesListThunk()),
        store.dispatch(getNewsListThunk()),
        store.dispatch(getShoppingCenterListThunk()),
        store.dispatch(getSalonCategoriesThunk()),
        store.dispatch(getShoppingCenterListByCitiesThunk()),
      ]);
    },
  },
  {
    template: 'production',
    component: OwnProduction,
    getInitialProps({ store }) {
      return Promise.all([
        store.dispatch(getMenuItemListThunk('hypermarket')),
        store.dispatch(getVacancyByCitiesListThunk()),
      ]);
    },
  },
  {
    template: 'product',
    component: Product,
    getInitialProps({ store }) {
      return Promise.all([store.dispatch(getVacancyByCitiesListThunk())]);
    },
  },
  {
    template: 'castle-tm',
    component: CastleTm,
    getInitialProps({ store }) {
      return Promise.all([store.dispatch(getVacancyByCitiesListThunk())]);
    },
  },
  {
    template: 'castle-tm-product',
    component: CastleTmProduct,
    getInitialProps({ store }) {
      return Promise.all([store.dispatch(getVacancyByCitiesListThunk())]);
    },
  },
  {
    template: 'recipes',
    component: Recipes,
    getInitialProps({ store }) {
      return Promise.all([]);
    },
  },
  {
    template: 'recipes-single',
    component: RecipesSingle,
    getInitialProps({ store }) {
      return Promise.all([]);
    },
  },
  {
    template: 'ideas',
    component: Ideas,
    getInitialProps({ store }) {
      return Promise.all([]);
    },
  },
  {
    template: 'ideas-single',
    component: IdeasSingle,
    getInitialProps({ store }) {
      return Promise.all([]);
    },
  },
  {
    template: 'restaurants',
    component: Restaurants,
    getInitialProps({ store }) {
      return Promise.all([]);
    },
  },
  {
    template: 'restaurants-single',
    component: RestaurantsSingle,
    getInitialProps({ store }) {
      return Promise.all([store.dispatch(getRestaurantsListThunk())]);
    },
  },
  {
    template: 'restaurants-menu',
    component: RestaurantsMenu,
    getInitialProps({ store }) {
      return Promise.all([]);
    },
  },
  {
    template: 'korona-dom',
    component: KoronaDom,
    getInitialProps({ store }) {
      return Promise.all([
        store.dispatch(getNewsListThunk()),
        store.dispatch(getShoppingCenterListThunk()),
        store.dispatch(getSalonCategoriesThunk()),
      ]);
    },
  },
  {
    template: 'fashionable-moll',
    component: FashionableMoll,
    getInitialProps({ store }) {
      return Promise.all([
        store.dispatch(getMenuItemListThunk('fashion-moll')),
        store.dispatch(getNewsListThunk()),
      ]);
    },
  },
  {
    template: 'fashionable-moll-single',
    component: FashionableMollSingle,
    getInitialProps({ store }) {
      return Promise.all([
        store.dispatch(getMenuItemListThunk('fashion-moll')),
        store.dispatch(getSettingItemListThunk()),
        store.dispatch(getNewsListThunk()),
        store.dispatch(getFashionMollsListThunk()),
      ]);
    },
  },
];

export function getPageModuleByTemplate(
  template: Nullish<string>
): PageModuleType {
  return (
    PAGE_MODULE_LIST.find((pageModule) => pageModule.template === template) ??
    DEFAULT_PAGE_MODULE
  );
}
