import React from 'react';
import styled from 'styled-components';

import Picture from '@/components/Picture';
import knifeUrl from '@/assets/images/sticker/knife.png';
import knifeUrl2x from '@/assets/images/sticker/knife@2x.png';
import knifeUrlWebp from '@/assets/images/sticker/knife.webp';
import knifeUrlWebp2x from '@/assets/images/sticker/knife@2x.webp';

function Knife() {
  return (
    <Component>
      <Inner>
        <Image>
          <Picture
            mobileSmall={{
              src: knifeUrl,
              src2x: knifeUrl2x,
              webp: knifeUrlWebp,
              webp2x: knifeUrlWebp2x,
            }}
          />
        </Image>
      </Inner>
    </Component>
  );
}

const Component = styled.div``;
const Inner = styled.div``;
const Image = styled.div`
  width: 100%;
  height: 100%;
  div,
  picture,
  img {
    width: 100%;
    height: 100%;
  }
`;

export default Knife;
