import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

interface Props {
  title: string;
}

function Hero({ title }: Props) {
  return (
    <Component>
      <Inner>
        <Title>{title}</Title>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  max-width: 1680px;
  padding: 0 40px;
  margin: 0 auto 80px;

  ${media.laptop(css`
    margin-bottom: 55px;
  `)}

  ${media.tablet(css`
    padding: 0 20px;
    margin-bottom: 52px;
  `)}
  
  ${media.mobile(css`
    margin-bottom: 56px;
  `)}
`;

const Inner = styled.div`
  padding-top: 94px;

  ${media.laptop(css`
    padding-top: 104px;
  `)}

  ${media.tablet(css`
    padding-top: 50px;
  `)}
  
  ${media.mobile(css`
    padding-top: 20px;
  `)}
`;

const Title = styled.h2`
  max-width: 784px;
  margin: 0;
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.black1000};

  ${media.laptop(css`
    max-width: 588px;
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    max-width: 438px;
    font-size: 48px;
    line-height: 56px;
  `)}
  
  ${media.mobile(css`
    max-width: 100%;
    font-size: 34px;
    line-height: 42px;
  `)}
`;

export default Hero;
