import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';
import { useMedia } from '@tager/web-core';

import { media } from '@/utils/mixin';
import { breakpoints, colors } from '@/constants/theme';
import { ImageThumbnail, StringField } from '@/typings/model';
import Picture from '@/components/Picture';

interface Props {
  title: StringField;
  address: StringField;
  topImage?: ImageThumbnail;
  workingTime: StringField;
}

function Hero({ title, address, topImage, workingTime }: Props) {
  const isTablet = useMedia(`(max-width: ${breakpoints.laptop - 1}px)`);

  return (
    <Component>
      <Inner>
        <Left>
          <LeftContent>
            <Title>{title}</Title>
            <Address>{address}</Address>
            {isTablet && workingTime && (
              <WorkingTime>Время работы: {workingTime}</WorkingTime>
            )}
          </LeftContent>
        </Left>
        <Right>
          {topImage && (
            <Image>
              <Picture mobileSmall={convertThumbnailToPictureImage(topImage)} />
            </Image>
          )}
        </Right>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  position: relative;
  max-width: 1920px;
  margin: 32px auto 0;

  ${media.laptop(css`
    margin-top: 24px;
  `)}

  ${media.tablet(css`
    margin-top: 50px;
  `)}
  
  ${media.mobile(css`
    margin-top: 19px;
  `)}
`;

const Inner = styled.div`
  max-width: 1680px;
  margin: 0 auto;
  padding: 0 40px;
  display: flex;

  ${media.tablet(css`
    display: block;
    padding: 0 20px;
  `)}
`;

const Left = styled.div`
  flex: 1 1 512px;
  max-width: 512px;
  margin-right: 32px;

  ${media.laptop(css`
    flex: 1 1 384px;
    max-width: 384px;
    margin-right: 24px;
  `)}

  ${media.tablet(css`
    max-width: 346px;
    margin-right: 0;
  `)}
  
  ${media.mobile(css`
    max-width: 100%;
  `)}
`;

const Right = styled.div`
  flex: 1 1 auto;

  ${media.tablet(css`
    position: relative;
    margin: 50px -20px 0;
  `)}

  ${media.mobile(css`
    margin-top: 30px;
  `)}

  &:before {
    display: block;
    content: '';
    padding-bottom: 58.62%;

    ${media.laptop(css`
      padding-bottom: 53.53%;
    `)}

    ${media.tablet(css`
      padding-bottom: 45.6%;
    `)}
    
    ${media.mobile(css`
      padding-bottom: 133.33%;
    `)}
  }
`;

const LeftContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  color: ${colors.black};
`;

const Title = styled.h2`
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  margin: 0 0 26px;

  ${media.laptop(css`
    font-size: 64px;
    line-height: 72px;
    margin-bottom: 16px;
  `)}

  ${media.tablet(css`
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 20px;
  `)}
  
  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
  `)}
`;

const Address = styled.address`
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;

  ${media.laptop(css`
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    font-size: 20px;
    line-height: 24px;
  `)}
  
  ${media.mobile(css`
    font-size: 16px;
    line-height: 20px;
  `)}
`;

const WorkingTime = styled.span`
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin-top: 10px;

  ${media.mobile(css`
    font-size: 12px;
  `)}
`;

const Image = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: calc((100vw - 1600px) / 2 + 1056px);

  ${media.laptop(css`
    max-width: calc(832px);
  `)}

  ${media.tablet(css`
    max-width: 100%;
  `)}

  div, picture {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;

    ${media.tablet(css`
      object-fit: cover;
    `)}
  }
`;

export default Hero;
