import React from 'react';
import styled, { css } from 'styled-components';

import { StringField } from '@/typings/model';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

type Props = {
  todayItem: {
    title: StringField;
    text: StringField;
  };
};

function AboutCard({ todayItem }: Props) {
  const { title, text } = todayItem;
  return (
    <Component>
      <Title>{title}</Title>
      <Text>{text}</Text>
    </Component>
  );
}

const Component = styled.div`
  color: ${colors.white};
  margin-bottom: 50px;

  ${media.laptop(css`
    margin-bottom: 40px;
  `)}

  ${media.tablet(css`
    margin-bottom: 50px;
  `)}

  &:last-child {
    margin-bottom: 0;
  }
`;

const Title = styled.span`
  display: block;
  margin-bottom: 14px;
  font-weight: 500;
  font-size: 54px;
  line-height: 64px;

  ${media.laptop(css`
    margin-bottom: 16px;
    font-size: 40px;
    line-height: 48px;
  `)}

  ${media.tablet(css`
    margin-bottom: 20px;
    font-size: 28px;
    line-height: 34px;
  `)}
  
  ${media.mobile(css`
    font-size: 22px;
    line-height: 28px;
  `)}
`;

const Text = styled.p`
  font-size: 30px;
  line-height: 36px;

  ${media.laptop(css`
    font-size: 20px;
    line-height: 26px;
  `)}

  ${media.tablet(css`
    font-size: 18px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    font-size: 14px;
    line-height: 20px;
  `)}
`;

export default AboutCard;
