import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import Link from '@/components/Link';
import { ButtonLink } from '@/components/Button';
import { ReactComponent as ArrowRight } from '@/assets/svg/arrow-right.svg';
import { PageByTemplateType } from '@/typings/model';

import CookingCard from './components/CookingCard';

interface Props {
  recipesList: Array<PageByTemplateType>;
  title: string;
  btnLink: string;
  btnLabel: string;
}

function Cooking({ recipesList, title, btnLabel, btnLink }: Props) {
  return (
    <Component>
      <Top>
        <Left>
          <Title>{title}</Title>
        </Left>
        <Right>
          <MoreBtn to={btnLink}>
            {btnLabel}
            <ArrowRight />
          </MoreBtn>
        </Right>
      </Top>
      <Content>
        <List>
          {recipesList.slice(-4).map((recipes, index) => {
            return <CookingCard recipe={recipes} key={index} />;
          })}
        </List>
      </Content>
      <Bottom>
        <BottomBtn href={btnLink ?? ''} variant="orange">
          {btnLabel}
        </BottomBtn>
      </Bottom>
    </Component>
  );
}

const Component = styled.div`
  max-width: 1680px;
  margin: 120px auto 240px;
  padding: 0 40px;

  ${media.laptop(css`
    margin-bottom: 280px;
  `)}

  ${media.tablet(css`
    margin: 30px 0 216px;
    padding: 0 20px;
  `)}
  
  ${media.mobile(css`
    margin: 60px 0 202px;
    position: relative;
    padding-bottom: 56px;
  `)}
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;

  ${media.laptop(css`
    margin-bottom: 40px;
  `)}

  ${media.tablet(css`
    margin-bottom: 30px;
  `)}
`;
const Content = styled.div``;

const Left = styled.div`
  max-width: 80%;

  ${media.mobile(css`
    max-width: 100%;
  `)}
`;

const Right = styled.div`
  display: flex;
  align-items: flex-end;

  padding: 0 5px 2px 0;

  ${media.laptop(css`
    padding: 0 3px 3px 0;
  `)}

  ${media.tabletSmall(css`
    padding: 0 2px 0 0;
  `)}
  
  ${media.mobile(css`
    position: absolute;
    bottom: 0;
    right: 22px;
    padding: 0;
  `)}
`;

const Title = styled.h2`
  margin: 0;
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.black};

  ${media.laptop(css`
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    font-size: 48px;
    line-height: 56px;
  `)}
  
  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
  `)}
`;

const MoreBtn = styled(Link)`
  display: flex;
  align-items: center;
  width: auto;

  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  padding: 0;
  color: ${colors.black};
  transition: all 0.3s linear;

  &:hover {
    color: ${colors.orange};
  }

  ${media.laptop(css`
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tabletSmall(css`
    font-size: 14px;
    line-height: 26px;
  `)}
  
  ${media.mobile(css`
    font-size: 14px;
    line-height: 26px;
  `)}

  svg {
    margin-left: 15px;
    width: 20px;
    height: 40px;

    ${media.laptop(css`
      margin-left: 8px;
      width: 18px;
      height: 15px;
    `)}

    ${media.tabletSmall(css`
      margin-left: 10px;
      height: 14px;
    `)}
    
    ${media.mobile(css`
      margin-left: 8px;
    `)}
  }
`;

const List = styled.div``;

const Bottom = styled.div`
  margin-top: 50px;

  ${media.laptop(css`
    margin-top: 40px;
  `)}

  ${media.tablet(css`
    margin-top: 30px;
  `)}
  
  ${media.mobile(css`
    display: none;
  `)}
`;

const BottomBtn = styled(ButtonLink)`
  display: block;
  width: 100%;
  padding: 21px 0;
  border-radius: 2px;
  font-weight: bold;
  font-size: 36px;
  line-height: 48px;
  transition: all 0.3s linear;
  text-transform: uppercase;

  ${media.laptop(css`
    padding: 24px 0;
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    padding: 17px 0;
    font-size: 20px;
    line-height: 26px;
  `)}
  
  ${media.mobile(css`
    font-size: 18px;
    line-height: 23px;
  `)}
`;

export default Cooking;
