import React from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as ArrowIcon } from '@/assets/svg/arrow-shop.svg';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { StringField } from '@/typings/model';
import Link from '@/components/Link';

interface Props {
  label: StringField;
  url: StringField;
  isSecondary?: boolean;
  isPointerHidden?: boolean;
  hover?: string;
  isActive?: boolean;
}

function StoreItem({
  label,
  url,
  isSecondary,
  hover,
  isPointerHidden,
  isActive,
}: Props) {
  return (
    <Component isSecondary={isSecondary} hover={hover} isActive={isActive}>
      <ComponentLink to={url ?? ''} />
      <Inner>
        {label && <Label>{label}</Label>}
        {!isPointerHidden ? (
          <Pointer>
            <ArrowIcon />
          </Pointer>
        ) : null}
      </Inner>
    </Component>
  );
}

const ComponentLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  transition: all 0.3s linear;

  border-radius: 50px;
  padding: 29px 38px;
  background: ${colors.black};

  ${media.laptop(css`
    border-radius: 35px;
    padding: 24px 32px;
  `)}

  ${media.tablet(css`
    padding: 14px 22px;
  `)}
  
  ${media.mobile(css`
    padding: 12px 20px;
  `)}
`;

const Label = styled.div`
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  color: ${colors.white};
  transition: all 0.3s linear;

  ${media.laptop(css`
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    font-size: 20px;
    line-height: 26px;
  `)}
  
  ${media.mobile(css`
    font-size: 16px;
    line-height: 20px;
  `)}
`;

const Pointer = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 100%;
  background: ${colors.white};
  margin-left: 22px;

  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out 0.1s;

  ${media.laptop(css`
    width: 40px;
    height: 40px;
    margin-left: 16px;
  `)}

  ${media.tablet(css`
    width: 34px;
    height: 34px;
    margin-left: 10px;
  `)}
  
  ${media.mobile(css`
    width: 40px;
    height: 40px;
  `)}

  svg {
    width: 38px;
    height: 38px;
    color: ${colors.black};
    transition: all 0.3s linear;

    ${media.laptop(css`
      width: 34px;
      height: 34px;
    `)}

    ${media.tablet(css`
      width: 32px;
      height: 32px;
    `)}
  }
`;

const Component = styled.div<{
  isSecondary?: boolean;
  hover?: string;
  isActive?: boolean;
}>`
  position: relative;

  &:hover {
    ${Inner} {
      background: ${colors.orange};
    }
    ${Pointer} {
      svg {
        color: ${colors.orange};
      }
    }
  }

  ${(props) =>
    props.hover === 'red'
      ? css`
          &:hover {
            ${Inner} {
              background: ${colors.red200};
            }
            ${Label} {
              color: ${colors.white};
            }
          }
        `
      : ''}

  ${(props) =>
    props.isActive
      ? css`
          ${Inner} {
            background: ${colors.red200};
          }
          ${Label} {
            color: ${colors.white};
          }
        `
      : ''}

  ${(props) =>
    props.isSecondary
      ? css`
          ${Inner} {
            display: inline-block;
            padding: 32px 38px;
            background: ${colors.white};

            ${media.laptop(css`
              padding: 24px 32px;
            `)}

            ${media.tablet(css`
              padding: 14px 22px;
            `)}
            
              ${media.mobile(css`
              padding: 12px 20px;
            `)}
          }

          ${Label} {
            color: ${colors.black};
          }

          ${Pointer} {
            display: none;
          }
        `
      : ''}
`;

export default StoreItem;
