import React from 'react';
import styled, { css } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Navigation,
  Autoplay,
  Pagination,
  EffectFade,
} from 'swiper';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import {
  StringField,
  TradeCenterCategoriesItemType,
  TradeCenterItemType,
} from '@/typings/model';

import CategoryItem from './components/CategoryItem';
import ShopsItem from './components/ShopsItem';

SwiperCore.use([Navigation, Autoplay, Pagination, EffectFade]);

interface Props {
  setActiveShop: (shop: TradeCenterItemType | null) => void;
  activeCityItem: TradeCenterCategoriesItemType;
  setActiveCityItem: (listItem: TradeCenterCategoriesItemType) => void;
  shopByCitiesList: Array<TradeCenterCategoriesItemType>;
  title: StringField;
}

function ShopInYourCity({
  setActiveShop,
  activeCityItem,
  setActiveCityItem,
  shopByCitiesList,
  title,
}: Props) {
  return (
    <Component>
      <Inner>
        {title && (
          <SectionContainer>
            <Top>
              <TitleSection>{title}</TitleSection>
            </Top>
          </SectionContainer>
        )}

        <Bottom>
          <SectionContainer>
            <Categories>
              <CategoriesList>
                <Swiper
                  slidesPerView="auto"
                  speed={500}
                  breakpoints={{
                    320: {
                      spaceBetween: 10,
                    },
                    1680: { spaceBetween: 30 },
                  }}
                >
                  {shopByCitiesList.map((categoryItem, index) => {
                    if (categoryItem.items.length === 0) return false;
                    return (
                      <SwiperSlide key={index}>
                        <CategoryItem
                          categoryItem={categoryItem}
                          isActive={categoryItem.name === activeCityItem.name}
                          onChange={setActiveCityItem}
                          count={categoryItem.items.length}
                          setActiveShop={(shop) => setActiveShop(shop)}
                        />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </CategoriesList>
            </Categories>
          </SectionContainer>

          <ShopsList>
            <Swiper
              slidesPerView="auto"
              speed={500}
              breakpoints={{
                320: {
                  spaceBetween: 20,
                },
                1260: { spaceBetween: 24 },
                1680: { spaceBetween: 30 },
              }}
            >
              {activeCityItem.items.map((shopItem, index) => {
                return (
                  <SwiperSlide key={index}>
                    <ShopsItem
                      shopItem={shopItem}
                      setActiveShop={(shop: TradeCenterItemType) =>
                        setActiveShop(shop)
                      }
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </ShopsList>
        </Bottom>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  position: relative;
  overflow: hidden;
  max-width: 1920px;
  margin: 0 auto;
`;

const Inner = styled.div``;

const SectionContainer = styled.div`
  max-width: 1680px;
  padding: 0 40px;
  margin: 0 auto;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

const Top = styled.div`
  margin-bottom: 72px;

  ${media.laptop(css`
    margin-bottom: 29px;
  `)}

  ${media.tablet(css`
    margin-bottom: 20px;
  `)}
`;

const Bottom = styled.div``;

const TitleSection = styled.span`
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.black};

  b {
    font-weight: 500;
    color: ${colors.orange};
  }

  ${media.laptop(css`
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    font-size: 48px;
    line-height: 56px;
  `)}
  
  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
  `)}
`;

const Categories = styled.div`
  margin-bottom: 58px;
  width: 100%;

  ${media.laptop(css`
    margin-bottom: 31px;
  `)}

  ${media.tablet(css`
    margin-bottom: 38px;
  `)}
`;

const CategoriesList = styled.div`
  .swiper-container {
    height: 100%;
    cursor: grab;
    margin: 0 -40px;
    padding: 0 40px;

    ${media.tablet(css`
      margin: 0 -20px;
      padding: 0 20px;
    `)}
  }

  .swiper-slide {
    width: auto !important;
    &:last-child {
      padding-right: 40px !important;

      ${media.tablet(css`
        padding-right: 20px !important;
      `)}
    }
  }
`;

const ShopsList = styled.div`
  .swiper-container {
    height: 100%;
    cursor: grab;
    margin: 0 -40px;
    padding: 0 40px;

    ${media.tablet(css`
      margin: 0 -20px;
      padding: 0 20px;
    `)}
  }

  .swiper-slide {
    width: 376px !important;
    max-width: 376px;
    flex: 0 0 376px;
    height: auto;

    &:last-child {
      width: 416px !important;
      max-width: 416px;
      flex: 0 0 416px;
      padding-right: 40px !important;
    }

    &:first-child {
      width: 416px !important;
      flex: 0 0 416px;
      max-width: 416px;

      padding-left: 40px !important;
      padding-right: 0 !important;
    }

    ${media.laptop(css`
      width: 282px !important;
      max-width: 282px;
      flex: 0 0 282px;

      &:last-child,
      &:first-child {
        width: 322px !important;
        max-width: 322px;
        flex: 0 0 322px;
      }
    `)}

    ${media.tablet(css`
      width: 241px !important;
      max-width: 241px;
      flex: 0 0 241px;

      &:last-child,
      &:first-child {
        width: 261px !important;
        max-width: 261px;
        flex: 0 0 261px;
      }

      &:last-child {
        padding-right: 20px !important;
      }

      &:first-child {
        padding-left: 20px !important;
        padding-right: 0 !important;
      }
    `)}
    
    ${media.mobileMedium(css`
      width: calc(50vw - 30px) !important;
      max-width: calc(50vw - 30px);
      flex: 0 0 calc(50vw - 30px);

      &:last-child,
      &:first-child {
        width: calc(50vw - 10px) !important;
        max-width: calc(50vw - 10px);
        flex: 0 0 calc(50vw - 10px);
      }
    `)}
  }
`;

export default ShopInYourCity;
