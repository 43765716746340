import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Navigation,
  Autoplay,
  Pagination,
  EffectFade,
} from 'swiper';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { ImageThumbnail, StringField } from '@/typings/model';
import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';

import HistorySlide from './components/HistorySlide';

SwiperCore.use([Navigation, Autoplay, Pagination, EffectFade]);

interface Props {
  historyItems?: Array<{
    image: ImageThumbnail;
    text: StringField;
    title: StringField;
    year: StringField;
  }>;
}

function HistorySlider({ historyItems }: Props) {
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);

  return (
    <Wrapper>
      <Image>
        {historyItems && historyItems[activeSlideIndex].image ? (
          <Picture
            mobileSmall={convertThumbnailToPictureImage(
              historyItems[activeSlideIndex].image
            )}
          />
        ) : null}
      </Image>
      <SliderContainer>
        <Swiper
          initialSlide={0}
          slidesPerView={1}
          spaceBetween={18}
          speed={500}
          autoHeight={true}
          onSlideChange={(swiper) => setActiveSlideIndex(swiper.activeIndex)}
        >
          {historyItems?.map((sliderItem, index) => {
            return (
              <SwiperSlide key={index}>
                <HistorySlide sliderItem={sliderItem} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </SliderContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  padding-top: 338px;
  position: relative;

  ${media.mobile(css`
    padding-top: 360px;
  `)}
`;

const SliderContainer = styled.div`
  overflow: hidden;
  width: 100%;

  .swiper-container {
    max-width: 537px;
    margin: 0 auto;
    overflow: visible;

    ${media.mobile(css`
      max-width: 80vw;
      margin: 0 20px;
    `)}
  }

  .swiper-slide {
    opacity: 0;
    height: auto !important;

    &.swiper-slide-next {
      opacity: 1;
      position: relative;

      &:before {
        position: absolute;
        content: '';
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        background: linear-gradient(
          90deg,
          rgba(2, 2, 2, 0.9) 0%,
          rgba(2, 2, 2, 0.3) 100%
        );
      }
    }

    &.swiper-slide-prev {
      opacity: 1;
      position: relative;
      &:before {
        position: absolute;
        content: '';
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        background: linear-gradient(
          90deg,
          rgba(2, 2, 2, 0.3) 0%,
          rgba(2, 2, 2, 0.9) 100%
        );
      }
    }

    &.swiper-slide-active {
      opacity: 1;
    }
  }
`;

const Image = styled.div`
  position: absolute;
  content: '';
  top: 0;
  right: 0;

  width: 306px;
  height: 306px;
  display: flex;
  justify-content: flex-end;

  ${media.mobile(css`
    top: 100px;
    width: 198px;
    height: 208px;
  `)}

  div,
  picture,
  img {
    max-width: 306px;
    height: 100%;
    object-fit: cover;
  }
`;

export default HistorySlider;
