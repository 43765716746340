import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { ShopsKoronaItemType } from '@/typings/model';
import Link from '@/components/Link';
import Picture from '@/components/Picture';

interface Props {
  shopItem: ShopsKoronaItemType;
}

function ShopsCard({ shopItem }: Props) {
  const { logo, name, note, page } = shopItem;
  return (
    <Component>
      <ComponentLink as={Link} to={page?.url ?? '#'} />
      <Inner>
        <Top>
          <Image>
            {logo && (
              <Picture mobileSmall={convertThumbnailToPictureImage(logo)} />
            )}
          </Image>
        </Top>
        <Description>
          <Name>{name}</Name>
          <Category>{note}</Category>
        </Description>
      </Inner>
    </Component>
  );
}

const ComponentLink = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

const Inner = styled.div``;

const Top = styled.div`
  position: relative;
  width: 100%;
  border: 1.33333px solid ${colors.black};
  border-radius: 53.3333px;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 22px;
  transition: all 0.3s linear;

  &:before {
    display: block;
    content: '';
    padding-bottom: 100%;
  }

  ${media.laptop(css`
    border: 1px solid ${colors.black};
    border-radius: 40px;
    margin-bottom: 16px;
  `)}

  ${media.tablet(css`
    border-radius: 34px;
    margin-bottom: 14px;
  `)}
  
  ${media.mobile(css`
    border-radius: 28px;
    margin-bottom: 10px;
  `)}
`;

const Description = styled.div``;

const Image = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 30px;
  transition: all 0.3s linear;

  ${media.laptop(css`
    padding: 25px;
  `)}

  ${media.tablet(css`
    padding: 35px;
  `)}
  
  ${media.mobile(css`
    padding: 28px;
  `)}

  div,
  picture {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
`;

const Name = styled.div`
  margin-bottom: 9px;
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  color: ${colors.black};
  text-align: center;

  ${media.laptop(css`
    margin-bottom: 4px;
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    font-size: 20px;
    line-height: 24px;
  `)}
  
  ${media.mobile(css`
    font-size: 14px;
    line-height: 20px;
  `)}
`;
const Category = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: ${colors.grayLight};
  text-align: center;

  ${media.laptop(css`
    font-size: 16px;
    line-height: 24px;
  `)}

  ${media.tablet(css`
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    font-size: 12px;
    line-height: 20px;
  `)}
`;

const Component = styled.div`
  position: relative;

  ${media.laptopUp(css`
    &:hover {
      ${Top} {
        border-radius: 0;
        border-color: transparent;
        background: ${colors.orange};
      }
    }
  `)}
`;

export default ShopsCard;
