import React from 'react';
import styled, { css } from 'styled-components';

import { Nullish } from '@tager/web-core';

import BannerCard from '@/modules/Home/components/BannerCard';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import { KoronaDomPageType } from '@/typings/model';
import SalesBtn from '@/modules/Home/components/Hero/components/SalesBtn';

import StoreTypes from './components/StoreTypes';

interface Props {
  page: Nullish<KoronaDomPageType>;
}

function BannerSection({ page }: Props) {
  if (!page?.templateFields) return null;

  const {
    bannerBlockLeftCircleLabel,

    bannerBlockBottomImage,
    bannerBlockBottomLabel,
    bannerBlockBottomLink,

    bannerBlockCenterImage,
    bannerBlockCenterLabel,
    bannerBlockCenterLink,

    bannerBlockRightLabel,
    shopsItems,
  } = page.templateFields;

  return (
    <Components>
      <HeroContainer>
        <Inner>
          <Left>
            <LeftTop>
              <LeftTopSales>
                <SalesBtn
                  label={bannerBlockLeftCircleLabel ?? ''}
                  link={''}
                  isSecond={true}
                  isTabletCircle={true}
                />
              </LeftTopSales>
              <LeftTopBanner>
                <BannerCard
                  image={bannerBlockCenterImage}
                  text={bannerBlockCenterLabel ?? ''}
                  link={bannerBlockCenterLink ?? ''}
                  isLink={!!bannerBlockCenterLink}
                />
              </LeftTopBanner>
            </LeftTop>
            <LeftBottom>
              <BannerCard
                image={bannerBlockBottomImage}
                text={bannerBlockBottomLabel ?? ''}
                link={bannerBlockBottomLink ?? ''}
                isLink={!!bannerBlockBottomLink}
              />
            </LeftBottom>
          </Left>
          <Right>
            <StoreTypes
              title={bannerBlockRightLabel}
              background={''}
              storeList={shopsItems}
            />
          </Right>
        </Inner>
      </HeroContainer>
    </Components>
  );
}

const Components = styled.div`
  margin-top: 38px;

  ${media.laptop(css`
    margin-top: 48px;
  `)}

  ${media.tablet(css`
    margin-top: 65px;
  `)}
  
  ${media.mobile(css`
    margin-top: 52px;
  `)}
`;

const HeroContainer = styled.div`
  max-width: 1920px;
  margin: 0 auto;
`;

const Inner = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.mobile(css`
    flex-direction: column-reverse;
  `)}
`;

const Left = styled.div`
  flex: 1 1 976px;
  max-width: 976px;
  width: 100%;

  display: flex;
  flex-direction: column;

  ${media.laptop(css`
    flex: 1 1 644px;
    max-width: 644px;
  `)}

  ${media.tablet(css`
    flex: 1 1 248px;
    max-width: 248px;
  `)}
  
  ${media.mobile(css`
    flex: 1 1 auto;
    max-width: 100%;
  `)}
`;

const Right = styled.div`
  position: relative;
  flex: 1 1 944px;
  max-width: 944px;
  ${media.laptop(css`
    flex: 1 1 636px;
    max-height: 644px;
  `)}

  ${media.tablet(css`
    flex: 1 1 520px;
    max-width: 100%;
  `)}
  
  ${media.mobile(css`
    flex: 1 1 auto;
  `)}
`;

const LeftTop = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.tablet(css`
    flex-direction: column;
  `)}
`;

const LeftBottom = styled.div`
  position: relative;
  flex: 1 1 449px;

  ${media.laptop(css`
    flex: 1 1 322px;
  `)}

  ${media.tabletOnly(css`
    display: none;
  `)}
  
  ${media.mobile(css`
    flex: 0 0 147vw;
    height: 147vw;
  `)}
`;

const LeftTopSales = styled.div`
  position: relative;
  flex: 1 1 494px;
  max-width: 494px;

  ${media.laptop(css`
    flex: 1 1 322px;
    max-width: 322px;
  `)}

  ${media.laptop(css`
    flex: 1 1 249px;
    max-width: 100%;
  `)}
  
  ${media.mobile(css`
    display: none;
  `)}
`;

const LeftTopBanner = styled.div`
  flex: 1 1 482px;
  max-width: 482px;

  ${media.laptop(css`
    flex: 1 1 322px;
    max-width: 322px;
  `)}

  ${media.laptop(css`
    flex: 1 1 249px;
    max-width: 100%;
  `)}
  
  ${media.mobile(css`
    flex: 0 0 100vw;
    height: 100vw;
  `)}
`;

export default BannerSection;
