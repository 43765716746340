import React, { useEffect } from 'react';
import styled from 'styled-components';

import { getSettingValueByKey } from '@tager/web-modules';

import Subscription from '@/components/Subscription';
import useCurrentPage from '@/hooks/useCurrentPage';
import { HomePageType } from '@/typings/model';
import TickerLine from '@/components/TickerLine';
import { useTypedSelector } from '@/store/store';
import { selectSettingItemList } from '@/store/reducers/tager/settings';
import { colors } from '@/constants/theme';
import { useLayout } from '@/components/Layout/Layout.hooks';
import HelpfulInfo from '@/modules/Home/components/SpecialsOffers/components/HelpfulInfo';
import BtnAllDiscount from '@/modules/Home/components/SpecialsOffers/components/BtnAllDiscount';
import useWindowDimensions from '@/hooks/useWindowDimensions';
import useWindowSize from '@/hooks/useWindowSize';

import Hero from './components/Hero';
import Products from './components/Products';
import SpecialOffers from './components/SpecialsOffers';
import Shops from './components/Shops';
import News from './components/News';
import Vacancies from './components/Vacancies';
import Questions from './components/Questions';

function Home() {
  const page = useCurrentPage<HomePageType>();
  const settingsList = useTypedSelector(selectSettingItemList);

  const bannerTitle = getSettingValueByKey(settingsList, 'bannerTitle');
  const bannerButtonLabel = getSettingValueByKey(
    settingsList,
    'bannerButtonLabel'
  );
  const bannerButtonUrl = getSettingValueByKey(settingsList, 'bannerButtonUrl');
  const bannerImage = getSettingValueByKey(settingsList, 'bannerImage');
  const bannerStickerLabel = getSettingValueByKey(
    settingsList,
    'bannerStickerLabel'
  );
  const bannerStickerUrl = getSettingValueByKey(
    settingsList,
    'bannerStickerUrl'
  );
  const { setActiveStoreName, setMenuStore } = useLayout();
  const windowSize = useWindowSize();

  useEffect(() => {
    setActiveStoreName({
      value: '',
      label: '',
    });
    setMenuStore([]);
  }, []);

  return (
    <Wrapper>
      <Hero page={page} />

      <Products isWhite={true} />
      {windowSize.width > 360 && page?.templateFields?.productsButton && (
        <BtnAllDiscount
          title={page?.templateFields.productsButton.label}
          link={page?.templateFields.productsButton.link}
        />
      )}
      {page?.templateFields?.helpfulInformation && (
        <HelpfulInfo
          helpfulInformation={page?.templateFields.helpfulInformation}
        />
      )}
      {page?.templateFields?.specialOffersIsVisible && (
        <SpecialOffers
          magazineTitle={page?.templateFields.magazineTitle ?? ''}
          magazineButtonLabel={page?.templateFields.magazineButtonLabel ?? ''}
          magazineButtonUrl={page?.templateFields.magazineButtonUrl ?? ''}
          discountTitle={page?.templateFields.discountTitle ?? ''}
          discountButtonLabel={page?.templateFields.discountButtonLabel ?? ''}
          discountButtonUrl={page?.templateFields.discountButtonUrl ?? ''}
          discountBlockquote={page?.templateFields.discountBlockquote ?? ''}
          discountImage={page?.templateFields.discountImage}
          discountStickerLabel={page?.templateFields.discountStickerLabel ?? ''}
          discountStickerUrl={page?.templateFields.discountStickerUrl ?? ''}
          mobileTitle={page?.templateFields.mobileTitle ?? ''}
          mobileButtonLabel={page?.templateFields.mobileButtonLabel ?? ''}
          mobileButtonUrl={page?.templateFields.mobileButtonUrl ?? ''}
          mobileImage={page?.templateFields.mobileImage}
          mobileImage1={page?.templateFields.mobileImage1}
          mobileImage2={page?.templateFields.mobileImage2}
          mobileImage3={page?.templateFields.mobileImage3}
          specialOffers={page?.templateFields.specialOffers}
        />
      )}

      <TickerLine
        backgroundTicket={page?.templateFields.tickerBackgroundFirst ?? ''}
        colorTicket={page?.templateFields.tickerColorFirst ?? ''}
        labelTicket={page?.templateFields.tickerTextFirst ?? ''}
        linkTicket={page?.templateFields.tickerLinkFirst ?? ''}
      />
      <Shops
        bannerTitle={bannerTitle}
        bannerButtonLabel={bannerButtonLabel}
        bannerButtonUrl={bannerButtonUrl}
        bannerImage={bannerImage}
        bannerStickerLabel={bannerStickerLabel}
        bannerStickerUrl={bannerStickerUrl}
      />
      {page?.templateFields.newsIsVisible ? (
        <News
          shopsTitle={page?.templateFields.shopsTitle ?? ''}
          shopsButtonLabel={page?.templateFields.shopsButtonLabel ?? ''}
          shopsButtonUrl={page?.templateFields.shopsButtonUrl ?? ''}
          newsList={page?.templateFields.news}
          backgroundTicket={page?.templateFields.tickerBackgroundSecond ?? ''}
          colorTicket={page?.templateFields.tickerColorSecond ?? ''}
          labelTicket={page?.templateFields.tickerTextSecond ?? ''}
          linkTicket={page?.templateFields.tickerLinkSecond ?? ''}
          newsButtonLabel={page?.templateFields.newsButtonLabel ?? ''}
          newsButtonUrl={page?.templateFields.newsButtonUrl ?? ''}
        />
      ) : null}
      <Vacancies
        careersTitle={page?.templateFields.careersTitle ?? ''}
        careersButtonLabel={page?.templateFields.careersButtonLabel ?? ''}
        careersButtonUrl={page?.templateFields.careersButtonUrl ?? ''}
      />
      <Questions
        questionList={page?.templateFields.bottomBlocks}
        bottomText={page?.templateFields.bottomText ?? ''}
      />
      <Subscription />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  overflow: hidden;
  background: ${colors.black};
`;

export default Home;
