import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { ImageThumbnail, StringField } from '@/typings/model';
import { media } from '@/utils/mixin';

import Banner from './components/Banner';

type Props = {
  freeChoiceTitle: StringField;
  freeChoiceText: StringField;
  freeChoiceImage?: ImageThumbnail;
};

function FreeChoice({
  freeChoiceTitle,
  freeChoiceText,
  freeChoiceImage,
}: Props) {
  return (
    <Component>
      <Inner>
        <Left>
          <Title dangerouslySetInnerHTML={{ __html: freeChoiceTitle ?? '' }} />
          {freeChoiceText ? <Text>{freeChoiceText}</Text> : null}
        </Left>
        <Right>
          <BannerWrapper>
            <Banner freeChoiceImage={freeChoiceImage} />
          </BannerWrapper>
        </Right>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  background: ${colors.white};
  position: relative;
  padding-bottom: 116px;

  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 142px;
    clip-path: polygon(0% 0%, 100% 0, 100% 100%, 0% 0);
    background: ${colors.black};

    ${media.laptop(css`
      clip-path: polygon(0% 0%, 100% 0, 100% 40%, 0% 0);
    `)}

    ${media.tablet(css`
      z-index: 2;
    `)}
  }

  ${media.laptop(css`
    margin-top: -24px;
    padding-bottom: 82px;
  `)}

  ${media.tablet(css`
    margin-top: -24px;
    padding-bottom: 0;
  `)}
`;

const Inner = styled.div`
  max-width: 1680px;
  margin: 0 auto;
  padding: 0 40px;
  display: flex;
  justify-content: space-between;

  ${media.tablet(css`
    padding: 0 20px;
  `)}

  ${media.mobile(css`
    display: block;
    padding: 0 20px 50px;
  `)}
`;

const Left = styled.div`
  flex: 1 1 730px;
  max-width: 730px;
  margin-top: 292px;

  ${media.laptop(css`
    margin-top: 154px;
    flex: 1 1 608px;
    max-width: 608px;
  `)}

  ${media.tablet(css`
    margin-top: 110px;
    flex: 1 1 346px;
  `)}
  
  ${media.mobile(css`
    margin-top: 0;
    padding-top: 80px;
    max-width: 100%;
  `)}
`;
const Right = styled.div`
  flex: 1 1 648px;
  max-width: 648px;

  ${media.laptop(css`
    flex: 1 1 486px;
    max-width: 486px;
  `)}

  ${media.tablet(css`
    flex: 1 1 366px;
    max-width: 366px;
  `)}
  
  ${media.mobile(css`
    margin-top: 50px;
    max-width: 100%;
  `)}
`;

const Title = styled.h2`
  margin: 0;
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.black};

  ${media.laptop(css`
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    font-size: 48px;
    line-height: 56px;
  `)}
  
  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
  `)}

  b {
    font-weight: 500;
    color: ${colors.orange};
  }
`;

const Text = styled.p`
  margin-top: 60px;
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  color: ${colors.black};

  ${media.laptop(css`
    max-width: 486px;
    margin-top: 39px;
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    margin-top: 30px;
    font-size: 20px;
    line-height: 24px;
  `)}
  
  ${media.mobile(css`
    font-size: 16px;
    line-height: 20px;
  `)}
`;

const BannerWrapper = styled.div`
  position: relative;
  top: -34px;

  ${media.laptop(css`
    top: -36px;
  `)}

  ${media.tablet(css`
    top: 0;
    right: -20px;
    background: ${colors.yellow100};
  `)}
  
  ${media.mobile(css`
    right: 0;
  `)}
`;

export default FreeChoice;
