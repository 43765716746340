import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { StringField } from '@/typings/model';
import RestaurantsLabel from '@/modules/Restaurants/components/RestaurantsList/components/RestaurantsLabel';

interface Props {
  title?: StringField;
  vacancies?: Array<{
    name: StringField;
    url: StringField;
  }>;
}

function VacancySection({ vacancies, title }: Props) {
  return (
    <Section>
      <Top>
        <Title>{title}</Title>
      </Top>
      <Content>
        <List>
          {vacancies?.map((vacancy, index) => {
            return (
              <Cell>
                <RestaurantsLabel link={vacancy.url} label={vacancy.name} />
              </Cell>
            );
          })}
        </List>
      </Content>
    </Section>
  );
}

const Section = styled.div`
  max-width: 1680px;
  margin: 0 auto;
  padding: 0 40px;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

const Top = styled.div`
  margin-bottom: 60px;

  ${media.laptop(css`
    margin-bottom: 40px;
  `)}

  ${media.tablet(css`
    margin-bottom: 30px;
  `)}
`;

const Content = styled.div``;

const Title = styled.h2`
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.black};
  margin: 0;
  padding: 0;

  ${media.laptop(css`
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    font-size: 48px;
    line-height: 56px;
  `)}

  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
  `)}
`;

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -11px;

  ${media.laptop(css`
    margin: -8px;
  `)}
`;

const Cell = styled.div`
  padding: 11px;

  ${media.laptop(css`
    padding: 8px;
  `)}
`;

export default VacancySection;
