import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import { HypermarketProductsType } from '@/typings/model';
import Picture from '@/components/Picture';
import PriceBlock from '@/components/PriceBlock';

import HeroSlidePrice from './HeroSlidePrice';

interface Props {
  sliderItem: HypermarketProductsType;
}

function HeroSliderItem({ sliderItem }: Props) {
  const { image, title, price, oldPrice, discountPercentage, pricePerKg } =
    sliderItem;
  return (
    <Component>
      <Inner>
        <Image>
          {image ? (
            <Picture mobileSmall={convertThumbnailToPictureImage(image)} />
          ) : null}
        </Image>
        <Description>{title}</Description>
        <Price>
          <PriceBlockWrapper>
            <PriceBlock
              priceOld={oldPrice}
              discount={discountPercentage}
              pricePerKg={pricePerKg}
              isCircle={true}
              isCenter={true}
            />
          </PriceBlockWrapper>
          <HeroSlidePrice rotate={'-25deg'} color="yellow" price={price} />
        </Price>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  height: 100%;
`;
const Inner = styled.div`
  height: 100%;

  position: relative;
  padding: 0 30px;

  display: flex;
  justify-content: center;

  ${media.laptop(css`
    padding: 0 20px;
  `)}
`;

const Image = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  div,
  picture {
    height: 100%;
    max-height: 90%;

    ${media.mobileLarge(css`
      display: flex;
      align-items: center;
    `)}
  }

  img {
    height: 100%;
    max-width: 100%;

    ${media.mobileLarge(css`
      max-height: 70%;
    `)}
  }
`;

const Description = styled.p`
  position: absolute;
  top: 240px;
  left: 30px;
  max-width: 280px;

  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  color: ${colors.black};

  ${media.laptop(css`
    top: 188px;
    left: 20px;
    max-width: 168px;
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    top: auto;
    bottom: 0;
    left: 0;
    background: rgba(196, 196, 196, 0.2);
    backdrop-filter: blur(30px);
    padding: 10px 40px 10px 20px;
    border-radius: 0 40px 0 0;
    max-width: 248px;
    min-height: 60px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 16px;
    line-height: 20px;
  `)}
`;

const Price = styled.div`
  position: absolute;
  bottom: 110px;
  left: 20%;
  transform: rotate(9deg);

  ${media.laptop(css`
    bottom: 71px;
    left: 22%;
  `)}

  ${media.tablet(css`
    bottom: 40px;
    left: 23%;
  `)}

  ${media.mobile(css`
    bottom: 10%;
    left: auto;
    right: 8%;
  `)}
`;

const PriceBlockWrapper = styled.div`
  transform: rotate(-30deg);
  position: relative;
  left: -20px;
  top: 55px;
  z-index: 20;

  ${media.tablet(css`
    left: -30px;
    top: 40px;
  `)}
`;

export default HeroSliderItem;
