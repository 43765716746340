import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { ImageThumbnail } from '@/typings/model';
import Picture from '@/components/Picture';

interface Props {
  galleryItem: ImageThumbnail;
}

function GalleryItem({ galleryItem }: Props) {
  return (
    <Component>
      <Inner>
        <Image>
          {galleryItem && (
            <Picture
              mobileSmall={convertThumbnailToPictureImage(galleryItem)}
            />
          )}
        </Image>
      </Inner>
    </Component>
  );
}

const Component = styled.div``;

const Inner = styled.div`
  position: relative;
  border-radius: 32px;
  overflow: hidden;
  background: ${colors.gray50};

  &:before {
    display: block;
    content: '';
    padding-bottom: calc(104.3%);
  }

  ${media.laptop(css`
    border-radius: 24px;
  `)}
`;

const Image = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  div,
  picture {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export default GalleryItem;
