import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';
import { useMedia } from '@tager/web-core';

import Picture from '@/components/Picture';
import { HypermarketProductsType, StringField } from '@/typings/model';
import { breakpoints, colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

type Props = {
  product: HypermarketProductsType;
};

function HeroCardModal({ product }: Props) {
  const isTablet = useMedia(`(max-width: ${breakpoints.laptop - 1}px)`);

  const {
    image,
    price,
    popupDescription,
    title,
    oldPrice,
    discountPercentage,
    pricePerKg,
  } = product;

  function setPriceSize(price: string) {
    const pointIndex = price.indexOf('.');

    if (pointIndex > 2) {
      return 'three-digit';
    } else if (pointIndex > 1) {
      return 'double-digit';
    } else {
      return 'simple';
    }
  }

  return (
    <Component>
      <Inner>
        <Left>
          {image ? (
            <Image>
              <Picture mobileSmall={convertThumbnailToPictureImage(image)} />
            </Image>
          ) : null}
        </Left>
        <Right>
          <RightInner>
            <Title>{title}</Title>
            <ProductsLabelWrapper>
              {discountPercentage !== 0 && discountPercentage !== null && (
                <ProductsLabel background={colors.red100}>
                  <ProductsLabelText>{discountPercentage}</ProductsLabelText>
                </ProductsLabel>
              )}
              {oldPrice !== 0 && oldPrice !== null ? (
                <ProductsLabel background={colors.black}>
                  <ProductsLabelText through={true}>
                    {String(oldPrice.toFixed(2))}
                  </ProductsLabelText>
                </ProductsLabel>
              ) : null}
            </ProductsLabelWrapper>
            <ProductBadge priceBadgeColor={''} priceBadgeMode={'rect'}>
              <ProductPrice priceSize={setPriceSize(String(price.toFixed(2)))}>
                {String(price).split('.')[0]}
                <ProductPricePenny priceSize={setPriceSize(String(price))}>
                  . {String(price.toFixed(2)).split('.')[1]}
                </ProductPricePenny>
              </ProductPrice>
            </ProductBadge>
            {pricePerKg !== 0 && pricePerKg !== null ? (
              <PricePerKg>{pricePerKg} - 1 кг</PricePerKg>
            ) : null}
            {!isTablet && <Text>{popupDescription}</Text>}
          </RightInner>
        </Right>
        {isTablet && <Text>{popupDescription}</Text>}
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  height: 100%;
`;

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;

  ${media.tablet(css`
    width: calc(100vw - 40px);
    padding-left: 60px;
    flex-wrap: wrap;
    justify-content: center;

    overflow-y: auto;
    overflow-x: hidden;

    scrollbar-color: transparent transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 0.375rem;

      &-track {
        background-color: transparent;
      }

      &-thumb {
        background-color: transparent;
        &:hover {
          background-color: transparent;
        }
      }
    }
  `)}

  ${media.mobile(css`
    width: calc(100vw - 40px);
    padding: 0;
    display: block;
  `)}
`;

const Left = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.tablet(css`
    flex: 0 0 250px;
    max-width: 250px;
  `)}

  ${media.mobile(css`
    max-width: 100%;
  `)}
`;

const Right = styled.div`
  flex: 1 1 696px;
  margin-left: 151px;
  max-width: 696px;
  width: 100%;
  display: flex;
  align-items: center;

  ${media.laptop(css`
    flex: 1 1 464px;
    margin-left: 102px;
    max-width: 464px;
  `)}

  ${media.tablet(css`
    flex: 1 1 346px;
    max-width: 65%;
    margin-left: 36px;
  `)}
  
  ${media.mobile(css`
    max-width: 100%;
    margin: 32px 0 0;
  `)}
`;

const RightInner = styled.div``;

const Image = styled.div`
  position: relative;
  width: 100%;

  &:before {
    display: block;
    content: '';
    padding-bottom: 114.2%;

    ${media.laptop(css`
      @media (max-height: 900px) {
        padding-bottom: 150%;
      }
    `)}

    ${media.tablet(css`
      padding-bottom: 550px;
    `)}

    ${media.mobile(css`
      padding-bottom: 202px;
    `)}
  }

  div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  picture {
    height: 100%;
    width: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;

    ${media.mobile(css`
      max-width: 100%;
      max-height: 100%;
    `)}
  }
`;

const Title = styled.span`
  display: block;
  margin-bottom: 48px;

  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.black};

  ${media.laptop(css`
    margin-bottom: 32px;

    font-size: 64px;
    line-height: 72px;
  `)}

  @media (max-height: 1000px) {
    font-size: 60px;
    line-height: 68px;
  }

  @media (max-height: 850px) {
    margin-bottom: 32px;
    font-size: 48px;
    line-height: 56px;
  }

  ${media.tablet(css`
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 50px;
  `)}

  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
    margin-bottom: 20px;
  `)}
`;

const ProductBadge = styled.div<{
  priceBadgeColor: StringField;
  priceBadgeMode: StringField;
}>`
  display: flex;
  align-items: center;
  color: ${colors.black};
  background: ${(props) =>
    props.priceBadgeColor ? `${props.priceBadgeColor}` : `${colors.yellow100}`};

  ${(props) =>
    props?.priceBadgeMode === 'rect'
      ? css`
          display: inline-flex;
          position: relative;
          background: transparent;
          padding: 6px 21px 6px 9px;
          height: 130px;
          margin-left: 18px;

          ${media.laptop(css`
            padding: 4px 15px 4px 7px;
            height: 90px;
            margin-left: 16px;
          `)}

          ${media.tablet(css`
            padding: 4px 22px 4px 6px;
            height: 80px;
            margin-left: 18px;
          `)}

          ${media.mobile(css`
            padding: 4px 24px 4px 11px;
            height: 52px;
            margin-left: 9px;
          `)};

          &:before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: ${colors.yellow100};
            transform: skew(-10deg, 0);

            ${media.laptop(css`
              transform: skew(-11deg, 0);
            `)}
          }
        `
      : css`
          width: 240px;
          height: 240px;
          border-radius: 100%;
          justify-content: center;

          ${media.laptop(css`
            width: 180px;
            height: 180px;
          `)}

          ${media.tablet(css`
            width: 160px;
            height: 160px;
          `)}
             
          ${media.mobile(css`
            min-width: 100px;
            height: 100px;
          `)}
        `};
`;

const ProductPrice = styled.div<{ priceSize?: string }>`
  display: flex;
  align-items: flex-start;
  z-index: 1;

  font-weight: bold;
  font-size: 120px;
  line-height: 128px;
  font-style: italic;
  letter-spacing: -5px;

  ${media.laptop(css`
    font-size: 88px;
    line-height: 96px;
    letter-spacing: -2px;
  `)}

  ${media.mobile(css`
    font-size: 48px;
    line-height: 48px;
    letter-spacing: -2px;
  `)}

  ${(props) =>
    props.priceSize === 'simple'
      ? css`
          font-size: 120px;
          line-height: 128px;

          ${media.laptop(css`
            font-size: 88px;
            line-height: 96px;
          `)}
          ${media.mobile(css`
            font-size: 48px;
            line-height: 48px;
            letter-spacing: -2px;
          `)}
        `
      : props.priceSize === 'double-digit'
      ? css`
          font-size: 100px;
          line-height: 108px;

          ${media.laptop(css`
            font-size: 74px;
            line-height: 82px;
          `)}

          ${media.mobile(css`
            font-size: 48px;
            line-height: 48px;
            letter-spacing: -2px;
          `)}
        `
      : css`
          font-size: 76px;
          line-height: 84px;

          ${media.laptop(css`
            font-size: 55px;
            line-height: 63px;
          `)}

          ${media.mobile(css`
            font-size: 48px;
            line-height: 48px;
            letter-spacing: -2px;
          `)}
        `}
`;
const ProductPricePenny = styled.div<{ priceSize?: string }>`
  font-weight: bold;
  font-size: 54px;
  line-height: 72px;
  margin-left: 12px;
  font-style: italic;
  white-space: nowrap;

  ${media.laptop(css`
    font-size: 40px;
    line-height: 54px;
  `)}

  ${media.mobile(css`
    font-size: 22px;
    line-height: 22px;
  `)}

  ${(props) =>
    props.priceSize === 'simple'
      ? css`
          font-size: 54px;
          line-height: 72px;

          ${media.laptop(css`
            font-size: 40px;
            line-height: 54px;
          `)}

          ${media.mobile(css`
            font-size: 22px;
            line-height: 22px;
          `)}
        `
      : props.priceSize === 'double-digit'
      ? css`
          font-size: 45px;
          line-height: 69px;

          ${media.laptop(css`
            font-size: 32px;
            line-height: 46px;
          `)}
          ${media.mobile(css`
            font-size: 22px;
            line-height: 22px;
          `)}
        `
      : css`
          font-size: 35px;
          line-height: 59px;

          ${media.laptop(css`
            font-size: 32px;
            line-height: 46px;
          `)}

          ${media.mobile(css`
            font-size: 22px;
            line-height: 22px;
          `)}
        `}
`;

const Text = styled.p`
  margin-top: 50px;
  font-weight: 500;
  font-size: 26px;
  line-height: 34px;
  color: ${colors.grayLight};

  ${media.laptop(css`
    margin-top: 40px;
    font-size: 16px;
    line-height: 24px;
  `)}

  @media (max-height: 850px) {
    margin-top: 20px;
  }

  ${media.tablet(css`
    flex: 1 1 100%;
    margin-top: 50px;
    font-size: 18px;
    line-height: 22px;
  `)}

  ${media.mobile(css`
    margin-top: 30px;
    font-size: 14px;
    line-height: 20px;
  `)}
`;

const ProductsLabelWrapper = styled.div`
  display: flex;
  margin-left: 34px;

  ${media.laptop(css`
    margin-left: 28px;
  `)}

  ${media.mobile(css`
    margin-left: 16px;
  `)}
`;

const ProductsLabel = styled.div<{ background: string }>`
  position: relative;
  display: inline-block;
  padding: 0 8.5px;

  &:nth-child(2) {
    left: -1px;
  }

  ${media.laptop(css`
    padding: 0 5.5px;
  `)}

  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: skew(-10deg, 0);
    background: ${(props) =>
      props.background ? `${props.background}` : `${colors.black}`};
    z-index: 1;
  }
`;

const ProductsLabelText = styled.div<{ through?: boolean }>`
  position: relative;
  z-index: 2;
  font-weight: bold;
  font-size: 24px;
  line-height: 42px;
  color: ${colors.white};
  text-decoration-line: ${(props) => (props.through ? 'line-through' : 'none')};

  ${media.laptop(css`
    font-size: 16px;
    line-height: 32px;
  `)}

  ${media.mobile(css`
    font-size: 12px;
    line-height: 16px;
  `)}
`;

const PricePerKg = styled.div`
  margin-top: 20px;
  font-weight: 500;
  font-size: 26px;
  line-height: 34px;
  color: ${colors.grayLight};

  ${media.laptop(css`
    font-size: 16px;
    line-height: 24px;
    margin-top: 16px;
  `)}
`;

export default HeroCardModal;
