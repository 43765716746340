import React from 'react';
import styled, { css } from 'styled-components';

import Breadcrumbs from '@/components/Breadcrumbs';
import { media } from '@/utils/mixin';
import useCurrentPage from '@/hooks/useCurrentPage';
import { AssistancePageType } from '@/typings/model';
import { useLayout } from '@/components/Layout/Layout.hooks';
import { BreadCrumbs } from '@/typings/common';
import { setBreadCrumbsList } from '@/utils/common';

import Hero from './components/Hero';
import AssistanceFormContainer from './components/AssistanceForm/AssistanceForm.container';

function Assistance() {
  const page = useCurrentPage<AssistancePageType>();
  const { setThemePage } = useLayout();

  setThemePage('');

  const BREADCRUMB_LIST: Array<BreadCrumbs> = setBreadCrumbsList(
    {
      title: page?.title ?? '',
      path: page?.path ?? '',
    },
    page?.parent
  );

  return (
    <Wrapper>
      <Breadcrumbs breadcrumbs={BREADCRUMB_LIST} />
      <Inner>
        <Hero
          title={page?.templateFields.pageTitle ?? ''}
          description={page?.templateFields.pageDescription ?? ''}
        />
        <Main>
          <MainTitle>{page?.templateFields.formBeforeText}</MainTitle>
          <AssistanceFormContainer
            formTitle={page?.templateFields.formTitle ?? ''}
            messageSuccess={page?.templateFields.formSuccessMessage ?? ''}
            formCheckboxes={page?.templateFields.formCheckboxes ?? []}
            buttonLabel={'ОТПРАВИТЬ'}
          />
        </Main>
      </Inner>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  overflow: hidden;
`;

const Inner = styled.div`
  padding-bottom: 215px;

  ${media.laptop(css`
    padding-bottom: 120px;
  `)}

  ${media.tablet(css`
    padding: 0 20px 120px 20px;
  `)}
`;

const Main = styled.div`
  margin-top: 74px;

  ${media.laptop(css`
    margin-top: 56px;
  `)}

  ${media.tablet(css`
    margin-top: 50px;
  `)}

  ${media.mobile(css`
    margin-top: 30px;
  `)}
`;

const MainTitle = styled.h3`
  max-width: 1056px;
  margin: 0 auto 32px;
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;

  ${media.laptop(css`
    max-width: 792px;
    margin-bottom: 24px;
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 24px;
  `)}

  ${media.mobile(css`
    font-size: 16px;
    line-height: 20px;
  `)}
`;

export default Assistance;
