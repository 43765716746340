import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { ImageThumbnail } from '@/typings/model';
import Picture from '@/components/Picture';

import Link from '../Link';

interface Props {
  label: string;
  link: string;
  image: ImageThumbnail;
}

function ShopPreview({ label, link, image }: Props) {
  return (
    <Component>
      {link && <ComponentLink to={link} />}
      <Background>
        {image ? (
          <Picture mobileSmall={convertThumbnailToPictureImage(image)} />
        ) : null}
      </Background>
      <Content>
        <Label>{label}</Label>
      </Content>
    </Component>
  );
}

const ComponentLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${colors.gray50};
  transition: all 0.3s linear;

  div,
  picture {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  padding: 30px;

  display: flex;
  align-items: flex-end;

  ${media.laptop(css`
    padding: 24px;
  `)}

  ${media.tablet(css`
    padding: 20px;
  `)}
`;

const Label = styled.div`
  display: inline-block;
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  color: ${colors.black};

  padding: 40px 54px;
  background: ${colors.white};
  border-radius: 52px;

  ${media.laptop(css`
    padding: 24px 32px;
    font-size: 24px;
    line-height: 32px;
    border-radius: 35px;
  `)}

  ${media.tablet(css`
    padding: 23px 24px;
    font-size: 20px;
    line-height: 26px;
  `)}
  
  ${media.mobile(css`
    padding: 24px 32px;
    font-size: 16px;
    line-height: 20px;
  `)}
`;

const Component = styled.div`
  position: relative;
  border-radius: 36px;
  overflow: hidden;

  &:before {
    display: block;
    content: '';
    padding-bottom: 81.25%;

    ${media.tablet(css`
      padding-bottom: 104.17%;
    `)}

    ${media.mobile(css`
      padding-bottom: 144.44%;
    `)}
  }

  &:hover {
    ${Background} {
      transform: scale(1.1);
    }
  }

  ${media.laptop(css`
    border-radius: 24px;
  `)}
`;

export default ShopPreview;
