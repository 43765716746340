import React from 'react';
import styled, { css } from 'styled-components';

import { StringField } from '@/typings/model';
import { colors } from '@/constants/theme';
import Picture from '@/components/Picture';
import backgroundUrl from '@/assets/images/discount/type-bg.png';
import backgroundUrl2x from '@/assets/images/discount/type-bg@2x.png';
import backgroundUrlWebp from '@/assets/images/discount/type-bg.webp';
import backgroundUrlWebp2x from '@/assets/images/discount/type-bg@2x.webp';
import backgroundUrlTablet from '@/assets/images/discount/type-bg_tablet.png';
import backgroundUrlTablet2x from '@/assets/images/discount/type-bg_tablet@2x.png';
import backgroundUrlTabletWebp from '@/assets/images/discount/type-bg_tablet.webp';
import backgroundUrlTabletWebp2x from '@/assets/images/discount/type-bg_tablet@2x.webp';
import backgroundUrlMobile from '@/assets/images/discount/type-bg_mobile.png';
import backgroundUrlMobile2x from '@/assets/images/discount/type-bg_mobile@2x.png';
import backgroundUrlMobileWebp from '@/assets/images/discount/type-bg_mobile.webp';
import backgroundUrlMobileWebp2x from '@/assets/images/discount/type-bg_mobile@2x.webp';
import handUrl from '@/assets/images/discount/hand.png';
import handUrl2x from '@/assets/images/discount/hand@2x.png';
// import handUrlWebp from '@/assets/images/discount/hand.webp';
// import handUrlWebp2x from '@/assets/images/discount/hand@2x.webp';
// import handUrlTablet from '@/assets/images/discount/hand_tablet.png';
// import handUrlTablet2x from '@/assets/images/discount/hand_tablet@2x.png';
// import handUrlTabletWebp from '@/assets/images/discount/hand_tablet.webp';
// import handUrlTabletWebp2x from '@/assets/images/discount/hand_tablet@2x.webp';
// import handUrlMobile from '@/assets/images/discount/hand_mobile.png';
// import handUrlMobile2x from '@/assets/images/discount/hand_mobile@2x.png';
// import handUrlMobileWebp from '@/assets/images/discount/hand_mobile.webp';
// import handUrlMobileWebp2x from '@/assets/images/discount/hand_mobile@2x.webp';
import { media } from '@/utils/mixin';
import Cross from '@/components/Sticker/Cross';

type Props = {
  discountProgramText: StringField;
  discountProgramTitle: StringField;
};

function DiscountProgram({ discountProgramText, discountProgramTitle }: Props) {
  return (
    <Component>
      <Background>
        <Picture
          laptop={{
            src: backgroundUrl,
            src2x: backgroundUrl2x,
            webp: backgroundUrlWebp,
            webp2x: backgroundUrlWebp2x,
          }}
          tabletSmall={{
            src: backgroundUrlTablet,
            src2x: backgroundUrlTablet2x,
            webp: backgroundUrlTabletWebp,
            webp2x: backgroundUrlTabletWebp2x,
          }}
          mobileSmall={{
            src: backgroundUrlMobile,
            src2x: backgroundUrlMobile2x,
            webp: backgroundUrlMobileWebp,
            webp2x: backgroundUrlMobileWebp2x,
          }}
        />
      </Background>
      <Inner>
        <Left>
          <Title>{discountProgramTitle}</Title>
          <Text>{discountProgramText}</Text>
        </Left>
        <Right>
          <HandImage>
            <Picture
              laptop={{
                src: handUrl,
                src2x: handUrl2x,
                // webp: handUrlWebp,
                // webp2x: handUrlWebp2x,
              }}
              // tabletSmall={{
              //   src: handUrl,
              //   src2x: handUrl2x,
              //   // webp: handUrlTabletWebp,
              //   // webp2x: handUrlTabletWebp2x,
              // }}
              // mobileSmall={{
              //   src: handUrl,
              //   src2x: handUrl2x,
              //   // webp: handUrlMobileWebp,
              //   // webp2x: handUrlMobileWebp2x,
              // }}
            />
          </HandImage>
        </Right>
        <CrossWrapper>
          <Cross />
        </CrossWrapper>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  position: relative;
  max-width: 1920px;
`;
const Inner = styled.div`
  max-width: 1580px;
  padding: 0 40px;
  margin: 0 auto;

  min-height: 840px;
  position: relative;

  display: flex;
  justify-content: space-between;

  ${media.laptop(css`
    min-height: 672px;
  `)}

  ${media.tablet(css`
    padding: 0 20px;
    min-height: 458px;
  `)}
  
  ${media.mobile(css`
    flex-direction: column-reverse;
    min-height: 446px;
  `)}
`;

const Left = styled.div`
  flex: 1 1 865px;
  max-width: 865px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  ${media.laptop(css`
    flex: 1 1 690px;
    max-width: 690px;
  `)}

  ${media.tablet(css`
    flex: 1 1 438px;
    max-width: 438px;
    padding: 80px 0;
  `)}
  
  ${media.mobile(css`
    flex: 1 1 auto;
    max-width: 100%;
    padding: 130px 0 50px;
  `)}
`;
const Right = styled.div`
  flex: 1 1 auto;
  position: relative;
`;

const Title = styled.h2`
  margin: 0;
  max-width: 564px;
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.white};

  ${media.laptop(css`
    max-width: 486px;
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    font-size: 48px;
    line-height: 56px;
  `)}
  
  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
  `)}
`;
const Text = styled.p`
  margin: 60px 0;
  font-size: 30px;
  line-height: 36px;
  color: ${colors.white};

  ${media.laptop(css`
    margin-top: 32px;
    font-size: 20px;
    line-height: 26px;
  `)}

  ${media.tablet(css`
    margin: 20px 0 0;
    font-size: 18px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    font-size: 14px;
    line-height: 20px;
  `)}
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  div,
  picture {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const HandImage = styled.div`
  position: absolute;
  top: 50px;
  right: -240px;
  width: 908px;

  ${media.laptop(css`
    width: 728px;
    top: 50px;
    right: 0px;
  `)}

  ${media.tablet(css`
    width: 496px;
    top: 70px;
    right: -40px;
  `)}

  ${media.mobile(css`
    width: 368px;
    top: -83px;
    bottom: auto;
    right: -35px;
  `)}
`;

const CrossWrapper = styled.div`
  position: absolute;
  bottom: -118px;
  left: 40px;
  max-width: 260px;
  max-height: 260px;

  ${media.laptop(css`
    bottom: -95px;
    max-width: 208px;
    max-height: 208px;
  `)}

  ${media.tablet(css`
    display: none;
  `)}
  
  ${media.mobile(css`
    display: block;
    max-width: 116px;
    max-height: 116px;
    left: auto;
    right: -27px;
    bottom: -40px;
  `)}
`;

export default DiscountProgram;
