import React from 'react';
import styled, { css } from 'styled-components';

import { ModalProps } from '@tager/web-components';

import { ReactComponent as User } from '@/assets/svg/user.svg';
import { ReactComponent as Email } from '@/assets/svg/email.svg';
import { ReactComponent as Phone } from '@/assets/svg/phone.svg';
import { media } from '@/utils/mixin';
import { ReactComponent as IconClose } from '@/assets/svg/close-message.svg';

type Props = ModalProps<{
  phones: string[];
  email: string;
  title: string;
  isDropDown: boolean;
}>;

function ContactDataModal({ innerProps, closeModal }: Props) {
  const isDrop = innerProps.isDropDown;
  return (
    <Wrapper isDropDown={isDrop}>
      <Row>
        <Row>
          <Title> </Title>
        </Row>
        <IconClose
          onClick={closeModal}
          style={{ margin: 10, height: 20, width: 20, color: '#020202' }}
        />
      </Row>

      {!isDrop && (
        <Row>
          <Title>Контакты</Title>
        </Row>
      )}

      <Inner isDropDown={isDrop}>
        <ContentRow isDropDown={isDrop}>
          <User style={{ marginRight: 12, width: 20, height: 20 }} />
          <Item>
            <Name>{innerProps.title} </Name>
          </Item>
        </ContentRow>

        <ContentRow isDropDown={isDrop}>
          <Email style={{ marginRight: 12, width: 20, height: 20 }} />
          <Item>
            <Emails href={`mailto:${innerProps.email}`}>
              {innerProps.email}
            </Emails>
          </Item>
        </ContentRow>

        <Rows isDropDown={isDrop}>
          <Phone
            style={{ marginRight: 12, marginTop: 2, width: 20, height: 20 }}
          />
          <Item>
            {innerProps.phones &&
              innerProps.phones.length > 0 &&
              innerProps.phones.map((item, index) => (
                <Column>
                  {index === 0 ? (
                    <TextPhone href={`tel:${item}`}>{item}</TextPhone>
                  ) : (
                    <ContentRow isDropDown={isDrop}>
                      {innerProps.phones.length === 1 && (
                        <Phone style={{ marginRight: 12, marginTop: 6 }} />
                      )}

                      <TextPhone href={`tel:${item}`}>{item}</TextPhone>
                    </ContentRow>
                  )}
                </Column>
              ))}
          </Item>
        </Rows>
      </Inner>
    </Wrapper>
  );
}

const ContentRow = styled.div<{ isDropDown: boolean }>`
  display: flex;
  align-items: center;
  margin-top: ${(props) => (props.isDropDown ? '0px' : '18px')};

  ${media.tabletSmall(css`
    margin-top: 8px;
  `)}
  ${media.mobileSmall(css`
    margin-top: 8px;
  `)}
`;

const Rows = styled.div<{ isDropDown: boolean }>`
  display: flex;
  margin-top: ${(props) => (props.isDropDown ? '0px' : '18px')};

  ${media.tabletSmall(css`
    margin-top: 8px;
  `)}
  ${media.mobileSmall(css`
    margin-top: 8px;
  `)}
`;

const Item = styled.address`
  //display: flex;
  font-style: normal;
`;
const Wrapper = styled.div<{ isDropDown: boolean }>`
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  background: white;
`;

const Row = styled.div`
  align-items: center;
  position: relative;
  display: flex;
  justify-content: space-between;
`;

const Column = styled.div`
  align-items: center;
  position: relative;
  justify-content: space-between;
`;

const Name = styled.p`
  font-size: 24px;
  line-height: 36px;
  color: #000000;
  display: inline;
  ${media.tabletSmall(css`
    font-size: 16px;
  `)}

  ${media.mobileSmall(css`
    font-size: 14px;
  `)}
`;

const Emails = styled.a`
  font-size: 24px;
  line-height: 34px;

  color: #f9683a;
  display: inline;

  ${media.tabletSmall(css`
    font-size: 16px;
  `)}

  ${media.mobileSmall(css`
    font-size: 14px;
  `)}
`;

const TextPhone = styled.a`
  font-size: 24px;
  line-height: 24px;
  color: #020202;
  display: inline;
  text-decoration: underline;
  ${media.tabletSmall(css`
    font-size: 16px;
  `)}
  ${media.mobileSmall(css`
    font-size: 14px;
  `)}
`;

const Title = styled.p`
  font-size: 24px;
  color: #000000;
  font-weight: 700;
  display: inline;
  ${media.tabletSmall(css`
    font-size: 16px;
  `)}
  ${media.mobileSmall(css`
    font-size: 14px;
  `)}
`;

const Inner = styled.div<{ isDropDown: boolean }>`
  margin-top: ${(props) => (props.isDropDown ? '0px' : '24px')};
  width: 100%;
  height: 100%;
  position: relative;
  padding-right: 60px;
`;

export default ContactDataModal;
