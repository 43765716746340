import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

type Props = {
  journalLink: string;
};

function CalameloWidget({ journalLink }: Props) {
  return (
    <Component>
      <iframe
        src={journalLink}
        width="100%"
        frameBorder="0"
        scrolling="no"
        // allowTransparency
        // allowFullScreen
      ></iframe>
    </Component>
  );
}

const Component = styled.div`
  iframe {
    margin: 0 auto;
    background-color: transparent;
    width: 100%;
    height: 642px;

    ${media.laptop(css`
      height: 481px;
    `)}

    ${media.tablet(css`
      height: 327px;
    `)}
    
    ${media.mobile(css`
      height: 416px;
    `)}
  }
`;

export default CalameloWidget;
