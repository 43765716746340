import React from 'react';
import styled, { css } from 'styled-components';

import Picture from '@/components/Picture';
import stickerUrl from '@/assets/images/sticker/sticker-hot.png';
import stickerUrl2x from '@/assets/images/sticker/sticker-hot@2x.png';
import stickerUrlWebp from '@/assets/images/sticker/sticker-hot.webp';
import stickerUrlWebp2x from '@/assets/images/sticker/sticker-hot@2x.webp';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

function HotSticker() {
  return (
    <Component>
      <Inner>
        <Background>
          <Picture
            mobileSmall={{
              src: stickerUrl,
              src2x: stickerUrl2x,
              webp: stickerUrlWebp,
              webp2x: stickerUrlWebp2x,
            }}
          />
        </Background>
        <Text>НАЙДИСЬ!</Text>
      </Inner>
    </Component>
  );
}

const Component = styled.div``;

const Inner = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 270px;
  height: 270px;

  ${media.laptop(css`
    width: 195px;
    height: 195px;
  `)}

  ${media.tablet(css`
    width: 130px;
    height: 130px;
  `)}
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  div,
  picture,
  img {
    width: 100%;
    height: 100%;
  }
`;

const Text = styled.span`
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  color: ${colors.white};
  transform: rotate(-15deg);

  ${media.laptop(css`
    font-size: 20px;
    line-height: 26px;
  `)}

  ${media.tablet(css`
    font-size: 18px;
    line-height: 22px;
    transform: rotate(15deg);
  `)}
`;

export default HotSticker;
