import React from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as NewsIcon } from '@/assets/svg/news.svg';
import { ReactComponent as ArrowRight } from '@/assets/svg/arrow-right.svg';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { StringField } from '@/typings/model';
import Link from '@/components/Link';

type Props = {
  newsButtonLabel: StringField;
  newsButtonUrl: StringField;
};

function NewsMore({ newsButtonLabel, newsButtonUrl }: Props) {
  return (
    <Component>
      {newsButtonUrl && <NewsLink to={newsButtonUrl ?? '#'} />}
      <Inner>
        <Left>
          <NewsIcon />
        </Left>
        <Right>
          {newsButtonLabel}
          <ArrowRight />
        </Right>
      </Inner>
    </Component>
  );
}

const NewsLink = styled(Link)`
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
`;

const Left = styled.div`
  margin-right: 16px;
  svg {
    width: 80px;
    height: 64px;
  }

  ${media.laptop(css`
    margin-right: 12px;
    svg {
      margin-top: 2px;
      width: 70px;
      height: 56px;
    }
  `)}
`;
const Right = styled.div`
  display: flex;
  align-items: center;

  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  color: ${colors.white};
  transition: all 0.3s linear;

  svg {
    margin-left: 10px;
    width: 28px;
    height: 17px;
    //transition: all 0.3s linear;
  }

  ${media.laptop(css`
    font-size: 20px;
    line-height: 26px;

    svg {
      margin-left: 0;
      width: 32px;
      height: 15px;
    }
  `)}

  ${media.tabletSmall(css`
    font-size: 18px;
    line-height: 22px;
  `)}
`;

const Component = styled.div`
  position: relative;

  &:hover {
    ${Right} {
      color: ${colors.orange};
      //svg {
      //  transform: translateX(5px);
      //}
    }
  }
`;

export default NewsMore;
