import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import { StringField } from '@/typings/model';

interface Props {
  size?: string;
  unit: string | null;
  priceOld: number | null;
  priceNew: number;
  perUnit: number;
  discount: StringField;
}

function PromotionPrice({
  size,
  unit,
  perUnit,
  priceNew,
  priceOld,
  discount,
}: Props) {
  return (
    <Component size={size}>
      <Inner>
        <PriceLabelWrapper>
          {discount && (
            <PriceLabel background={colors.red100}>
              <PriceLabelText>{discount}</PriceLabelText>
            </PriceLabel>
          )}
          {priceOld && priceOld !== 0 ? (
            <PriceLabel background={colors.black}>
              <PriceLabelText through={true}>
                {String(priceOld.toFixed(2))}
              </PriceLabelText>
            </PriceLabel>
          ) : null}
        </PriceLabelWrapper>
        {unit ? (
          <PriceContent>
            <ProductPrice>
              {String(priceNew).split('.')[0]}
              <ProductPricePenny>
                .{String(priceNew.toFixed(2)).split('.')[1]}
              </ProductPricePenny>
            </ProductPrice>

            {unit && (
              <PriceContainerUnit>
                <ProductUnit>{`${perUnit} / ${unit}`}</ProductUnit>
              </PriceContainerUnit>
            )}
          </PriceContent>
        ) : (
          <PriceNew>
            <ProductPrice>
              {String(priceNew).split('.')[0]}
              <ProductPricePenny>
                .{String(priceNew.toFixed(2)).split('.')[1]}
              </ProductPricePenny>
            </ProductPrice>
          </PriceNew>
        )}
      </Inner>
    </Component>
  );
}

const Inner = styled.div``;

const PriceLabel = styled.div<{ background: string }>`
  position: relative;
  display: inline-block;
  padding: 0 8.5px;

  &:nth-child(2) {
    left: -1px;
  }

  ${media.laptop(css`
    padding: 0 5.5px;
  `)}
  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: skew(-10deg, 0);
    background: ${(props) =>
      props.background ? `${props.background}` : `${colors.black}`};
    z-index: 1;

    ${media.tablet(css`
      transform: skew(-11deg, 0);
    `)}
  }
`;

export const PriceContent = styled.div`
  position: relative;

  ${media.laptop(css`
    padding: 4px 0px 0px 0px;
  `)}
  ${media.tablet(css`
    padding: 4px 0px 0px 0px;
  `)}
  ${media.mobile(css`
    padding: 4px 0px 0px 0px;
  `)}
  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${colors.yellow100};
    transform: skew(-10deg, 0);

    ${media.laptop(css`
      transform: skew(-11deg, 0);
    `)}
  }
`;

export const PriceNew = styled.div`
  position: relative;
  padding: 6px 21px 6px 9px;

  ${media.laptop(css`
    padding: 4px 15px 4px 7px;
  `)}
  ${media.tablet(css`
    padding: 4px 22px 4px 6px;
  `)}
  ${media.mobile(css`
    padding: 4px 24px 4px 11px;
  `)}
  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${colors.yellow100};
    transform: skew(-10deg, 0);

    ${media.laptop(css`
      transform: skew(-11deg, 0);
    `)}
  }
`;

export const PriceContainerUnit = styled.div`
  position: relative;
  padding: 6px 21px 6px 9px;
  left: -5px;

  ${media.laptop(css`
    padding: 4px 15px 4px 7px;
    left: -5px;
  `)}
  ${media.tablet(css`
    padding: 4px 22px 4px 6px;
    left: -4.5px;
  `)}
  ${media.mobile(css`
    padding: 4px 24px 4px 11px;
    left: -5px;
  `)}
  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${colors.yellowActive};
    transform: skew(-10deg, 0);

    ${media.laptop(css`
      transform: skew(-11deg, 0);
    `)}
  }
`;

const PriceLabelText = styled.span<{ through?: boolean }>`
  position: relative;
  z-index: 2;
  font-weight: bold;
  font-size: 24px;
  line-height: 42px;
  color: ${colors.white};
  text-decoration-line: ${(props) => (props.through ? 'line-through' : 'none')};

  ${media.laptop(css`
    font-size: 16px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    font-size: 20px;
    line-height: 26px;
  `)}

  ${media.mobile(css`
    font-size: 24px;
    line-height: 32px;
  `)}
`;

export const ProductPrice = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  padding: 0px 6px 0px 20px;
  font-style: italic;
  font-weight: bold;
  font-size: 120px;
  line-height: 128px;
  letter-spacing: -6px;
  color: ${colors.black};

  ${media.laptop(css`
    font-size: 88px;
    line-height: 96px;
    letter-spacing: -4px;
  `)}

  ${media.tablet(css`
    font-size: 70px;
    line-height: 78px;
    letter-spacing: -2px;
    padding: 0px 6px 0px 30px;
  `)}

  ${media.mobile(css`
    font-size: 88px;
    line-height: 96px;
    letter-spacing: -4px;
    padding: 0px 6px 0px 30px;
  `)}
`;

export const ProductUnit = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;

  font-style: italic;
  font-size: 19px;
  font-weight: 700;
  line-height: 20px;
  color: ${colors.white};
  ${media.laptop(css`
    font-size: 16px;
    line-height: 28px;
  `)}

  ${media.tablet(css`
    font-size: 16px;
    line-height: 28px;
  `)}

  ${media.mobile(css`
    font-size: 16px;
    line-height: 28px;
  `)}
`;

const ProductPricePenny = styled.span`
  font-style: italic;
  font-weight: bold;
  font-size: 54px;
  line-height: 72px;
  margin-left: 15px;
  color: ${colors.black};
  letter-spacing: 0;

  ${media.laptop(css`
    font-size: 40px;
    line-height: 54px;
    margin-left: 11px;
  `)}

  ${media.tablet(css`
    font-size: 32px;
    line-height: 44px;
    margin-left: 4px;
  `)}

  ${media.mobile(css`
    font-size: 40px;
    line-height: 54px;
  `)}
`;

const Component = styled.div<{ size?: string }>`
  position: absolute;
  bottom: 20px;
  left: 35px;

  ${media.laptop(css`
    bottom: 16px;
    left: 26px;
  `)}

  ${media.tablet(css`
    bottom: -9px;
    left: 22px;
  `)}

  ${media.mobile(css`
    bottom: -10px;
    left: 11px;
  `)}


  ${(props) =>
    props.size === 'middle'
      ? css`
          left: 23px;
          bottom: 9px;

          ${media.laptop(css`
            bottom: 8px;
            left: 18px;
          `)}

          ${media.tablet(css`
            bottom: -9px;
            left: 22px;
          `)}

        ${media.mobile(css`
            bottom: -10px;
            left: -9px;
          `)}
        `
      : props.size === 'small'
      ? css`
          bottom: 11px;
          left: 17px;

          ${PriceLabelWrapper} {
            margin-left: 10px;
          }

          ${PriceNew} {
            padding: 0 9px;
          }

          ${PriceLabelText} {
            font-size: 20px;
            line-height: 32px;
          }

          ${ProductPrice} {
            font-size: 54px;
            line-height: 64px;
          }

          ${ProductPricePenny} {
            font-size: 32px;
            line-height: 42px;
          }

          ${media.laptop(css`
            bottom: 8px;
            left: 13px;

            ${PriceLabelWrapper} {
              margin-left: 8px;
            }

            ${PriceLabel} {
              padding: 0 4.5px;
            }

            ${PriceNew} {
              padding: 0 8px 0 3px;
            }

            ${PriceLabelText} {
              font-size: 14px;
              line-height: 24px;
            }

            ${ProductPrice} {
              font-size: 40px;
              line-height: 48px;
            }

            ${ProductPricePenny} {
              font-size: 24px;
              line-height: 32px;
            }
          `)}

          ${media.tablet(css`
            bottom: -6px;
            left: 17px;

            ${PriceLabelWrapper} {
              margin-left: 6px;
            }

            ${PriceLabel} {
              padding: 0 4.5px;
            }

            ${PriceNew} {
              display: inline-block;
              padding: 0 15px 0 3px;
            }

            ${PriceLabelText} {
              font-size: 12px;
              line-height: 18px;
            }

            ${ProductPrice} {
              font-size: 30px;
              line-height: 36px;
              letter-spacing: -2px;
            }

            ${ProductPricePenny} {
              font-size: 18px;
              line-height: 24px;
              margin-left: 2px;
            }
          `)}

          ${media.mobile(css`
            bottom: 8px;
            left: 4px;

            ${PriceLabelWrapper} {
              margin-left: 6px;
            }

            ${PriceLabel} {
              padding: 0 4.5px;
            }

            ${PriceNew} {
              padding: 0 14px 0 3px;
            }

            ${PriceLabelText} {
              font-size: 14px;
              line-height: 20px;
            }

            ${ProductPrice} {
              font-size: 34px;
              line-height: 40px;
            }

            ${ProductPricePenny} {
              font-size: 20px;
              line-height: 26px;
            }
          `)}
        `
      : ''}
`;

const PriceLabelWrapper = styled.div`
  display: flex;
  margin-left: 17px;

  ${media.laptop(css`
    margin-left: 12.5px;
  `)}

  ${media.tablet(css`
    margin-left: 10.5px;
  `)}

  ${media.mobile(css`
    margin-left: 12.5px;
  `)}
`;

export default PromotionPrice;
