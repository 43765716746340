import React from 'react';
import styled, { css, keyframes } from 'styled-components';

import { colors } from '@/constants/theme';
import { StringField } from '@/typings/model';
import { media } from '@/utils/mixin';
import Link from '@/components/Link';
import { ReactComponent as ArrowIcon } from '@/assets/svg/arrow-tablet.svg';

type Props = {
  label: StringField;
  link: StringField;
  isSecond?: boolean;
  isTabletCircle?: boolean;
};

function SalesBtn({ label, link, isSecond, isTabletCircle }: Props) {
  return (
    <Component isSecond={isSecond} isTabletCircle={isTabletCircle}>
      {link && <SalesLink to={link} />}
      <SalesContent>
        <SalesTitle>{label}</SalesTitle>
        <IconWrapper>
          <ArrowIcon />
        </IconWrapper>
      </SalesContent>
    </Component>
  );
}

const spin = keyframes`
  from {
    transform: rotate(0) translate3d(0,0,0);
  }
  to {
    transform: rotate(360deg) translate3d(0,0,0);
  }
`;

const SalesTitle = styled.span`
  font-weight: bold;
  font-size: 36px;
  line-height: 48px;
  text-transform: uppercase;
  color: ${colors.yellow100};
  transition: all 0.3s linear;
  text-align: center;

  ${media.laptop(css`
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    font-size: 20px;
    line-height: 28px;
  `)}
  
  ${media.tabletSmall(css`
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    color: ${colors.yellow100};
  `)}
  
  ${media.mobile(css`
    font-size: 22px;
    line-height: 100%;
  `)}
  
  ${media.mobileLarge(css`
    display: inline;
  `)}
`;

const SalesLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const SalesContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: ${spin} 8s infinite linear;

  display: flex;
  justify-content: center;
  align-items: center;

  ${media.tabletSmall(css`
    position: static;
    animation: none;
  `)}

  ${media.mobileLarge(css`
    padding: 0 60px;
  `)};
`;

const IconWrapper = styled.div`
  display: none;
  color: ${colors.yellow100};

  ${media.tabletSmall(css`
    display: flex;
    margin-left: 8px;

    svg {
      width: 34px;
      height: 34px;
    }
  `)};

  ${media.mobileLarge(css`
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    margin-left: 0;

    svg {
      width: 38px;
      height: 38px;
    }
  `)};
`;

const Component = styled.div<{ isSecond?: boolean; isTabletCircle?: boolean }>`
  width: 100%;
  position: relative;
  background: ${colors.black100};
  border-radius: 100%;
  overflow: hidden;
  transition: all 0.3s linear;

  &:before {
    display: block;
    content: '';
    padding-bottom: 100%;
  }

  ${media.tabletUp(css`
    &:hover {
      background: ${colors.yellow100};

      ${SalesTitle} {
        color: ${colors.orange};
      }
    }
  `)}

  ${media.tabletSmall(css`
    border-radius: 0;
    padding: 18px 0;
    background: ${colors.black};

    &:before {
      display: none;
    }
  `)}
  
  ${media.mobile(css`
    min-height: 76px;
  `)}

  ${(props) =>
    props.isSecond
      ? css`
          background: ${colors.orange};

          ${SalesTitle} {
            color: ${colors.white};
          }
        `
      : ''}
  
  ${(props) =>
    props.isTabletCircle
      ? css`
          ${media.tabletSmall(css`
            border-radius: 100%;
            padding: 0;
            background: ${colors.black};

            &:before {
              display: block;
            }

            ${SalesContent} {
              position: absolute;
              animation: ${spin} 8s infinite linear;
            }

            ${IconWrapper} {
              display: none;
            }

            ${SalesTitle} {
              font-size: 20px;
              line-height: 24px;
            }
          `)}

          ${media.mobile(css`
            border-radius: 0;
            padding: 18px 0;
            background: ${colors.yellow100};

            &:before {
              display: none;
            }

            ${SalesContent} {
              position: static;
              animation: none;
            }

            ${IconWrapper} {
              display: block;
              color: ${colors.black};
            }

            ${SalesTitle} {
              font-size: 22px;
              line-height: 100%;
              color: ${colors.black};
            }
          `)}
        `
      : ''}
`;

export default SalesBtn;
