import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { media } from '@/utils/mixin';
import { ImageThumbnail } from '@/typings/model';
import Picture from '@/components/Picture';

import Link from '../Link';

interface Props {
  link?: string;
  image?: ImageThumbnail;
}

function NotificationsCard({ image, link }: Props) {
  return (
    <Component>
      {link && <ComponentLink to={link} />}
      <Inner>
        <Image>
          {image ? (
            <Picture mobileSmall={convertThumbnailToPictureImage(image)} />
          ) : null}
        </Image>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  position: relative;

  ${media.mobile(css`
    border-radius: 24px;
    overflow: hidden;
  `)}
`;

const ComponentLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const Inner = styled.div``;

const Image = styled.div`
  height: 480px;

  ${media.laptop(css`
    height: 320px;
  `)}

  ${media.tablet(css`
    height: 200px;
  `)}
  
  ${media.mobile(css`
    height: 250px;
  `)}

  div,
  picture,
  img {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: contain;
  }
`;

export default NotificationsCard;
