import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { Clusterer, Placemark, YMaps, Map } from 'react-yandex-maps';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import PlacemarkIcon from '@/assets/svg/placemark.svg';
import useSettingItem from '@/hooks/useSettingItem';

interface Props {
  currentCoordinates: { latitude: number; longitude: number };
  mapIcon?: string | null;
}

function SingleMap({ currentCoordinates, mapIcon }: Props) {
  const [coordinates, setCoordinates] = useState<Array<number>>(
    currentCoordinates?.latitude && currentCoordinates?.longitude
      ? [currentCoordinates.latitude, currentCoordinates.longitude]
      : [53.9, 27.57]
  );
  const [zoom, setZoom] = useState<number>(16);

  function setMapState(coordinates: Array<number>, zoom: number) {
    return {
      center: coordinates,
      zoom: zoom,
    };
  }

  const mapState = useMemo<{ center: Array<number>; zoom: number }>(
    () => setMapState(coordinates, zoom),
    [zoom, coordinates]
  );

  const yandexApiKey = useSettingItem('YANDEX_API_KEY') || '';

  return (
    <Component>
      <Inner>
        <ShopLocation>
          <ShopLocationInner>
            <YMaps
              query={{
                apikey: yandexApiKey,
              }}
            >
              <Map
                state={mapState}
                options={{ autoFitToViewport: 'always' }}
                modules={['geolocation', 'geocode']}
              >
                <Clusterer
                  options={{
                    preset: 'islands#invertedDarkOrangeClusterIcons',
                    groupByCoordinates: false,
                  }}
                >
                  <Placemark
                    geometry={[
                      currentCoordinates?.latitude ?? 0,
                      currentCoordinates?.longitude ?? 0,
                    ]}
                    options={{
                      iconLayout: 'default#image',
                      iconImageHref: mapIcon ?? PlacemarkIcon,
                      iconImageSize: [160, 160],
                      cursor: 'pointer',
                    }}
                  />
                </Clusterer>
              </Map>
            </YMaps>
          </ShopLocationInner>
        </ShopLocation>
      </Inner>
    </Component>
  );
}

const Inner = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
  border-radius: 106.6px;
  border: 1.3325px solid rgba(153, 153, 153, 0.6);

  ${media.laptop(css`
    border: 1px solid rgba(153, 153, 153, 0.4);
    border-radius: 80px;
  `)}

  &:before {
    display: block;
    content: '';
    padding-bottom: 53.25%;
  }
`;

const ShopLocation = styled.div`
  flex: 1 1 auto;
  min-width: 50%;
  background: ${colors.black};
`;

const ShopLocationInner = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 54px;
  background: ${colors.gray50};

  div:first-child,
  .ymaps-2-1-78-map {
    width: 100% !important;
    height: 100% !important;
  }

  .ymaps-2-1-78-copyrights-pane {
    opacity: 0;
  }

  .ymaps-2-1-78-image,
  .ymaps-2-1-79-image {
    width: 160px !important;
    height: 160px !important;
    background-size: 160px !important;

    ${media.laptop(css`
      width: 120px !important;
      height: 120px !important;
      background-size: 120px !important;
    `)}

    ${media.tablet(css`
      width: 82px !important;
      height: 82px !important;
      background-size: 82px !important;
    `)}
    
    ${media.mobileMedium(css`
      width: 52px !important;
      height: 52px !important;
      background-size: 52px !important;
    `)}
  }
`;

const Component = styled.div`
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;

  ${Inner} {
    border-radius: 0;
    border: none;
    &:before {
      padding-bottom: 100%;
    }
  }
`;

export default SingleMap;
