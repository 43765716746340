import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

type Props = {
  title?: string;
};

function Hero({ title }: Props) {
  return (
    <Component>
      <Inner>
        <Title>{title}</Title>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  padding-top: 92px;

  ${media.laptop(css`
    padding-top: 64px;
  `)}

  ${media.tablet(css`
    padding-top: 44px;
  `)}
  
  ${media.mobile(css`
    padding-top: 30px;
  `)}
`;

const Inner = styled.div`
  max-width: 1056px;
  margin: 0 auto;

  ${media.laptop(css`
    max-width: 792px;
  `)}

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

const Title = styled.h2`
  margin: 0;
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;

  ${media.laptop(css`
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    font-size: 48px;
    line-height: 56px;
  `)}
  
  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
  `)}
`;

export default Hero;
