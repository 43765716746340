import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';

import Picture from '@/components/Picture';
import badgeUrl from '@/assets/images/careers/badge.png';
import badgeUrl2x from '@/assets/images/careers/badge@2x.png';
import badgeUrlWebp from '@/assets/images/careers/badge.webp';
import badgeUrlWebp2x from '@/assets/images/careers/badge@2x.webp';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { StringField, VacancyStoresItemType } from '@/typings/model';
import Button from '@/components/Button';

type Props = {
  position: string;
  city: string;
  shop: string;
  vacancyList: Array<VacancyStoresItemType>;
  defaultText: StringField;
};

function Badge({ position, city, shop, vacancyList, defaultText }: Props) {
  const router = useRouter();

  const [isDisabledBtn, setDisabledBtn] = useState<boolean>(true);
  const [pathVacancy, setPathVacancy] = useState<string>('');

  function getVacancy(
    position: string,
    city: string,
    shop: string,
    list: Array<VacancyStoresItemType>
  ) {
    list.map((listItem, index) => {
      listItem.vacancies.find((vacanciesItem, index) => {
        if (vacanciesItem.name === position && vacanciesItem.store === shop) {
          setPathVacancy(vacanciesItem.urlAlias);
        }
      });
    });
  }

  function motionToVacancy() {
    router.push(`/careers/vacancies/${pathVacancy}`);
  }

  useEffect(() => {
    if (position && city && shop) {
      getVacancy(position, city, shop, vacancyList);
      setDisabledBtn(false);
    } else {
      setDisabledBtn(true);
    }
  }, [position, city, shop]);

  return (
    <Component>
      <Inner>
        <Background>
          <Picture
            mobileSmall={{
              src: badgeUrl,
              src2x: badgeUrl2x,
              webp: badgeUrlWebp,
              webp2x: badgeUrlWebp2x,
            }}
          />
        </Background>
        <Content>
          <ContentInner>
            <Top>
              {position || city || shop ? (
                <>
                  <Position>
                    <PositionInner>{position}</PositionInner>
                  </Position>
                  <City>{city ? `г.${city}` : ''}</City>
                  <Shop>{shop}</Shop>
                </>
              ) : (
                <DefaultText>{defaultText}</DefaultText>
              )}
            </Top>
            <Bottom>
              <SearchBtn
                variant="orange"
                onClick={motionToVacancy}
                isDisabledBtn={isDisabledBtn}
              >
                НАЙТИ
              </SearchBtn>
            </Bottom>
          </ContentInner>
        </Content>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const Inner = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;

  display: flex;
  justify-content: center;

  div,
  picture,
  img {
    width: 648px;
    height: 100%;

    ${media.laptop(css`
      max-width: 486px;
      width: 100%;
    `)}
  }

  img {
    position: relative;
    right: -82px;

    ${media.laptop(css`
      right: -62px;
    `)}
  }

  ${media.tablet(css`
    display: none;
  `)}
`;

const Content = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
`;

const ContentInner = styled.div`
  max-width: 512px;
  position: relative;
  right: -72px;
  min-height: 674px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  padding: 110px 32px 0;

  ${media.laptop(css`
    right: -55px;
    max-width: 384px;
    min-height: 478px;
    padding: 86px 24px 0;
  `)}

  ${media.tablet(css`
    position: initial;
    padding: 0;
    right: 0;
    max-width: none;
    min-height: auto;
  `)}
`;

const Top = styled.div`
  color: ${colors.black};
  margin-bottom: auto;

  ${media.tablet(css`
    display: none;
  `)}
`;

const Bottom = styled.div`
  margin: 0 24px;
`;

const DefaultText = styled.div`
  min-height: 400px;
  max-height: 458px;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 54px;
  line-height: 64px;

  ${media.laptop(css`
    min-height: 240px;
    max-height: 312px;
    overflow: hidden;
    font-size: 40px;
    line-height: 48px;
  `)}
`;

const Position = styled.div`
  padding: 10px 0;
  font-weight: 500;
  font-size: 54px;
  line-height: 64px;
  max-width: 448px;
  height: 210px;
  margin-bottom: 10px;

  display: flex;
  align-items: center;

  overflow: hidden;

  ${media.laptop(css`
    max-width: 336px;
    padding: 0;
    height: 96px;

    margin-bottom: 32px;
    font-size: 40px;
    line-height: 48px;
  `)}
`;

const PositionInner = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  ${media.laptop(css`
    -webkit-line-clamp: 2;
  `)}
`;

const City = styled.span`
  display: block;
  margin-bottom: 42px;
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;

  ${media.laptop(css`
    margin-bottom: 32px;
    font-size: 24px;
    line-height: 32px;
  `)}
`;
const Shop = styled.p`
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  ${media.laptop(css`
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.laptop(css`
    font-size: 20px;
    line-height: 24px;
  `)}
`;

const SearchBtn = styled(Button)<{ isDisabledBtn: boolean }>`
  position: relative;
  display: block;
  text-align: center;
  width: calc(100% + 64px);
  min-height: 106px;
  padding: 29px 0;
  margin: 0 -32px;
  transition: all 0.3s linear;

  font-weight: bold;
  font-size: 36px;
  line-height: 48px;
  text-transform: uppercase;

  ${media.laptop(css`
    width: calc(100% + 48px);
    min-height: 80px;
    margin: 0 -24px;
    padding: 24px 0;
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    min-height: 52px;
    padding: 13px 0;
    font-size: 20px;
    line-height: 26px;
  `)}
  
  ${media.mobile(css`
    position: initial;
    padding: 15px 0;
    font-size: 16px;
    line-height: 20px;
  `)}

  ${(props) =>
    props.isDisabledBtn
      ? css`
          pointer-events: none;
          background: ${colors.gray50};
          color: ${colors.black};
          border: 1px solid rgba(203, 204, 218, 0.6);
        `
      : ''}
`;

export default Badge;
