import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { colors } from '@/constants/theme';
import Picture from '@/components/Picture';
import { ImageThumbnail, StringField } from '@/typings/model';
import { media } from '@/utils/mixin';

type Props = {
  offlineCertificateItem: {
    image?: ImageThumbnail;
    name: StringField;
    phone: StringField;
    link?: StringField;
  };
  isHeader?: boolean;
};

function TableRow({ offlineCertificateItem, isHeader }: Props) {
  const { image, name, phone, link } = offlineCertificateItem;
  return (
    <Component isHeader={isHeader}>
      {link && <TableRowLink href={link} />}
      <Inner isHeader={isHeader}>
        <Col>{name}</Col>
        <Col>{phone}</Col>

        {image ? (
          <ImageWrapper>
            <Image>
              <Picture mobileSmall={convertThumbnailToPictureImage(image)} />
            </Image>
          </ImageWrapper>
        ) : null}
      </Inner>
    </Component>
  );
}

const TableRowLink = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

const Col = styled.div`
  flex: 1 1 auto;
  font-size: 30px;
  line-height: 36px;

  ${media.laptop(css`
    font-size: 20px;
    line-height: 26px;
  `)}

  ${media.tablet(css`
    font-size: 18px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    font-size: 14px;
    line-height: 20px;
  `)}

  &:first-child {
    flex: 1 1 912px;
    max-width: 912px;

    ${media.laptop(css`
      flex: 1 1 612px;
      max-width: 612px;
    `)}

    ${media.tablet(css`
      flex: 1 1 500px;
      max-width: 500px;
    `)}
  }

  &:nth-child(2) {
    ${media.mobile(css`
      margin-top: 10px;
      font-size: 16px;
      line-height: 20px;
      color: ${colors.orange};
    `)}
  }
`;

const ImageWrapper = styled.div`
  position: absolute;
  content: '';
  top: -96%;
  right: 0;

  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.3s linear;
  z-index: 10;

  ${media.laptop(css`
    top: -137%;
    right: 7px;
  `)}

  ${media.tablet(css`
    top: -80%;
    right: 0;
  `)}
`;

const Image = styled.div`
  width: 342px;
  position: relative;

  ${media.tablet(css`
    width: 200px;
  `)}

  &:before {
    display: block;
    content: '';
    padding-bottom: 110%;
  }

  div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  img,
  picture {
    width: 100%;
    height: 100%;
  }
`;

const Inner = styled.div<{ isHeader?: boolean }>`
  padding: 44px 40px 45px;
  display: flex;
  position: relative;
  color: ${colors.white};
  transition: all 0.3s linear;

  ${media.laptop(css`
    padding: 35px 40px 36px;
  `)}

  ${media.tablet(css`
    padding: 30px 20px;
  `)}
  
  ${media.mobile(css`
    padding: 20px;
    display: block;
  `)}

  ${(props) =>
    props.isHeader
      ? css`
          padding: 0 40px 20px;
          color: rgba(255, 255, 255, 0.5);

          ${media.laptop(css`
            padding: 0 40px 18px;
          `)}

          ${media.tablet(css`
            padding: 0 20px 20px;
          `)}
          
          ${Col} {
            &:nth-child(2) {
              ${media.mobile(css`
                display: none;
              `)}
            }
          }
        `
      : ''}
`;

const Component = styled.div<{ isHeader?: boolean }>`
  transition: all 0.3s linear;
  cursor: pointer;
  position: relative;

  &:before {
    position: absolute;
    content: '';
    top: -2px;
    left: 0;
    width: 100%;
    height: 3px;
    background: transparent;
    transition: all 0.3s linear;
    z-index: 2;
  }

  &:after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: ${colors.white};
    transition: all 0.3s linear;

    ${media.laptop(css`
      width: calc(100vw - 80px);
      left: 50%;
      transform: translate(-50%);
    `)}

    ${media.tablet(css`
      width: calc(100vw - 40px);
    `)}
  }

  &:last-child {
    &:after {
      background: transparent;
    }
  }

  ${(props) =>
    props.isHeader
      ? css`
          pointer-events: none;
        `
      : ''};

  &:hover {
    &:after {
      background: ${colors.orange};
    }

    &:before {
      background: ${colors.orange};
    }

    ${Inner} {
      background: ${colors.orange};
    }

    ${ImageWrapper} {
      transition: all 0.5s linear 0.3s;
      opacity: 1;
      visibility: visible;
    }
  }
`;

export default TableRow;
