import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import DropDownSelect from '@/components/DropDownSelect';
import { OptionType } from '@/components/DropDownSelect/DropDownSelect';
import { useTypedSelector } from '@/store/store';
import { PromotionListTypeItem } from '@/typings/model';
import { selectPromotionsTypesList } from '@/store/reducers/promotionTypes';

interface Props {
  setActiveType: (value: number) => void;
  activeType: number;
}

function TypesSelect({ setActiveType, activeType }: Props) {
  const promotionTypes = useTypedSelector(selectPromotionsTypesList);

  const [typesOptions, setTypesOptions] = useState<Array<OptionType>>(
    createTypesOptions(promotionTypes)
  );
  const [selectedTypes, setSelectedTypes] = useState<OptionType>();

  function createTypesOptions(list: Array<PromotionListTypeItem>) {
    const optionList = [{ label: 'Все акции', value: '0' }];

    list.forEach((listItem) => {
      let newItem: OptionType = { label: '', value: '' };
      newItem.label = listItem.name ?? '';
      newItem.value = String(listItem.id) ?? '';
      optionList.push(newItem);
    });

    return optionList;
  }

  const sortTypesValue = useMemo<OptionType>(
    () =>
      typesOptions.find((option) => option.value === selectedTypes?.value) ??
      typesOptions[0],
    [selectedTypes, typesOptions]
  );

  function handlesTypesChange(selectedOption: OptionType | null): void {
    if (selectedOption) {
      setSelectedTypes(selectedOption);

      if (selectedOption.value === '0') {
        setActiveType(0);
      } else {
        promotionTypes.forEach((type) => {
          if (type.id === Number(selectedOption.value)) {
            setActiveType(type.id);
          }
        });
      }
    }
  }

  useEffect(() => {
    setTypesOptions(createTypesOptions(promotionTypes));
  }, [promotionTypes]);

  useEffect(() => {
    typesOptions.forEach((type) => {
      if (type.value === String(activeType)) {
        setSelectedTypes(type);
      }
    });
  }, [activeType]);

  return (
    <Component>
      <DropDownSelect
        placeholder="Выбрать..."
        options={typesOptions}
        value={sortTypesValue}
        onChange={handlesTypesChange}
      />
    </Component>
  );
}

const Component = styled.div``;

export default TypesSelect;
