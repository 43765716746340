import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import SelectComponent from '@/components/Select';
import { ButtonLink } from '@/components/Button';
import { ButtonType, ShopsTypeItem } from '@/typings/model';
import { useTypedSelector } from '@/store/store';
import { selectShopByCitiesList } from '@/store/reducers/shopsCities';
import { selectShopsTypeByCities } from '@/store/reducers/shopsTypeByCities';
import Link from '@/components/Link';

interface Props {
  nearShopsLabelSelectText: string;
  nearShopsButton: ButtonType;
}

function FindBox({ nearShopsLabelSelectText, nearShopsButton }: Props) {
  const shopsByCitiesList = useTypedSelector(selectShopByCitiesList);
  const shopTypeByCitiesList = useTypedSelector(selectShopsTypeByCities);

  const [activeCity, setActiveCity] = useState(-1);
  const [activeType, setActiveType] = useState<string>('');
  const [activeAddress, setActiveAddress] = useState<number>(-1);

  const [isClearType, setClearType] = useState<boolean>(false);
  const [isClearAddress, setClearAddress] = useState<boolean>(false);
  const [isDisabledType, setDisabledType] = useState<boolean>(true);
  const [isDisabledAddress, setDisabledAddress] = useState<boolean>(true);
  const [isDisabledBtn, setDisabledBtn] = useState<boolean>(true);

  const [shopTypes, setShopTypes] = useState<
    Array<{
      name: string;
      shops: Array<ShopsTypeItem>;
    }>
  >([]);

  const [addressList, setAddressList] = useState<
    Array<{ name: string; id: number }>
  >([]);

  const [shopSearch, setShopSearch] = useState<ShopsTypeItem | null>(null);
  const [shopList, setShopList] = useState<Array<ShopsTypeItem>>();

  useEffect(() => {
    if (activeCity < 0) return;

    shopTypeByCitiesList.forEach((city) => {
      if (city.id === activeCity) {
        setShopTypes(city.shopTypes);
      }
    });

    setDisabledType(false);
    setDisabledAddress(true);
    setDisabledBtn(true);

    setActiveType('');
    setActiveAddress(-1);
    setClearType(true);
    setClearAddress(true);
    setShopSearch(null);

    setTimeout(() => {
      setClearType(false);
      setClearAddress(false);
    }, 100);
  }, [activeCity]);

  useEffect(() => {
    if (!activeType) return;
    shopTypes?.map((shopTypesItem) => {
      if (shopTypesItem.name === activeType) {
        setShopList(shopTypesItem.shops);

        const newAddressList: Array<{ name: string; id: number }> = [];

        shopTypesItem.shops.map((shop) => {
          const newAddress = {
            id: -1,
            name: '',
          };
          newAddress.id = shop.id;
          newAddress.name = shop.address ?? '';
          newAddressList.push(newAddress);
        });
        setAddressList(newAddressList);

        setDisabledAddress(false);
        setDisabledBtn(true);
        setActiveAddress(-1);
        setClearAddress(true);
        setShopSearch(null);

        setTimeout(() => {
          setClearAddress(false);
        }, 100);
      }
    });
  }, [activeType]);

  useEffect(() => {
    if (activeAddress < 0) {
      setShopSearch(null);
      setDisabledBtn(true);
    } else {
      shopList?.map((shop) => {
        if (shop.id === activeAddress) {
          setShopSearch(shop);
          setDisabledBtn(false);
        }
      });
      setDisabledBtn(false);
    }
  }, [activeAddress]);

  return (
    <Component>
      <Top>
        <SubTitle
          dangerouslySetInnerHTML={{
            __html: nearShopsLabelSelectText ?? '',
          }}
        />
      </Top>
      <List>
        <Cell>
          <SelectComponent
            optionList={shopsByCitiesList}
            activeValue={0}
            placeholder={'Город'}
            onChange={(value: number) => setActiveCity(value)}
            isWhite={true}
          />
        </Cell>
        <Cell>
          <SelectComponent
            optionList={shopTypes}
            activeValue={0}
            placeholder={'Тип магазина'}
            isDisabled={isDisabledType}
            onChange={(value: string) => setActiveType(value)}
            isLabel={true}
            isClearValue={isClearType}
            isWhite={true}
          />
        </Cell>
        <Cell>
          <SelectComponent
            optionList={addressList}
            activeValue={0}
            placeholder={'Адрес'}
            isDisabled={isDisabledAddress}
            onChange={(value: number) => setActiveAddress(value)}
            isClearValue={isClearAddress}
            isWhite={true}
          />
        </Cell>
      </List>
      <Bottom>
        <SearchBtn
          isDisabledBtn={isDisabledBtn}
          to={`/hypermarkets/shops/${shopSearch?.urlAlias}`}
        >
          {nearShopsButton?.label}
        </SearchBtn>
      </Bottom>
    </Component>
  );
}

const Component = styled.div`
  ${media.laptop(css`
    margin-left: 10px;
  `)}

  ${media.tablet(css`
    margin-left: 0;
  `)}
`;

const Top = styled.div`
  margin-bottom: 51px;

  ${media.laptop(css`
    margin-bottom: 36px;
  `)}

  ${media.mobile(css`
    margin-bottom: 30px;
  `)}
`;

const Bottom = styled.div`
  margin-top: 58px;

  ${media.laptop(css`
    margin-top: 32px;
  `)}
`;

const List = styled.div``;

const Cell = styled.div`
  margin-bottom: 56px;

  ${media.laptop(css`
    margin-bottom: 28px;
  `)}
`;

const SubTitle = styled.div`
  margin: 0;
  font-weight: 500;
  font-size: 54px;
  line-height: 64px;
  color: ${colors.white};

  b {
    font-weight: 500;
    color: ${colors.orange};
  }

  ${media.laptop(css`
    font-size: 40px;
    line-height: 48px;
  `)}

  ${media.tablet(css`
    font-size: 28px;
    line-height: 34px;
  `)}
  
  ${media.mobile(css`
    font-size: 22px;
    line-height: 28px;
  `)}
`;

const SearchBtn = styled(Link)<{ isDisabledBtn?: boolean }>`
  background-color: ${colors.orange};
  color: ${colors.white};

  &:hover {
    background-color: ${colors.orangeHover};
  }
  &:active {
    background-color: ${colors.orangeActive};
  }

  display: block;
  position: relative;
  width: 100%;
  text-align: center;
  min-height: 66px;
  padding: 20px 0;
  transition: all 0.3s linear;

  font-weight: 500;
  font-size: 20px;
  line-height: 26px;

  ${media.laptop(css`
    min-height: 46px;
    padding: 11px 0;
    font-size: 16px;
    line-height: 24px;
  `)}

  ${(props) =>
    props.isDisabledBtn
      ? css`
          pointer-events: none;
          background: ${colors.gray50};
          color: ${colors.black};
        `
      : ''}
`;

export default FindBox;
