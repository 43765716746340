import React from 'react';
import styled, { css } from 'styled-components';

import { MenuCategoriesItemType, StringField } from '@/typings/model';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import InformationLabel from '@/components/InformationLabel';
import StoreItem from '@/modules/ShoppingCenterSingle/components/Hero/components/StoreItem';

interface Props {
  name: StringField;
  address: StringField;
  city: StringField;
  menuTypes: Array<MenuCategoriesItemType>;
  menuUrl: StringField;
  activeMenu: number;
  setActiveMenu: (value: number) => void;
}

function Hero({
  name,
  address,
  city,
  menuTypes,
  menuUrl,
  activeMenu,
  setActiveMenu,
}: Props) {
  return (
    <Section>
      <Inner>
        <Left>
          <Title>{name}</Title>
          <Address>
            {city}, {address}
          </Address>
        </Left>
        <Right>
          <List>
            {menuTypes?.map((menuCategoriesItem, index) => {
              return (
                <Cell key={index} onClick={() => setActiveMenu(index + 1)}>
                  <StoreItem
                    label={menuCategoriesItem.name}
                    url={`${menuUrl}?menu=${menuCategoriesItem.name}`}
                    hover={'red'}
                    isPointerHidden={true}
                    isActive={activeMenu - 1 === index}
                  />
                </Cell>
              );
            })}
          </List>
        </Right>
      </Inner>
    </Section>
  );
}

const Section = styled.div`
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
`;

const Inner = styled.div`
  max-width: 1680px;
  margin: 0 auto;
  padding: 97px 40px 63px;
  display: flex;
  justify-content: space-between;

  ${media.laptop(css`
    padding: 51px 40px 28px;
  `)}

  ${media.tablet(css`
    display: block;
    padding: 30px 20px;
  `)}
  
  ${media.mobile(css`
    padding: 20px;
  `)}
`;

const Left = styled.div`
  flex: 1 1 512px;
  max-width: 512px;
  margin-right: 30px;

  ${media.tablet(css`
    max-width: 100%;
    margin: 0 0 30px;
  `)}

  ${media.mobile(css`
    margin-bottom: 50px;
  `)}
`;

const Right = styled.div`
  flex: 1 1 1000px;
  max-width: 1000px;

  ${media.tablet(css`
    max-width: 100%;
  `)}
`;

const Title = styled.h2`
  margin: 0 0 32px;
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.black};

  ${media.laptop(css`
    margin-bottom: 15px;
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    margin-bottom: 20px;
    font-size: 48px;
    line-height: 56px;
  `)}
  
  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
  `)}
`;

const Address = styled.address`
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  color: ${colors.black};

  ${media.laptop(css`
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    font-size: 20px;
    line-height: 24px;
  `)}
  
  ${media.mobile(css`
    font-size: 16px;
    line-height: 20px;
  `)}
`;

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -11px;

  ${media.laptop(css`
    margin: -8px;
  `)}
`;

const Cell = styled.div`
  margin: 11px;

  ${media.laptop(css`
    margin: 8px;
  `)}
`;

export default Hero;
