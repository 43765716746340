import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { ImageThumbnail, StringField } from '@/typings/model';

import BrandsCard from './components/BrandsCard';

interface Props {
  title: StringField;
  list: Array<{
    image: ImageThumbnail;
  }>;
}

function Brands({ title, list }: Props) {
  return (
    <Component>
      <Inner>
        <Top>
          <Title>{title}</Title>
        </Top>
        <List>
          {list?.slice(0, 6).map((brand, index) => {
            return (
              <Cell key={index}>
                <BrandsCard image={brand.image} />
              </Cell>
            );
          })}
        </List>
      </Inner>
    </Component>
  );
}

const Component = styled.div``;

const Inner = styled.div`
  max-width: 1680px;
  margin: 0 auto;
  padding: 0 40px;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

const Top = styled.div`
  margin-bottom: 34px;

  ${media.laptop(css`
    margin-bottom: 24px;
  `)}

  ${media.tablet(css`
    margin-bottom: 36px;
  `)}
  
  ${media.mobile(css`
    margin-bottom: 42px;
  `)}
`;

const List = styled.div`
  display: flex;
  margin: 0 -16px;

  ${media.laptop(css`
    margin: 0 -12px;
  `)}

  ${media.tablet(css`
    flex-wrap: wrap;
    margin: -11px;
  `)}
  
  ${media.mobile(css`
    margin: -5px -10px;
  `)}
`;

const Title = styled.h3`
  margin: 0;
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.black};

  ${media.laptop(css`
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    font-size: 48px;
    line-height: 56px;
  `)}
  
  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
  `)}
`;

const Cell = styled.div`
  flex: 1 1 16.67%;
  max-width: 16.67%;
  padding: 0 16px;

  ${media.laptop(css`
    padding: 0 12px;
  `)}

  ${media.tablet(css`
    flex: 1 1 33.33%;
    max-width: 33.33%;
    padding: 11px;
  `)}
  
  ${media.mobile(css`
    flex: 1 1 50%;
    max-width: 50%;
    padding: 5px 10px;
  `)}
`;

export default Brands;
