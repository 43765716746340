import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { gsap } from 'gsap';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import SectionPreview from '@/modules/Home/components/SectionPreview';
import Picture from '@/components/Picture';
import phonePreviewUrl from '@/assets/images/special-offers/phone.png';
import phonePreviewUrl2x from '@/assets/images/special-offers/phone@2x.png';
import phonePreviewUrlWebp from '@/assets/images/special-offers/phone.webp';
import phonePreviewUrlWebp2x from '@/assets/images/special-offers/phone@2x.webp';
import quoteUrl from '@/assets/images/special-offers/quote.png';
import quoteUrl2x from '@/assets/images/special-offers/quote@2x.png';
import quoteUrlWebp from '@/assets/images/special-offers/quote.webp';
import quoteUrlWebp2x from '@/assets/images/special-offers/quote@2x.webp';
import { ImageThumbnail, StringField } from '@/typings/model';
import Offers from '@/modules/Home/components/SpecialsOffers/components/Offers';
import Star from '@/components/Sticker/Star';

gsap.registerPlugin(ScrollTrigger);

type Props = {
  discountTitle: StringField;
  discountButtonLabel: StringField;
  discountButtonUrl: StringField;
  discountBlockquote: StringField;
  discountImage?: ImageThumbnail;
  discountStickerLabel: StringField;
  discountStickerUrl: StringField;
  specialOffers?: Array<{
    image: {
      fifth: ImageThumbnail;
      first: ImageThumbnail;
      fourth: ImageThumbnail;
      second: ImageThumbnail;
      sixth: ImageThumbnail;
      third: ImageThumbnail;
    };
    title: StringField;
    link: StringField;
    design: StringField;
  }>;
};

function Discount({
  discountTitle,
  discountImage,
  discountButtonUrl,
  discountButtonLabel,
  discountBlockquote,
  discountStickerUrl,
  discountStickerLabel,
  specialOffers,
}: Props) {
  const starRef = useRef<HTMLDivElement | null>(null);
  const pigRef = useRef<HTMLDivElement | null>(null);
  const quoteRef = useRef<HTMLDivElement | null>(null);

  const starTween = useRef<gsap.core.Tween>();

  useEffect(() => {
    if (!starRef.current) {
      return;
    }
    const delayedCall = gsap.delayedCall(0, () => {
      ScrollTrigger.matchMedia({
        '(max-width: 1680px)': () => {
          starTween.current = gsap.to(starRef.current, {
            x: -50,
            rotate: 0,
            paused: true,
          });
        },
      });
    });

    return () => {
      starTween.current?.kill();
      delayedCall.kill();
    };
  }, []);

  function animateIn() {
    starTween.current?.play();
  }

  function animateOut() {
    starTween.current?.reverse();
  }

  useEffect(() => {
    if (!pigRef.current) {
      return;
    }
    let timeline: gsap.core.Timeline;
    const set = gsap.set(pigRef.current, { opacity: 0, bottom: 230 });
    const delayedCall = gsap.delayedCall(0, () => {
      if (!pigRef.current) {
        return;
      }
      timeline = gsap
        .timeline({
          scrollTrigger: {
            trigger: pigRef.current,
            toggleActions: 'restart none none reset',
          },
        })
        .to(pigRef.current, { opacity: 1 })
        .to(
          pigRef.current,
          {
            y: 180,
            duration: 1.5,
          },
          '-=0.3'
        );
    });

    return () => {
      set.kill();
      timeline?.kill();
      delayedCall.kill();
    };
  }, []);

  useEffect(() => {
    if (!pigRef.current || !quoteRef.current) {
      return;
    }
    let timeline: gsap.core.Timeline;
    const set = gsap.set(quoteRef.current, { opacity: 0 });
    const delayedCall = gsap.delayedCall(0, () => {
      if (!pigRef.current || !quoteRef.current) {
        return;
      }
      timeline = gsap
        .timeline({
          scrollTrigger: {
            trigger: pigRef.current,
            toggleActions: 'restart none none reset',
          },
        })
        .to(quoteRef.current, { opacity: 1 })
        .to(
          quoteRef.current,
          {
            rotate: 0,
            duration: 1.5,
          },
          '-=0.3'
        );
    });

    return () => {
      set.kill();
      timeline?.kill();
      delayedCall.kill();
    };
  }, [discountImage]);

  return (
    <Component>
      <Inner>
        <Left>
          <LeftOffers>
            {specialOffers?.map((offersItem, index) => {
              if (index !== 1) return false;
              return (
                <Offers
                  key={index}
                  image={
                    offersItem.image
                      ? offersItem.image.second
                        ? offersItem.image.second
                        : null
                      : null
                  }
                  title={offersItem.title}
                  link={offersItem.link}
                  design="big"
                />
              );
            })}
          </LeftOffers>

          <Preview>
            <Top>
              <SectionPreview
                title={discountTitle}
                buttonLabel={discountButtonLabel}
                buttonUrl={discountButtonUrl}
              />
            </Top>
            <Bottom>
              <BottomBackground>
                <Picture
                  mobileSmall={{
                    src: phonePreviewUrl,
                    src2x: phonePreviewUrl2x,
                    webp: phonePreviewUrlWebp,
                    webp2x: phonePreviewUrlWebp2x,
                  }}
                />
              </BottomBackground>
              <Quote ref={quoteRef}>
                <QuoteInner>
                  <QuoteImage>
                    <Picture
                      mobileSmall={{
                        src: quoteUrl,
                        src2x: quoteUrl2x,
                        webp: quoteUrlWebp,
                        webp2x: quoteUrlWebp2x,
                      }}
                    />
                  </QuoteImage>
                  <QuoteContent
                    dangerouslySetInnerHTML={{
                      __html: discountBlockquote ?? '',
                    }}
                  />
                </QuoteInner>
              </Quote>
            </Bottom>
            <PiggyBank ref={pigRef}>
              {discountImage && (
                <PiggyBankImage>
                  <Picture
                    mobileSmall={convertThumbnailToPictureImage(discountImage)}
                  />
                </PiggyBankImage>
              )}
            </PiggyBank>
          </Preview>
        </Left>
        <Right>
          {specialOffers ? (
            <RightOffers>
              <Offers
                image={
                  specialOffers[2].image
                    ? specialOffers[2].image.third
                      ? specialOffers[2].image.third
                      : null
                    : null
                }
                title={specialOffers[2].title ?? ''}
                link={specialOffers[2].link ?? '#'}
                design="middle"
              />
              <Offers
                image={
                  specialOffers[3].image
                    ? specialOffers[3].image.fourth
                      ? specialOffers[3].image.fourth
                      : null
                    : null
                }
                title={specialOffers[3].title ?? ''}
                link={specialOffers[3].link ?? '#'}
                design="small"
              />
              <Offers
                image={
                  specialOffers[4].image
                    ? specialOffers[4].image.fifth
                      ? specialOffers[4].image.fifth
                      : null
                    : null
                }
                title={specialOffers[4].title ?? ''}
                link={specialOffers[4].link ?? '#'}
                design="big"
                isLeft={true}
              />
            </RightOffers>
          ) : null}
          <StarWrapper
            ref={starRef}
            onMouseEnter={animateIn}
            onMouseLeave={animateOut}
          >
            <Star
              color="yellow"
              size="big"
              label={discountStickerLabel ?? ''}
              link={discountStickerUrl ?? ''}
              rotate="15deg"
            />
          </StarWrapper>
        </Right>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  background: ${colors.black};
  position: relative;
`;

const Inner = styled.div`
  display: flex;
  position: relative;
`;

const Left = styled.div`
  flex: 1 1 52%;
  margin-top: -160px;

  ${media.laptop(css`
    margin-top: -136px;
  `)}
`;
const Right = styled.div`
  flex: 1 1 50%;
  margin-top: -346px;
  position: relative;

  ${media.laptop(css`
    margin-top: -240px;
  `)}
`;

const RightOffers = styled.div``;
const LeftOffers = styled.div``;

const Preview = styled.div`
  position: relative;
  margin-top: 148px;

  ${media.laptop(css`
    margin-top: 79px;
  `)}
`;

const Top = styled.div`
  max-width: 680px;
  position: relative;
  z-index: 3;

  ${media.laptop(css`
    max-width: 532px;
  `)}
`;

const Bottom = styled.div`
  position: relative;
  margin-top: -22px;
  ${media.laptop(css`
    padding-top: 80px;
    margin: 0 -12px 0 -46px;
  `)}
`;
const BottomBackground = styled.div`
  position: relative;
  left: -20px;
`;

const PiggyBank = styled.div`
  position: absolute;
  content: '';
  bottom: 50px;
  left: -36px;
  width: 100%;
  ${media.laptop(css`
    // bottom: -52px;
    // bottom: 175px;
    left: -67px;
  `)}
`;
const PiggyBankImage = styled.div`
  ${media.laptop(css`
    img {
      max-width: 120%;
    }
  `)}
`;

const Quote = styled.div`
  position: absolute;
  content: '';
  top: 0;
  right: 32px;
  transform: rotate(30deg);

  ${media.laptop(css`
    right: 37px;
  `)}
`;
const QuoteInner = styled.div`
  position: relative;
  padding: 54px 60px 50px 70px;
  min-width: 290px;
  height: 214px;

  ${media.laptop(css`
    padding: 54px 64px 50px;
    min-width: 284px;
  `)}
`;
const QuoteImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;
const QuoteContent = styled.div`
  position: absolute;
  top: 30%;
  left: 45px;
  width: 100%;
  height: 100%;
  z-index: 2;
  max-width: 200px;
  text-align: center;

  display: flex;
  justify-content: center;

  font-size: 24px;
  line-height: 32px;
  color: ${colors.white};

  ${media.laptop(css`
    left: 42px;
  `)}
`;

const StarWrapper = styled.div`
  position: absolute;
  top: -136px;
  right: -135px;
  z-index: 2;
  will-change: transform;

  width: 316px;
  height: 316px;

  ${media.laptop(css`
    top: -126px;
    right: -111px;
    width: 240px;
    height: 240px;
  `)}
`;

export default Discount;
