import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { TradeCenterItemType } from '@/typings/model';
import { ReactComponent as ClockIcon } from '@/assets/svg/clock.svg';
import { ReactComponent as InfoIcon } from '@/assets/svg/info.svg';
import Picture from '@/components/Picture';

interface Props {
  shopItem: TradeCenterItemType;
  setActiveShop: (shop: TradeCenterItemType) => void;
}

function ShopsItem({ shopItem, setActiveShop }: Props) {
  const { name, address, image, phone, timetable } = shopItem;
  return (
    <Component onClick={() => setActiveShop(shopItem)}>
      <Inner>
        <Top>
          <Image>
            {image && (
              <Picture mobileSmall={convertThumbnailToPictureImage(image)} />
            )}
          </Image>
        </Top>
        <Bottom>
          <Name>{name}</Name>
          <Address>{address}</Address>
          <Row>
            <ClockIcon />
            {timetable}
          </Row>
          <Row>
            <InfoIcon />
            {phone}
          </Row>
        </Bottom>
      </Inner>
    </Component>
  );
}

const Inner = styled.div`
  position: relative;
`;

const Component = styled.div`
  width: 100% !important;
  height: 100%;
  border-radius: 32px 32px 0 0;
  background: ${colors.white};
  cursor: pointer;
  transition: all 0.3s ease-in-out 0.1s;

  ${media.laptop(css`
    max-width: 282px;
    border-radius: 24px 24px 0 0;
  `)}
`;

const Bottom = styled.div`
  padding: 14px 22px 22px;

  ${media.laptop(css`
    padding: 16px;
  `)}

  ${media.tablet(css`
    padding: 20px 16px;
  `)}
  
  ${media.mobile(css`
    padding: 20px 10px;
  `)}
`;

const Image = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s linear;

  div,
  picture {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Name = styled.h3`
  margin: 0 0 14px;
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  color: ${colors.black};

  ${media.laptop(css`
    margin-bottom: 9px;
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    margin-bottom: 8px;
    font-size: 20px;
    line-height: 24px;
  `)}
  
  ${media.mobile(css`
    font-size: 16px;
    line-height: 20px;
  `)}
`;

const Address = styled.address`
  margin-bottom: 14px;
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  line-height: 26px;
  color: ${colors.black};

  ${media.laptop(css`
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 24px;
  `)}

  ${media.tablet(css`
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    margin-bottom: 20px;
    font-size: 12px;
    line-height: 14px;
  `)}
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: ${colors.black};
  margin-bottom: 10px;

  ${media.laptop(css`
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
  `)}

  ${media.tablet(css`
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    font-size: 12px;
    line-height: 14px;
  `)}

  svg {
    flex: 0 0 32px;
    width: 32px;
    height: 32px;
    margin-right: 4px;

    ${media.laptop(css`
      flex: 0 0 24px;
      width: 24px;
      height: 24px;
      margin-right: 2px;
    `)}

    ${media.tablet(css`
      flex: 0 0 20px;
      width: 20px;
      height: 20px;
    `)}
    
    ${media.mobile(css`
      flex: 0 0 12px;
      width: 12px;
      height: 12px;
      margin-right: 6px;
    `)}
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const Top = styled.div`
  position: relative;
  border-radius: 32px;
  overflow: hidden;
  background: ${colors.grayLight};

  &:before {
    display: block;
    content: '';
    padding-bottom: 100%;
  }

  &:hover {
    ${Image} {
      transform: scale(1.1);
    }
  }

  ${media.laptop(css`
    border-radius: 24px;
  `)}
`;

export default ShopsItem;
