import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { ImageThumbnail, StringField } from '@/typings/model';
import { media } from '@/utils/mixin';

import NewsCard from './NewsCard';

type Props = {
  newsList?: Array<{
    image: {
      landscape: ImageThumbnail;
      portrait: ImageThumbnail;
    };
    title: StringField;
    link: StringField;
  }>;
};

const NewsList = forwardRef<HTMLDivElement, Props>(function NewsList(
  { newsList }: Props,
  ref
) {
  return (
    <Components ref={ref}>
      {newsList?.slice(2).map((newsItem, index) => {
        return (
          <Cell key={index}>
            <NewsCard newsItem={newsItem} />
          </Cell>
        );
      })}
    </Components>
  );
});

const Components = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -25px;

  ${media.laptop(css`
    margin: -20px -34px -20px -19px;
  `)}

  ${media.tabletSmall(css`
    margin: -10px;
  `)}
`;
const Cell = styled.div`
  flex: 1 1 50%;
  max-width: 50%;
  padding: 25px;

  ${media.laptop(css`
    padding: 20px 19px;
  `)}

  ${media.tabletSmall(css`
    padding: 10px;
  `)}
`;

export default NewsList;
