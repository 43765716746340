import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { ButtonType } from '@/typings/model';

import Link from '../Link';

interface Props {
  backgroundLine: string;
  colorLine: string;
  fieldLine?: ButtonType;
}

function InformationLine({ backgroundLine, colorLine, fieldLine }: Props) {
  return (
    <Component backgroundLine={backgroundLine} colorLine={colorLine}>
      <ComponentLink to={`${fieldLine?.link}`} />

      <Inner>
        <Text>{fieldLine?.label}</Text>
      </Inner>
    </Component>
  );
}

const Component = styled.div<{ backgroundLine: string; colorLine: string }>`
  background: ${(props) => `${props.backgroundLine}`};
  color: ${(props) => `${props.colorLine}`};
  position: relative;
  transition: all 0.3s linear;

  &:hover {
    opacity: 0.95;
  }
`;

const ComponentLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;

  ${media.laptop(css`
    height: 58px;
  `)}

  ${media.mobile(css`
    min-height: 58px;
    height: auto;
    padding: 8px 0;
  `)}
`;

const Text = styled.span`
  font-weight: 600;
  font-size: 30px;
  line-height: 26px;
  text-transform: uppercase;

  ${media.laptop(css`
    font-size: 20px;
    line-height: 26px;
  `)}

  ${media.tablet(css`
    font-size: 18px;
    line-height: 24px;
  `)}
  
  ${media.mobile(css`
    font-size: 22px;
    line-height: 120%;
    text-align: center;
  `)}
`;

export default InformationLine;
