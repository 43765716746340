import React from 'react';
import styled from 'styled-components';

import Breadcrumbs from '@/components/Breadcrumbs';
import { useLayout } from '@/components/Layout/Layout.hooks';
import useCurrentChildrenPages from '@/hooks/useCurrentChildrenPages';
import useCurrentPage from '@/hooks/useCurrentPage';
import { BreadCrumbs } from '@/typings/common';
import { setBreadCrumbsList } from '@/utils/common';

import MagazinesList from './components/MagazinesList';

function Magazines() {
  const page = useCurrentPage();
  const { data: magazinesList } = useCurrentChildrenPages('magazines-single');

  const { setThemePage } = useLayout();
  setThemePage('');

  const BREADCRUMB_LIST: Array<BreadCrumbs> = setBreadCrumbsList(
    {
      title: page?.title ?? '',
      path: page?.path ?? '',
    },
    { id: 2, title: 'Гипермаркет', path: '/hypermarkets' }
  );

  return (
    <Wrapper>
      <Breadcrumbs breadcrumbs={BREADCRUMB_LIST} />
      <MagazinesList magazinesList={magazinesList} />
    </Wrapper>
  );
}

const Wrapper = styled.div``;

export default Magazines;
