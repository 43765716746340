import React from 'react';
import styled, { css } from 'styled-components';

import Breadcrumbs from '@/components/Breadcrumbs';
import { media } from '@/utils/mixin';
import useCurrentPage from '@/hooks/useCurrentPage';
import { HelpPageType } from '@/typings/model';
import { useLayout } from '@/components/Layout/Layout.hooks';
import { BreadCrumbs } from '@/typings/common';
import { setBreadCrumbsList } from '@/utils/common';

import Static from '../Post/components/Main/components/Static';
import Faq from '../Discount/components/Faq';

import Hero from './components/Hero';

function Help() {
  const page = useCurrentPage<HelpPageType>();
  const { setThemePage } = useLayout();
  setThemePage('');

  const BREADCRUMB_LIST: Array<BreadCrumbs> = setBreadCrumbsList(
    {
      title: page?.title ?? '',
      path: page?.path ?? '',
    },
    page?.parent
  );

  return (
    <Wrapper>
      <Breadcrumbs breadcrumbs={BREADCRUMB_LIST} />
      <Inner>
        <Hero title={page?.title ?? ''} />
        {page?.body ? (
          <StaticWrapper>
            <Static body={page?.body} />
          </StaticWrapper>
        ) : null}
        <FaqWrapper>
          <Faq
            faqTitle={page?.templateFields.faqTitle ?? ''}
            faqItems={page?.templateFields.faqItems}
          />
        </FaqWrapper>
      </Inner>
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const Inner = styled.div``;

const StaticWrapper = styled.div`
  margin-top: 60px;

  ${media.laptop(css`
    margin-top: 40px;
  `)}

  ${media.tablet(css`
    margin-top: 20px;
  `)}
`;

const FaqWrapper = styled.div`
  margin: 120px 0 187px;

  ${media.laptop(css`
    margin: 80px 0 142px;
  `)}

  ${media.tablet(css`
    margin: 60px 0 122px;
  `)}

  ${media.mobile(css`
    margin: 40px 0 122px;
  `)}
`;

export default Help;
