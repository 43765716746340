import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import NotificationsCard from '@/components/NotificationsCard';
import InformationBlock from '@/modules/OwnProduction/components/InformationBlock';
import Subscription from '@/components/Subscription';
import { colors } from '@/constants/theme';
import useCurrentPage from '@/hooks/useCurrentPage';
import { RestaurantsItem, RestaurantsPageType } from '@/typings/model';
import { media } from '@/utils/mixin';
import { getRestaurantsList } from '@/services/requests';
import { useLayout } from '@/components/Layout/Layout.hooks';

import TickerLineSection from '../ShoppingCenterSingle/components/TickerLineSection';

import Hero from './components/Hero';
import BannerSection from './components/BannerSection';
import OtherRestaurantsSection from './components/OtherRestaurantsSection';

function Restaurants() {
  const page = useCurrentPage<RestaurantsPageType>();
  const [restaurantsList, setRestaurantsList] = useState<
    Array<RestaurantsItem>
  >([]);

  const { setThemePage } = useLayout();
  useEffect(() => {
    getRestaurantsList(`filter[type]=${page?.templateFields.type}`).then(
      (response) => {
        if (!response.data) return;
        setRestaurantsList(response.data);
      }
    );

    setThemePage('#CD2544');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  if (!page) return <></>;
  const {
    newsBlockLeftBlocks,
    newsBlockRightTopImage,
    newsBlockRightTopLink,
    newsBlockRightTopText,
    newsBlockRightBottomImage,
    newsBlockRightBottomLink,
    newsBlockRightBottomText,
    bannerImage,
    bannerLink,
    bottomText,
    bottomTitle,
    advertisementImage,
    advertisementLink,
    advertisementText,
    advertisementTitle,
    tickers,
  } = page?.templateFields;

  return (
    <Wrapper>
      <Inner>
        <HeroWrapper>
          <Hero text={page?.excerpt} logo={page?.image} />
          <BannerSection
            newsBlockLeftBlocks={newsBlockLeftBlocks}
            newsBlockRightTopImage={newsBlockRightTopImage}
            newsBlockRightTopText={newsBlockRightTopText}
            newsBlockRightTopLink={newsBlockRightTopLink}
            newsBlockRightBottomImage={newsBlockRightBottomImage}
            newsBlockRightBottomText={newsBlockRightBottomText}
            newsBlockRightBottomLink={newsBlockRightBottomLink}
            restaurantsList={restaurantsList}
          />
        </HeroWrapper>

        {tickers?.length > 0 && <TickerLineSection tickers={tickers} />}

        {bannerImage && (
          <Notifications>
            <NotificationsCard image={bannerImage} link={bannerLink ?? ''} />
          </Notifications>
        )}

        {advertisementImage && advertisementTitle && (
          <InformationBlockWrapper>
            <InformationBlock
              image={advertisementImage}
              title={advertisementTitle}
              text={advertisementText}
              link={advertisementLink}
            />
          </InformationBlockWrapper>
        )}

        {restaurantsList.length > 0 && (
          <OtherRestaurantsSection
            title={bottomTitle}
            text={bottomText}
            restaurantsList={restaurantsList}
          />
        )}
      </Inner>
      <Subscription background={colors.gray50} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  overflow: hidden;
`;

const Inner = styled.div`
  padding: 128px 0 242px;

  ${media.laptop(css`
    padding: 108px 0 242px;
  `)}

  ${media.tablet(css`
    padding: 64px 0 108px;
  `)}

  ${media.mobile(css`
    padding: 50px 0 126px;
  `)}
`;

const HeroWrapper = styled.div`
  ${media.tablet(css`
    position: relative;
    min-height: 498px;
  `)}

  ${media.mobile(css`
    min-height: auto;
  `)}
`;

const Notifications = styled.div`
  max-width: 1680px;
  margin: 0 auto;
  padding: 70px 40px 0;

  ${media.laptop(
    css`
      padding-top: 53px;
    `
  )}

  ${media.tablet(css`
    padding: 53px 20px 0;
  `)}

  ${media.mobile(css`
    padding: 30px 1px 0;
  `)}
`;

const InformationBlockWrapper = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  padding: 80px 0 0;

  ${media.laptop(
    css`
      padding-top: 40px;
    `
  )}
`;

export default Restaurants;
