import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { gsap } from 'gsap';

export const LocomotiveScroll: React.FC = ({ children }) => {
  const scrollerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!scrollerRef.current) return;

    const LocomotiveScroll = require('locomotive-scroll').default;

    const locomotiveScroll = new LocomotiveScroll({
      el: scrollerRef.current,
      // multiplier: 0.85,
      // firefoxMultiplier: 40,
      // lerp: 0.08,
      smooth: true,
    });

    locomotiveScroll.on('scroll', () => ScrollTrigger.update());

    ScrollTrigger.scrollerProxy(scrollerRef.current, {
      scrollTop(value) {
        return arguments.length > 0 && value !== undefined
          ? locomotiveScroll.scrollTo(value)
          : locomotiveScroll.scroll.instance.scroll.y;
      },
      getBoundingClientRect() {
        return {
          top: 0,
          left: 0,
          width: window.innerWidth,
          height: window.innerHeight,
        };
      },
    });

    function killScrollTriggerInstances() {
      gsap.killTweensOf(window);
      ScrollTrigger.getAll().forEach((instance) => {
        return instance.kill(true);
      });
      locomotiveScroll.destroy();
    }

    function handleScrollTriggerRefresh() {
      locomotiveScroll.update();
    }

    ScrollTrigger.addEventListener('refresh', handleScrollTriggerRefresh);
    ScrollTrigger.refresh();

    ScrollTrigger.defaults({ scroller: scrollerRef.current });

    return () => {
      ScrollTrigger.removeEventListener('refresh', handleScrollTriggerRefresh);
      killScrollTriggerInstances();
      ScrollTrigger.defaults({ scroller: 'body' });
    };
  }, []);

  return <Scroller ref={scrollerRef}>{children}</Scroller>;
};

const Scroller = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;
