import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import Breadcrumbs from '@/components/Breadcrumbs';
import useCurrentPage from '@/hooks/useCurrentPage';
import { RestaurantsItem, RestaurantsMenuPageType } from '@/typings/model';
import OtherRestaurantsSection from '@/modules/Restaurants/components/OtherRestaurantsSection';
import Subscription from '@/components/Subscription';
import { colors } from '@/constants/theme';
import { getRestaurantsList, getRestaurantsView } from '@/services/requests';
import { useLayout } from '@/components/Layout/Layout.hooks';
import { media } from '@/utils/mixin';
import { BreadCrumbs } from '@/typings/common';
import { setBreadCrumbsList } from '@/utils/common';

import Hero from './components/Hero';
import Menu from './components/Menu';

function RestaurantsMenu() {
  const page = useCurrentPage<RestaurantsMenuPageType>();
  const { setThemePage } = useLayout();

  const [restaurantsList, setRestaurantsList] = useState<
    Array<RestaurantsItem>
  >([]);
  const [activeRestaurants, setActiveRestaurants] = useState<RestaurantsItem>();
  const [activeMenu, setActiveMenu] = useState<number>(0);

  useEffect(() => {
    getRestaurantsView(page?.templateFields?.model_id).then((response) => {
      if (!response.data) return;
      setActiveRestaurants(response.data);
    });

    setThemePage('#CD2544');
  }, []);

  useEffect(() => {
    getRestaurantsList(`filter[type]=${activeRestaurants?.type}`).then(
      (response) => {
        if (!response.data) return;
        setRestaurantsList(response.data);
      }
    );
  }, [activeRestaurants]);

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let activeIndex = 0;

    activeRestaurants?.menu.forEach((menuItem, index) => {
      if (params.get('menu') === menuItem.name) {
        activeIndex = index + 1;
      }
    });

    activeIndex === 0 ? setActiveMenu(1) : setActiveMenu(activeIndex);
  }, [activeMenu, activeRestaurants]);

  const BREADCRUMB_LIST: Array<BreadCrumbs> = setBreadCrumbsList(
    {
      title: page?.title ?? '',
      path: page?.path ?? '',
    },
    page?.parent
  );

  function getOtherRestaurants(
    activeRestaurants: RestaurantsItem | undefined,
    restaurantsList: Array<RestaurantsItem>
  ) {
    if (!activeRestaurants) return restaurantsList;

    const newRestaurantsList: Array<RestaurantsItem> = [];

    restaurantsList.forEach((restaurants) => {
      if (
        restaurants.city === activeRestaurants?.city &&
        restaurants.address === activeRestaurants?.address
      )
        return;

      newRestaurantsList.push(restaurants);
    });

    return newRestaurantsList;
  }

  if (!page?.templateFields) return null;

  return (
    <Wrapper>
      <Inner>
        <Breadcrumbs breadcrumbs={BREADCRUMB_LIST} />
        <Hero
          name={page?.title}
          address={activeRestaurants?.address ?? ''}
          city={activeRestaurants?.city ?? ''}
          menuTypes={activeRestaurants?.menu ?? []}
          menuUrl={activeRestaurants?.menuUrl ?? ''}
          activeMenu={activeMenu}
          setActiveMenu={(value: number) => setActiveMenu(value)}
        />

        <Menu menu={activeRestaurants?.menu[activeMenu - 1].categories ?? []} />
        <OtherRestaurantsSection
          title={
            page.parent?.path.startsWith('/amsterdam')
              ? 'Другие кафе/рестораны «Амстердам»'
              : 'Другие рестораны Пит STOP'
          }
          restaurantsList={getOtherRestaurants(
            activeRestaurants,
            restaurantsList
          )}
        />
      </Inner>
      <Subscription background={colors.gray50} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  overflow: hidden;
`;

const Inner = styled.div`
  padding-bottom: 242px;

  ${media.tablet(css`
    padding-bottom: 106px;
  `)}

  ${media.mobile(css`
    padding-bottom: 127px;
  `)}
`;

export default RestaurantsMenu;
