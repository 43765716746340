import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import BannerCard from '@/modules/Home/components/BannerCard';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import {
  ImageThumbnail,
  ShoppingCenterSinglePageType,
  StringField,
} from '@/typings/model';
import SalesSlider from '@/modules/Home/components/Hero/components/SalesSlider';

interface Props {
  shoppingCenter?: ShoppingCenterSinglePageType | null;
}

function BannerSection({ shoppingCenter }: Props) {
  if (!shoppingCenter?.templateFields) return <></>;

  const {
    newsBlockLeftTopImage,
    newsBlockLeftTopLink,
    newsBlockLeftTopText,
    newsBlockCenterBlocks,
    newsBlockLeftBottomImage,
    newsBlockLeftBottomLink,
    newsBlockLeftBottomText,
    newsBlockRightImage,
    newsBlockRightLabel,
    newsBlockRightLink,
    newsBlockRightTitle,
  } = shoppingCenter.templateFields;

  function getListLength(
    list: Array<{
      image: ImageThumbnail;
      link: StringField;
      title: StringField;
    }>
  ) {
    let listLength = 0;
    list.forEach((listItem) => {
      if (!listItem.image && !listItem.title && !listItem.link) return;
      listLength += 1;
    });
    return listLength;
  }

  return (
    <Component>
      <BannerContainer>
        <BannerInner>
          <BannerLeft>
            <BannerLeftCell>
              <BannerCard
                text={newsBlockLeftTopText}
                link={newsBlockLeftTopLink}
                image={newsBlockLeftTopImage}
                isLink={true}
              />
            </BannerLeftCell>
            <BannerLeftCell>
              <BannerCard
                text={newsBlockLeftBottomText}
                link={newsBlockLeftBottomLink}
                image={newsBlockLeftBottomImage}
                isLink={true}
              />
            </BannerLeftCell>
          </BannerLeft>
          <BannerCenter>
            {getListLength(newsBlockCenterBlocks) <= 1 ? (
              newsBlockCenterBlocks.map((salesItem) => {
                return (
                  <BannerCard
                    image={salesItem.image}
                    text={salesItem.title}
                    link={salesItem.link}
                  />
                );
              })
            ) : (
              <SalesSlider salesList={newsBlockCenterBlocks} />
            )}
          </BannerCenter>
          <BannerRight>
            <BannerCard
              title={newsBlockRightTitle}
              text={newsBlockRightLabel}
              link={newsBlockRightLink}
              image={newsBlockRightImage}
              isLink={true}
            />
          </BannerRight>
        </BannerInner>
      </BannerContainer>
    </Component>
  );
}

const Component = styled.div``;

const BannerContainer = styled.div`
  max-width: 1920px;
  margin: 0 auto;
`;

const BannerInner = styled.div`
  display: flex;
  height: calc(100vw / 100 * 49.12);
  overflow: hidden;

  @media (min-width: 1921px) {
    height: calc(1920px / 100 * 49.12);
  }

  ${media.laptop(css`
    height: calc(100vw / 100 * 50.31);
  `)}

  ${media.tablet(css`
    height: calc(100vw / 100 * 64.8);
  `)}
  
    ${media.mobile(css`
    height: calc(100vw / 100 * 70);
  `)}
  
    
  ${media.mobileLarge(css`
    height: calc(var(--vh, 1vh) * 100 - 196px);
  `)}
`;

const BannerLeft = styled.div`
  position: relative;
  flex: 1 1 449px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  ${media.laptop(css`
    flex: 1 1 322px;
    max-width: 322px;
  `)}

  ${media.tablet(css`
    display: none;
  `)}
`;

const BannerCenter = styled.div`
  position: relative;
  height: 100%;

  flex: 1 1 483px;
  max-width: 483px;

  ${media.laptop(css`
    flex: 1 1 322px;
    max-width: 322px;
  `)}

  ${media.tablet(css`
    flex: 1 1 248px;
    max-width: 32.3vw;
  `)}
  
  ${media.mobileLarge(css`
    flex: 1 1 100%;
    max-width: 450px;
    margin: 0 auto;
  `)}
  
  ${media.mobileMedium(css`
    flex: 1 1 100%;
    max-width: 100%;
  `)}
`;

const BannerRight = styled.div`
  position: relative;
  flex: 1 1 944px;
  max-width: 944px;
  background: ${colors.black};

  ${media.laptop(css`
    flex: 1 1 636px;
  `)}

  ${media.tablet(css`
    flex: 1 1 520px;
    max-width: 100%;
  `)}
  
  ${media.mobile(css`
    display: none;
  `)}
`;

const BannerLeftCell = styled.div`
  width: 100%;
  flex: 1 1 50%;
  &:last-child {
    border-radius: 30px;
    overflow: hidden;

    ${media.laptop(css`
      border-radius: 0;
    `)}
  }
`;

export default BannerSection;
