import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import Picture from '@/components/Picture';
import { ReactComponent as ArrowLink } from '@/assets/svg/arrow-link.svg';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { ImageThumbnail, StringField } from '@/typings/model';
import Link from '@/components/Link';

type Props = {
  newsItem: {
    image: {
      landscape: ImageThumbnail;
      portrait: ImageThumbnail;
    };
    title: StringField;
    link: StringField;
  };
  isAside?: boolean;
};

function NewsCard({ newsItem, isAside }: Props) {
  const { image, link, title } = newsItem;

  return (
    <Component isAside={isAside}>
      {link && <NewsCardLink to={link ?? '#'} />}
      {image ? (
        isAside ? (
          <NewsCardBackground>
            {image.portrait ? (
              <Picture
                mobileSmall={convertThumbnailToPictureImage(image.portrait)}
              />
            ) : null}
          </NewsCardBackground>
        ) : (
          <NewsCardBackground>
            {image.landscape ? (
              <Picture
                mobileSmall={convertThumbnailToPictureImage(image.landscape)}
              />
            ) : null}
          </NewsCardBackground>
        )
      ) : null}

      <NewsCardInner>
        <NewsCardTextWrapper>
          <NewsCardText>{title}</NewsCardText>
        </NewsCardTextWrapper>
        {link ? (
          <NewsCardLinkPointer isAside={isAside}>
            <ArrowLink />
          </NewsCardLinkPointer>
        ) : null}
      </NewsCardInner>
    </Component>
  );
}

const NewsCardLinkPointer = styled.div<{ isAside?: boolean }>`
  width: 54px;
  height: 54px;
  border-radius: 100%;
  background: ${colors.white};

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  bottom: 30px;
  right: 30px;
  transition: all 0.3s linear;

  svg {
    pointer-events: none;
  }

  ${media.laptop(css`
    width: 36px;
    height: 36px;
    bottom: 16px;
    right: 16px;

    svg {
      width: 16px;
      height: 14px;
    }
  `)}

  ${media.tabletSmall(css`
    display: none;
  `)}

  ${(props) =>
    props.isAside
      ? css`
          display: none;
        `
      : ''}
`;

const NewsCardBackground = styled.div`
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  div,
  picture {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    will-change: transform;
    transition: all 0.3s linear !important;

    ${media.mobile(css`
      object-fit: cover;
    `)}
  }
`;

const NewsCardLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
`;

const NewsCardInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  padding: 0 30px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 1;

  ${media.laptop(css`
    padding: 16px;
  `)}

  ${media.tabletSmall(css`
    padding: 20px;
  `)}
`;

const NewsCardTextWrapper = styled.div`
  max-width: 86%;

  ${media.tabletSmall(css`
    max-width: 100%;
  `)}
`;

const NewsCardText = styled.span`
  background: ${colors.white};
  padding: 4px 10px;
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
  color: ${colors.black};

  -webkit-box-decoration-break: clone;
  -o-box-decoration-break: clone;
  box-decoration-break: clone;

  ${media.laptop(css`
    padding: 1px 4px;
    font-size: 20px;
    line-height: 28px;
  `)}

  ${media.tabletSmall(css`
    padding: 0 4px;
    font-size: 18px;
    line-height: 24px;
  `)}
`;

const Component = styled.div<{ isAside?: boolean }>`
  position: relative;

  &:before {
    display: block;
    content: '';
    padding-bottom: 66.5%;
  }

  ${media.laptop(css`
    &:before {
      padding-bottom: 61.4%;
    }
  `)}
  ${media.tabletSmall(css`
    &:before {
      padding-bottom: 97.8%;
    }
  `)}
  &:hover {
    ${NewsCardBackground} {
      img {
        transform: scale(1.15) translate3d(0, 0, 0);
      }
    }
  }

  ${(props) =>
    props.isAside
      ? css`
          &:before {
            padding-bottom: 162.2%;
          }

          ${NewsCardTextWrapper} {
            max-width: 100%;
          }

          ${NewsCardInner} {
            height: 102%;
          }

          ${media.laptop(css`
            &:before {
              padding-bottom: 166.7%;
            }

            ${NewsCardInner} {
              height: 100%;
              padding: 14px 16px;
            }

            ${NewsCardText} {
              font-weight: 600;
            }
          `)}

          ${media.tabletSmall(css`
            &:before {
              padding-bottom: 162.4%;
            }

            ${NewsCardInner} {
              height: 100%;
              padding: 20px;
            }

            ${NewsCardText} {
              font-weight: 500;
              font-size: 18px;
              line-height: 23px;
            }
          `)}
        `
      : ''}
`;

export default NewsCard;
