import React, { useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { gsap } from 'gsap';

import { convertThumbnailToPictureImage } from '@tager/web-modules';
import { useMedia } from '@tager/web-core';

import { media } from '@/utils/mixin';
import { ImageThumbnail, StringField } from '@/typings/model';
import { breakpoints, colors } from '@/constants/theme';
import Picture from '@/components/Picture';
import { ReactComponent as EmblemIcon } from '@/assets/svg/emblem.svg';

import { STARS_YELLOW_BACKGROUND_MIDDLE } from '../Vacancies/Vacancies.constants';

import HistoryCard from './components/HistoryCard';
import HistorySlider from './components/HistorySlider';

interface Props {
  historyItems?: Array<{
    image: ImageThumbnail;
    text: StringField;
    title: StringField;
    year: StringField;
  }>;
  historyTitle: StringField;
}

function History({ historyItems, historyTitle }: Props) {
  const emblemRef = useRef<HTMLDivElement | null>(null);
  const historyBottomRef = useRef<HTMLDivElement | null>(null);

  const isTablet = useMedia(`(max-width: ${breakpoints.laptop - 1}px)`);

  useEffect(() => {
    if (!historyBottomRef.current || !emblemRef.current) {
      return;
    }
    let timeline: gsap.core.Timeline;
    const delayedCall = gsap.delayedCall(0, () => {
      if (!historyBottomRef.current || !emblemRef.current || isTablet) {
        return;
      }
      timeline = gsap
        .timeline()
        .to(emblemRef.current, {
          rotate: -(360 * 2),
          duration: 1,
          ease: 'none',
          scrollTrigger: {
            trigger: historyBottomRef.current,
            scrub: 0.2,
          },
        })
        .to(emblemRef.current, {
          scrollTrigger: {
            trigger: emblemRef.current,
            start: 'center center',
            end: 'bottom bottom',
            endTrigger: historyBottomRef.current,
            scrub: true,
            pin: true,
          },
        });
    });

    return () => {
      timeline?.kill();
      delayedCall.kill();
    };
  }, [isTablet]);

  return (
    <Component>
      <Inner>
        <IconWrapper ref={emblemRef}>
          <EmblemIcon />
        </IconWrapper>
        <Top>
          <TitleWrapper>
            <Title>{historyTitle}</Title>
            <Image>
              <Picture
                mobileSmall={convertThumbnailToPictureImage(
                  STARS_YELLOW_BACKGROUND_MIDDLE
                )}
              />
            </Image>
          </TitleWrapper>
        </Top>
        <Bottom ref={historyBottomRef}>
          {isTablet ? (
            <HistorySlider historyItems={historyItems} />
          ) : (
            <HistoryList>
              {historyItems?.map((historyItem, index) => {
                return (
                  <HistoryCell key={index}>
                    <HistoryCard historyItem={historyItem} index={index} />
                  </HistoryCell>
                );
              })}
            </HistoryList>
          )}
        </Bottom>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  margin-top: 116px;

  ${media.laptop(css`
    margin-top: 120px;
  `)}

  ${media.tablet(css`
    margin: 58px 0 126px;
  `)}
  
  ${media.mobile(css`
    margin: 46px 0 86px;
  `)}
`;

const Inner = styled.div`
  position: relative;
`;

const IconWrapper = styled.div`
  position: absolute;
  content: '';
  top: 284px;
  left: 0;
  transform: translateX(-50%);
  will-change: transform;

  svg {
    width: 1045px;
    height: 1045px;

    ${media.laptop(css`
      width: 841px;
      height: 841px;
    `)}

    ${media.tablet(css`
      width: 629px;
      height: 629px;
    `)}
    
    ${media.mobile(css`
      width: 574px;
      height: 574px;
    `)}
  }

  ${media.laptop(css`
    top: 272px;
  `)}

  ${media.tablet(css`
    top: 27px;
  `)}
  
  ${media.mobile(css`
    top: 4px;
  `)}
`;

const Top = styled.div`
  display: flex;
  justify-content: center;
`;

const Bottom = styled.div`
  max-width: 1920px;
  margin: 80px auto 0;

  display: flex;
  justify-content: flex-end;
  position: relative;

  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  ${media.laptop(css`
    margin-top: 40px;
  `)}
`;

const HistoryList = styled.div`
  padding-top: 165px;
  max-width: 1080px;
  width: 100%;
  position: relative;

  ${media.laptop(css`
    max-width: 730px;
    padding-top: 40px;
  `)}
`;

const HistoryCell = styled.div`
  margin-bottom: 100px;

  ${media.laptop(css`
    margin-bottom: 72px;
  `)}
`;

const Title = styled.h2`
  position: relative;
  text-align: center;
  max-width: 288px;
  margin: 176px 112px;
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.black};

  ${media.laptop(css`
    margin: 120px 48px;
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    font-size: 48px;
    line-height: 56px;
    max-width: 192px;
    margin: 92px 52px;
  `)}
  
  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
    max-width: 174px;
    margin: 58px 14px;
    transform: rotate(-15deg);
  `)}
`;

const TitleWrapper = styled.div`
  position: relative;
  left: -137px;
  margin: 0 auto;
  display: inline-block;
  z-index: 1;

  ${media.laptop(css`
    left: -102px;
  `)}

  ${media.tablet(css`
    left: auto;
    right: -120px;
  `)}
  
  ${media.mobile(css`
    transform: rotate(30deg);
    right: -80px;
  `)}
`;

const Image = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  img,
  div,
  picture {
    width: 100%;
    height: 100%;
  }
`;

export default History;
