import React from 'react';
import styled, { css } from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

import useCurrentPage from '@/hooks/useCurrentPage';
import { DiscountPageType } from '@/typings/model';
import Breadcrumbs from '@/components/Breadcrumbs';
import { colors } from '@/constants/theme';
import Subscription from '@/components/Subscription';
import { media } from '@/utils/mixin';
import { useLayout } from '@/components/Layout/Layout.hooks';
import { BreadCrumbs } from '@/typings/common';
import { setBreadCrumbsList } from '@/utils/common';

import Hero from './components/Hero';
import IssueCard from './components/IssueCard';
import DiscountCard from './components/DiscountCard';
import DiscountType from './components/DiscountType';
import DiscountProgram from './components/DiscountProgram';
import UseCorrectly from './components/UseCorrectly';
import Faq from './components/Faq';

gsap.registerPlugin(ScrollTrigger);

function Discount() {
  const page = useCurrentPage<DiscountPageType>();
  const { setThemePage } = useLayout();
  setThemePage('');

  const BREADCRUMB_LIST: Array<BreadCrumbs> = setBreadCrumbsList(
    {
      title: page?.title ?? '',
      path: page?.path ?? '',
    },
    page?.parent
  );

  return (
    <Wrapper>
      <Breadcrumbs breadcrumbs={BREADCRUMB_LIST} isWhite={true} />
      <Hero title={page?.templateFields.title ?? ''} />
      <IssueCard
        issueCardTitle={page?.templateFields.issueCardTitle ?? ''}
        issueCardImage={page?.templateFields.issueCardImage}
      />
      <DiscountCard
        bannerTitle={page?.templateFields.bannerTitle ?? ''}
        bannerButtonLabel={page?.templateFields.bannerButtonLabel ?? ''}
        bannerButtonUrl={page?.templateFields.bannerButtonUrl ?? ''}
      />
      <DiscountType
        sizeTitle={page?.templateFields.sizeTitle ?? ''}
        sizeText={page?.templateFields.sizeText ?? ''}
        sizeItems={page?.templateFields.sizeItems}
      />
      <DiscountProgram
        discountProgramText={page?.templateFields.discountProgramText ?? ''}
        discountProgramTitle={page?.templateFields.discountProgramTitle ?? ''}
      />
      <UseCorrectly
        useCorrectlyTitle={page?.templateFields.useCorrectlyTitle ?? ''}
        useCorrectlyItems={page?.templateFields.useCorrectlyItems}
      />
      <FaqWrapper>
        <Faq
          faqTitle={page?.templateFields.faqTitle ?? ''}
          faqItems={page?.templateFields.faqItems}
        />
      </FaqWrapper>
      <Subscription />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  overflow: hidden;
  background: ${colors.black};
`;

const FaqWrapper = styled.div`
  background: ${colors.white};
  padding-bottom: 265px;

  ${media.laptop(css`
    padding-bottom: 282px;
  `)}

  ${media.tablet(css`
    padding-bottom: 94px;
  `)}

  ${media.mobile(css`
    padding-bottom: 108px;
  `)}
`;

export default Discount;
