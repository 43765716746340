import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { StringField } from '@/typings/model';
import { media } from '@/utils/mixin';

import SocialPackageCard from './components/SocialPackageCard';

type Props = {
  socialTitle: StringField;
  socialItems?: Array<{
    title: StringField;
    text: StringField;
  }>;
};

function SocialPackage({ socialItems, socialTitle }: Props) {
  return (
    <Component>
      <SocialPackageContainer>
        <Inner>
          <Top>
            <Title dangerouslySetInnerHTML={{ __html: socialTitle ?? '' }} />
          </Top>
          <List>
            {socialItems?.map((socialItem, index) => {
              return (
                <Cell key={index}>
                  <SocialPackageCard socialItem={socialItem} />
                </Cell>
              );
            })}
          </List>
        </Inner>
      </SocialPackageContainer>
    </Component>
  );
}

const Component = styled.div`
  background: ${colors.black};
  padding: 242px 0 418px;

  ${media.laptop(css`
    padding: 133px 0 270px;
  `)}

  ${media.tablet(css`
    padding: 80px 0 150px;
  `)}
  
  ${media.mobile(css`
    padding: 45px 0 373px;
  `)}
`;

const SocialPackageContainer = styled.div`
  max-width: 1408px;
  padding: 0 40px;
  margin: 0 auto;

  ${media.laptop(css`
    max-width: 1076px;
  `)}

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

const Inner = styled.div``;

const Top = styled.div`
  margin-bottom: 66px;

  ${media.laptop(css`
    margin-bottom: 56px;
  `)}

  ${media.tablet(css`
    margin-bottom: 50px;
  `)}
  
  ${media.mobile(css`
    margin-bottom: 39px;
  `)}
`;

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -31.5px -25px;

  ${media.laptop(css`
    margin: -24px -12px;
  `)}
`;

const Title = styled.h2`
  margin: 0;
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.white};

  b {
    font-weight: 500;
    color: ${colors.orange};
  }

  ${media.laptop(css`
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    font-size: 48px;
    line-height: 56px;
  `)}
  
   ${media.mobile(css`
    max-width: 60%;
    font-size: 34px;
    line-height: 42px;
  `)}
`;

const Cell = styled.div`
  flex: 1 1 33.33%;
  padding: 31.5px 25px;

  ${media.laptop(css`
    padding: 24px 12px;
  `)}

  ${media.tablet(css`
    flex: 1 1 50%;
  `)}
  
  ${media.mobile(css`
    flex: 1 1 100%;
    padding: 15px 12px;
  `)}
`;

export default SocialPackage;
