import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import SectionPreview from '@/modules/Home/components/SectionPreview';
import { media } from '@/utils/mixin';
import Picture from '@/components/Picture';
import { ReactComponent as ArrowRight } from '@/assets/svg/arrow-right-big.svg';
import { ImageThumbnail, StringField } from '@/typings/model';
import Offers from '@/modules/Home/components/SpecialsOffers/components/Offers';

interface Props {
  mobileTitle: StringField;
  mobileButtonLabel: StringField;
  mobileButtonUrl: StringField;
  mobileImage?: ImageThumbnail;
  mobileImage1?: ImageThumbnail;
  mobileImage2?: ImageThumbnail;
  mobileImage3?: ImageThumbnail;
  specialOffers?: Array<{
    image: {
      fifth: ImageThumbnail;
      first: ImageThumbnail;
      fourth: ImageThumbnail;
      second: ImageThumbnail;
      sixth: ImageThumbnail;
      third: ImageThumbnail;
    };
    title: StringField;
    link: StringField;
    design: StringField;
  }>;
}

function App({
  mobileTitle,
  mobileButtonLabel,
  mobileButtonUrl,
  mobileImage,
  mobileImage1,
  mobileImage2,
  mobileImage3,
  specialOffers,
}: Props) {
  const arrowRightRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const phoneContainerRef = useRef<HTMLDivElement | null>(null);
  const phoneRef = useRef<HTMLDivElement | null>(null);
  const phoneImageRef = useRef<HTMLImageElement | null>(null);
  const leftCardRef = useRef<HTMLDivElement | null>(null);
  const centerCardRef = useRef<HTMLDivElement | null>(null);
  const rightCardRef = useRef<HTMLDivElement | null>(null);

  useEffect(function animateArrowRight() {
    if (!arrowRightRef.current) {
      return;
    }
    let timeline: gsap.core.Timeline;
    const delayedCall = gsap.delayedCall(0, () => {
      if (!arrowRightRef.current) {
        return;
      }
      timeline = gsap
        .timeline({ repeat: -1 })
        .to(arrowRightRef.current, { x: 50, duration: 0.6 })
        .to(arrowRightRef.current, { x: 0, duration: 0.6 });
    });

    return () => {
      timeline?.kill();
      delayedCall.kill();
    };
  }, []);

  const cards = useRef<gsap.core.Timeline>();

  useEffect(() => {
    const width = phoneImageRef.current?.offsetWidth;
    if (leftCardRef.current && width) {
      const img = leftCardRef.current?.querySelector('img');
      if (img) {
        img.style.width = `${width}px`;
      }
    }
    if (centerCardRef.current && width) {
      const img = centerCardRef.current?.querySelector('img');
      if (img) {
        img.style.width = `${width}px`;
      }
    }
    if (rightCardRef.current && width) {
      const img = rightCardRef.current?.querySelector('img');
      if (img) {
        img.style.width = `${width}px`;
      }
    }

    const delayedCall = gsap.delayedCall(0, () => {
      cards.current = gsap.timeline({ paused: true });
      if (leftCardRef.current) {
        cards.current?.to(
          leftCardRef.current,
          { yPercent: -65, xPercent: -35, rotate: 20, duration: 0.5 },
          0
        );
      }
      if (centerCardRef.current) {
        cards.current?.to(
          centerCardRef.current,
          { yPercent: -80, xPercent: 0, rotate: -55 },
          0
        );
      }
      if (rightCardRef.current) {
        cards.current?.to(
          rightCardRef.current,
          { yPercent: -65, xPercent: 35, rotate: -20 },
          0
        );
      }
    });

    return () => {
      delayedCall.kill();
      cards.current?.kill();
    };
  }, [mobileImage1, mobileImage2, mobileImage3]);

  useEffect(() => {
    if (!containerRef.current || !phoneRef.current) {
      return;
    }
    let timeline: gsap.core.Timeline;
    const set = gsap.set(phoneRef.current, { autoAlpha: 0 });
    const delayedCall = gsap.delayedCall(0, () => {
      ScrollTrigger.matchMedia({
        '(min-width: 1600px)': () => {
          if (!containerRef.current || !phoneRef.current) {
            return;
          }
          timeline = gsap
            .timeline()
            .to(
              phoneRef.current,
              {
                autoAlpha: 1,
                scrollTrigger: { trigger: containerRef.current, scrub: true },
              },
              0
            )
            .to(
              phoneRef.current,
              {
                top: '40%',
                ease: 'none',
                scrollTrigger: {
                  trigger: containerRef.current,
                  end: 'bottom bottom',
                  scrub: true,
                  onLeave: () => {
                    cards.current?.play();
                  },
                  onEnterBack: () => {
                    cards.current?.reverse();
                  },
                },
              },
              0
            );
        },
        '(max-width: 1600px)': () => {
          if (!containerRef.current || !phoneRef.current) {
            return;
          }
          timeline = gsap
            .timeline()
            .to(
              phoneRef.current,
              {
                autoAlpha: 1,
                scrollTrigger: { trigger: containerRef.current, scrub: true },
              },
              0
            )
            .to(phoneRef.current, {
              top: '42.2%',
              ease: 'none',
              scrollTrigger: {
                trigger: containerRef.current,
                end: 'bottom bottom',
                scrub: true,
                onLeave: () => {
                  cards.current?.play();
                },
                onEnterBack: () => {
                  cards.current?.reverse();
                },
              },
            });
        },
      });
    });

    return () => {
      set.kill();
      timeline?.kill();
      delayedCall.kill();
    };
  }, [mobileImage]);

  return (
    <Component ref={containerRef}>
      <Inner>
        <Left>
          <LeftOffer>
            {specialOffers?.slice(5).map((offersItem, index) => {
              return (
                <Offers
                  key={index}
                  image={offersItem.image ? offersItem.image.sixth : null}
                  title={offersItem.title}
                  link={offersItem.link}
                  design="small"
                />
              );
            })}
          </LeftOffer>
          <Preview>
            <SectionPreview
              title={mobileTitle}
              buttonLabel={mobileButtonLabel}
              buttonUrl={mobileButtonUrl}
            />
          </Preview>
          <LeftIcon ref={arrowRightRef}>
            <ArrowRight />
          </LeftIcon>
        </Left>
        <Right ref={phoneContainerRef}>
          {mobileImage && (
            <PhoneImage ref={phoneRef}>
              <PhoneImageInner>
                {mobileImage1 && (
                  <LeftCardImage ref={leftCardRef}>
                    <Picture
                      mobileSmall={convertThumbnailToPictureImage(mobileImage1)}
                    />
                  </LeftCardImage>
                )}
                {mobileImage2 && (
                  <CenterCardImage ref={centerCardRef}>
                    <Picture
                      mobileSmall={convertThumbnailToPictureImage(mobileImage2)}
                    />
                  </CenterCardImage>
                )}
                {mobileImage3 && (
                  <RightCardImage ref={rightCardRef}>
                    <Picture
                      mobileSmall={convertThumbnailToPictureImage(mobileImage3)}
                    />
                  </RightCardImage>
                )}

                <PhoneImageContainer>
                  <Picture
                    imageRef={phoneImageRef}
                    mobileSmall={convertThumbnailToPictureImage(mobileImage)}
                  />
                </PhoneImageContainer>
              </PhoneImageInner>
            </PhoneImage>
          )}
        </Right>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  position: relative;
`;

const Inner = styled.div`
  position: relative;
  display: flex;

  ${media.mobile(css`
    display: block;
  `)}
`;

const Left = styled.div`
  position: relative;
  padding: 142px 0 246px;

  ${media.laptop(css`
    padding: 85px 0 224px;
  `)}

  ${media.tablet(css`
    padding: 90px 0 100px;
  `)}
  
  ${media.mobile(css`
    padding: 50px 0 0;
  `)}
`;

const Right = styled.div`
  position: relative;
  flex: 1 1 auto;

  ${media.mobile(css`
    padding-top: 50px;
    height: 470px;
  `)}
`;

const LeftOffer = styled.div`
  flex: 1 1 auto;
  max-width: 784px;

  ${media.laptop(css`
    max-width: 588px;
    width: 100%;
  `)}

  ${media.tablet(css`
    max-width: 442px;
    width: 100%;
  `)}
  
  ${media.mobile(css`
    width: calc(100vw - 20px);
    margin: 0 -20px;
  `)}
`;

const Preview = styled.div`
  max-width: 790px;
  margin-top: 260px;

  ${media.laptop(css`
    margin-top: 80px;
    max-width: 588px;
  `)}

  ${media.tablet(css`
    margin-top: 90px;
    max-width: 442px;
  `)}
  
  ${media.mobile(css`
    margin-top: 50px;
  `)}
`;

const LeftIcon = styled.div`
  position: absolute;
  bottom: 160px;
  right: 0;

  ${media.laptop(css`
    bottom: 157px;
    right: -10px;
    svg {
      width: 141px;
      height: 124px;
    }
  `)}

  ${media.tablet(css`
    transform: translate3d(0, 0, 0) !important;
    bottom: 80px;
    right: 0px;
    svg {
      width: 80px;
      height: 90px;
    }
  `)}
  
  ${media.mobile(css`
    display: none;
  `)}
`;

const PhoneImage = styled.div`
  position: absolute;
  top: 0;
  right: -93px;
  width: 100%;

  display: flex;
  justify-content: center;

  @media (max-width: 1780px) {
    max-width: 30vw;
    right: 50%;
    transform: translateX(50%);
  }

  ${media.laptop(css`
    max-width: 29.3vw;
    top: 0;
    right: 98px;
    transform: translateX(0);
  `)}

  ${media.tablet(css`
    max-width: 32.3vw;
    top: 45.2% !important;
    right: 0;
    transform: translateX(0);
  `)}
  
  ${media.mobile(css`
    position: static;
    max-width: calc(100vw - 40px);
  `)}
`;

const PhoneImageInner = styled.div`
  position: relative;
`;

const PhoneImageContainer = styled.div`
  position: relative;
  z-index: 3;
`;

const LeftCardImage = styled.div`
  position: absolute;
  top: 20%;
  left: 6%;
  right: 6%;
  z-index: 2;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${media.mobile(css`
    display: none;
  `)}
`;

const CenterCardImage = styled.div`
  position: absolute;
  top: 20%;
  left: 6%;
  right: 6%;
  z-index: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${media.mobile(css`
    display: none;
  `)}
`;

const RightCardImage = styled.div`
  position: absolute;
  top: 20%;
  left: 6%;
  right: 6%;
  z-index: 2;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${media.mobile(css`
    display: none;
  `)}
`;

export default App;
