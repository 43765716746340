import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import Link from '@/components/Link';
import { ImageThumbnail, StringField } from '@/typings/model';
import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

interface Props {
  product: {
    design: StringField;
    image: ImageThumbnail;
    link: StringField;
    title: StringField;
  };
}

function ProductsCard({ product }: Props) {
  return (
    <Components design={product.design}>
      <Inner>
        <ComponentsLink to={product.link ?? '#'} />
        <Image>
          {product.image && (
            <Picture
              mobileSmall={convertThumbnailToPictureImage(product.image)}
            />
          )}
        </Image>
        <Label>{product.title}</Label>
      </Inner>
    </Components>
  );
}

const Inner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Image = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  div,
  picture {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Label = styled.div`
  position: absolute;
  z-index: 2;
  bottom: 30px;
  left: 30px;
  padding: 40px 54px;
  background: ${colors.white};
  border-radius: 50px;
  overflow: hidden;
  transition: all 0.3s linear;

  font-weight: 500;
  font-size: 32px;
  line-height: 42px;

  ${media.laptop(css`
    border-radius: 35px;
    font-size: 24px;
    line-height: 32px;
    padding: 24px 32px;

    bottom: 24px;
    left: 24px;
  `)}

  ${media.tablet(css`
    font-size: 20px;
    line-height: 24px;
    padding: 14px 22px;

    bottom: 20px;
    left: 20px;
  `)}

  ${media.mobile(css`
    border-radius: 34px;
    font-size: 16px;
    line-height: 20px;
    padding: 12px 20px;

    bottom: 30px;
  `)}
`;

const ComponentsLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
`;

const Components = styled.div<{ design?: StringField }>`
  grid-column: span 1;
  grid-row: span 1;
  border-radius: 36px;
  overflow: hidden;

  ${media.laptop(css`
    border-radius: 24px;
  `)}

  ${(props) =>
    props.design === 'big'
      ? css`
          grid-column: span 2;
          grid-row: span 2;

          ${media.mobile(css`
            grid-column: span 1;
          `)}
        `
      : props.design === 'medium'
      ? css`
          grid-column: span 2;
          grid-row: span 1;
        `
      : ''};

  &:hover {
    ${Label} {
      background: ${colors.orange};
      color: ${colors.white};
    }
  }
`;

export default ProductsCard;
