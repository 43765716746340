import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { VacancyCategoriesItemType } from '@/typings/model';

type Props = {
  label: string;
  value: number;
  isActive: boolean;
  onChange: (value: number) => void;
  index: number;
};

function CategoryItem({ label, value, isActive, onChange, index }: Props) {
  return (
    <Component onClick={() => onChange(index)}>
      <Inner isActive={isActive}>
        <Label>{label}</Label>
        <Count>{value}</Count>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  min-height: 48px;

  ${media.laptop(css`
    min-height: 40px;
  `)}

  ${media.tablet(css`
    min-height: 22px;
  `)}
`;

const Label = styled.span`
  transition: all 0.15s linear, font-weight 0.1s linear;
`;

const Count = styled.span`
  margin-left: 8px;
  transition: all 0.15s linear, font-weight 0.1s linear;

  ${media.laptop(css`
    margin-left: 6px;
  `)}
`;

const Inner = styled.div<{ isActive?: boolean }>`
  display: flex;
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  color: ${colors.black};
  cursor: pointer;

  ${media.laptop(css`
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    font-size: 18px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    font-size: 16px;
    line-height: 20px;
  `)}

  &:hover {
    ${Label} {
      color: ${colors.orange};
    }
  }

  ${(props) =>
    props.isActive
      ? css`
          ${Label} {
            color: ${colors.orange};
          }
        `
      : null}
`;

export default CategoryItem;
