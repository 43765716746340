import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Navigation,
  Autoplay,
  Pagination,
  EffectFade,
} from 'swiper';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { useTypedSelector } from '@/store/store';
import { selectShopByCitiesList } from '@/store/reducers/shopsCities';
import { ShopsTypeItem } from '@/typings/model';

import CategoryItem from './components/CategoryItem';
import ShopsItem from './components/ShopsItem';

SwiperCore.use([Navigation, Autoplay, Pagination, EffectFade]);

interface Props {
  setActiveShop: (shop: ShopsTypeItem) => void;
}

function ShopInYourCity({ setActiveShop }: Props) {
  const shopByCitiesList = useTypedSelector(selectShopByCitiesList);

  const [isActiveItem, setActiveItem] = useState(
    shopByCitiesList[0] ?? { shops: [] }
  );
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);

  useEffect(() => {
    if (!shopByCitiesList[0]) return;

    setActiveItem(shopByCitiesList[0]);
  }, [shopByCitiesList]);

  return (
    <Component>
      <Inner>
        <SectionContainer>
          <Top>
            <TitleSection>Корона в твоем городе</TitleSection>
          </Top>

          <Bottom>
            <Categories>
              <CategoriesList>
                <Swiper
                  slidesPerView="auto"
                  speed={500}
                  onSlideChange={(swiper) =>
                    setActiveSlideIndex(swiper.activeIndex)
                  }
                  breakpoints={{
                    320: {
                      spaceBetween: 10,
                    },
                    1680: { spaceBetween: 30 },
                  }}
                >
                  {shopByCitiesList.map((categoryItem, index) => {
                    if (categoryItem.shops.length === 0) return false;
                    return (
                      <SwiperSlide key={index}>
                        <CategoryItem
                          categoryItem={categoryItem}
                          isActive={categoryItem.name === isActiveItem.name}
                          onChange={setActiveItem}
                          count={categoryItem.shopsCount}
                        />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </CategoriesList>
            </Categories>
            <ShopsList>
              {isActiveItem.shops.map((shopItem) => {
                return (
                  <ShopsCell key={shopItem.id}>
                    <ShopsItem
                      shopItem={shopItem}
                      setActiveShop={(shop: ShopsTypeItem) =>
                        setActiveShop(shop)
                      }
                    />
                  </ShopsCell>
                );
              })}
            </ShopsList>
          </Bottom>
        </SectionContainer>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  position: relative;
  overflow: hidden;
`;
const Inner = styled.div``;

const SectionContainer = styled.div`
  max-width: 1680px;
  padding: 0 40px;
  margin: 0 auto;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

const Top = styled.div`
  margin-bottom: 72px;

  ${media.laptop(css`
    margin-bottom: 29px;
  `)}

  ${media.tablet(css`
    margin-bottom: 21px;
  `)}
`;

const Bottom = styled.div``;

const TitleSection = styled.span`
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.black};

  b {
    font-weight: 500;
    color: ${colors.orange};
  }

  ${media.laptop(css`
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    font-size: 48px;
    line-height: 56px;
  `)}
  
  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
  `)}
`;

const Categories = styled.div`
  margin-bottom: 58px;
  width: 100%;

  ${media.laptop(css`
    margin-bottom: 31px;
  `)}

  ${media.tablet(css`
    margin-bottom: 39px;
  `)}
  
  ${media.mobile(css`
    margin-bottom: 20px;
  `)}
`;

const CategoriesList = styled.div`
  .swiper-container {
    height: 100%;
    cursor: grab;
    margin: 0 -40px;
    padding: 0 40px;

    ${media.tablet(css`
      margin: 0 -20px;
      padding: 0 20px;
    `)}
  }

  .swiper-slide {
    width: auto !important;
    &:last-child {
      padding-right: 40px !important;

      ${media.tablet(css`
        padding-right: 20px !important;
      `)}
    }
  }
`;

const ShopsList = styled.div`
  margin: -6px 0;
  ${media.laptop(css`
    margin: -4px 0;
  `)}

  ${media.tablet(css`
    margin: -3px 0;
  `)}
  
  ${media.mobile(css`
    margin: -5px 0;
  `)}
`;

const ShopsCell = styled.div`
  padding: 6px 0;

  ${media.laptop(css`
    padding: 4px 0;
  `)}

  ${media.tablet(css`
    padding: 3px 0;
  `)}
  
  ${media.mobile(css`
    padding: 5px 0;
  `)}
`;

export default ShopInYourCity;
