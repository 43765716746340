import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

interface Props {
  title?: string;
  children?: React.ReactNode;
}

function SalonSection({ title, children }: Props) {
  return (
    <Component>
      <Inner>
        {title && (
          <Top>
            <Title>{title}</Title>
          </Top>
        )}
        <Content>{children}</Content>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  margin-bottom: 80px;

  ${media.tablet(css`
    margin-bottom: 50px;
  `)}

  ${media.mobile(css`
    margin-bottom: 30px;
  `)}
`;

const Inner = styled.div`
  max-width: 1680px;
  margin: 0 auto;
  padding: 0 40px;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

const Top = styled.div`
  margin-bottom: 60px;

  ${media.laptop(css`
    margin-bottom: 40px;
  `)}

  ${media.tablet(css`
    margin-bottom: 30px;
  `)}
  
  ${media.mobile(css`
    margin-bottom: 20px;
  `)}
`;

const Content = styled.div``;

const Title = styled.h2`
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  margin: 0;

  ${media.laptop(css`
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    font-size: 48px;
    line-height: 56px;
  `)}
  
  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
  `)}
`;

export default SalonSection;
