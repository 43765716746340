import React from 'react';
import styled, { css } from 'styled-components';

import TickerLine from '@/components/TickerLine';
import { media } from '@/utils/mixin';
import { TickerLineItemType } from '@/typings/model';

interface Props {
  tickers: Array<TickerLineItemType>;
}

function TickerLineSection({ tickers }: Props) {
  if (!tickers || tickers?.length === 0) return null;

  const tickerRotate: Array<string> = ['-3', '4', '1', '-2'];

  return (
    <Wrapper>
      {tickers.slice(0, 4).map((ticker, index) => {
        const { background, text, color, link } = ticker;
        return (
          <Cell key={index}>
            <TickerLine
              backgroundTicket={background}
              colorTicket={color}
              labelTicket={text}
              linkTicket={link}
              rotateTicket={tickerRotate[index]}
            />
          </Cell>
        );
      })}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-bottom: 96px;

  ${media.laptop(css`
    margin-bottom: 56px;
  `)}

  ${media.tablet(css`
    margin-bottom: 30px;
  `)}
`;

const Cell = styled.div`
  &:first-child {
    z-index: 5;
    position: relative;
  }

  &:nth-child(2) {
    position: relative;
    top: -45px;

    ${media.laptop(css`
      top: -35px;
    `)}

    ${media.tablet(css`
      top: -22px;
    `)}
    
    ${media.mobile(css`
      top: -40px;
    `)}
  }

  &:nth-child(3) {
    position: relative;
    top: -19px;
    z-index: 4;

    ${media.laptop(css`
      top: -25px;
    `)}

    ${media.tablet(css`
      top: -15px;
    `)}
  }

  &:nth-child(4) {
    position: relative;
    top: -13px;

    ${media.laptop(css`
      top: -25px;
    `)}

    ${media.tablet(css`
      top: -16px;
    `)}
  }

  ${media.tablet(css`
    & > div {
      height: 35px;

      & li {
        font-size: 14px !important;
        line-height: 16px !important;
      }
    }
  `)}
`;

export default TickerLineSection;
