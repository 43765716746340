import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { gsap } from 'gsap';

import { convertThumbnailToPictureImage } from '@tager/web-modules';
import { useModal } from '@tager/web-components';
import { useMedia } from '@tager/web-core';

import { ImageThumbnail, StringField } from '@/typings/model';
import Picture from '@/components/Picture';
import { breakpoints, colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import ProductsModal from '@/components/modals/ProductsModal';
import PriceBlock from '@/components/PriceBlock';

type Props = {
  product: {
    design: StringField;
    image?: ImageThumbnail;
    name: StringField;
    price: Number;
    priceBadgeMode: StringField;
    priceBadgeRotate: StringField;
    priceBadgeColor: StringField;
    priceBadgePosition: StringField;
    additionalImage: ImageThumbnail;
    additionalImagePositionX: Number;
    additionalImagePositionY: Number;
    animationName: StringField;
    oldPrice: Number;
    discountPercentage: Number;
    pricePerKg: Number;
    isScrollUp: boolean;
  };
  onTap: () => void;
  productsRef: React.MutableRefObject<HTMLDivElement | null>;
  children: React.ReactNode;
};

function ProductCard({ product, productsRef, onTap, children }: Props) {
  const productCardRef = useRef<HTMLDivElement | null>(null);
  const picture1Ref = useRef<HTMLImageElement | null>(null);
  const picture3Ref = useRef<HTMLImageElement | null>(null);
  const additionalRef = useRef<HTMLImageElement | null>(null);
  const priceRef = useRef<HTMLDivElement | null>(null);
  const badgeTextRef = useRef<HTMLDivElement | null>(null);

  const isMobile = useMedia(`(max-width: ${breakpoints.tabletSmall - 1}px)`);

  const {
    design,
    image,
    name,
    price,
    priceBadgeColor,
    priceBadgeMode,
    priceBadgeRotate,
    priceBadgePosition,
    additionalImage,
    additionalImagePositionX,
    additionalImagePositionY,
    animationName,
    oldPrice,
    discountPercentage,
    pricePerKg,
    isScrollUp,
  } = product;

  useEffect(() => {
    let tween: gsap.core.Tween;

    if (isMobile) return;

    const delayedCall = gsap.delayedCall(0, () => {
      if (isScrollUp && productsRef.current) {
        tween = gsap.fromTo(
          productCardRef.current,
          {
            y: 300,
          },
          {
            y: 0,
            duration: 1,
            scrollTrigger: {
              trigger: productsRef.current,
              toggleActions: 'restart pause resume pause',
            },
          }
        );
      }
    });

    return () => {
      tween?.kill();
      delayedCall.kill();
    };
  }, [isScrollUp, productsRef, isMobile]);

  const doll = useRef<gsap.core.Tween>();

  useEffect(() => {
    const delayedCall = gsap.delayedCall(0, () => {
      doll.current = gsap.to(picture1Ref.current, {
        rotate: '-15%',
        paused: true,
      });
    });

    return () => {
      delayedCall.kill();
      doll.current?.kill();
    };
  }, []);

  const separation = useRef<gsap.core.Timeline>();

  useEffect(() => {
    const delayedCall = gsap.delayedCall(0, () => {
      separation.current = gsap
        .timeline({ paused: true })
        .to(
          picture1Ref.current,
          {
            rotate: -10,
          },
          0
        )
        .to(
          additionalRef.current,
          {
            rotate: 10,
          },
          0
        );
    });

    return () => {
      delayedCall.kill();
      separation.current?.kill();
    };
  }, []);

  const garmon = useRef<gsap.core.Timeline>();

  useEffect(() => {
    const delayedCall = gsap.delayedCall(0, () => {
      garmon.current = gsap
        .timeline({ paused: true })
        .to(
          picture1Ref.current,
          {
            rotate: 6,
          },
          0
        )
        .to(
          picture3Ref.current,
          {
            rotate: -6,
          },
          0
        )
        .to(
          additionalRef.current,
          {
            rotate: -45,
          },
          0
        );
    });

    return () => {
      delayedCall.kill();
      garmon.current?.kill();
    };
  }, []);

  function animateIn() {
    if (animationName === 'garmon') {
      garmon.current?.play();
    }

    if (animationName === 'separation') {
      separation.current?.play();
    }

    if (animationName === 'doll') {
      doll.current?.play();
    }
  }

  function animateOut() {
    if (animationName === 'garmon') {
      garmon.current?.delay(0.03).reverse();
    }

    if (animationName === 'separation') {
      separation.current?.delay(0.03).reverse();
    }

    if (animationName === 'doll') {
      doll.current?.delay(0.03).reverse();
    }
  }

  function setPriceSize(price: string) {
    const pointIndex = price.indexOf('.');

    if (pointIndex > 2) {
      return 'three-digit';
    } else if (pointIndex > 1) {
      return 'double-digit';
    } else {
      return 'simple';
    }
  }

  return (
    <Component
      ref={productCardRef}
      design={design}
      onClick={onTap}
      onMouseEnter={animateIn}
      onMouseLeave={animateOut}
    >
      <ProductCardInner>
        {additionalImage ? (
          <ProductCardAdditionalImage
            style={{
              transform: `translate(${additionalImagePositionX}%, ${additionalImagePositionY}%)`,
            }}
          >
            <Picture
              imageRef={additionalRef}
              mobileSmall={convertThumbnailToPictureImage(additionalImage)}
            />
          </ProductCardAdditionalImage>
        ) : null}

        <ProductCardImage>
          {animationName === 'garmon' ? (
            <>
              <Picture
                imageRef={picture3Ref}
                mobileSmall={convertThumbnailToPictureImage(image)}
              />
              <Picture mobileSmall={convertThumbnailToPictureImage(image)} />
            </>
          ) : null}

          <Picture
            imageRef={picture1Ref}
            mobileSmall={convertThumbnailToPictureImage(image)}
          />
        </ProductCardImage>

        <ProductCardBadge
          priceBadgeColor={priceBadgeColor}
          priceBadgeRotate={priceBadgeRotate}
          priceBadgeMode={priceBadgeMode}
          priceBadgePosition={priceBadgePosition}
        >
          <PriceBlockWrapper priceBadgeMode={priceBadgeMode}>
            <PriceBlock
              priceOld={oldPrice}
              discount={discountPercentage}
              pricePerKg={pricePerKg}
              isCircle={priceBadgeMode === 'circle'}
            />
          </PriceBlockWrapper>
          <ProductCardPrice
            ref={priceRef}
            priceSize={setPriceSize(String(price))}
          >
            {String(price).split('.')[0]}
            <ProductCardPricePenny priceSize={setPriceSize(String(price))}>
              .{String(price.toFixed(2)).split('.')[1]}
            </ProductCardPricePenny>
          </ProductCardPrice>

          <BadgeTextContainer>
            <BadgeText
              design={design}
              priceBadgeMode={priceBadgeMode}
              ref={badgeTextRef}
            >
              {name}
            </BadgeText>
          </BadgeTextContainer>
        </ProductCardBadge>
      </ProductCardInner>
      {children}
    </Component>
  );
}

const ProductCardBadge = styled.div<{
  priceBadgeColor: StringField;
  priceBadgeRotate: StringField;
  priceBadgeMode: StringField;
  priceBadgePosition: StringField;
}>`
  position: absolute;
  bottom: 44px;
  z-index: 6;

  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: ${(props) =>
    props.priceBadgeColor ? `${props.priceBadgeColor}` : `${colors.yellow100}`};
  transform: rotate(
    ${(props) =>
      props.priceBadgeRotate ? `${props.priceBadgeRotate}deg` : '0deg'}
  );

  ${media.laptop(css`
    bottom: 30px;
  `)}

  ${(props) =>
    props?.priceBadgeMode === 'rect'
      ? css`
          background: transparent;
          padding: 6px 21px 6px 9px;
          height: 150px;

          ${media.laptop(css`
            padding: 4px 15px 4px 7px;
            height: 95px;
          `)}

          ${media.tablet(css`
            padding: 4px 22px 4px 6px;
            height: 65px;
          `)}

          ${media.mobile(css`
            padding: 4px 24px 4px 11px;
            height: 65px;
          `)};

          &:before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: ${colors.yellow100};
            transform: skew(-10deg, 0);

            ${media.laptop(css`
              transform: skew(-11deg, 0);
            `)}
          }
        `
      : css`
          width: 240px;
          height: 240px;
          border-radius: 100%;

          ${media.laptop(css`
            width: 160px;
            height: 160px;
          `)}

          ${media.tabletSmall(css`
            width: 104px;
            height: 104px;
          `)}
        `}

  ${(props) =>
    props.priceBadgePosition === 'leftBottom'
      ? props?.priceBadgeMode === 'rect'
        ? css`
            left: 28px;
            ${media.laptop(css`
              left: 22px;
            `)}
          `
        : css`
            left: 42px;
            ${media.laptop(css`
              left: 30px;
            `)}
          `
      : props?.priceBadgeMode === 'rect'
      ? css`
          right: 28px;
          ${media.laptop(css`
            right: 22px;
          `)}
        `
      : css`
          right: 46px;
          ${media.laptop(css`
            right: 30px;
          `)}
        `}
  
  
  &:before {
    background: ${(props) =>
      props.priceBadgeColor
        ? `${props.priceBadgeColor}`
        : `${colors.yellow100}`};
  }
`;

const ProductCardInner = styled.div``;

const PriceBlockWrapper = styled.div<{
  priceBadgeMode: StringField;
}>`
  position: absolute;
  bottom: 100%;
  left: 23px;
  z-index: 100;

  ${media.laptop(css`
    left: 20px;
  `)}

  ${media.tablet(css`
    left: 12px;
  `)}

  ${(props) =>
    props.priceBadgeMode === 'circle' &&
    css`
      left: auto;
      bottom: calc(100% - 30px);

      ${media.tablet(css`
        bottom: calc(100% - 20px);
        left: auto;
      `)}
    `}
`;

const ProductCardAdditionalImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;

  img {
    z-index: 3;
  }
`;

const ProductCardImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img,
  div,
  picture {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: contain;
  }

  > * {
    position: absolute !important;
    top: 0;
    left: 0;

    &:nth-child(1) {
      z-index: 4;
    }

    &:nth-child(2) {
      z-index: 2;
    }

    &:nth-child(3) {
      z-index: 1;
    }
  }
`;

const ProductCardPrice = styled.div<{ priceSize?: string }>`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: flex-start;
  font-weight: bold;
  font-size: 108px;
  line-height: 120px;
  color: ${colors.black};
  font-style: italic;
  letter-spacing: 4px;
  transition: all 0.3s linear 0.05s;

  ${media.laptop(css`
    font-size: 72px;
    line-height: 80px;
    letter-spacing: 4px;
  `)}

  ${media.tabletSmall(css`
    letter-spacing: 2px;
  `)}

  ${(props) =>
    props.priceSize === 'simple'
      ? css`
          font-size: 108px;
          line-height: 120px;

          ${media.laptop(css`
            font-size: 72px;
            line-height: 80px;
          `)}

          ${media.tabletSmall(css`
            font-size: 42px;
            line-height: 52px;
          `)}
          
           ${media.mobile(css`
            font-size: 48px;
            line-height: 40px;
          `)}
        `
      : props.priceSize === 'double-digit'
      ? css`
          font-size: 88px;
          line-height: 100px;

          ${media.laptop(css`
            font-size: 63px;
            line-height: 71px;
          `)}

          ${media.tabletSmall(css`
            font-size: 34px;
            line-height: 52px;
          `)}
          
          ${media.mobile(css`
            line-height: 40px;
          `)}
        `
      : css`
          font-size: 64px;
          line-height: 76px;

          ${media.laptop(css`
            font-size: 40px;
            line-height: 53px;
          `)}

          ${media.tabletSmall(css`
            font-size: 24px;
            line-height: 40px;
          `)}
        `}
`;

const ProductCardPricePenny = styled.span<{ priceSize?: string }>`
  font-weight: bold;
  font-size: 60px;
  line-height: 84px;
  font-style: italic;

  ${media.laptop(css`
    font-size: 40px;
    line-height: 56px;
    margin-left: 2px;
  `)}

  ${(props) =>
    props.priceSize === 'simple'
      ? css`
          font-size: 60px;
          line-height: 84px;

          ${media.laptop(css`
            font-size: 36px;
            line-height: 56px;
          `)}

          ${media.tabletSmall(css`
            font-size: 22px;
            line-height: 32px;
          `)}
          
          ${media.mobile(css`
            font-size: 24px;
            line-height: 24px;
          `)}
        `
      : props.priceSize === 'double-digit'
      ? css`
          font-size: 45px;
          line-height: 69px;

          ${media.laptop(css`
            font-size: 28px;
            line-height: 44px;
          `)}

          ${media.tabletSmall(css`
            font-size: 22px;
            line-height: 32px;
          `)}
          
          ${media.mobile(css`
            font-size: 24px;
            line-height: 24px;
          `)}
        `
      : css`
          font-size: 35px;
          line-height: 59px;

          ${media.laptop(css`
            font-size: 28px;
            line-height: 44px;
          `)}

          ${media.tabletSmall(css`
            font-size: 20px;
            line-height: 30px;
          `)}
          
          ${media.mobile(css`
            font-size: 16px;
            line-height: 24px;
          `)}
        `}
`;

const BadgeTextContainer = styled.div`
  position: absolute;
  top: 60%;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);

  display: flex;
  align-items: center;
  justify-content: center;

  ${media.tablet(css`
    display: none;
  `)}
`;

const BadgeText = styled.span<{
  design: StringField;
  priceBadgeMode: StringField;
}>`
  opacity: 0;
  max-width: 65%;
  transition: all 0.3s linear;

  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  word-break: break-word;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;

  ${media.laptop(css`
    font-size: 16px;
    line-height: 20px;
  `)}

  ${media.tabletSmall(css`
    font-size: 12px;
    line-height: 14px;
  `)}

  ${(props) =>
    props.design === 'big'
      ? css`
          max-width: 65%;
          -webkit-line-clamp: 3;
        `
      : css`
          max-width: 60%;
          -webkit-line-clamp: 2;
          position: relative;
          top: -10px;

          ${media.laptop(css`
            top: -5px;
          `)}
        `}

  ${(props) =>
    props?.priceBadgeMode === 'rect'
      ? css`
          left: -16px;
          ${media.laptop(css`
            left: -10px;
          `)}
        `
      : ''}
`;

const Component = styled.div<{ design: StringField }>`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;

  ${(props) =>
    props.design === 'big'
      ? css`
          ${ProductCardBadge} {
            bottom: auto;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 360px !important;
            height: 360px !important;

            ${media.laptop(css`
              width: 240px !important;
              height: 240px !important;
            `)}

            ${media.tabletSmall(css`
              width: 228px !important;
              height: 228px !important;
            `)}
            
            ${media.mobile(css`
              width: 100px !important;
              height: 100px !important;
            `)}
          }

          ${ProductCardPrice} {
            font-size: 132px;
            line-height: 144px;
            ${media.laptop(css`
              font-size: 88px;
              line-height: 96px;
            `)}

            ${media.tabletSmall(css`
              font-size: 84px;
              line-height: 92px;
            `)}
            
            ${media.mobile(css`
              font-size: 48px;
              line-height: 40px;
            `)}
          }

          ${ProductCardPricePenny} {
            font-size: 60px;
            line-height: 81px;

            ${media.laptop(css`
              font-size: 40px;
              line-height: 54px;
            `)}

            ${media.tabletSmall(css`
              font-size: 38px;
              line-height: 52px;
            `)}

            ${media.mobile(css`
              font-size: 24px;
              line-height: 24px;
            `)}
          }

          &:hover {
            ${ProductCardPrice} {
              transform: translateY(-35px) scale(0.85) !important;
              transition: all 0.3s linear;
            }
            ${BadgeText} {
              opacity: 1 !important;
              transition: all 0.3s linear 0.2s;
            }
          }
        `
      : css`
          &:hover {
            ${ProductCardPrice} {
              transform: translateY(-35px) scale(0.7) !important;
              transition: all 0.3s linear;
              ${media.laptop(css`
                transform: translateY(-25px) scale(0.7) !important;
              `)}
            }
            ${BadgeText} {
              opacity: 1 !important;
              transition: all 0.3s linear 0.2s;
            }
          }
        `}
`;

export default ProductCard;
