import React from 'react';
import styled, { css } from 'styled-components';

import { useModal } from '@tager/web-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import Star from '@/components/Sticker/Star';
import Picture from '@/components/Picture';
import { PromotionProductsItem } from '@/typings/model';
import PromotionsModal from '@/components/modals/PromotionsModal';

import { convertThumbnailToPictureImage } from './helpers';
import PromotionPrice from './PromotionPrice';

interface Props {
  size?: string;
  product: PromotionProductsItem;
  index: number;
  activeProducts: Array<PromotionProductsItem>;
}

function PromotionCard({ size, product, index, activeProducts }: Props) {
  const openModal = useModal();
  const {
    image,
    name,
    date,
    price,
    discountPrice,
    discount,
    stickerText,
    stickerColor,
    priceUnit,
    pricePerKg,
  } = product;

  function openProductsModal(
    indexActive: number,
    products: Array<PromotionProductsItem>
  ) {
    openModal(PromotionsModal, {
      activeSlide: indexActive,
      products: products,
    });
  }

  return (
    <Component
      size={size}
      onClick={() => openProductsModal(index, activeProducts)}
    >
      <Inner>
        <Top>
          <Image>
            {image ? (
              <Picture mobileSmall={convertThumbnailToPictureImage(image)} />
            ) : null}
          </Image>
          <PromotionPrice
            size={size}
            priceOld={price}
            priceNew={discountPrice}
            discount={discount}
            unit={priceUnit}
            perUnit={pricePerKg}
          />
        </Top>
        <Date>{date}</Date>
        <Description>{name}</Description>
      </Inner>
      {stickerText && (
        <StickerWrapper size={size}>
          <Star
            color={'red'}
            customColor={stickerColor ?? ''}
            size="big"
            label={stickerText}
            rotate={'6deg'}
          />
        </StickerWrapper>
      )}
    </Component>
  );
}

const Inner = styled.div`
  width: 100%;
`;

const Top = styled.div`
  position: relative;
  margin-bottom: 34px;
  border-radius: 240px;
  background: ${colors.gray50};

  &:before {
    display: block;
    content: '';
    padding-bottom: 71.4%;

    ${media.mobile(css`
      padding-bottom: 100%;
    `)}
  }

  ${media.laptop(
    css`
      border-radius: 180px;
      margin-bottom: 24px;
    `
  )}

  ${media.tablet(css`
    border-radius: 106px;
    margin-bottom: 30px;
  `)}
  
  ${media.mobile(css`
    border-radius: 118px;
  `)}
`;

const Image = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 240px;

  ${media.laptop(css`
    border-radius: 180px;
  `)}

  ${media.tablet(css`
    border-radius: 106px;
  `)}
  
  ${media.mobile(css`
    border-radius: 118px;
  `)}

  div {
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    will-change: transform;
    transition: transform 0.3s linear;
  }

  img {
    max-width: 100%;
    height: 488px;

    ${media.laptop(css`
      height: 356px;
    `)}

    ${media.tablet(css`
      height: 248px;
    `)}
  }
`;

const Description = styled.p`
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  text-align: center;
  color: ${colors.black400};
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: all 0.3s linear;

  ${media.laptop(css`
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    font-size: 20px;
    line-height: 24px;
  `)}
  
  ${media.mobile(css`
    font-size: 16px;
    line-height: 20px;
  `)}
`;

const Date = styled.p`
  font-size: 18px;
  color: ${colors.black400};
  margin-bottom: 10px;

  ${media.laptop(css`
    font-size: 14px;
  `)}
`;

const Component = styled.div<{ size?: string }>`
  position: relative;
  flex: 1 1 50%;
  max-width: 50%;
  padding: 35px 16px;
  cursor: pointer;

  ${media.laptop(css`
    padding: 28px 12px;
  `)};

  ${media.tablet(css`
    padding: 25px 18px;
  `)};

  ${media.mobile(css`
    padding: 25px 10px;
    flex: 1 1 100%;
    max-width: 100%;
  `)};

  &:hover {
    ${Image} {
      div {
        transform: scale(1.12);
      }
    }

    ${Description} {
      text-decoration-color: ${colors.black400};
    }
  }

  ${(props) =>
    props.size === 'MEDIUM'
      ? css`
          flex: 1 1 33.33%;
          max-width: 33.33%;

          ${media.tablet(css`
            flex: 1 1 50%;
            max-width: 50%;
          `)}

          ${media.mobile(css`
            flex: 1 1 100%;
            max-width: 100%;
          `)}

          ${Image} {
            img {
              height: 293px;

              ${media.laptop(css`
                height: 220px;
              `)}

              ${media.tablet(css`
                height: 248px;
              `)}
         
              ${media.mobile(css`
                height: 210px;
              `)}
            }
          }
        `
      : props.size === 'small'
      ? css`
          flex: 1 1 16.66%;
          max-width: 16.66%;

          ${media.tablet(css`
            flex: 1 1 25%;
            max-width: 25%;
            padding: 15px 18px;
          `)}

          ${media.mobile(css`
            flex: 1 1 50%;
            max-width: 50%;
            padding: 15px 10px;
          `)}

          ${Top} {
            border-radius: 106px;
            margin-bottom: 24px;

            &:before {
              display: block;
              content: '';
              padding-bottom: 122.5%;

              ${media.tablet(css`
                padding-bottom: 140.6%;
              `)}

              ${media.mobile(css`
                padding-bottom: 122.7%;
              `)}
            }
          }

          ${Image} {
            border-radius: 106px;

            ${media.laptop(css`
              border-radius: 80px;
            `)}

            ${media.tablet(css`
              border-radius: 103px;
            `)}
            
            ${media.mobile(css`
              border-radius: 66px;
            `)}

            img {
              height: 220px;

              ${media.laptop(css`
                height: 160px;
              `)}

              ${media.tablet(css`
                height: 140px;
              `)}
              
              ${media.mobile(css`
                height: 120px;
              `)}
            }
          }

          ${Description} {
            font-weight: 500;
            font-size: 20px;
            line-height: 26px;
            text-align: left;
          }

          ${media.laptop(css`
            ${Top} {
              border-radius: 80px;
              margin-bottom: 16px;
            }

            ${Description} {
              font-size: 16px;
              line-height: 24px;
            }
          `)}

          ${media.tablet(css`
            ${Top} {
              border-radius: 103px;
              margin-bottom: 20px;
            }
          `)}
          
          ${media.mobile(css`
            ${Top} {
              border-radius: 66px;
              margin-bottom: 14px;
            }

            ${Description} {
              line-height: 20px;
            }
          `)}
        `
      : ''}
`;

const StickerWrapper = styled.div<{ size?: string }>`
  position: absolute;
  top: -15px;
  right: -27px;
  width: 280px;
  height: 280px;
  transform: rotate(9deg);

  ${media.laptop(css`
    top: 8px;
    right: -8px;
    width: 200px;
    height: 200px;
  `)}

  ${media.tablet(css`
    width: 180px;
    height: 180px;
  `)}
  
  ${media.mobile(css`
    width: 160px;
    height: 160px;
  `)}

  ${(props) =>
    props.size === 'small'
      ? css`
          width: 140px;
          height: 140px;
          span {
            font-size: 18px;
            line-height: 24px;
          }

          ${media.laptop(css`
            width: 100px;
            height: 100px;
            span {
              font-size: 12px;
              line-height: 18px;
            }
          `)}

          ${media.tablet(css`
            width: 80px;
            height: 80px;
            span {
              line-height: 16px;
            }
          `)}
          
         ${media.mobile(css`
            width: 60px;
            height: 60px;
            span {
              font-size: 10px;
              line-height: 12px;
            }
          `)}
        `
      : props.size === 'MEDIUM'
      ? css`
          width: 240px;
          height: 240px;
          span {
            font-size: 28px;
            line-height: 38px;
          }

          ${media.laptop(css`
            width: 160px;
            height: 160px;
            span {
              font-size: 20px;
              line-height: 28px;
            }
          `)}

          ${media.tablet(css`
            width: 140px;
            height: 140px;
            span {
              font-size: 20px;
              line-height: 24px;
            }
          `)}
          
          ${media.mobile(css`
            width: 120px;
            height: 120px;
            span {
              font-size: 14px;
              line-height: 18px;
            }
          `)}
        `
      : ''}
`;

export default PromotionCard;
