import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import { ImageThumbnail, StringField } from '@/typings/model';
import ProductsSlide from '@/modules/OwnProduction/components/ProductsSlider/components/ProductsSlide';

interface Props {
  title: StringField;
  list: Array<{
    image: ImageThumbnail;
    link: StringField;
    title: StringField;
  }>;
}

function ShopsSection({ title, list }: Props) {
  return (
    <Component>
      <Inner>
        <Top>
          <Title>{title}</Title>
        </Top>
        <List>
          {list?.map((listItem) => {
            return (
              <Cell>
                <ProductsSlide product={listItem} isProduct={true} />
              </Cell>
            );
          })}
        </List>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  margin-top: 145px;

  ${media.laptop(css`
    margin-top: 80px;
  `)}

  ${media.tablet(css`
    margin-top: 70px;
  `)}
`;

const Inner = styled.div``;

const Top = styled.div`
  max-width: 1680px;
  margin: 0 auto 88px;
  padding: 0 40px;

  ${media.laptop(css`
    margin-bottom: 40px;
  `)}

  ${media.tablet(css`
    padding: 0 20px;
    margin-bottom: 50px;
  `)}
`;

const Title = styled.h2`
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.black};

  ${media.laptop(css`
    max-width: 920px;
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    font-size: 48px;
    line-height: 56px;
  `)}
  
  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
  `)}
`;

const List = styled.div`
  display: flex;

  ${media.tablet(css`
    overflow-x: auto;
    overflow-y: hidden;

    scrollbar-color: transparent transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 0;

      &-track {
        background-color: transparent;
      }

      &-thumb {
        background-color: transparent;
        &:hover {
          background-color: transparent;
        }
      }
    }
  `)}
`;
const Cell = styled.div`
  flex: 1 1 33.33%;
  max-width: 33.33%;

  ${media.tablet(css`
    flex: 0 0 42vw;
    max-width: 42vw;
  `)}

  ${media.mobile(css`
    flex: 0 0 71vw;
    max-width: 71vw;
  `)}
`;

export default ShopsSection;
