import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { StringField } from '@/typings/model';

import AboutItem from './components/AboutItem';

type Props = {
  aboutTitle: StringField;
  aboutItems?: Array<{
    title: StringField;
    text: StringField;
  }>;
};

function About({ aboutItems, aboutTitle }: Props) {
  return (
    <Component>
      <Top>
        <Title>{aboutTitle}</Title>
      </Top>
      <Bottom>
        {aboutItems?.map((aboutItem, index) => {
          return <AboutItem key={index} aboutItem={aboutItem} />;
        })}
      </Bottom>
    </Component>
  );
}

const Component = styled.div`
  max-width: 1680px;
  padding: 0 40px;
  margin: 230px auto 0;

  ${media.laptop(css`
    margin: 204px auto 0;
  `)}

  ${media.tablet(css`
    margin: 80px auto 0;
    padding: 0 20px;
  `)}
  
  ${media.mobile(css`
    margin: 50px auto 0;
  `)}
`;

const Top = styled.div`
  margin-bottom: 62px;

  ${media.laptop(css`
    margin-bottom: 38px;
  `)}

  ${media.tablet(css`
    margin-bottom: 30px;
  `)}
  
  ${media.mobile(css`
    margin-bottom: 55px;
  `)}
`;

const Bottom = styled.div``;

const Title = styled.div`
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.black};

  ${media.laptop(css`
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    font-size: 48px;
    line-height: 56px;
  `)}
  
  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
  `)}
`;

export default About;
