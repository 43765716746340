import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { ImageThumbnail, StringField } from '@/typings/model';
import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

import Circle from './components/Circle';

type Props = {
  certificateValidityTitle: StringField;
  certificateValidityImage?: ImageThumbnail;
  certificateValidityItems?: Array<{
    title: StringField;
  }>;
};

function Shops({
  certificateValidityTitle,
  certificateValidityImage,
  certificateValidityItems,
}: Props) {
  return (
    <Component>
      {certificateValidityImage ? (
        <Background>
          <Picture
            mobileSmall={convertThumbnailToPictureImage(
              certificateValidityImage
            )}
          />
        </Background>
      ) : null}

      <Inner>
        <Title
          dangerouslySetInnerHTML={{ __html: certificateValidityTitle ?? '' }}
        />
        <Content>
          <ListWrapper>
            <List>
              {certificateValidityItems?.map(
                (certificateValidityItem, index) => {
                  return (
                    <ListItem key={index}>
                      <ListItemText>
                        {certificateValidityItem.title}
                      </ListItemText>
                    </ListItem>
                  );
                }
              )}
            </List>
          </ListWrapper>
        </Content>
        <CircleYellow>
          <Circle color={'yellow'} />
        </CircleYellow>
        <CircleOrange>
          <Circle color={'orange'} />
        </CircleOrange>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  padding: 250px 0 70px;
  z-index: 1;

  ${media.laptop(css`
    padding: 142px 0 155px;
  `)}

  ${media.tablet(css`
    padding: 118px 0 0;
  `)}
  
  ${media.mobile(css`
    padding: 80px 0 115px;
  `)}

  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45));
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  div,
  picture {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Inner = styled.div`
  max-width: 1680px;
  margin: 0 auto;
  padding: 0 40px;
  position: relative;
  z-index: 2;

  display: flex;
  flex-direction: column;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

const Title = styled.div`
  max-width: 920px;
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.white};

  ${media.laptop(css`
    max-width: 720px;
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    max-width: 633px;
    font-size: 48px;
    line-height: 56px;
  `)}
  
  ${media.mobile(css`
    max-width: 100%;
    font-size: 34px;
    line-height: 42px;
    position: relative;
    z-index: 2;
  `)}

  b {
    font-weight: 500;
    color: ${colors.orange};
  }
`;

const Content = styled.div`
  margin-top: 128px;
  padding: 127px 0 127px 146px;
  flex: 1 1 875px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.laptop(css`
    flex: 1 1 540px;
    margin-top: 90px;
    padding: 163px 0 163px 146px;
  `)}

  ${media.tablet(css`
    margin-top: 90px;
    display: flex;
    justify-content: flex-end;
    padding: 196px 20px 100px 0;
  `)}
  
  ${media.mobile(css`
    flex: 1 1 auto;
    margin-top: 50px;
    padding: 0;
    justify-content: flex-start;
  `)}
`;

const ListWrapper = styled.div`
  min-width: 927px;
  background: rgba(196, 196, 196, 0.2);
  backdrop-filter: blur(30px);
  border-radius: 0 30px 0 0;
  border-left: 3px solid ${colors.white};
  border-bottom: 3px solid ${colors.white};

  position: relative;
  left: 4px;
  top: -4px;

  ${media.laptop(css`
    min-width: 700px;
    left: 17px;
    top: 1px;
  `)}

  ${media.tablet(css`
    min-width: 632px;
    z-index: 2;
  `)}
  
  ${media.mobile(css`
    min-width: 398px;
    left: 0;
  `)}
`;

const List = styled.div`
  padding: 44px 80px 44px 100px;
  counter-reset: list;

  ${media.laptop(css`
    padding: 36px 58px 36px 71px;
  `)}

  ${media.tablet(css`
    padding: 34px 50px;
  `)}
  
  ${media.mobile(css`
    padding: 20px 15px;
  `)}
`;

const ListItem = styled.div`
  ${media.mobile(css`
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }
  `)}

  &:before {
    font-weight: bold;
    font-size: 82px;
    line-height: 90px;
    letter-spacing: -1px;
    counter-increment: list;
    content: counter(list) '';
    color: ${colors.orange};
    margin-right: 14px;

    ${media.laptop(css`
      margin-right: 8px;
      font-size: 72px;
      line-height: 78px;
    `)}

    ${media.tablet(css`
      font-size: 54px;
      line-height: 62px;
      margin-right: 8px;
    `)}
    
    ${media.mobile(css`
      font-size: 34px;
      line-height: 42px;
    `)}
  }
`;
const ListItemText = styled.span`
  width: 100%;
  padding: 0 55px;
  font-weight: bold;
  font-size: 82px;
  line-height: 90px;
  letter-spacing: -1px;
  position: relative;
  color: ${colors.white};

  &:before {
    position: absolute;
    content: '<<';
    top: 50%;
    transform: translateY(-47%);
    left: 0;
    font-size: 55px;
    line-height: 70px;
    color: ${colors.white};

    ${media.laptop(css`
      left: 3px;
      transform: translateY(-46%);
      font-size: 48px;
    `)}

    ${media.tablet(css`
      left: 0;
      font-size: 38px;
      transform: translateY(-50%);
    `)}
    
    ${media.mobile(css`
      font-size: 24px;
      line-height: 100%;
    `)}
  }

  &:after {
    position: absolute;
    content: '>>';
    top: 50%;
    transform: translateY(-47%);
    right: 0;
    font-size: 55px;
    line-height: 70px;
    color: ${colors.white};

    ${media.laptop(css`
      right: 2px;
      transform: translateY(-46%);
      font-size: 48px;
    `)}

    ${media.tablet(css`
      right: 0;
      font-size: 38px;
      transform: translateY(-50%);
    `)}
    
    ${media.mobile(css`
      font-size: 24px;
      line-height: 100%;
    `)}
  }

  ${media.laptop(css`
    padding: 0 51px;
    font-size: 72px;
    line-height: 50px;
  `)}

  ${media.tablet(css`
    padding: 0 42px;
    font-size: 54px;
    line-height: 62px;
  `)}
  
  ${media.mobile(css`
    padding: 0 24px;
    font-size: 34px;
    line-height: 42px;
  `)}
`;

const CircleYellow = styled.div`
  max-width: 870px;
  width: 100%;
  position: absolute;
  top: -19.9%;
  right: -24.7%;
  z-index: 1;

  ${media.laptop(css`
    max-width: 625.74px;
    top: -18%;
    right: -18.7%;
  `)}

  ${media.tablet(css`
    max-width: 335px;
    top: 2%;
    right: -16%;
  `)}
  
  ${media.mobile(css`
    max-width: 162px;
    top: -21%;
    right: -14%;
  `)}
`;
const CircleOrange = styled.div`
  max-width: 1036px;
  width: 100%;
  position: absolute;
  bottom: -7%;
  left: -38%;

  ${media.laptop(css`
    max-width: 705.74px;
    bottom: -8%;
    left: -30%;
  `)}

  ${media.tablet(css`
    max-width: 516px;
    bottom: 14%;
    left: -34%;
  `)}
  
  ${media.mobile(css`
    max-width: 412px;
    width: 412px;
    bottom: 8%;
    left: -57%;
  `)}
`;

export default Shops;
