import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { StringField } from '@/typings/model';
import { media } from '@/utils/mixin';

type Props = {
  sizeItems?: Array<{
    cost: StringField;
    discount: StringField;
  }>;
};

function DiscountList({ sizeItems }: Props) {
  return (
    <Component>
      <CircleList>
        {sizeItems?.map((sizeItem, index) => {
          return (
            <CircleCell key={index}>
              <CircleItem>
                <CircleText>{sizeItem.discount}%</CircleText>
              </CircleItem>
            </CircleCell>
          );
        })}
      </CircleList>
      <CostList>
        <CostItem>0 р.</CostItem>
        {sizeItems?.map((sizeItem, index) => {
          return (
            <CostItem key={index}>
              {sizeItem.cost}
              <CostSubtext>p.</CostSubtext>
            </CostItem>
          );
        })}
      </CostList>
    </Component>
  );
}

const Component = styled.div`
  ${media.mobile(css`
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  `)}

  ${media.mobileMedium(css`
    justify-content: flex-start;
  `)}
`;

const CircleList = styled.div`
  display: flex;

  ${media.mobile(css`
    flex-direction: column;
    margin: 24px 0 0 -40px;
  `)}

  ${media.mobileSmall(css`
    margin: 24px 0 0 -73px;
    position: relative;
    right: -10px;
  `)}
`;

const CircleText = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;

  ${media.laptop(css`
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    font-size: 14px;
    line-height: 20px;
  `)}
  
  ${media.mobile(css`
    line-height: 19px;
  `)}
`;

const CircleItem = styled.div`
  position: relative;
  border-radius: 100%;
  width: 100%;

  &:before {
    display: block;
    content: '';
    padding-bottom: 100%;
  }
`;

const CircleCell = styled.div`
  display: flex;
  align-items: flex-end;
  flex: 1 1 106px;
  max-width: 106px;

  ${CircleItem} {
    background: ${colors.yellow200};
    color: ${colors.black};
  }

  ${media.tablet(css`
    flex: 1 1 50px;
    max-width: 100%;
  `)}

  ${media.mobile(css`
    flex: 0 0 46px;
    width: 46px;
  `)}

  &:nth-child(2) {
    flex: 1 1 213px;
    max-width: 213px;

    ${media.tablet(css`
      flex: 1 1 98px;
      max-width: 100%;
    `)}

    ${media.mobile(css`
      flex: 0 0 96px;
      width: 96px;
    `)}

    ${CircleText} {
      font-size: 74px;
      line-height: 86px;

      ${media.laptop(css`
        font-size: 56px;
        line-height: 64px;
      `)}

      ${media.tablet(css`
        font-size: 34px;
        line-height: 38px;
      `)}
      
      ${media.mobile(css`
        font-size: 32px;
        line-height: 38px;
      `)}
    }
  }

  &:nth-child(3) {
    flex: 1 1 320px;
    max-width: 320px;

    ${media.tablet(css`
      flex: 1 1 146px;
      max-width: 100%;
    `)}

    ${media.mobile(css`
      flex: 0 0 142px;
      width: 142px;
    `)}

    ${CircleText} {
      font-size: 118px;
      line-height: 128px;

      ${media.laptop(css`
        font-size: 88px;
        line-height: 96px;
      `)}

      ${media.tablet(css`
        font-size: 54px;
        line-height: 58px;
      `)}
      
      ${media.mobile(css`
        font-size: 52px;
        line-height: 56px;
      `)}
    }
  }
  &:nth-child(4) {
    flex: 1 1 426px;
    max-width: 426px;

    ${media.tablet(css`
      flex: 1 1 194px;
      max-width: 100%;
    `)}

    ${media.mobile(css`
      flex: 0 0 188px;
      width: 188px;
    `)}

    ${CircleText} {
      font-size: 170px;
      line-height: 182px;

      ${media.laptop(css`
        font-size: 128px;
        line-height: 136px;
      `)}

      ${media.tablet(css`
        font-size: 78px;
        line-height: 82px;
      `)}
      
      ${media.mobile(css`
        font-size: 75px;
        line-height: 80px;
      `)}
    }
  }

  &:last-child {
    flex: 1 1 533px;
    max-width: 533px;

    ${media.tablet(css`
      flex: 1 1 242px;
      max-width: 100%;
    `)}

    ${media.mobile(css`
      flex: 0 0 235px;
      width: 235px;
    `)}

    ${CircleItem} {
      background: ${colors.orange};
      color: ${colors.white};
    }

    ${CircleText} {
      font-size: 234px;
      line-height: 245px;

      ${media.laptop(css`
        font-size: 176px;
        line-height: 184px;
      `)}

      ${media.tablet(css`
        font-size: 106px;
        line-height: 111px;
      `)}
      
      ${media.mobile(css`
        font-size: 103px;
        line-height: 108px;
      `)}
    }
  }
`;

const CostList = styled.div`
  border-top: 2px solid ${colors.black};
  display: flex;

  ${media.laptop(css`
    border-top: 1px solid ${colors.black};
  `)}

  ${media.tablet(css`
    padding-top: 2px;
  `)}
  
  ${media.mobile(css`
    display: flex;
    flex-direction: column;
    border: none;
    max-width: 153px;
    width: 100%;
    padding-left: 28px;
  `)}
`;

const CostSubtext = styled.span`
  margin-left: 5px;
`;

const CostItem = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;

  ${media.laptop(css`
    font-size: 16px;
    line-height: 24px;
  `)};

  ${media.tablet(css`
    font-size: 10px;
    line-height: 16px;
  `)};

  ${media.mobile(css`
    font-size: 12px;
    line-height: 20px;
  `)};

  flex: 1 1 53px;
  max-width: 53px;
  white-space: nowrap;

  ${media.mobile(css`
    flex: 1 1 auto;
    max-width: 65px !important;
    text-align: center;
    align-items: flex-end;
    position: relative;

    &:before {
      position: absolute;
      content: '';
      top: auto;
      bottom: -4px;
      left: 0;
      width: 65px;
      height: 1px;
      background: ${colors.black1000};
    }

    &:first-child {
      &:before {
        top: 20px;
        bottom: auto;
      }
    }
  `)};

  &:nth-child(2) {
    flex: 1 1 110px;
    max-width: 110px;
    display: flex;
    justify-content: center;

    ${media.mobile(css`
      flex: 0 0 44px;
    `)};
  }

  &:nth-child(3) {
    flex: 1 1 310px;
    max-width: 310px;
    display: flex;
    justify-content: center;

    ${media.laptop(css`
      max-width: 330px;
    `)};

    ${media.mobile(css`
      flex: 0 0 96px;
    `)};
  }

  &:nth-child(4) {
    flex: 1 1 335px;
    max-width: 335px;
    display: flex;
    justify-content: center;

    ${media.laptop(css`
      flex: 1 1 320px;
      max-width: 320px;
    `)};

    ${media.mobile(css`
      flex: 0 0 142px;
    `)};
  }

  &:nth-child(5) {
    flex: 1 1 560px;
    max-width: 560px;
    display: flex;
    justify-content: center;

    ${media.mobile(css`
      flex: 0 0 188px;
    `)};
  }

  &:last-child {
    flex: 1 1 auto;
    max-width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 8px;

    ${media.laptop(css`
      padding-right: 0;
    `)};

    ${media.mobile(css`
      flex: 0 0 235px;
      max-width: 100% !important;
    `)};

    ${CostSubtext} {
      display: none;
    }
  }
`;

export default DiscountList;
