import React from 'react';
import styled, { css } from 'styled-components';

import { StringField } from '@/typings/model';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

type Props = {
  title: StringField;
  text: StringField;
};

function Hero({ title, text }: Props) {
  return (
    <Component>
      <HeroContainer>
        <Inner>
          <Left>
            <Title dangerouslySetInnerHTML={{ __html: title ?? '' }} />
          </Left>
          {text && (
            <Right>
              <Text>{text}</Text>
            </Right>
          )}
        </Inner>
      </HeroContainer>
    </Component>
  );
}

const Component = styled.div`
  padding-top: 132px;

  ${media.laptop(css`
    padding-top: 103px;
  `)}

  ${media.tablet(css`
    padding-top: 30px;
  `)}
`;

const HeroContainer = styled.div`
  max-width: 1680px;
  padding: 0 40px;
  margin: 0 auto;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

const Inner = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.mobile(css`
    display: block;
  `)}
`;

const Left = styled.div`
  flex: 1 1 50%;
  max-width: 50%;

  ${media.tablet(css`
    flex: 1 1 422px;
    max-width: 100%;
  `)}
`;

const Right = styled.div`
  flex: 1 1 50%;
  max-width: 50%;
  margin-left: 35px;
  padding-bottom: 25px;

  display: flex;
  align-items: flex-end;

  ${media.laptop(css`
    flex: 1 1 486px;
    max-width: 486px;
    padding-bottom: 0;
  `)}

  ${media.tablet(css`
    flex: 1 1 300px;
  `)}
  
  ${media.tabletSmall(css`
    flex: 1 1 250px;
    max-width: 32.6vw;
  `)}
  
  ${media.mobile(css`
    margin: 30px 0 0;
    max-width: 100%;
  `)}
`;

const Title = styled.h1`
  margin: 0;
  max-width: 650px;
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.white};

  b {
    font-weight: 500;
    color: ${colors.orange};
  }

  ${media.laptop(css`
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    font-size: 48px;
    line-height: 56px;
  `)}
  
  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
  `)}
`;
const Text = styled.p`
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  color: ${colors.white};

  ${media.laptop(css`
    font-size: 20px;
    line-height: 26px;
  `)}

  ${media.tablet(css`
    font-size: 18px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    font-size: 14px;
    line-height: 20px;
  `)}
`;

export default Hero;
