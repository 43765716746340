import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from 'swiper';

import { useModal } from '@tager/web-components';

import { ReactComponent as NextIcon } from '@/assets/svg/slider-right.svg';
import { ReactComponent as PrevIcon } from '@/assets/svg/slider-left.svg';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import { HypermarketProductsType } from '@/typings/model';
import HypermarketsModal from '@/components/modals/HypermarketsModal';

import HeroSliderItem from './components/HeroSliderItem';

SwiperCore.use([Navigation, Autoplay, Pagination, EffectFade]);

interface Props {
  sliderList: Array<HypermarketProductsType>;
}

function HeroSlider({ sliderList }: Props) {
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);

  const openModal = useModal();

  function openHypermarketsModal(indexActive: number) {
    openModal(HypermarketsModal, {
      activeSlide: indexActive,
      hypermarketsList: sliderList,
    });
  }

  return (
    <Component>
      <SliderContainer>
        <Swiper
          initialSlide={0}
          slidesPerView={1}
          speed={500}
          autoplay={{ delay: 4000, disableOnInteraction: false }}
          loop={true}
          pagination={{
            el: `.swiper-pagination`,
            type: 'bullets',
            clickable: true,
          }}
          navigation={{
            nextEl: '.swiper-next',
            prevEl: '.swiper-prev',
          }}
          onSlideChange={(swiper) => setActiveSlideIndex(swiper.activeIndex)}
        >
          {sliderList.map((sliderItem, index) => {
            return (
              <SwiperSlide key={sliderItem.title}>
                <SwiperSlideInner onClick={() => openHypermarketsModal(index)}>
                  <HeroSliderItem sliderItem={sliderItem} />
                </SwiperSlideInner>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <ButtonPrev className="swiper-prev">
          <PrevIcon />
        </ButtonPrev>
        <ButtonNext className="swiper-next">
          <NextIcon />
        </ButtonNext>
      </SliderContainer>
    </Component>
  );
}

const Component = styled.div`
  width: 100%;
  height: 100%;
`;

const SliderContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  .swiper-container {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    width: 100% !important;
  }
`;

const SwiperSlideInner = styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const ButtonPrev = styled.div`
  position: absolute;
  top: 50%;
  left: 16px;
  z-index: 2;
  cursor: pointer;
  transition: all 0.3s linear;
  transform: translateY(-50%);

  width: 84px;
  height: 84px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 100%;

  svg {
    position: relative;
    top: -1px;
    left: -2px;
    width: 40px;
    height: 40px;
    pointer-events: none;
    will-change: transform;
  }

  ${media.laptop(css`
    left: 12px;

    width: 56px;
    height: 56px;

    svg {
      top: 0;
      left: -1px;
      width: 30px;
      height: 30px;
    }
  `)}

  ${media.tablet(css`
    left: 21px;
    width: 40px;
    height: 40px;
  `)}

  ${media.laptopUp(css`
    &:hover {
      background: ${colors.gray50};
    }
  `)}
`;

const ButtonNext = styled.div`
  position: absolute;
  top: 50%;
  right: 16px;
  z-index: 2;
  cursor: pointer;
  transition: all 0.3s linear;
  transform: translateY(-50%);

  width: 84px;
  height: 84px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 100%;

  svg {
    position: relative;
    top: -1px;
    right: -2px;
    width: 40px;
    height: 40px;
    pointer-events: none;
    will-change: transform;
  }

  ${media.laptop(css`
    right: 12px;

    width: 56px;
    height: 56px;

    svg {
      top: 0;
      right: -1px;
      width: 30px;
      height: 30px;
    }
  `)}

  ${media.tablet(css`
    right: 21px;
    width: 40px;
    height: 40px;
  `)}

  ${media.laptopUp(css`
    &:hover {
      background: ${colors.gray50};
    }
  `)}
`;

export default HeroSlider;
