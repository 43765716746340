import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { StringField } from '@/typings/model';

interface Props {
  count: number;
  label: StringField;
}

function StoreLabel({ label, count }: Props) {
  return (
    <Component>
      <Label>
        <Title>{label}</Title>
        <Description>{count}</Description>
      </Label>
    </Component>
  );
}

const Component = styled.div`
  padding: 11px;

  ${media.laptop(css`
    padding: 8px;
  `)}

  ${media.mobile(css`
    padding: 8px 4px;
  `)}
`;

const Label = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;

  padding: 32px 38px;
  background: ${colors.black};
  border-radius: 50px;
  transition: all 0.3s linear;
  font-weight: 500;
  color: ${colors.white};

  ${media.laptop(css`
    padding: 24px 32px;
    border-radius: 35px;
  `)}

  ${media.tablet(css`
    padding: 14px 22px;
  `)}
  
  ${media.mobile(css`
    padding: 12px 20px;
  `)}
`;

const Title = styled.span`
  font-size: 32px;
  line-height: 42px;

  ${media.laptop(css`
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    font-size: 20px;
    line-height: 24px;
  `)}
  
  ${media.mobile(css`
    font-size: 16px;
    line-height: 20px;
  `)}
`;

const Description = styled.span`
  margin-left: 8px;
  font-size: 14px;
  line-height: 18px;

  ${media.laptop(css`
    margin-left: 4px;
    font-size: 12px;
    line-height: 18px;
  `)}

  ${media.tablet(css`
    font-size: 10px;
    line-height: 16px;
  `)}
  
  ${media.mobile(css`
    font-size: 12px;
    line-height: 10px;
  `)}
`;

export default StoreLabel;
