import React from 'react';
import styled, { css } from 'styled-components';

import { ModalProps } from '@tager/web-components';

import { media } from '@/utils/mixin';
import Picture from '@/components/Picture';
import { StringField } from '@/typings/model';
import { ReactComponent as CloseIcon } from '@/assets/svg/close.svg';
import { colors } from '@/constants/theme';

type Props = ModalProps<{
  imagePopup: StringField;
}>;

function MenuModal({ innerProps, closeModal }: Props) {
  const { imagePopup } = innerProps;
  return (
    <Wrapper>
      <CloseButton onClick={closeModal}>
        <CloseIcon />
      </CloseButton>
      <Inner>
        <Image>
          {imagePopup && (
            <Picture
              mobileSmall={{
                src: imagePopup,
              }}
            />
          )}
        </Image>
      </Inner>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  padding: 100px 90px;
  position: relative;

  ${media.laptop(css`
    padding: 50px 90px;
  `)}

  ${media.tablet(css`
    padding: 0;
  `)}
`;

const Inner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.div`
  position: relative;
  max-width: 720px;
  width: 100%;

  ${media.laptop(css`
    max-width: 640px;
  `)}

  ${media.tablet(css`
    max-width: 537px;
  `)}

  &:before {
    display: block;
    content: '';
    padding-bottom: 77.77%;

    ${media.laptop(css`
      padding-bottom: 75%;
    `)}

    ${media.mobile(css`
      padding-bottom: 100%;
    `)}
  }

  div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  picture {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 16px;
    overflow: hidden;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 22px;
  right: 45px;
  border-radius: 50%;
  transition: background-color 0.1s;
  padding: 20px;
  z-index: 10;

  ${media.laptop(css`
    top: 14px;
    right: 14px;
    padding: 10px;
  `)}

  ${media.tablet(css`
    cursor: default;
  `)}
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  svg {
    width: 26px;
    height: 26px;
    display: block;
    color: ${colors.white};

    ${media.laptop(css`
      width: 16px;
      height: 16px;
    `)}

    ${media.tablet(css`
      width: 24px;
      height: 24px;
    `)}
  }
`;

export default MenuModal;
