import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as Clock } from '@/assets/svg/oclock.svg';
import { ReactComponent as Work } from '@/assets/svg/work.svg';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { ButtonLink } from '@/components/Button';
import { shouldAddCityPrefix } from '@/utils/common';
import useWindowSize from '@/hooks/useWindowSize';
import ContactDataModal from '@/components/modals/ContactDataModal';

import HotSticker from './HotSticker';

type Props = {
  vacancyItem: {
    description: string;
    id: number;
    name: string;
    store: string;
    urlAlias: string;
    city: string;
    isDisplayOnHome: number;
    isHotVacancy: number;
    contact_name: string;
    contact_email: string;
    contact_phone: string;
    salary: string;
    experience: string | null;
    schedule: string | null;
  };
  updateList?: number;
  onTapContact: (data: {
    contact_name: string;
    contact_email: string;
    contact_phone: string;
  }) => void;
};

function VacanciesItem({ vacancyItem, onTapContact }: Props) {
  const accordContentRef = useRef<HTMLDivElement>(null);
  const { name, store, urlAlias, id, city, isHotVacancy } = vacancyItem;

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const windowSize = useWindowSize();

  const toggling = () => {
    if (windowSize.width > 768) {
      setIsOpen(!isOpen);
    } else {
      onTapContact({
        contact_email: vacancyItem.contact_email,
        contact_phone: vacancyItem.contact_phone,
        contact_name: vacancyItem.contact_name,
      });
    }
  };

  const onOptionClicked = (value: any) => () => {
    setSelectedOption(value);
    setIsOpen(false);
  };

  return (
    <Component isActive={true} id={String(id)}>
      {isHotVacancy ? (
        <StickerWrapper>
          <HotSticker />
        </StickerWrapper>
      ) : null}
      <Inner>
        <Top>
          <Left>
            <Title>{name}</Title>
            <Address>
              {shouldAddCityPrefix(city)}, {store}
            </Address>
            {vacancyItem.salary && <Money>{vacancyItem.salary}</Money>}

            <ContainerInfo>
              {vacancyItem.experience && (
                <ContentRow>
                  <Work style={{ marginRight: 12, height: 24, width: 24 }} />
                  <Item>{vacancyItem.experience}</Item>
                </ContentRow>
              )}
              {vacancyItem.schedule && (
                <ContentRow>
                  <Clock style={{ marginRight: 12, height: 24, width: 24 }} />
                  <Item>{vacancyItem.schedule}</Item>
                </ContentRow>
              )}
            </ContainerInfo>
          </Left>
        </Top>
        <Content>
          <ContentInner ref={accordContentRef}>
            <ContentRow>
              <VacancyBtn
                variant="orange"
                href={`/careers/vacancies/${urlAlias}`}
              >
                Заполнить анкету
              </VacancyBtn>

              <DropDownContainer>
                <DropDownHeader onClick={toggling}>
                  <p>{selectedOption || 'Контакты'}</p>
                </DropDownHeader>
                {windowSize.width > 768 && isOpen && (
                  <DropDownListContainer>
                    <DropDownList>
                      <ContactDataModal
                        innerProps={{
                          phones: vacancyItem.contact_phone.split(';'),
                          email: vacancyItem.contact_email,
                          title: vacancyItem.contact_name,
                          isDropDown: true,
                        }}
                        closeModal={() => setIsOpen(false)}
                      />
                    </DropDownList>
                  </DropDownListContainer>
                )}
              </DropDownContainer>
            </ContentRow>
          </ContentInner>
        </Content>
      </Inner>
    </Component>
  );
}

const DropDownContainer = styled('div')`
  background: white;
  color: #f9683a;
  border: 2px solid #f9683a;
  display: block;
  width: 192px;
  height: 65px;
  transition: all 0.3s linear;
  cursor: pointer;
  align-content: center;

  justify-content: center;

  font-weight: 500;
  font-size: 30px;
  line-height: 36px;

  &:hover {
    background: #f98544;
    border: 2px solid #f98544;
    color: white;
  }
  ${media.tablet(css`
    width: 159px;
    height: 53px;

    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
  `)}

  ${media.mobile(css`
    width: 126px;
    height: 53px;

    font-weight: 500;
    font-size: 18px;
    line-height: 36px;
  `)}

  ${media.mobileSmall(css`
    width: 95px;
    height: 36px;

    font-weight: 500;
    font-size: 14px;
    line-height: 12px;
  `)}
`;

const DropDownHeader = styled('div')`
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-content: center;
  align-self: center;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.48em;
  padding-top: 0.48em;
  ${media.tabletOnly(css`
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    padding-bottom: 0.38em;
    padding-top: 0.38em;
  `)}

  ${media.mobile(css`
    font-weight: 500;
    font-size: 18px;
    line-height: 36px;
  `)}

  ${media.mobileSmall(css`
    font-weight: 500;
    font-size: 14px;
    line-height: 12px;
  `)}
`;

const DropDownListContainer = styled('div')`
  position: absolute;
  z-index: 100;
  -webkit-box-shadow: 7px 3px 13px -1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 7px 3px 13px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 7px 3px 13px -1px rgba(0, 0, 0, 0.2);
`;

const DropDownList = styled('ul')``;

const Inner = styled.div`
  padding-bottom: 48px;
  border-bottom: 2px solid ${colors.grayLight};

  ${media.laptop(css`
    padding-bottom: 62px;
    border-bottom: 1px solid ${colors.grayLight};
  `)}

  ${media.tablet(css`
    padding-bottom: 30px;
  `)}
`;

const Left = styled.div``;

const Right = styled.div`
  padding: 16px 10px 0 0;

  ${media.laptop(css`
    padding: 13px 9px 0 0;
  `)}
  ${media.tablet(css`
    margin-left: 20px;
  `)}
  svg {
    width: 28px;
    height: 17px;
    transition: all 0.45s ease-in-out;

    ${media.laptop(css`
      width: 22px;
      height: 14px;
    `)}
  }
`;

const Content = styled.div`
  ${media.mobileSmall(css`
    margin-top: 22px;
  `)}
`;
const ContentInner = styled.div``;
const ContainerInfo = styled.div`
  margin-top: 10px;
  margin-bottom: 32px;
`;

const ContentRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0px;
`;

const Title = styled.span`
  display: block;
  max-width: 700px;
  font-weight: 500;
  font-size: 50px;
  line-height: 58px;
  color: ${colors.black};
  transition: all 0.3s linear;

  ${media.laptop(css`
    max-width: 516px;
    font-size: 48px;
    line-height: 56px;
  `)}

  ${media.tablet(css`
    max-width: none;
    font-size: 32px;
    line-height: 38px;
  `)}

  ${media.mobile(css`
    font-size: 28px;
    line-height: 34px;
  `)}
`;

const Address = styled.address`
  display: flex;
  font-style: normal;
  font-size: 30px;
  line-height: 36px;
  margin-top: 8px;
  color: ${colors.grayLight};

  ${media.laptop(css`
    font-size: 20px;
    line-height: 26px;
  `)}

  ${media.mobileSmall(css`
    font-size: 14px;
    line-height: 26px;
  `)}
`;

const Item = styled.address`
  display: flex;
  font-style: normal;
  font-size: 30px;
  line-height: 36px;
  color: ${colors.black1000};

  ${media.tabletOnly(css`
    font-size: 24px;
    line-height: 36px;
  `)}

  ${media.tabletSmall(css`
    font-size: 18px;
    line-height: 36px;
  `)}

  ${media.mobileSmall(css`
    font-size: 14px;
    line-height: 36px;
  `)}
`;

const Money = styled.p`
  display: flex;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: ${colors.black1000};
  margin-top: 34px;
  margin-bottom: 34px;

  ${media.tabletOnly(css`
    font-size: 24px;
    line-height: 36px;
  `)}

  ${media.tabletSmall(css`
    font-size: 18px;
    line-height: 36px;
    margin-top: 21px;
    margin-bottom: 21px;
  `)}

  ${media.mobileSmall(css`
    font-size: 16px;
    line-height: 36px;
    margin-top: 18px;
    margin-bottom: 18px;
  `)}
`;

const VacancyBtn = styled(ButtonLink)`
  display: block;
  width: 313px;
  height: 65px;
  transition: all 0.3s linear;
  margin-right: 24px;
  align-content: center;

  font-weight: 500;
  font-size: 30px;
  line-height: 36px;

  ${media.tablet(css`
    width: 256px;
    height: 53px;

    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
  `)}

  ${media.mobile(css`
    width: 197px;
    height: 53px;

    font-weight: 500;
    font-size: 18px;
    line-height: 36px;
  `)}

  ${media.mobileSmall(css`
    width: 146px;
    height: 36px;

    font-weight: 500;
    font-size: 14px;
    line-height: 12px;
  `)}
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  z-index: 2;
  position: relative;
`;

const StickerWrapper = styled.div`
  position: absolute;
  top: -148px;
  left: -182px;
  z-index: 1;

  ${media.laptop(css`
    top: -123px;
    left: -115px;
  `)}

  ${media.tablet(css`
    top: -70px;
    left: 138px;
  `)}

  ${media.mobile(css`
    display: none;
  `)}
`;

const Component = styled.div<{
  isActive: boolean;
  maxHeight?: number;
}>`
  margin-bottom: 50px;
  position: relative;

  ${media.laptop(css`
    margin-bottom: 40px;
  `)}
  ${media.tablet(css`
    margin-bottom: 30px;
  `)}
  &:last-child {
    margin-bottom: 0;

    ${Inner} {
      border-bottom: none;
    }
  }

  ${(props) =>
    props.isActive
      ? css`
          ${Right} {
            svg {
              transition: all 0.3s ease-in-out 0.5s;
              transform: rotate(-180deg);
            }
          }

          ${Content} {
            visibility: visible;
            max-height: 1000px;
            transition: all 0.5s ease-in-out 0.5s;
          }
        `
      : ''}
`;

export default VacanciesItem;
