import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import Star from '@/components/Sticker/Star';
import { HypermarketsPageType } from '@/typings/model';

import FindBox from './components/FindBox';
import Map from './components/Map';
import ShoppingCart from './components/ShoppingCart';

interface Props {
  page: HypermarketsPageType;
}

function FindSection({ page }: Props) {
  const {
    nearShopsLabel,
    nearShopsLabelSelectText,
    nearShopsLabelLink,
    nearShopsButton,
  } = page.templateFields;

  return (
    <Component>
      <Top>
        <InnerContent>
          <Title
            dangerouslySetInnerHTML={{
              __html: nearShopsLabel ?? '',
            }}
          />
        </InnerContent>
      </Top>
      <Bottom>
        <InnerContent>
          <BottomContent>
            <FindBox
              nearShopsLabelSelectText={nearShopsLabelSelectText ?? ''}
              nearShopsButton={nearShopsButton}
            />
          </BottomContent>
        </InnerContent>
      </Bottom>
      <Map />
      <ShoppingCart />
      <StarWrapper>
        <Star
          rotate={'18deg'}
          color="orange"
          label={nearShopsLabelLink?.label ?? ''}
          link={nearShopsLabelLink?.link ?? ''}
          isTarget={nearShopsLabelLink?.isNewTab ? '_blank' : '_self'}
          size={'big'}
        />
      </StarWrapper>
    </Component>
  );
}

const Component = styled.div`
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
`;

const InnerContent = styled.div`
  max-width: 1680px;
  padding: 0 40px;
  margin: 0 auto;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

const Top = styled.div`
  min-height: 620px;
  padding: 180px 0 120px;

  ${media.laptop(css`
    min-height: 448px;
    padding: 80px 0;
  `)}

  ${media.tablet(css`
    min-height: 384px;
  `)}
  
  ${media.mobile(css`
    min-height: 370px;
    padding: 50px 0 110px;
  `)}
`;

const Bottom = styled.div`
  position: relative;
  padding: 173px 0 220px;
  background: ${colors.black};

  ${media.laptop(css`
    padding: 146px 0;
  `)}

  ${media.tablet(css`
    padding: 170px 0 95px;
  `)}
    
  ${media.mobile(css`
    padding: 130px 0 50px;
  `)}


  &:before {
    position: absolute;
    content: '';
    top: 0;
    height: 100%;
    left: -3000px;
    right: -3000px;
    background: ${colors.black};
  }
`;

const BottomContent = styled.div`
  max-width: 512px;
  position: relative;
  z-index: 2;

  ${media.laptop(css`
    max-width: 394px;
  `)}

  ${media.tablet(css`
    max-width: 442px;
  `)}
`;

const Title = styled.h2`
  max-width: 716px;
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.black};

  b {
    font-weight: 500;
    color: ${colors.orange};
  }

  ${media.laptop(css`
    max-width: 640px;
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    max-width: 450px;
    font-size: 48px;
    line-height: 56px;
  `)}
  
  ${media.mobile(css`
    max-width: 300px;
    font-size: 34px;
    line-height: 42px;
  `)}
`;

const StarWrapper = styled.div`
  position: absolute;
  top: 32px;
  right: 150px;
  transform: rotate(-3deg);

  width: 360px;
  height: 360px;

  ${media.laptop(css`
    top: -40px;
    right: 255px;
    width: 240px;
    height: 240px;
  `)}

  ${media.tablet(css`
    top: -50px;
    right: 189px;
    width: 200px;
    height: 200px;
  `)}
  
  ${media.mobile(css`
    top: 198px;
    right: 126px;
    width: 164px;
    height: 164px;
  `)}
`;

export default FindSection;
