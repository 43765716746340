import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

interface Props {
  priceOld: Number;
  discount: Number;
  pricePerKg: Number;
  isCircle?: boolean;
  isCenter?: boolean;
}

function PriceBlock({
  priceOld,
  discount,
  pricePerKg,
  isCircle,
  isCenter,
}: Props) {
  return (
    <Component isCircle={isCircle} isCenter={isCenter}>
      <Left>
        {discount ? (
          <PriceLabel background={colors.red100}>
            <PriceLabelText>{discount}</PriceLabelText>
          </PriceLabel>
        ) : null}
        {priceOld !== 0 && priceOld !== null ? (
          <PriceLabel background={colors.black}>
            <PriceLabelText through={true}>
              {String(priceOld.toFixed(2))}
            </PriceLabelText>
          </PriceLabel>
        ) : null}
      </Left>
      <Right>
        {pricePerKg !== 0 && pricePerKg !== null && (
          <PriceLabel background={colors.yellowActive}>
            <PriceLabelText>{String(pricePerKg.toFixed(2))}/1кг</PriceLabelText>
          </PriceLabel>
        )}
      </Right>
    </Component>
  );
}

const PriceLabel = styled.div<{ background: string }>`
  position: relative;
  display: inline-block;
  padding: 0 8.5px;

  &:nth-child(2),
  &:last-child {
    left: -1px;
  }

  ${media.laptop(css`
    padding: 2px 5.5px;
  `)}

  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: skew(-10deg, 0);
    background: ${(props) =>
      props.background ? `${props.background}` : `${colors.black}`};
    z-index: 1;

    ${media.tablet(css`
      transform: skew(-11deg, 0);
    `)}
  }
`;

const PriceLabelText = styled.span<{ through?: boolean }>`
  position: relative;
  z-index: 2;
  font-weight: bold;
  font-size: 16px;
  line-height: 32px;
  color: ${colors.white};
  text-decoration-line: ${(props) => (props.through ? 'line-through' : 'none')};

  ${media.laptop(css`
    font-size: 16px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    font-size: 12px;
    line-height: 18px;
  `)}
    
  ${media.mobile(css`
    font-size: 14px;
    line-height: 20px;
  `)}
`;

const Left = styled.div`
  display: flex;
`;

const Right = styled.div`
  ${PriceLabel} {
    display: inline-block;
    left: -7px !important;
    padding: 0 8.5px !important;
    min-height: auto !important;
    min-width: auto !important;
    overflow: visible !important;

    ${media.laptop(css`
      padding: 2px 5.5px !important;
    `)}

    ${media.tablet(css`
      left: -4px !important;
    `)}
  }
`;

const Component = styled.div<{ isCircle?: boolean; isCenter?: boolean }>`
  ${(props) =>
    props.isCircle &&
    css`
      ${PriceLabel} {
        padding: 5.5px;
        border-radius: 100%;
        overflow: hidden;
        min-width: 60px;
        min-height: 60px;

        display: flex;
        justify-content: center;
        align-items: center;

        ${media.tablet(css`
          min-width: 50px;
          min-height: 50px;
        `)}

        ${media.mobile(css`
          min-width: 50px;
          min-height: 50px;
          padding: 0;
        `)}
      }

      ${Right} {
        span {
          padding: 4px 8.5px !important;

          ${media.laptop(css`
            padding: 4px 5.5px !important;
          `)}
        }
      }
    `};

  ${(props) =>
    props.isCenter &&
    css`
      display: flex;
      flex-direction: column-reverse;

      ${Left} {
        justify-content: center;
      }

      ${Right} {
        display: inline-flex;
        justify-content: center;
      }
    `}
`;
export default PriceBlock;
