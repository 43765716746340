import React from 'react';
import styled, { css } from 'styled-components';

import { StringField } from '@/typings/model';
import Star from '@/components/Sticker/Star';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

type Props = {
  title: StringField;
};

function Hero({ title }: Props) {
  return (
    <Component>
      <Inner>
        <Left>
          <Title dangerouslySetInnerHTML={{ __html: title ?? '' }} />
        </Left>
        <Right>
          <StarWrapper>
            <Star
              label={'С КАРТОЙ МОЖНО ЭКОНОМИТЬ'}
              size={'big'}
              color={'lime'}
              isDiscount={true}
              rotate={'6deg'}
            />
          </StarWrapper>
        </Right>
      </Inner>
    </Component>
  );
}

const Component = styled.div``;

const Inner = styled.div`
  display: flex;
  max-width: 1680px;
  padding: 0 40px;
  margin: 0 auto;

  ${media.tablet(css`
    padding: 0 20px;
  `)}

  ${media.mobile(css`
    position: relative;
    margin-bottom: 58px;
  `)}
`;

const Left = styled.div`
  flex: 1 1 auto;
  padding-top: 132px;

  ${media.laptop(css`
    padding-top: 104px;
  `)}

  ${media.tablet(css`
    padding-top: 50px;
  `)}
  
  ${media.mobile(css`
    padding-top: 30px;
  `)}
`;

const Right = styled.div`
  flex: 1 1 auto;
  padding: 58px 0 0 295px;

  ${media.laptop(css`
    padding: 104px 0 0 96px;
  `)}

  ${media.tablet(css`
    padding-top: 50px;
  `)}
  
  ${media.mobile(css`
    padding: 0;
    position: absolute;
    content: '';
    bottom: -180px;
    right: -41px;
  `)}
`;

const Title = styled.h2`
  max-width: 560px;
  margin: 0;
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.white};

  b {
    font-weight: 500;
    color: ${colors.orange};
  }

  ${media.laptop(css`
    max-width: 500px;
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tabletSmall(css`
    max-width: 442px;
    font-size: 48px;
    line-height: 56px;
  `)}
  
  ${media.mobile(css`
    max-width: 100%;
    font-size: 34px;
    line-height: 42px;
  `)}
`;

const StarWrapper = styled.div`
  transform: rotate(9deg);
  width: 470px;
  height: 470px;

  ${media.laptop(css`
    width: 400px;
    height: 400px;
  `)}

  ${media.tablet(css`
    width: 280px;
    height: 280px;
  `)}
  
  ${media.mobile(css`
    width: 210px;
    height: 210px;
  `)}
`;

export default Hero;
