import React from 'react';
import styled from 'styled-components';

import { colors } from '@/constants/theme';
import { GiftCertificatePageType } from '@/typings/model';
import useCurrentPage from '@/hooks/useCurrentPage';
import Breadcrumbs from '@/components/Breadcrumbs';
import Subscription from '@/components/Subscription';
import { useLayout } from '@/components/Layout/Layout.hooks';
import { BreadCrumbs } from '@/typings/common';
import { setBreadCrumbsList } from '@/utils/common';

import Hero from './components/Hero';
import FreeChoice from './components/FreeChoice';
import Shops from './components/Shops';
import OfflineCertificate from './components/OfflineCertificate';
import Procedures from './components/Procedures';

function GiftCertificate() {
  const page = useCurrentPage<GiftCertificatePageType>();
  const { setThemePage } = useLayout();
  setThemePage('');

  const BREADCRUMB_LIST: Array<BreadCrumbs> = setBreadCrumbsList(
    {
      title: page?.title ?? '',
      path: page?.path ?? '',
    },
    page?.parent
  );

  return (
    <Wrapper>
      <Breadcrumbs breadcrumbs={BREADCRUMB_LIST} isWhite={true} />
      <Hero
        firstBlockTitle={page?.templateFields.firstBlockTitle ?? ''}
        firstBlockText={page?.templateFields.firstBlockText ?? ''}
        firstBlockImage={page?.templateFields.firstBlockImage}
        firstBlockItems={page?.templateFields.firstBlockItems}
      />
      <FreeChoice
        freeChoiceTitle={page?.templateFields.freeChoiceTitle ?? ''}
        freeChoiceText={page?.templateFields.freeChoiceText ?? ''}
        freeChoiceImage={page?.templateFields.freeChoiceImage}
      />
      <OfflineCertificate
        offlineCertificateTitle={
          page?.templateFields.offlineCertificateTitle ?? ''
        }
        offlineCertificateButtonLabel={
          page?.templateFields.offlineCertificateButtonLabel ?? ''
        }
        offlineCertificateItems={page?.templateFields.offlineCertificateItems}
      />
      <Procedures
        procedureTitle={page?.templateFields.procedureTitle ?? ''}
        procedureItems={page?.templateFields.procedureItems}
      />
      <Shops
        certificateValidityTitle={
          page?.templateFields.certificateValidityTitle ?? ''
        }
        certificateValidityImage={page?.templateFields.certificateValidityImage}
        certificateValidityItems={page?.templateFields.certificateValidityItems}
      />
      <Subscription />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  overflow: hidden;
  background: ${colors.black};
`;

export default GiftCertificate;
