import React from 'react';
import styled, { css } from 'styled-components';

import { ButtonLink } from '@/components/Button';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { ReactComponent as ArrowRight } from '@/assets/svg/arrow-right.svg';
import { StringField } from '@/typings/model';
import Link from '@/components/Link';

type Props = {
  title: StringField;
  text: StringField;
  buttonLabel: StringField;
  buttonUrl: StringField;
  colorCard: StringField;
  isHypermarket?: boolean;
};

function QuestionCard({
  title,
  text,
  buttonLabel,
  buttonUrl,
  colorCard,
  isHypermarket,
}: Props) {
  return (
    <Component colorCard={colorCard}>
      <Inner isHypermarket={isHypermarket}>
        <Top>
          <Title>{title}</Title>
          <Text>{text}</Text>
        </Top>
        <Bottom>
          <MoreBtn colorCard={colorCard} to={buttonUrl ?? '#'}>
            {buttonLabel}
            <ArrowRight />
          </MoreBtn>
        </Bottom>
      </Inner>
    </Component>
  );
}

const Component = styled.div<{
  colorCard: StringField;
}>`
  height: 100%;
  position: relative;
  color: ${(props) =>
    props.colorCard ? `${props.colorCard}` : `${colors.white}`};
`;

const Inner = styled.div<{ isHypermarket?: boolean }>`
  padding: 160px;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${media.laptop(css`
    padding: 80px;
  `)}

  ${media.tablet(css`
    padding: 100px 20px;
  `)}
  
  ${media.mobile(css`
    padding: 50px 20px;
  `)}

  ${(props) =>
    props.isHypermarket
      ? css`
          padding: 100px;

          @media (max-width: 1750px) {
            padding: 80px;
          }

          ${media.laptop(css`
            padding: 40px;
          `)}

          ${media.tablet(css`
            padding: 40px 20px;
          `)}
          
          ${media.mobile(css`
            padding: 80px 20px;
          `)}
        `
      : ''}
`;

const Top = styled.div`
  margin-bottom: 78px;

  ${media.laptop(css`
    margin-bottom: 50px;
  `)}

  ${media.tablet(css`
    margin-bottom: 51px;
  `)}
`;

const Bottom = styled.div``;

const Title = styled.span`
  display: block;
  margin-bottom: 40px;
  font-weight: 500;
  font-size: 64px;
  line-height: 72px;

  ${media.laptop(css`
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 56px;
  `)}

  ${media.tablet(css`
    margin-bottom: 30px;
    font-size: 32px;
    line-height: 38px;
  `)}
  
  ${media.mobile(css`
    margin-bottom: 30px;
    font-size: 28px;
    line-height: 34px;
  `)}
`;

const Text = styled.p`
  display: block;
  font-size: 30px;
  line-height: 36px;

  ${media.laptop(css`
    font-size: 20px;
    line-height: 26px;
  `)}

  ${media.tablet(css`
    font-size: 18px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    font-size: 14px;
    line-height: 20px;
  `)}
`;

const MoreBtn = styled(Link)<{ colorCard: StringField }>`
  display: flex;
  align-items: center;
  width: auto;

  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.06em;
  padding: 0;
  color: ${(props) =>
    props.colorCard ? `${props.colorCard}` : `${colors.white}`};

  &:hover {
    svg {
      transform: translateX(5px);
    }
  }

  ${media.laptop(css`
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
  `)}

  ${media.tablet(css`
    font-size: 16px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    line-height: 24px;
  `)}

  svg {
    margin-left: 25px;
    width: 18px;
    height: 15px;
    transition: all 0.3s linear;

    ${media.laptop(css`
      margin-left: 9px;
      width: 18px;
      height: 15px;
    `)}

    ${media.tablet(css`
      width: 15px;
      height: 12px;
    `)}
  }
`;

export default QuestionCard;
