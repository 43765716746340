import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import Hero from '@/modules/KoronaDom/components/Hero';
import NotificationsCard from '@/components/NotificationsCard';
import { media } from '@/utils/mixin';
import TickerLineSection from '@/modules/ShoppingCenterSingle/components/TickerLineSection';
import Brands from '@/components/Brands';
import News from '@/modules/Hypermarkets/components/News';
import TickerLine from '@/components/TickerLine';
import InformationBlock from '@/modules/OwnProduction/components/InformationBlock';
import useCurrentPage from '@/hooks/useCurrentPage';
import { FashionableMollPageType } from '@/typings/model';
import Subscription from '@/components/Subscription';
import { breakpoints, colors } from '@/constants/theme';
import { useLayout } from '@/components/Layout/Layout.hooks';
import useCurrentNewsList from '@/hooks/useCurrentNewsList';
import { useTypedSelector } from '@/store/store';
import { selectMenuItemListByAlias } from '@/store/reducers/tager/menus';

import BottomSection from './components/BottomSection';
import ShopsSection from './components/ShopsSection';

function FashionableMoll() {
  const page = useCurrentPage<FashionableMollPageType>();
  const pageFields = page?.templateFields;
  const { setThemePage, setActiveStoreName, setMenuStore } = useLayout();

  const { data: newsList } = useCurrentNewsList('modnyy-moll');
  const isMobile = useMedia(`(max-width: ${breakpoints.tabletSmall - 1}px)`);

  const headerMenuFashionMoll =
    useTypedSelector((state) =>
      selectMenuItemListByAlias(state, 'fashion-moll')
    ) ?? [];

  useEffect(() => {
    setThemePage('#E4FF41');

    setActiveStoreName({
      value: 'Модный молл',
      label: 'Модный молл',
    });
    setMenuStore(headerMenuFashionMoll);

    return () => {
      setActiveStoreName({
        value: '',
        label: '',
      });
      setMenuStore([]);
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [headerMenuFashionMoll]);

  if (!pageFields) return null;

  const {
    topText,
    topLogo,
    shops,
    firstBannerImage,
    firstBannerLink,
    brandsTitle,
    brandsList,
    bannerImage,
    bannerTitle,
    bannerText,
    bannerLink,
    tickerBlockBackgroundFifth,
    tickerBlockColorFifth,
    tickerBlockLinkFifth,
    tickerBlockTextFifth,
    bottomText,
    bottomImage,
    bottomStickerLabel,
    bottomStickerLink,
    tickersFirst,
    tickersSecond,
  } = pageFields;

  return (
    <Wrapper>
      <Hero text={topText} image={topLogo} isSecondary={true} />
      <ShopsSection list={shops} />
      <NotificationWrapper>
        <NotificationsCard
          link={firstBannerLink ?? ''}
          image={firstBannerImage}
        />
      </NotificationWrapper>

      {tickersFirst?.length > 0 && <TickerLineSection tickers={tickersFirst} />}

      {brandsList.length !== 0 ? (
        <BrandsWrapper>
          <Brands title={brandsTitle} list={brandsList} />
        </BrandsWrapper>
      ) : null}

      {tickersSecond?.length > 0 && (
        <TickerLineSection tickers={tickersSecond} isSecondary={true} />
      )}

      <InformationBlockWrapper>
        <InformationBlock
          image={bannerImage}
          title={bannerTitle}
          text={bannerText}
          link={bannerLink}
          isStickerUp={true}
        />
      </InformationBlockWrapper>

      {newsList.length > 0 && (
        <NewsWrapper>
          <News newsList={newsList} />
        </NewsWrapper>
      )}

      <TickerLine
        backgroundTicket={tickerBlockBackgroundFifth ?? ''}
        colorTicket={tickerBlockColorFifth ?? ''}
        labelTicket={tickerBlockTextFifth ?? ''}
        linkTicket={tickerBlockLinkFifth ?? ''}
        rotateTicket={isMobile ? '-10' : '-5'}
        sizeTicket={'largest'}
      />
      <BottomSection
        text={bottomText}
        image={bottomImage}
        stickerLabel={bottomStickerLabel}
        stickerLink={bottomStickerLink}
      />
      <Subscription background={colors.gray50} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  overflow: hidden;
`;

const NotificationWrapper = styled.div`
  max-width: 1680px;
  margin: 100px auto 0;
  padding: 0 40px;

  ${media.laptop(css`
    margin-top: 80px;
  `)}

  ${media.tablet(css`
    margin-top: 68px;
    padding: 0 20px;
  `)}

  ${media.mobile(css`
    padding: 0 1px;
  `)}
`;

const BrandsWrapper = styled.div`
  margin: 62px 0 -32px;

  ${media.laptop(css`
    margin: 25px 0 -32px;
  `)}

  ${media.mobile(css`
    margin: 5px 0 -40px;
  `)}
`;

const InformationBlockWrapper = styled.div`
  margin: 72px 0 0;

  ${media.laptop(css`
    margin-top: 29px;
  `)}

  ${media.tablet(css`
    margin-top: 49px;
  `)}

  ${media.mobile(css`
    margin-top: 60px;
  `)}
`;

const NewsWrapper = styled.div`
  margin: 100px 0 160px;

  ${media.laptop(css`
    margin: 80px 0 110px;
  `)}

  ${media.tablet(css`
    margin: 50px 0 62px;
  `)}

  ${media.mobile(css`
    margin: 70px 0;
  `)}
`;

export default FashionableMoll;
