import React from 'react';
import styled from 'styled-components';

import { useLayout } from '@/components/Layout/Layout.hooks';
import Link from '@/components/Link';
import Breadcrumbs from '@/components/Breadcrumbs';
import Hero from '@/modules/Post/components/Hero';
import Main from '@/modules/Post/components/Main';
import useCurrentPage from '@/hooks/useCurrentPage';
import { IdeasSinglePageType } from '@/typings/model';

function IdeasSingle() {
  const page = useCurrentPage<IdeasSinglePageType>();
  const { setThemePage } = useLayout();
  setThemePage('');

  const pageFields = page?.templateFields;
  if (!pageFields) return null;

  const { date, banner } = pageFields;

  const BREADCRUMB_LIST: Array<{
    id: number;
    label: string;
    to: React.ComponentProps<typeof Link>['to'];
  }> = [
    { id: 1, label: 'Главная', to: '/' },
    { id: 2, label: `${page?.parent?.title}`, to: `${page?.parent?.path}` },
    {
      id: 3,
      label: `${page?.title}`,
      to: `${page?.path}`,
    },
  ];

  return (
    <Wrapper>
      <Breadcrumbs breadcrumbs={BREADCRUMB_LIST} />
      <Content>
        <Hero title={page?.title ?? ''} date={date} banner={banner} />
        <Main body={page?.body ?? ''} />
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const Content = styled.div``;

export default IdeasSingle;
