import React from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import Breadcrumbs from '@/components/Breadcrumbs';
import Hero from '@/modules/OwnProduction/components/Hero';
import SectionSlider from '@/components/SectionSlider';
import GalleryBlock from '@/components/GalleryBlock';
import useCurrentPage from '@/hooks/useCurrentPage';
import { ImageThumbnail, ProductPageType } from '@/typings/model';
import Subscription from '@/components/Subscription';
import { breakpoints, colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { useLayout } from '@/components/Layout/Layout.hooks';
import { BreadCrumbs } from '@/typings/common';
import { setBreadCrumbsList } from '@/utils/common';

import TableSection from './components/TableSection';
import PostSection from './components/PostSection';
import GallerySection from './components/GallerySection';
import ShopsSection from './components/ShopsSection';

function Product() {
  const page = useCurrentPage<ProductPageType>();
  const { setThemePage } = useLayout();
  setThemePage('');

  const isMobile = useMedia(`(max-width: ${breakpoints.tabletSmall - 1}px)`);

  const BREADCRUMB_LIST: Array<BreadCrumbs> = setBreadCrumbsList(
    {
      title: page?.title ?? '',
      path: page?.path ?? '',
    },
    page?.parent
  );

  if (!page) return <></>;

  const {
    topTitle,
    topText,
    topStickerText,
    topStickerLink,
    gallerySlider,
    galleryBottom,
    featuresTitle,
    features,
    products,
    aboutText,
    aboutTitle,
    shopTitle,
    shopList,
  } = page?.templateFields;

  function setSlider(slides: Array<ImageThumbnail> | null) {
    const newSlider: Array<string> = [];
    if (slides) {
      slides.forEach((slide) => {
        if (slide?.url) {
          newSlider.push(slide.url);
        }
      });
    }
    return newSlider;
  }

  return (
    <Wrapper>
      <Inner>
        <Breadcrumbs breadcrumbs={BREADCRUMB_LIST} />
        <Hero
          title={topTitle}
          stickerLabel={
            isMobile && gallerySlider.length === 0 ? '' : topStickerText
          }
          stickerLink={topStickerLink}
          text={topText}
          isProduct={true}
        />
        {setSlider(gallerySlider ?? []).length !== 0 && (
          <SliderWrapper>
            <SliderInner>
              <SectionSlider gallery={setSlider(gallerySlider ?? [])} />
            </SliderInner>
          </SliderWrapper>
        )}

        {galleryBottom.length > 0 && (
          <GalleryBlockWrapper>
            <GalleryBlock gallery={galleryBottom ?? []} />
          </GalleryBlockWrapper>
        )}

        {features.length > 0 && (
          <TableSection title={featuresTitle} list={features} />
        )}

        {products.length > 0 && <GallerySection products={products} />}

        {aboutText && aboutTitle && (
          <PostSection title={aboutTitle} text={aboutText} />
        )}
        {shopList.length > 0 && (
          <ShopsSection title={shopTitle} list={shopList} />
        )}
      </Inner>

      <Subscription background={colors.gray50} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  overflow: hidden;
`;

const Inner = styled.div`
  margin-bottom: 245px;

  ${media.laptop(css`
    margin-bottom: 282px;
  `)}

  ${media.tablet(css`
    margin-bottom: 90px;
  `)}

  ${media.mobile(css`
    margin-bottom: 110px;
  `)}
`;

const SliderWrapper = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  position: relative;

  &:before {
    display: block;
    content: '';
    padding-bottom: calc(100vw / 2);

    @media (min-width: 1920px) {
      padding-bottom: 960px;
    }

    ${media.tablet(css`
      padding-bottom: 62%;
    `)}

    ${media.mobile(css`
      padding-bottom: 141.67%;
    `)}
  }

  ${media.laptop(css`
    margin-top: 56px;
  `)}

  ${media.tablet(css`
    margin-top: 40px;
  `)}
`;

const SliderInner = styled.div`
  position: absolute;
  content: '';
  width: calc(100vw - 0.375rem);
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  @media (min-width: 1921px) {
    width: calc(1920px - 0.375rem);
  }

  ${media.tablet(css`
    height: 100%;
    width: 100vw;
    left: 0;
    transform: translateX(0);
  `)}
`;

const GalleryBlockWrapper = styled.div`
  max-width: 1680px;
  margin: -38px auto 0;
  padding: 0 40px;

  ${media.laptop(css`
    margin-top: -2px;
  `)}

  ${media.tablet(css`
    margin-top: -25px;
    padding: 0 20px;
  `)}

  ${media.mobile(css`
    margin-top: -57px;
  `)}
`;

export default Product;
