import React from 'react';
import styled, { css } from 'styled-components';

import { MenuRowType } from '@/typings/model';
import { media } from '@/utils/mixin';

import MenuAccordsItem from './components/MenuAccordsItem';

interface Props {
  menu: Array<MenuRowType>;
}

function Menu({ menu }: Props) {
  return (
    <Section>
      <List>
        {menu?.map((menuItem, index) => {
          return (
            <MenuAccordsItem
              menu={menu}
              title={menuItem.name}
              positions={menuItem.items}
            />
          );
        })}
      </List>
    </Section>
  );
}

const Section = styled.div`
  max-width: 1680px;
  padding: 0 40px;
  margin: 60px auto 40px;

  ${media.laptop(css`
    margin: 57px auto 0;
  `)}

  ${media.tablet(css`
    margin-top: 42px;
    padding: 0 20px;
  `)}
  
  ${media.mobile(css`
    margin-top: 34px;
  `)}
`;

const List = styled.div`
  margin: -5px 0;

  ${media.laptop(css`
    margin: -4px 0;
  `)}

  ${media.tablet(css`
    margin: -3px 0;
  `)}
`;

export default Menu;
