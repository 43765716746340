import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Navigation,
  Autoplay,
  Pagination,
  EffectFade,
} from 'swiper';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { ShopsCategoriesItemType, StringField } from '@/typings/model';
import useWindowSize from '@/hooks/useWindowSize';

import ShopsCard from './components/ShopsCard';
import CategoriesItem from './components/CategoriesItem';

SwiperCore.use([Navigation, Autoplay, Pagination, EffectFade]);

interface Props {
  title: StringField;
  colorProgress?: string;
  categoriesList: Array<ShopsCategoriesItemType>;
}

function ShoppingCenterShops({ title, categoriesList, colorProgress }: Props) {
  const [progress, setProgress] = useState<number>(0);
  const windowSize = useWindowSize();

  const [activeCategoriesItem, setActiveCategoriesItem] = useState(
    getFinalCategoriesList()[0] ?? { items: [] }
  );

  function getFinalCategoriesList() {
    const categoriesListNew: Array<ShopsCategoriesItemType> = [];
    const allShops: ShopsCategoriesItemType = {
      name: 'Все магазины',
      items: [],
    };

    categoriesList?.forEach((category) => {
      categoriesListNew.push(category);
      category.items.forEach((item) => {
        allShops.items.push(item);
      });
    });

    categoriesListNew.unshift(allShops);
    return categoriesListNew;
  }

  const progressMainWrapper = {
    background: '#D9D9D9',
    height: '6px',
    width: '100%',
    marginTop: 24,
  };
  const progressMainStyle = {
    height: '6px',
    background: colorProgress ? colorProgress : '#F9683A',
    width: `${progress}%`,
  };

  return (
    <Component>
      <Inner>
        <Top>
          <Title>{title}</Title>
        </Top>
        <Content>
          <Categories>
            <Swiper
              slidesPerView="auto"
              speed={500}
              onRealIndexChange={(swiper) => {
                console.log(
                  `[developer] onRealIndexChange progress: ${swiper.progress}, realIndex: ${swiper.realIndex}, activeIndex: ${swiper.activeIndex}, pagination: ${swiper.pagination}`
                );
              }}
              onInit={(swiper) => {
                console.log(
                  `[developer] onInit progress: ${swiper.progress}, realIndex: ${swiper.realIndex}, activeIndex: ${swiper.activeIndex}, pagination: ${swiper.pagination}`
                );
              }}
              onScroll={(swiper, event) => {
                console.log(
                  `[developer] onScroll deltaX: ${event.deltaX}, deltaY: ${event.deltaY}, clientX: ${event.clientX}, clientY: ${event.clientY}, x: ${event.x}, y: ${event.y}`
                );
                console.log(
                  `[developer] onScroll progress: ${swiper.progress}, realIndex: ${swiper.realIndex}, activeIndex: ${swiper.activeIndex}, pagination: ${swiper.pagination}`
                );
              }}
              onProgress={(swiper, event) => {
                console.log(`[developer] onProgress progress: ${event}`);
              }}
              watchOverflow={true}
              breakpoints={{
                320: {
                  spaceBetween: 12,
                },
                1680: { spaceBetween: 30 },
              }}
            >
              {getFinalCategoriesList().map((categoryItem, index) => {
                if (categoryItem.items.length === 0) return null;
                return (
                  <SwiperSlide key={index}>
                    <CategoriesItem
                      categoryItem={categoryItem}
                      isActive={categoryItem.name === activeCategoriesItem.name}
                      onChange={setActiveCategoriesItem}
                      count={categoryItem.items.length}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Categories>
          <ShopsList>
            <Swiper
              slidesPerView="auto"
              speed={500}
              noSwiping={false}
              watchOverflow={true}
              onInit={(swiper) => {
                console.log(swiper.progress);
                const value =
                  windowSize.width > 768 && windowSize.width < 1268 ? 4 : 6;

                let progressInPercentage =
                  (value / activeCategoriesItem.items.length) * 100;

                setProgress(progressInPercentage);
              }}
              onSlideNextTransitionStart={(swiper) => {
                console.log(swiper.progress);
                let progressInPercentage =
                  (1 / activeCategoriesItem.items.length) * 100;

                if (progress < 100 && !swiper.isEnd) {
                  setProgress((prev) => {
                    return Math.floor(prev + progressInPercentage);
                  });
                } else {
                  setProgress(100);
                }
              }}
              onSlidePrevTransitionStart={(swiper) => {
                const value =
                  windowSize.width > 768 && windowSize.width < 1268 ? 4 : 6;
                let all = (value / activeCategoriesItem.items.length) * 100;

                let progressInPercentage = Math.floor(
                  (1 / activeCategoriesItem.items.length) * 100
                );

                if (progress > all && !swiper.isBeginning) {
                  setProgress((prev) =>
                    Math.floor(prev - progressInPercentage)
                  );
                } else {
                  setProgress(all);
                }
              }}
            >
              {activeCategoriesItem.items.map((shopItem, index) => {
                return (
                  <SwiperSlide key={index}>
                    <ShopsCell>
                      <ShopsCard shopItem={shopItem} />
                    </ShopsCell>
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <ShopsProgress>
              {windowSize.width > 768 && activeCategoriesItem.items.length > 6 && (
                <div className="progress-bar" style={progressMainWrapper}>
                  <div style={progressMainStyle} />
                </div>
              )}
            </ShopsProgress>
          </ShopsList>
        </Content>
      </Inner>
    </Component>
  );
}

const Component = styled.div``;
const Inner = styled.div`
  max-width: 1680px;
  margin: 0 auto;
  padding: 0 40px;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

const Top = styled.div`
  margin-bottom: 48px;

  ${media.laptop(css`
    margin-bottom: 38px;
  `)}

  ${media.tablet(css`
    margin-bottom: 30px;
  `)}
`;

const Content = styled.div``;

const Title = styled.h4`
  font-weight: 500;
  font-size: 54px;
  line-height: 64px;
  color: ${colors.black};

  ${media.laptop(css`
    font-size: 40px;
    line-height: 48px;
  `)}

  ${media.tablet(css`
    font-size: 28px;
    line-height: 34px;
  `)}
`;

const Categories = styled.div`
  margin-bottom: 56px;

  .swiper-slide {
    width: auto !important;
  }

  ${media.laptop(css`
    margin-bottom: 47px;
  `)}

  ${media.tablet(css`
    margin-bottom: 30px;
  `)}
`;
const ShopsList = styled.div`
  margin: 0 -16px;

  .swiper-slide {
    width: 16.67% !important;
    max-width: 16.67%;

    ${media.tablet(css`
      width: 25% !important;
      max-width: 25%;
    `)}

    ${media.mobile(css`
      width: 148px !important;
      max-width: 148px;
    `)}
  }

  ${media.laptop(css`
    margin: 0 -12px;
  `)}

  ${media.tablet(css`
    margin: 0 -18px;
  `)}

  ${media.mobile(css`
    margin: 0 -20px;
    position: relative;
    display: flex;
  `)}
`;

const ShopsProgress = styled.div`
  margin: 0 20px;
`;
const ShopsCell = styled.div`
  width: 100%;
  padding: 0 16px;

  ${media.laptop(css`
    padding: 0 12px;
  `)}

  ${media.tablet(css`
    padding: 0 18px;
  `)}

  ${media.mobile(css`
    padding: 0 10px;
  `)}
`;

export default ShoppingCenterShops;
