import React, { useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { getSettingValueByKey } from '@tager/web-modules';

import { StringField } from '@/typings/model';
import { media } from '@/utils/mixin';
import { useTypedSelector } from '@/store/store';
import { selectSettingItemList } from '@/store/reducers/tager/settings';
import { colors } from '@/constants/theme';
import { ReactComponent as IconClose } from '@/assets/svg/close.svg';
import useWindowSize from '@/hooks/useWindowSize';

import ProductCard from './components/ProductCard';

type Props = {
  isWhite?: boolean;
};

function Products({ isWhite }: Props) {
  const productsRef = useRef<HTMLDivElement | null>(null);
  const settingsList = useTypedSelector(selectSettingItemList);
  const windowSize = useWindowSize();

  const hotProducts = getSettingValueByKey(settingsList, 'HOT_PRODUCTS');

  const [selectedId, setSelectedId] = useState(-1);
  const [isModalVisible, setModalVisible] = useState(false);

  const showModal = (selectedId: number) => {
    setSelectedId(selectedId);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setTimeout(() => setSelectedId(-1), 300); // Установите задержку для завершения анимации
  };

  return (
    <Component ref={productsRef} isWhite={isWhite}>
      <ComponentContainer>
        <ProductsInner>
          {hotProducts?.map((productItem, index) => {
            return (
              <ProductsCell key={index} size={productItem.design}>
                <ProductCard
                  product={productItem}
                  productsRef={productsRef}
                  onTap={() => showModal(index)}
                >
                  {selectedId === index && (
                    <Modal isVisible={isModalVisible}>
                      <Header>
                        <Title>{productItem.modalTitle}</Title>

                        <Shape onClick={closeModal}>
                          <ContainerClose />
                        </Shape>
                      </Header>
                      <Body>
                        <Text>{productItem.modalText}</Text>
                      </Body>
                    </Modal>
                  )}
                </ProductCard>
              </ProductsCell>
            );
          })}
        </ProductsInner>
      </ComponentContainer>
    </Component>
  );
}

const Shape = styled.div`
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background: #cee53a;
  }
  ${media.laptopOnly(css`
    width: 54px;
    height: 54px;
  `)}

  ${media.tabletOnly(css`
    width: 44px;
    height: 44px;
  `)}
  ${media.mobileSmall(css`
    width: 34px;
    height: 34px;
  `)}
  ${media.mobile(css`
    width: 34px;
    height: 34px;
  `)}
`;

const ContainerClose = styled(IconClose)`
  width: 26px;
  height: 26px;
  ${media.laptopOnly(css`
    width: 22px;
    height: 22px;
  `)}

  ${media.laptopOnly(css`
    width: 22px;
    height: 22px;
  `)} 

  ${media.tabletOnly(css`
    width: 16px;
    height: 16px;
  `)}
  ${media.mobileSmall(css`
    width: 16px;
    height: 16px;
  `)}
  ${media.mobile(css`
    width: 16px;
    height: 16px;
  `)}
`;

const Header = styled.div`
  width: 100%;
  padding-bottom: 54px;
  flex-direction: row;
  justify-content: space-between;
  display: inline-flex;
  padding-top: 4px;
  padding-right: 4px;
  ${media.laptopOnly(css`
    padding-bottom: 12px;
  `)}
  ${media.tabletOnly(css`
    padding-bottom: 12px;
  `)}
  ${media.mobileSmall(css`
    padding-bottom: 12px;
  `)}

  ${media.mobileLarge(css`
    padding-bottom: 12px;
  `)}
`;

const Body = styled.div`
  width: 100%;
  padding-left: 43px;
  padding-right: 34px;

  ${media.tabletOnly(css`
    padding-left: 0;
    padding-right: 0;
  `)}
  ${media.mobileLarge(css`
    padding-left: 0;
    padding-right: 0;
  `)}
  ${media.mobileSmall(css`
    padding-left: 0;
    padding-right: 0;
  `)}
`;

const Title = styled.p`
  line-height: 30px;
  font-weight: 500;
  font-size: 24px;
  width: 90%;
  padding-top: 40px;
  padding-left: 43px;
  //padding-right: 34px;

  ${media.laptopOnly(css`
    font-size: 24px;
    line-height: 30px;
    padding-top: 40px;
  `)}

  ${media.tabletOnly(css`
    font-size: 16px;
    line-height: 20px;
    padding-top: 12px;
    padding-left: 0;
    padding-right: 0;
  `)}

  ${media.mobileLarge(css`
    font-size: 16px;
    line-height: 20px;
    padding-top: 6px;
    padding-left: 0;
    padding-right: 0;
  `)}
  ${media.mobileSmall(css`
    font-size: 14px;
    line-height: 16px;
    padding-top: 6px;
    padding-left: 0;
    padding-right: 0;
  `)}
`;

const Text = styled.p`
  line-height: 27px;
  font-weight: 500;
  font-size: 19px;
  ${media.laptopOnly(css`
    font-size: 12px;
    line-height: 18px;
  `)}

  ${media.tabletOnly(css`
    font-size: 12px;
    line-height: 16px;
  `)}

  ${media.mobileLarge(css`
    font-size: 12px;
    line-height: 20px;
    padding-top: 12px;
  `)}
  ${media.mobileSmall(css`
    padding-top: 6px;

    font-size: 12px;
    line-height: 18px;
  `)}
`;

const Modal = styled.div<{ isVisible: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #e4ff41;
  border-radius: 8px;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  cursor: auto;

  ${media.tabletOnly(css`
    padding-left: 12px;
    padding-right: 12px;
  `)}
  ${media.mobileSmall(css`
    padding-left: 12px;
    padding-right: 12px;
  `)}
  ${media.mobile(css`
    padding-left: 12px;
    padding-right: 12px;
  `)}
  align-items: center;
  animation: ${({ isVisible }) => (isVisible ? fadeIn : fadeOut)} 0.3s forwards;
`;

const fadeIn = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
`;
const Component = styled.div<{ isWhite?: boolean }>`
  padding: 80px 0 62px;
  margin-top: -5px;

  ${(props) =>
    props.isWhite
      ? css`
          background: ${colors.white};
        `
      : ''}

  ${media.laptop(css`
    padding: 40px 0;
  `)}
  
  ${media.mobile(css`
    padding: 30px 0;
  `)}
`;

const ComponentContainer = styled.div`
  max-width: 1920px;
  margin: 0 auto;

  ${media.mobile(css`
    overflow-x: auto;
    overflow-y: hidden;

    scrollbar-color: ${colors.gray30} ${colors.gray80};
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      height: 4px;

      &-track {
        background-color: ${colors.gray80};
      }

      &-thumb {
        background-color: ${colors.gray30};
      }
    }
  `)}
`;

const ProductsInner = styled.div`
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(4, 25%);
  grid-auto-rows: calc(100vw / 100 * 54.68 / 2);

  @media (min-width: 1921px) {
    grid-auto-rows: calc(1920px / 100 * 54.68 / 2);
  }

  ${media.tabletSmall(css`
    grid-auto-flow: column dense;
    grid-template-columns: repeat(3, 33.33%);
    grid-auto-rows: calc(100vw / 100 * 80 / 3);
  `)}

  ${media.mobile(css`
    grid-template-rows: repeat(2, calc(100vw / 100 * 152 / 2));
    grid-template-columns: repeat(2, 50vw);
    grid-auto-columns: 50vw;
    grid-auto-flow: column dense;
    padding-bottom: 30px;
  `)}
`;

const ProductsCell = styled.div<{ size: StringField }>`
  ${(props) =>
    props.size === 'big'
      ? css`
          grid-row-end: span 2;

          ${media.tabletSmall(css`
            grid-row-end: span 3;
          `)}

          ${media.mobile(css`
            grid-row-end: span 1;
          `)}
        `
      : ``}
`;

export default Products;
