import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { RestaurantsItem } from '@/typings/model';

import RestaurantsLabel from './components/RestaurantsLabel';

interface Props {
  restaurantsList: Array<RestaurantsItem>;
  isAddressLabel?: boolean;
}

function RestaurantsList({ restaurantsList, isAddressLabel }: Props) {
  return (
    <Component>
      <List>
        {restaurantsList?.map((restaurantsItem, index) => {
          const { city, address, url } = restaurantsItem;
          return (
            <ListCell key={index}>
              {isAddressLabel ? (
                <RestaurantsLabel link={url} label={address} value={city} />
              ) : (
                <RestaurantsLabel link={url} label={city} value={address} />
              )}
            </ListCell>
          );
        })}
      </List>
    </Component>
  );
}

const Component = styled.div``;

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -11px;

  ${media.laptop(css`
    margin: -8px;
  `)}

  ${media.tabletOnly(css`
    margin: -6px;
  `)}
`;

const ListCell = styled.div`
  padding: 11px;

  ${media.laptop(css`
    padding: 8px;
  `)}

  ${media.tabletOnly(css`
    padding: 6px;
  `)}
`;

export default RestaurantsList;
