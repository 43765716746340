import React from 'react';
import styled, { css } from 'styled-components';

import { getSettingValueByKey } from '@tager/web-modules';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { ReactComponent as ArrowRight } from '@/assets/svg/arrow-right.svg';
import { PageByTemplateType } from '@/typings/model';
import { useTypedSelector } from '@/store/store';
import { selectSettingItemList } from '@/store/reducers/tager/settings';
import Link from '@/components/Link';

import PastIssuesCard from './components/PastIssuesCard';

type Props = {
  magazinesList: Array<PageByTemplateType>;
  title: string;
};

function PastIssues({ magazinesList, title }: Props) {
  const settingsList = useTypedSelector(selectSettingItemList);

  const sectionTitle = getSettingValueByKey(settingsList, 'MAGAZINES_TITLE');
  const buttonLabel = getSettingValueByKey(
    settingsList,
    'MAGAZINES_BUTTON_LABEL'
  );
  const buttonUrl = getSettingValueByKey(settingsList, 'MAGAZINES_BUTTON_URL');

  function setPastIssuesList(list: Array<PageByTemplateType>, title: string) {
    let indexItem = list.findIndex((el) => el.title === title);

    const oldList = [...list];
    let newList = [];

    if (indexItem <= 3) {
      newList = oldList
        .filter((item, index) => {
          return indexItem !== index;
        })
        .slice(0, indexItem + 3);
    } else {
      newList = oldList
        .filter((item, index) => {
          return indexItem !== index;
        })
        .slice(indexItem - 3, indexItem + 3);
    }
    return newList;
  }

  return (
    <Component>
      <Top>
        <Left>
          <Title>{sectionTitle}</Title>
        </Left>
        <Right>
          <MoreBtn to={buttonUrl ?? '#'}>
            {buttonLabel}
            <ArrowRight />
          </MoreBtn>
        </Right>
      </Top>
      <Content>
        <List>
          {setPastIssuesList(magazinesList, title)
            .slice(-3)
            .map((magazinesItem, index) => {
              return (
                <PastIssuesCard key={index} magazinesItem={magazinesItem} />
              );
            })}
        </List>
      </Content>
    </Component>
  );
}

const Component = styled.div``;

const Top = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.mobileMedium(css`
    flex-direction: column;
  `)}
`;
const Content = styled.div`
  margin-top: 50px;

  ${media.laptop(css`
    margin-top: 40px;
  `)}

  ${media.tablet(css`
    margin: 30px -20px 0;
    padding: 0 20px;
    overflow: auto;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  `)}
`;

const Left = styled.div`
  max-width: 80%;
`;
const Right = styled.div`
  display: flex;
  align-items: flex-end;

  padding: 0 5px 2px 0;

  ${media.laptop(css`
    padding: 0 2px 5px 0;
  `)}

  ${media.mobileMedium(css`
    padding: 0;
    margin-top: 15px;
  `)}
`;

const Title = styled.h2`
  margin: 0;
  font-weight: 500;
  font-size: 64px;
  line-height: 72px;
  color: ${colors.black1000};

  ${media.laptop(css`
    font-size: 48px;
    line-height: 56px;
  `)}

  ${media.tablet(css`
    font-size: 32px;
    line-height: 38px;
  `)}
  
  ${media.mobile(css`
    font-size: 28px;
    line-height: 34px;
  `)}
  
  ${media.mobileMedium(css`
    font-size: 26px;
  `)}
`;

const MoreBtn = styled(Link)`
  display: flex;
  align-items: center;
  width: auto;

  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  padding: 0;
  color: ${colors.black};
  transition: all 0.3s linear;

  &:hover {
    color: ${colors.orange};

    //svg {
    //transform: translateX(5px);
    //color: ${colors.orange};
    //}
  }

  ${media.laptop(css`
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
  `)}

  ${media.tablet(css`
    font-size: 18px;
    line-height: 24px;
    margin-right: 20px;
  `)}
  
  ${media.mobile(css`
    font-size: 16px;
    line-height: 22px;
  `)}

  svg {
    margin-left: 15px;
    width: 20px;
    height: 40px;
    //transition: all 0.3s linear;

    ${media.laptop(css`
      margin-left: 8px;
      width: 18px;
      height: 15px;
    `)}
  }
`;

const List = styled.div`
  display: flex;

  ${media.tablet(css`
    flex-wrap: nowrap;
  `)}

  ${media.tabletSmall(css`
    position: relative;

    &:after {
      flex: 0 0 20px;
      display: block;
      content: '';
      height: 20px;
    }
  `)}
`;

export default PastIssues;
