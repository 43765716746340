import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { PageByTemplateType } from '@/typings/model';
import Picture from '@/components/Picture';
import Link from '@/components/Link';

interface Props {
  recipe: PageByTemplateType;
}

function CookingCard({ recipe }: Props) {
  const { title, path, image, excerpt } = recipe;
  return (
    <Component>
      <ComponentLink to={`${path}`} />
      <Inner>
        <Left>
          <Image>
            {image && (
              <Picture mobileSmall={convertThumbnailToPictureImage(image)} />
            )}
          </Image>
        </Left>
        <Right>
          <Content>
            <Title>{title}</Title>
            <Text>{excerpt}</Text>
          </Content>
        </Right>
      </Inner>
    </Component>
  );
}

const ComponentLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

const Inner = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.mobile(css`
    display: block;
  `)}
`;
const Left = styled.div`
  flex: 0 0 512px;
  max-width: 512px;
  margin-right: 32px;

  ${media.laptop(css`
    flex: 0 0 385px;
    max-width: 385px;
    margin-right: 24px;
  `)}

  ${media.tablet(css`
    flex: 0 0 345px;
    max-width: 345px;
    margin-right: 36px;
  `)}
  
  ${media.mobile(css`
    max-width: 100%;
    margin: 0 0 20px;
  `)}
`;

const Right = styled.div`
  flex: 1 1 auto;
`;

const Content = styled.div``;

const Image = styled.div`
  border-radius: 32px;
  overflow: hidden;
  height: 100%;
  position: relative;

  &:before {
    display: block;
    content: '';
    padding-bottom: 62.1%;
    background: ${colors.grayLight};
  }

  ${media.laptop(css`
    border-radius: 24px;

    &:before {
      padding-bottom: 61.8%;
    }
  `)}

  ${media.mobile(css`
    &:before {
      padding-bottom: 81.25%;
    }
  `)}

  div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  picture {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Title = styled.h3`
  font-weight: 500;
  font-size: 64px;
  line-height: 72px;
  color: ${colors.black400};
  margin: 0 0 28px;
  max-width: 670px;
  transition: all 0.3s linear;

  ${media.laptop(css`
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 17px;
  `)}

  ${media.tablet(css`
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 16px;
  `)}

  ${media.mobile(css`
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 20px;
  `)}
`;

const Text = styled.p`
  font-size: 30px;
  line-height: 36px;
  color: ${colors.black500};

  ${media.laptop(css`
    font-size: 20px;
    line-height: 26px;
  `)}

  ${media.tablet(css`
    font-size: 18px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    font-size: 14px;
    line-height: 20px;
  `)}
`;

const Component = styled.div`
  position: relative;
  padding: 54px 0 52px;
  border-bottom: 2px solid ${colors.grayLight};

  ${media.laptop(css`
    padding: 40px 0 39px;
    border-bottom: 1px solid ${colors.grayLight};
  `)}

  ${media.tablet(css`
    padding: 30px 0;
  `)}

  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }

  ${media.laptopUp(css`
    &:hover {
      ${Title} {
        color: ${colors.orange};
      }
    }
  `)}
`;

export default CookingCard;
