import React from 'react';
import styled, { css } from 'styled-components';

import Button from '@/components/Button';
import { colors } from '@/constants/theme';
import { ShopsItemByCities } from '@/typings/model';
import { media } from '@/utils/mixin';

type Props = {
  categoryItem: ShopsItemByCities;
  isActive: boolean;
  onChange: (categoryItem: ShopsItemByCities) => void;
  count: number;
};

function CategoryItem({ categoryItem, isActive, onChange, count }: Props) {
  return (
    <Component onClick={() => onChange(categoryItem)}>
      <CategoryButton isActive={isActive}>
        {categoryItem.name}
        <CategoryItemCount>{count}</CategoryItemCount>
      </CategoryButton>
    </Component>
  );
}

const CategoryItemCount = styled.span`
  position: absolute;
  content: '';
  top: 9px;
  left: 92%;

  font-weight: 500;
  font-size: 32px;
  line-height: 42px;

  ${media.laptop(css`
    left: 89%;
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    left: auto;
    right: 10px;
    font-size: 20px;
    line-height: 26px;
  `)} 
    
   ${media.mobile(css`
    right: 15px;
    font-size: 16px;
    line-height: 20px;
  `)}
`;

const CategoryButton = styled(Button)<{ isActive?: boolean }>`
  display: block;
  position: relative;
  padding-right: 32px;
  font-weight: 500;
  font-size: 54px;
  line-height: 64px;
  color: ${colors.black32};
  transition: all 0.4s linear;

  &:hover {
    color: ${colors.black};
  }

  ${(props) =>
    props.isActive
      ? css`
          color: ${colors.black};
        `
      : ''}

  ${media.laptop(css`
    font-size: 40px;
    line-height: 48px;
  `)}
  
  ${media.tablet(css`
    font-size: 28px;
    line-height: 34px;
  `)}
  
  ${media.mobile(css`
    font-size: 22px;
    line-height: 28px;
  `)}
`;

const Component = styled.div`
  width: 100%;
  padding-right: 30px;

  ${media.laptop(css`
    padding-right: 14px;
  `)}

  ${media.tablet(css`
    padding-right: 0;

    &:first-child {
      ${CategoryButton} {
        padding-left: 0;
      }
    }
  `)}
`;

export default CategoryItem;
