import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { gsap } from 'gsap';

import { convertThumbnailToPictureImage } from '@tager/web-modules';
import { nl2br } from '@tager/web-core';

import { colors } from '@/constants/theme';
import { ImageThumbnail, StringField } from '@/typings/model';
import Picture from '@/components/Picture';
import TickerLine from '@/components/TickerLine';
import { media } from '@/utils/mixin';
import packageUrl from '@/assets/images/careers/package.png';
import packageUrl2x from '@/assets/images/careers/package@2x.png';
import packageUrlWebp from '@/assets/images/careers/package.webp';
import packageUrlWebp2x from '@/assets/images/careers/package@2x.webp';

type Props = {
  progressTitle: StringField;
  progressText: StringField;
  progressImage?: ImageThumbnail;

  tickerTextFirst: StringField;
  tickerLinkFirst: StringField;
  tickerBackgroundFirst: StringField;
  tickerColorFirst: StringField;

  tickerTextSecond: StringField;
  tickerLinkSecond: StringField;
  tickerBackgroundSecond: StringField;
  tickerColorSecond: StringField;
};

function CareerAdvancement({
  progressImage,
  progressText,
  progressTitle,
  tickerTextFirst,
  tickerLinkFirst,
  tickerColorFirst,
  tickerBackgroundFirst,
  tickerTextSecond,
  tickerLinkSecond,
  tickerColorSecond,
  tickerBackgroundSecond,
}: Props) {
  const packageRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    let tween: gsap.core.Tween;

    const delayedCall = gsap.delayedCall(0, () => {
      if (packageRef.current) {
        tween = gsap.to(packageRef.current, {
          ease: 'linear',
          y: '30%',
          scrollTrigger: {
            trigger: packageRef.current,
            scrub: true,
          },
        });
      }
    });

    return () => {
      tween?.kill();
      delayedCall.kill();
    };
  }, []);

  return (
    <Component>
      {tickerTextFirst ? (
        <TickerFirstWrapper>
          <TickerLine
            backgroundTicket={tickerBackgroundFirst}
            colorTicket={tickerColorFirst}
            labelTicket={tickerTextFirst ?? ''}
            linkTicket={tickerLinkFirst}
            rotateTicket={'-10'}
            sizeTicket={'large'}
          />
        </TickerFirstWrapper>
      ) : null}
      <Inner>
        {progressImage ? (
          <Background>
            <Picture
              mobileSmall={convertThumbnailToPictureImage(progressImage)}
            />
          </Background>
        ) : null}
        <Content>
          <Title>{progressTitle}</Title>
          <Text>{nl2br(progressText)}</Text>
        </Content>
        <PackageWrapper ref={packageRef}>
          <Picture
            mobileSmall={{
              src: packageUrl,
              src2x: packageUrl2x,
              webp: packageUrlWebp,
              webp2x: packageUrlWebp2x,
            }}
          />
        </PackageWrapper>
      </Inner>
      {tickerTextSecond ? (
        <TickerSecondWrapper>
          <TickerLine
            backgroundTicket={tickerBackgroundSecond}
            colorTicket={tickerColorSecond}
            labelTicket={tickerTextSecond ?? ''}
            linkTicket={tickerLinkSecond}
            rotateTicket={'5'}
            sizeTicket={'large'}
          />
        </TickerSecondWrapper>
      ) : null}
    </Component>
  );
}

const Component = styled.div`
  position: relative;
  margin-top: 170px;
  padding-top: 140px;

  &:before {
    position: absolute;
    content: '';
    background: ${colors.black};
    top: 368px;
    bottom: 0;
    left: -10000px;
    right: -10000px;

    ${media.laptop(css`
      top: 346px;
    `)}

    ${media.tablet(css`
      top: 290px;
    `)}
    
    ${media.mobile(css`
      top: 150px;
    `)}
  }

  ${media.laptop(css`
    margin-top: 30px;
    padding-top: 145px;
  `)}

  ${media.tablet(css`
    padding: 145px 20px 0 20px;
    min-height: 485px;
  `)}
  
  ${media.mobile(css`
    padding: 70px 20px 0 20px;
    min-height: 870px;
  `)}
`;

const Inner = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  position: relative;

  &:before {
    display: block;
    content: '';
    padding-bottom: 66.6%;
  }

  ${media.laptop(css`
    max-width: 1200px;
  `)}

  ${media.tablet(css`
    max-width: 728px;
  `)}
  
  ${media.mobile(css`
    max-width: 320px;
  `)}
`;

const TickerFirstWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  ${media.tablet(css`
    top: 70px;
  `)}

  ${media.mobile(css`
    top: 30px;
  `)}
`;

const TickerSecondWrapper = styled.div`
  position: absolute;
  bottom: 33px;
  left: 0;
  width: 100%;

  ${media.laptop(css`
    bottom: 45px;
  `)}

  ${media.tablet(css`
    bottom: 30px;
  `)}
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img,
  picture,
  div {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;

const Content = styled.div`
  padding: 280px 136px 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  ${media.laptop(css`
    padding: 202px 102px 0;
  `)}

  ${media.tablet(css`
    padding: 70px 50px 0;
  `)}
  
  ${media.mobile(css`
    padding: 250px 0 0;
  `)}
`;

const Title = styled.h2`
  max-width: 800px;
  margin: 0 0 70px;
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.white};

  ${media.laptop(css`
    font-size: 64px;
    line-height: 72px;
    margin-bottom: 56px;
  `)}

  ${media.tablet(css`
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 30px;
  `)}
  
  ${media.mobile(css`
    font-size: 32px;
    line-height: 42px;
    margin-bottom: 30px;
  `)}
  
  @media (max-width: 359.9px) {
    font-size: 29px;
  }
`;

const Text = styled.div`
  font-weight: 500;
  font-size: 54px;
  line-height: 64px;
  color: ${colors.white};

  ${media.laptop(css`
    font-size: 40px;
    line-height: 48px;
  `)}

  ${media.tablet(css`
    font-size: 26px;
    line-height: 34px;
  `)}
  
  ${media.mobile(css`
    font-size: 22px;
    line-height: 28px;
  `)}
`;

const PackageWrapper = styled.div`
  position: absolute;
  bottom: -242px;
  right: 0;
  z-index: 10;

  ${media.laptop(css`
    max-width: 289px;
    bottom: -184px;
  `)}

  ${media.tablet(css`
    max-width: 155px;
    bottom: -100px;
  `)}
  
   ${media.mobile(css`
    max-width: 112px;
    bottom: -680px;
    right: -50px;
  `)}
`;

export default CareerAdvancement;
