import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { gsap } from 'gsap';

import { useMedia } from '@tager/web-core';

import { StringField } from '@/typings/model';
import { breakpoints, colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

import AboutCard from './components/AboutCard';

type Props = {
  todayTitle: StringField;
  todayItems?: Array<{
    text: StringField;
    title: StringField;
  }>;
};

function Today({ todayTitle, todayItems }: Props) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const titleRef = useRef<HTMLHeadingElement | null>(null);

  const isTablet = useMedia(`(max-width: ${breakpoints.laptop - 1}px)`);

  useEffect(() => {
    if (!containerRef.current || !titleRef.current || isTablet) {
      return;
    }
    let tween: gsap.core.Tween;
    const delayedCall = gsap.delayedCall(0, () => {
      if (!containerRef.current || !titleRef.current) {
        return;
      }
      tween = gsap.to(titleRef.current, {
        scrollTrigger: {
          trigger: titleRef.current,
          end: `bottom top+=${
            titleRef.current?.getBoundingClientRect().height
          }`,
          endTrigger: containerRef.current,
          scrub: true,
          pin: true,
          pinSpacing: false,
        },
      });
    });

    return () => {
      tween?.kill();
      delayedCall.kill();
    };
  }, [isTablet]);

  return (
    <Component ref={containerRef}>
      <TodayContainer>
        <Inner>
          <Left>
            <Title
              ref={titleRef}
              dangerouslySetInnerHTML={{ __html: todayTitle ?? '' }}
            />
          </Left>
          <Right>
            <List>
              <Col>
                {todayItems?.map((todayItem, index) => {
                  if (Math.floor(todayItems?.length / 2) <= index) {
                    return;
                  }
                  return <AboutCard key={index} todayItem={todayItem} />;
                })}
              </Col>
              <Col>
                {todayItems?.map((todayItem, index) => {
                  if (Math.floor(todayItems?.length / 2) > index) {
                    return;
                  }
                  return <AboutCard key={index} todayItem={todayItem} />;
                })}
              </Col>
            </List>
          </Right>
        </Inner>
      </TodayContainer>
    </Component>
  );
}

const Component = styled.div`
  position: relative;
  margin-top: -340px;
  z-index: 3;

  ${media.laptop(css`
    margin-top: -297px;
  `)}

  ${media.tablet(css`
    margin-top: -227px;
  `)}
  
    ${media.mobile(css`
    margin-top: -173px;
  `)}
`;

const TodayContainer = styled.div`
  max-width: 1680px;
  padding: 0 40px;
  margin: 0 auto;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

const Inner = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.tablet(css`
    display: block;
  `)}
`;
const Left = styled.div`
  flex: 1 1 648px;
  max-width: 648px;
  padding-top: 40px;

  ${media.laptop(css`
    flex: 1 1 486px;
    max-width: 486px;
    padding-top: 26px;
  `)}

  ${media.tablet(css`
    max-width: 442px;
    padding-top: 0;
    margin-bottom: 43px;
  `)}
  
  ${media.mobile(css`
    max-width: 442px;
    padding-top: 0;
    margin-bottom: 49px;
  `)}
`;

const Right = styled.div`
  padding-right: 48px;

  ${media.laptop(css`
    padding-right: 32px;
  `)}

  ${media.tablet(css`
    padding-right: 0;
    display: flex;
    justify-content: flex-end;
  `)}
`;

const List = styled.div`
  display: flex;
  margin: 0 -40px;

  ${media.laptop(css`
    margin: 0 -28px;
  `)}

  ${media.tablet(css`
    max-width: 688px;
    width: 100%;
    justify-content: space-between;
  `)}
  
  ${media.mobile(css`
    display: block;
    margin: 0;
  `)}
`;

const Col = styled.div`
  margin: 0 40px;
  flex: 1 1 328px;
  max-width: 328px;

  ${media.laptop(css`
    flex: 1 1 250px;
    max-width: 250px;
    margin: 0 28px;
  `)}

  ${media.mobile(css`
    margin: 0 0 50px;
    max-width: 100%;

    &:last-child {
      margin-bottom: 0;
    }
  `)}
`;

const Title = styled.h2`
  display: block;
  margin: 0;

  font-weight: bold;
  font-size: 82px;
  line-height: 90px;
  letter-spacing: -1px;
  color: ${colors.white};

  ${media.laptop(css`
    font-size: 72px;
    line-height: 80px;
  `)}

  ${media.tablet(css`
    font-size: 64px;
    line-height: 72px;
  `)}
  
  ${media.mobile(css`
    font-size: 44px;
    line-height: 52px;
  `)}

  b {
    font-weight: bold;
    color: ${colors.orange};
  }
`;

export default Today;
