import React, { RefObject } from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { ShopsCategoriesItemType, StringField } from '@/typings/model';
import Picture from '@/components/Picture';
import StoreTypes from '@/modules/KoronaDom/components/BannersSection/components/StoreTypes';

import StoreItem from './components/StoreItem';

interface Props {
  shoppingCenter?: any;
  mapRef: RefObject<HTMLDivElement>;
  isSecondary?: boolean;
  storeList?: Array<ShopsCategoriesItemType>;
}

function Hero({ shoppingCenter, mapRef, isSecondary, storeList }: Props) {
  if (!shoppingCenter?.templateFields) return <></>;

  const {
    topTitle,
    topAddress,
    topCity,
    topImage,
    stickerBackground,
    stickerColor,
    stickerLabel,
    topLinks,
    workTime,
  } = shoppingCenter.templateFields;

  function scrollTo(section: any) {
    const targetElement = section;
    if (targetElement) {
      const y =
        targetElement.getBoundingClientRect().top + window.pageYOffset - 50;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }

  return (
    <Component isSecondary={isSecondary}>
      <Inner>
        <Left>
          <LeftContent>
            <Title>{topTitle}</Title>
            <Address>
              {topCity}, {topAddress}
            </Address>
            {workTime && <TimeWork>{workTime}</TimeWork>}
          </LeftContent>
          <StickerCircle
            background={stickerBackground}
            onClick={() => scrollTo(mapRef.current)}
          >
            <StickerCircleLabel
              color={stickerColor ?? ''}
              dangerouslySetInnerHTML={{ __html: stickerLabel ?? '' }}
            />
          </StickerCircle>
        </Left>
        <Center>
          <StoreList>
            {topLinks?.map((link: any) => {
              return (
                <StoreCell key={link.label}>
                  <StoreItem label={link.label} url={link.link} />
                </StoreCell>
              );
            })}
            {storeList && (
              <StoreTypes
                title={''}
                background={'transparent'}
                storeList={storeList}
              />
            )}
          </StoreList>
        </Center>
        <Right>
          {topImage && (
            <Image>
              <Picture mobileSmall={convertThumbnailToPictureImage(topImage)} />
            </Image>
          )}
        </Right>
      </Inner>
    </Component>
  );
}

const Inner = styled.div`
  max-width: 1680px;
  margin: 0 auto;
  padding: 112px 40px 54px;
  display: flex;

  ${media.laptop(css`
    padding: 64px 40px 40px;
  `)}

  ${media.tablet(css`
    padding: 30px 20px 40px;
    display: block;
    position: relative;
    min-height: 664px;
  `)}
  
  ${media.mobile(css`
    padding: 20px 20px 0;
    min-height: auto;
  `)}
`;

const Left = styled.div`
  flex: 1 1 512px;
  max-width: 512px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${media.laptop(css`
    flex: 1 1 384px;
    max-width: 384px;
  `)}
`;

const Center = styled.div`
  flex: 1 1 477px;
  max-width: 477px;
  margin: 0 64px 0 32px;

  ${media.laptop(css`
    flex: 1 1 368px;
    max-width: 368px;
    margin: 0 40px 0 24px;
  `)}

  ${media.tablet(css`
    max-width: 380px;
    margin: 30px 0 0;
  `)}
  
  ${media.mobile(css`
    max-width: 100%;
  `)}
`;

const Right = styled.div`
  flex: 1 1 auto;
`;

const LeftContent = styled.div``;

const Title = styled.h2`
  margin: 0 0 32px;
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.black};

  ${media.laptop(css`
    margin-bottom: 15px;
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    margin-bottom: 30px;
    font-size: 48px;
    line-height: 56px;
  `)}
  
  ${media.mobile(css`
    margin-bottom: 10px;
    font-size: 34px;
    line-height: 42px;
  `)}
`;

const Address = styled.address`
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  color: ${colors.black};

  ${media.laptop(css`
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    font-size: 20px;
    line-height: 24px;
  `)}
  
  ${media.mobile(css`
    font-size: 16px;
    line-height: 20px;
  `)}
`;

const TimeWork = styled.address`
  margin-top: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 28px;
  color: ${colors.black};

  ${media.laptop(css`
    font-size: 28px;
    line-height: 28px;
  `)}

  ${media.tablet(css`
    font-size: 24px;
    line-height: 28px;
  `)}
  
  ${media.mobile(css`
    font-size: 20px;
    line-height: 20px;
  `)}
`;

const StickerCircle = styled.div<{ background: StringField }>`
  margin-top: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 376px;
  height: 376px;
  border-radius: 100%;
  background: ${(props) =>
    props.background ? `${props.background}` : `${colors.gray50}`};
  transform: rotate(-15deg);
  cursor: pointer;
  transition: all 0.3s linear;

  &:hover {
    background: ${colors.grayHover};
  }

  ${media.laptop(css`
    margin-top: 89px;
    width: 282px;
    height: 282px;
  `)}

  ${media.tablet(css`
    position: absolute;
    right: 20px;
    bottom: 20px;
    margin: 0;
    z-index: 10;
    width: 134px;
    height: 134px;
    transform: rotate(15deg);
  `)}
  
  ${media.mobile(css`
    position: absolute;
    right: auto;
    left: 20px;
    bottom: 310px;
    margin: 0;
    z-index: 10;
    width: 150px;
    height: 150px;
    transform: rotate(-15deg);
  `)}
`;

const StickerCircleLabel = styled.span<{ color: StringField }>`
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  color: ${(props) => (props.color ? `${props.color}` : `${colors.black}`)};
  text-align: center;

  ${media.laptop(css`
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    font-size: 16px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    line-height: 20px;
  `)}
`;

const StoreList = styled.div`
  margin: -11px 0;

  ${media.laptop(css`
    margin: -8px 0;
  `)}

  ${media.tablet(css`
    display: flex;
    flex-direction: column;
    & > div {
      padding: 0;
    }
  `)}
  
  ${media.mobile(css`
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    overflow-y: hidden;
    overflow-x: auto;
    margin: 0 -20px;
    padding: 0 20px;

    scrollbar-color: transparent transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 0;

      &-track {
        background-color: transparent;
      }

      &-thumb {
        background-color: transparent;
        &:hover {
          background-color: transparent;
        }
      }
    }
  `)}
`;

const StoreCell = styled.div`
  display: inline-block;
  padding: 11px 0;

  ${media.laptop(css`
    padding: 8px 0;
  `)}

  ${media.tablet(css`
    display: inline-flex;
    padding: 8px 0 !important;
  `)}
  
  ${media.mobile(css`
    padding: 0 16px 0 0 !important;
    white-space: nowrap;

    &:last-child {
      padding-right: 0 !important;
    }
  `)}
`;

const Image = styled.div`
  position: absolute;
  content: '';
  top: 32px;
  bottom: 0;
  right: 0;
  width: 100%;
  max-width: calc((100vw - 1600px) / 2 + 515px);
  border-radius: 38px;
  overflow: hidden;
  background: ${colors.gray50};

  ${media.desktopLarge(css`
    max-width: 672px;
  `)}

  ${media.laptop(css`
    top: 22px;
    max-width: 424px;
    border-radius: 24px;
  `)}
  
  ${media.tablet(css`
    max-width: 366px;
    top: 20px;
  `)}
  
  ${media.mobile(css`
    position: static;
    margin: 18px -20px 0;
    width: 100vw;
    max-width: 100vw !important;
    height: 133vw;
  `)}

  div, picture,img {
    width: 100%;
    height: 100%;

    ${media.mobile(css`
      object-fit: cover;
    `)}
  }
`;

const Component = styled.div<{ isSecondary?: boolean }>`
  position: relative;
  max-width: 1920px;
  margin: 0 auto;

  ${(props) =>
    props.isSecondary
      ? css`
          ${Inner} {
            padding: 92px 40px 54px;

            ${media.laptop(css`
              padding: 64px 40px 40px;
            `)}

            ${media.tablet(css`
              padding: 50px 20px 40px;
              min-height: 690px;
            `)}
            
              ${media.mobile(css`
              padding: 20px 20px 0;
              min-height: auto;
            `)}
          }

          ${Center} {
            flex: 1 1 600px;
            max-width: 600px;

            ${media.laptop(css`
              flex: 1 1 474px;
              max-width: 474px;
            `)}

            ${media.tablet(css`
              max-width: 380px;
              margin: 30px 0 0;
            `)}
            
            ${media.mobile(css`
              max-width: 100%;
              margin-top: 50px;
            `)}
          }

          ${Image} {
            max-width: calc((100vw - 1600px) / 2 + 376px);

            ${media.desktopLarge(css`
              max-width: 536px;
            `)}

            ${media.laptop(css`
              max-width: 322px;
            `)}
            
            ${media.tablet(css`
              max-width: 320px;
              top: 50px;
            `)}
            
            ${media.mobile(css`
              margin: 50px -20px 0;
            `)}
          }
        `
      : ''}
`;

export default Hero;
