import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import DropDownSelect from '@/components/DropDownSelect';
import { OptionType } from '@/components/DropDownSelect/DropDownSelect';
import { useTypedSelector } from '@/store/store';
import { selectShopByCitiesList } from '@/store/reducers/shopsCities';
import { ShopsItemByCities } from '@/typings/model';

interface Props {
  setActiveCity: (value: number) => void;
  activeCity: number;
}

function CitiesSelect({ setActiveCity, activeCity }: Props) {
  const cityList = useTypedSelector(selectShopByCitiesList);

  const [citiesOptions, setCitiesOptions] = useState<Array<OptionType>>(
    createCitiesOptions(cityList)
  );
  const [selectedCities, setSelectedCities] = useState<OptionType>();

  function createCitiesOptions(list: Array<ShopsItemByCities>) {
    const optionList = [{ label: 'Все города', value: '0' }];
    list.forEach((listItem) => {
      let newItem: OptionType = { label: '', value: '' };
      newItem.label = listItem.name ?? '';
      newItem.value = String(listItem.id) ?? '';
      optionList.push(newItem);
    });

    return optionList;
  }

  const sortCitiesValue = useMemo<OptionType>(
    () =>
      citiesOptions.find((option) => option.value === selectedCities?.value) ??
      citiesOptions[0],
    [selectedCities, citiesOptions]
  );

  function handlesCitiesChange(selectedOption: OptionType | null): void {
    if (selectedOption) {
      setSelectedCities(selectedOption);

      if (selectedOption.value === '0') {
        setActiveCity(0);
      } else {
        cityList.forEach((city) => {
          if (city.id === Number(selectedOption.value)) {
            setActiveCity(city.id);
          }
        });
      }
    }
  }

  useEffect(() => {
    setCitiesOptions(createCitiesOptions(cityList));
  }, [cityList]);

  useEffect(() => {
    citiesOptions.forEach((city) => {
      if (city.value === String(activeCity)) {
        setSelectedCities(city);
      }
    });
  }, [activeCity]);

  return (
    <Component>
      <DropDownSelect
        placeholder="Выбрать..."
        options={citiesOptions}
        value={sortCitiesValue}
        onChange={handlesCitiesChange}
      />
    </Component>
  );
}

const Component = styled.div``;

export default CitiesSelect;
