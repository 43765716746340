import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { ImageThumbnail, StringField } from '@/typings/model';

import NewsCard from './NewsCard';

type Props = {
  newsList?: Array<{
    image: {
      landscape: ImageThumbnail;
      portrait: ImageThumbnail;
    };
    title: StringField;
    link: StringField;
  }>;
};

const NewsAside = forwardRef<HTMLDivElement, Props>(function NewsAside(
  { newsList }: Props,
  ref
) {
  return (
    <Components>
      <div ref={ref}>
        {newsList?.slice(0, 2).map((newsItem, index) => {
          return (
            <Cell key={index}>
              <NewsCard newsItem={newsItem} isAside={true} />
            </Cell>
          );
        })}
      </div>
    </Components>
  );
});

const Components = styled.div`
  flex: 0 0 376px;
  max-width: 376px;
  margin-right: 32px;
  position: relative;
  z-index: 3;

  ${media.laptop(css`
    margin-right: 24px;
    flex: 0 0 240px;
    max-width: 240px;
  `)}

  ${media.tabletSmall(css`
    margin-right: 20px;
    flex: 0 0 218px;
    max-width: 218px;
  `)}
`;

const Cell = styled.div`
  width: 100%;
  margin-bottom: 50px;

  ${media.laptop(css`
    margin-bottom: 40px;
  `)}

  ${media.tabletSmall(css`
    margin-bottom: 20px;
  `)}

  &:last-child {
    margin-bottom: 0;
  }
`;

export default NewsAside;
