import React from 'react';
import styled, { css } from 'styled-components';

import { placeholderAnimation } from '@tager/web-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

function PromotionPlaceholderCard() {
  return (
    <Component>
      <Inner>
        <Top>
          <ImageBlock />
        </Top>
      </Inner>
    </Component>
  );
}

const customPlaceholder = css`
  animation: ${placeholderAnimation} 2s linear;
  animation-iteration-count: infinite;
  background-image: linear-gradient(
    to right,
    rgb(0 0 0/ 3%) 0,
    rgb(0 0 0 / 3%) 15%,
    rgb(241 241 241 / 90%) 30%
  );
  background-color: var(--bg-color-placeholder);
  background-size: 1200px 100%;
`;

const ImageBlock = styled.div`
  ${customPlaceholder};
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`;

const Component = styled.div<{ size?: string }>`
  position: relative;
  flex: 1 1 16.66%;
  max-width: 16.66%;
  padding: 35px 16px;
  cursor: pointer;

  ${media.laptop(css`
    padding: 28px 12px;
  `)}

  ${media.tablet(css`
    flex: 1 1 25%;
    max-width: 25%;
    padding: 25px 18px;
  `)}

  ${media.mobile(css`
    flex: 1 1 50%;
    max-width: 50%;
    padding: 25px 10px;
  `)}
`;

const Inner = styled.div`
  width: 100%;
`;
const Top = styled.div`
  ${customPlaceholder};
  position: relative;
  border-radius: 106px;
  margin-bottom: 24px;
  overflow: hidden;
  //background: ${colors.gray50};

  &:before {
    display: block;
    content: '';
    padding-bottom: 122.5%;
  }

  ${media.laptop(
    css`
      border-radius: 80px;
      margin-bottom: 16px;
    `
  )}
`;
export default PromotionPlaceholderCard;
