import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

interface Props {
  label: string;
  children?: React.ReactNode;
  isBlock?: boolean;
}

function InformationRow({ label, children, isBlock }: Props) {
  return (
    <Component>
      <Inner>
        <Top>
          <Label>{label}</Label>
        </Top>
        <Content isBlock={isBlock}>{children}</Content>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  margin-bottom: 24px;

  ${media.laptop(css`
    margin-bottom: 16px;
  `)}

  ${media.mobile(css`
    margin-bottom: 28px;
  `)}

  &:last-child {
    margin-bottom: 0;
  }
`;

const Inner = styled.div``;

const Top = styled.div`
  margin-bottom: 12px;

  ${media.laptop(css`
    margin-bottom: 4px;
  `)}
`;

const Content = styled.div<{ isBlock?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  margin: -10px;

  ${(props) =>
    props.isBlock
      ? css`
          display: block;
        `
      : ''}
`;

const Label = styled.span`
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: ${colors.black};

  ${media.laptop(css`
    font-size: 16px;
    line-height: 24px;
  `)}

  ${media.tablet(css`
    font-size: 16px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    font-size: 12px;
    line-height: 20px;
  `)}
`;

export default InformationRow;
