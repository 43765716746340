import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { ReactComponent as ArrowIcon } from '@/assets/svg/arrow-shop.svg';
import { ShopsTypeItem } from '@/typings/model';

interface Props {
  shopItem: ShopsTypeItem;
  setActiveShop: (shop: ShopsTypeItem) => void;
}

function ShopsItem({ shopItem, setActiveShop }: Props) {
  const { name, address } = shopItem;
  return (
    <Component onClick={() => setActiveShop(shopItem)}>
      <Inner>
        <Content>
          <Title>
            {name}, {address}
          </Title>
          <Text>Показать на карте</Text>
        </Content>
        <Pointer>
          <ArrowIcon />
        </Pointer>
      </Inner>
    </Component>
  );
}

const Inner = styled.div`
  position: relative;
  padding: 50px 60px;

  ${media.laptop(css`
    padding: 32px 40px 31px;
  `)}

  ${media.tablet(css`
    padding: 26px 30px;
  `)}
  
  ${media.mobile(css`
    padding: 30px 20px;
  `)}
`;

const Content = styled.div``;

const Title = styled.div`
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;

  ${media.laptop(css`
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    font-size: 20px;
    line-height: 24px;
  `)}
  
  ${media.mobile(css`
    font-size: 16px;
    line-height: 20px;
  `)}
`;

const Text = styled.p`
  margin-top: 15px;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: ${colors.orange};

  ${media.laptop(css`
    margin-top: 3px;
    font-size: 16px;
    line-height: 24px;
  `)}

  ${media.tablet(css`
    margin-top: 6px;
    font-size: 16px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    margin-top: 10px;
    font-size: 12px;
    line-height: 20px;
  `)}
`;

const Pointer = styled.div`
  position: absolute;
  top: 50%;
  right: 46px;
  transform: translateY(-50%);

  width: 91px;
  height: 91px;
  border-radius: 100%;
  opacity: 0;
  background: ${colors.white};

  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out 0.1s;

  ${media.laptop(css`
    right: 32px;
    width: 58px;
    height: 58px;
  `)}

  svg {
    width: 61px;
    height: 61px;
    color: ${colors.black};

    ${media.laptop(css`
      width: 39px;
      height: 39px;
    `)}
  }
`;

const Component = styled.div`
  position: relative;
  background: ${colors.white};
  color: ${colors.black};
  border-radius: 60px;
  cursor: pointer;
  transition: all 0.3s ease-in-out 0.1s;

  ${media.laptop(css`
    border-radius: 40px;
  `)}

  &:hover {
    background: ${colors.black};
    color: ${colors.white};

    ${Pointer} {
      opacity: 1;
    }
  }
`;

export default ShopsItem;
