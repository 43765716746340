import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Form, FormikProps } from 'formik';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import Button from '@/components/Button';
import Spinner from '@/components/Spinner';
import TextAreaFormik from '@/components/TextArea/TextArea.formik';
import { TextInputFormik } from '@/components/TextInput';
import { MaskedTextInputFormik } from '@/components/MaskedTextInput';
import {
  AssistanceFormPayload,
  getVacanciesByStore,
} from '@/services/requests';
import {
  FilesType,
  FormCheckbox,
  VacancyStoresItemType,
} from '@/typings/model';
import { useTypedSelector } from '@/store/store';
import { selectVacancyByCitiesList } from '@/store/reducers/vacanciesCities';
import SelectComponent from '@/components/Select';
import UploadFileFinal from '@/components/UploadFileFinal';
import Checkbox from '@/components/Checkbox';

import MessageSuccess from './MessageSuccess';

export type AssistanceFormValues = AssistanceFormPayload;

type Props = FormikProps<AssistanceFormValues> & {
  isSentSuccess: boolean;
  setSentSuccess: (value: boolean) => void;
  fileId: FilesType;
  setFileId: (value: FilesType) => void;
  storeId: number;
  setStoreId: (value: number) => void;

  isClearValue: boolean;
  isCityClear: boolean;
  setClearValue: (value: boolean) => void;

  isDisabled: boolean;
  setDisabled: (value: boolean) => void;

  formTitle: string;
  messageSuccess: string;
  buttonLabel: string;
  formCheckboxes: Array<FormCheckbox>;
};

function AssistanceForm({
  isSentSuccess,
  setSentSuccess,
  isSubmitting,
  fileId,
  setFileId,
  storeId,
  setStoreId,
  formTitle,
  buttonLabel,
  messageSuccess,
  isClearValue,
  isCityClear,
  setClearValue,
  isDisabled,
  setDisabled,
  formCheckboxes,
}: Props) {
  const vacancyByCitiesList = useTypedSelector(selectVacancyByCitiesList);
  const contentRef = useRef<HTMLFieldSetElement>(null);

  const [checkboxValues, setCheckboxValues] = useState<Array<boolean>>(
    formCheckboxes.map(() => false)
  );
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [isFile, setFile] = useState<boolean>(false);

  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);

  const [isActiveCity, setActiveCity] = useState<number | null>(null);

  const [isActiveStore, setActiveStore] = useState<
    Array<VacancyStoresItemType>
  >([]);

  function scrollTo(section: any) {
    const targetElement = section;
    if (targetElement) {
      const y =
        targetElement.getBoundingClientRect().top + window.pageYOffset - 200;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }

  useEffect(() => {
    if (!isSentSuccess) return;
    scrollTo(contentRef.current);
  }, [isSentSuccess]);

  useEffect(() => {
    if (!isActiveCity) return;

    setDisabled(false);
    getVacanciesByStore(isActiveCity ?? 0).then((response) => {
      setActiveStore(response.data);
    });

    vacancyByCitiesList.forEach((item) => {
      if (item.id === isActiveCity) {
        setStoreId(0);
        setClearValue(true);
        setTimeout(() => setClearValue(false), 100);
      }
    });
  }, [isActiveCity]);

  useEffect(() => {
    if (!isCityClear) return;
    setActiveCity(null);
  }, [isCityClear]);

  useEffect(() => {
    const isRequiredCheckboxesChecked =
      checkboxValues.filter(
        (checkbox, index) => formCheckboxes[index].required && checkbox
      ).length ===
      formCheckboxes.filter((checkbox) => checkbox.required).length;

    if (
      name &&
      message &&
      email &&
      phone &&
      storeId &&
      isRequiredCheckboxesChecked
    ) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }, [name, message, email, storeId, phone, checkboxValues, formCheckboxes]);

  return (
    <Form autoComplete="off" noValidate>
      <FormContent ref={contentRef}>
        <MessageSuccess
          isSentSuccess={isSentSuccess}
          messageSuccess={messageSuccess}
          setSentSuccess={setSentSuccess}
        />
        <FormInner isSentSuccess={isSentSuccess}>
          <Top>
            <Title>{formTitle}</Title>
            <TopInner>
              <Row>
                <Col>
                  <Input
                    type="text"
                    name="name"
                    required
                    placeholder="Имя"
                    autoComplete="off"
                    setFormValue={(value: string) => setName(value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <SelectComponent
                    optionList={vacancyByCitiesList}
                    placeholder={'Город'}
                    onChange={(value: number) => setActiveCity(value)}
                    isVacancyForm={true}
                    isClearValue={isCityClear}
                    isFeedback={true}
                  />
                </Col>
                <Col>
                  <SelectComponent
                    optionList={isActiveStore}
                    placeholder={'Магазин'}
                    onChange={(value: number) => setStoreId(value)}
                    isDisabled={isDisabled}
                    isClearValue={isClearValue}
                    isFeedback={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input
                    type="email"
                    name="email"
                    required
                    placeholder="Email"
                    autoComplete="off"
                    setFormValue={(value: string) => setEmail(value)}
                  />
                </Col>
                <Col>
                  <MaskedTextInputFormik
                    name="phone"
                    placeholder="Телефон"
                    mask="{+375}(00)000-00-00"
                    clearErrorOnChange
                    required
                    data-testid="phone-field"
                    autoComplete="off"
                    isSentSuccess={isSentSuccess}
                    setFormValue={(value: string) => setPhone(value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextAreaFormik
                    placeholder={'Сообщение'}
                    name="body"
                    autoHeight
                    setFormValue={(value: string) => setMessage(value)}
                  />
                </Col>
              </Row>
            </TopInner>
          </Top>
          <AttachWrapper>
            <UploadFileFinal
              name={'fileId'}
              type={'file'}
              isSmall={true}
              fileId={fileId}
              setFileId={setFileId}
              isFeedback={true}
              isSentSuccess={isSentSuccess}
              setFile={(value: boolean) => setFile(value)}
              description={`Перетащите сюда файл или <span>загрузите</span> его с
                      компьютера (до 5 Мб)`}
            />
          </AttachWrapper>
          {!!formCheckboxes.length && (
            <CheckboxWrapper>
              {formCheckboxes.map((checkbox, index) => (
                <Checkbox
                  key={index}
                  checked={checkboxValues[index]}
                  onChange={() =>
                    setCheckboxValues(
                      checkboxValues.map((checkbox, checkboxIndex) =>
                        checkboxIndex === index ? !checkbox : checkbox
                      )
                    )
                  }
                  label={checkbox.text ?? ''}
                  id={'vacancy-form-checkbox-' + index}
                />
              ))}
            </CheckboxWrapper>
          )}
          <Bottom>
            <SendButton
              variant="orange"
              type="submit"
              isSubmitting={isSubmitting}
              isDisabled={btnDisabled}
            >
              {!isSubmitting ? (
                buttonLabel
              ) : (
                <Spinner color="orange" absolute={true} />
              )}
            </SendButton>
          </Bottom>
        </FormInner>
      </FormContent>
    </Form>
  );
}

const FormContent = styled.fieldset`
  border: none;
  position: relative;
  padding: 0;
  width: 100%;
  flex: 1 1 auto;
`;

const FormInner = styled.div<{ isSentSuccess: boolean }>`
  max-width: 1056px;
  margin: 0 auto;
  transition: all 0.3s ease-in-out;

  ${media.laptop(css`
    max-width: 792px;
  `)};

  ${(props) =>
    props.isSentSuccess
      ? css`
          opacity: 0;
          position: absolute;
          visibility: hidden;
          z-index: -1000;
        `
      : css`
          opacity: 1;
          z-index: 1;
          visibility: visible;
        `};
`;

const Top = styled.div`
  background: rgba(241, 241, 241, 0.5);
  padding: 60px;

  ${media.laptop(css`
    padding: 40px;
  `)}

  ${media.tablet(css`
    padding: 40px 30px;
  `)}
  
  ${media.mobile(css`
    padding: 30px 20px;
  `)}
`;

const TopInner = styled.div`
  margin: -11px -16px;

  ${media.laptop(css`
    margin: -8px -12px;
  `)}
`;

const AttachWrapper = styled.div`
  margin-top: 25px;

  ${media.laptop(css`
    margin-top: 24px;
  `)}

  ${media.tablet(css`
    margin-top: 20px;
  `)}
`;

const Bottom = styled.div`
  margin-top: 30px;
  text-align: center;

  ${media.laptop(css`
    margin-top: 24px;
  `)}

  ${media.tablet(css`
    margin-top: 20px;
  `)}
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;

  ${media.mobile(css`
    flex-direction: column;

    & > div:nth-child(2) {
      margin-top: 8px;
    }
  `)}

  &:first-child {
    margin-top: 0;
  }
`;

const Col = styled.div`
  flex: 1 1 50%;
  position: relative;
  padding: 11px 16px;

  ${media.laptop(css`
    padding: 8px 12px;
  `)}

  input {
    border: none;
    border-bottom: 2px solid ${colors.black};
    padding: 8px 0;
    height: 52px;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    color: ${colors.black};

    ${media.laptop(css`
      height: 30px;
      border-bottom: 1px solid ${colors.black};
      font-size: 20px;
      line-height: 26px;
      padding: 4px 0;
    `)}

    ${media.tablet(css`
      font-size: 18px;
      line-height: 22px;
    `)}
    
    ${media.mobile(css`
      font-size: 14px;
      line-height: 20px;
    `)}

    &::placeholder {
      opacity: 0.4;
      color: ${colors.black};

      ${media.laptop(css`
        font-size: 20px;
        line-height: 26px;
      `)}

      ${media.tablet(css`
        font-size: 18px;
        line-height: 22px;
      `)}
      
      ${media.mobile(css`
        font-size: 14px;
        line-height: 20px;
      `)}
    }
  }

  span {
    right: 16px;

    ${media.laptop(css`
      right: 12px;
    `)}
  }
`;

const Input = styled(TextInputFormik)`
  input {
    border: none;
    border-bottom: 2px solid ${colors.black};
    padding: 8px 0;
    height: 52px;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    color: ${colors.black};

    ${media.laptop(css`
      height: 30px;
      border-bottom: 1px solid ${colors.black};
      font-size: 20px;
      line-height: 26px;
      padding: 4px 0;
    `)}

    ${media.tablet(css`
      font-size: 18px;
      line-height: 22px;
    `)}
    
    ${media.mobile(css`
      font-size: 14px;
      line-height: 20px;
    `)}

    &::placeholder {
      opacity: 0.4;
      color: ${colors.black};

      ${media.laptop(css`
        font-size: 20px;
        line-height: 26px;
      `)}

      ${media.tablet(css`
        font-size: 18px;
        line-height: 22px;
      `)}
      
      ${media.mobile(css`
        font-size: 14px;
        line-height: 20px;
      `)}
    }
  }
`;

const Title = styled.h2`
  margin: 0 0 25px;
  font-weight: 500;
  font-size: 54px;
  line-height: 64px;
  color: ${colors.black};

  ${media.laptop(css`
    margin-bottom: 21px;
    font-size: 40px;
    line-height: 48px;
  `)}

  ${media.tablet(css`
    margin-bottom: 30px;
    font-size: 28px;
    line-height: 34px;
  `)}
  
  ${media.mobile(css`
    margin-bottom: 38px;
    font-size: 22px;
    line-height: 28px;
  `)}
`;

const SendButton = styled(Button)<{
  isSubmitting: boolean;
  isDisabled?: boolean;
}>`
  position: relative;
  display: block;
  min-height: 90px;
  height: 100%;
  width: 100%;
  padding: 19px 35px;

  text-transform: uppercase;
  font-weight: bold;
  font-size: 36px;
  line-height: 48px;

  transition: all 0.3s linear;

  ${media.laptop(css`
    min-height: 80px;
    padding: 9px 35px;
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    min-height: 60px;
    padding: 17px 35px;
    font-size: 20px;
    line-height: 26px;
  `)}
  
  ${media.mobile(css`
    min-height: 50px;
    padding: 15px 35px;
    font-size: 16px;
    line-height: 20px;
  `)}

  ${(props) =>
    props.isSubmitting
      ? css`
          border: none;
          pointer-events: none;
        `
      : css``}

  ${(props) =>
    props.isDisabled
      ? css`
          pointer-events: none;
          background: ${colors.gray50};
          color: ${colors.black};
        `
      : ''}
`;

const CheckboxWrapper = styled.div`
  margin-top: 10px;
`;

export default AssistanceForm;
