import React from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import { StringField } from '@/typings/model';
import { breakpoints, colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

import ProcedureItem from './components/ProcedureItem';
import ProcedureAccord from './components/ProcuderuAccord';

type Props = {
  procedureTitle: StringField;
  procedureItems?: Array<{
    title: StringField;
    text: StringField;
  }>;
};

function Procedures({ procedureTitle, procedureItems }: Props) {
  const isMobile = useMedia(`(max-width: ${breakpoints.tabletSmall - 1}px)`);

  return (
    <Component>
      <Inner>
        <Top>
          <Title dangerouslySetInnerHTML={{ __html: procedureTitle ?? '' }} />
        </Top>
        <List>
          {procedureItems?.map((procedureItem, index) => {
            if (isMobile) {
              return (
                <ProcedureAccord
                  key={index}
                  accordItem={procedureItem}
                  index={index}
                />
              );
            } else {
              return (
                <ProcedureItem key={index} procedureItem={procedureItem} />
              );
            }
          })}
        </List>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  margin-bottom: 261px;
  z-index: 100;

  ${media.laptop(css`
    margin-bottom: 138px;
  `)}

  ${media.tablet(css`
    margin-bottom: 90px;
  `)}

  ${media.mobile(css`
    margin-bottom: 60px;
  `)}
`;

const Inner = styled.div`
  max-width: 1680px;
  margin: 0 auto;
  padding: 0 40px;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

const Top = styled.div``;

const Title = styled.h2`
  max-width: 1056px;
  margin: 0;
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.white};

  ${media.laptop(css`
    max-width: 877px;
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    font-size: 48px;
    line-height: 56px;
  `)}

  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
  `)}

  b {
    font-weight: 500;
    color: ${colors.orange};
  }
`;

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 6px -36px -33.5px;
  counter-reset: list;

  ${media.laptop(css`
    margin: 43px -29px -23px;
  `)}

  ${media.tablet(css`
    margin: 50px -20px;
  `)}
  
  ${media.mobile(css`
    margin: 11px 0 0;
  `)}
`;

export default Procedures;
