import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { VacanciesShortItem, VacancyCategoriesItemType } from '@/typings/model';

import CategoryItem from './components/CategoryItem';

type Props = {
  categoryList: Array<VacancyCategoriesItemType>;
  vacanciesLength: number;
  isActiveIndex: number;
  setActiveIndex: (value: number) => void;
  valueShop: string;
};

function CategoryList({
  categoryList,
  vacanciesLength,
  setActiveIndex,
  isActiveIndex,
  valueShop,
}: Props) {
  function getLengthList(categoryItem: VacancyCategoriesItemType) {
    const vacancyList: Array<VacanciesShortItem> = [];

    categoryItem.vacancies.map((vacancyItem, index) => {
      vacancyList.push(vacancyItem);
    });

    if (valueShop) {
      const vacancyByStore = vacancyList.filter(
        (vacancy) => vacancy.store === valueShop
      );
      return vacancyByStore.length;
    } else {
      return vacancyList.length;
    }
  }

  return (
    <Component>
      {vacanciesLength !== 0 ? (
        <Cell>
          <CategoryItem
            isActive={0 === isActiveIndex}
            label={'Все вакансии'}
            value={vacanciesLength}
            onChange={setActiveIndex}
            index={0}
          />
        </Cell>
      ) : null}

      {categoryList.map((categoryItem, index) => {
        if (getLengthList(categoryItem) === 0) return;
        return (
          <Cell key={categoryItem.id}>
            <CategoryItem
              isActive={index + 1 === isActiveIndex}
              label={categoryItem.name}
              value={getLengthList(categoryItem)}
              onChange={setActiveIndex}
              index={index + 1}
            />
          </Cell>
        );
      })}
    </Component>
  );
}

const Component = styled.div`
  margin-top: 60px;

  ${media.laptop(css`
    margin-top: 48px;
  `)}

  ${media.tablet(css`
    display: flex;
    flex-wrap: wrap;
    margin-top: 31px;
  `)}
  
  ${media.mobile(css`
    display: initial;
    margin-top: 30px;
  `)}
`;

const Cell = styled.div`
  margin-bottom: 29px;

  ${media.laptop(css`
    margin-bottom: 16px;
  `)}

  ${media.tablet(css`
    margin-bottom: 20px;
    margin-right: 20px;
  `)}
  
  ${media.mobile(css`
    margin-right: 0;
  `)}

  &:last-child {
    margin-bottom: 0;
  }
`;

export default CategoryList;
