import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { ImageThumbnail, StringField } from '@/typings/model';

import GallerySlide from './components/GallerySlide';
import GalleryActiveField from './components/GalleryActiveField';

interface Props {
  products: Array<{
    image: ImageThumbnail;
    name: StringField;
    variety: StringField;
  }>;
}

function GallerySection({ products }: Props) {
  const [activeProduct, setActiveProduct] = useState<number>(0);
  return (
    <Component>
      <Top>
        <Col>
          <GalleryActiveField product={products[activeProduct]} />
        </Col>
        <Col>
          <List>
            {products?.slice(0, 6)?.map((product, index) => {
              if (!product.image) return false;
              return (
                <Cell key={index}>
                  <GallerySlide
                    index={index}
                    isActive={activeProduct === index}
                    product={product}
                    setActiveProduct={(value: number) =>
                      setActiveProduct(value)
                    }
                  />
                </Cell>
              );
            })}
          </List>
        </Col>
      </Top>
      {products && products.length > 6 && (
        <Bottom>
          <List>
            {products?.slice(6)?.map((product, index) => {
              if (!product.image) return false;
              return (
                <Cell key={index} isSmall={true}>
                  <GallerySlide
                    index={index + 6}
                    isActive={activeProduct === index + 6}
                    product={product}
                    setActiveProduct={(value: number) =>
                      setActiveProduct(value)
                    }
                  />
                </Cell>
              );
            })}
          </List>
        </Bottom>
      )}
    </Component>
  );
}

const Component = styled.div`
  max-width: 1680px;
  margin: 100px auto 0;
  padding: 0 40px;

  ${media.tablet(css`
    margin-top: 70px;
    padding: 0 20px;
  `)}

  ${media.mobile(css`
    margin-top: 50px;
  `)}
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -16px;

  ${media.laptop(css`
    margin: 0 -12px;
  `)}

  ${media.tablet(css`
    margin: 0 -10px;
  `)}
  
  ${media.mobile(css`
    display: block;
    margin: 0;
  `)}
`;

const Bottom = styled.div`
  margin-top: 30px;

  ${media.laptop(css`
    margin-top: 24px;
  `)}

  ${media.tablet(css`
    margin-top: 12px;
  `)}
  
  ${media.mobile(css`
    margin-top: 0;
  `)}
`;

const Col = styled.div`
  flex: 1 1 50%;
  max-width: 50%;
  padding: 0 16px;

  ${media.laptop(css`
    padding: 0 12px;
  `)}

  ${media.tablet(css`
    padding: 0 10px;
  `)}
  
  ${media.mobile(css`
    max-width: 100%;
    padding: 0;

    &:first-child {
      margin-bottom: 20px;
    }
  `)}
`;

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -15px -16px;

  ${media.laptop(css`
    margin: -12px;
  `)}

  ${media.tablet(css`
    margin: -6px;
  `)}
  
  ${media.mobile(css`
    margin: -10px -6px;
  `)}
`;

const Cell = styled.div<{ isSmall?: boolean }>`
  flex: 1 1 calc(100% / 3);
  max-width: calc(100% / 3);
  padding: 15px 16px;

  ${(props) =>
    props.isSmall &&
    css`
      flex: 1 1 calc(100% / 6);
      max-width: calc(100% / 6);

      ${media.mobile(css`
        flex: 1 1 calc(100% / 3);
        max-width: calc(100% / 3);
      `)}
    `}

  ${media.laptop(css`
    padding: 12px;
  `)}

  ${media.tablet(css`
    padding: 6px;
  `)}
  
  ${media.mobile(css`
    padding: 10px 6px;
  `)}
`;

export default GallerySection;
