import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import Breadcrumbs from '@/components/Breadcrumbs';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import { ShopsTypeItem } from '@/typings/model';
import useCurrentPage from '@/hooks/useCurrentPage';
import MapBlock from '@/modules/ShopContacts/components/Map';
import { useTypedSelector } from '@/store/store';
import { selectShopByCitiesList } from '@/store/reducers/shopsCities';
import { useLayout } from '@/components/Layout/Layout.hooks';
import { selectMenuItemListByAlias } from '@/store/reducers/tager/menus';
import { setBreadCrumbsList } from '@/utils/common';
import { BreadCrumbs } from '@/typings/common';

import Hero from './components/Hero';
import Filter from './components/Filter';
import ShopInYourCity from './components/ShopInYourCity';

function Shops() {
  const page = useCurrentPage();
  const { setThemePage, setActiveStoreName, setMenuStore } = useLayout();

  const mapRef = useRef<HTMLDivElement>(null);
  const [activeShop, setActiveShop] = useState<ShopsTypeItem | null>(null);
  const [activeCityName, setActiveCityName] = useState<string>('');
  const shopByCitiesList = useTypedSelector(selectShopByCitiesList);

  const [placemarkList, setPlacemarkList] = useState<Array<ShopsTypeItem>>(() =>
    defaultPlacemarkList()
  );
  const [activePlacemark, setActivePlacemark] = useState<Array<ShopsTypeItem>>(
    []
  );

  const headerMenuHypermarkets =
    useTypedSelector((state) =>
      selectMenuItemListByAlias(state, 'hypermarket')
    ) ?? [];

  const BREADCRUMB_LIST: Array<BreadCrumbs> = setBreadCrumbsList({
    title: page?.title ?? '',
    path: page?.path ?? '',
  });

  function scrollTo(section: any) {
    const targetElement = section;
    if (targetElement) {
      const y =
        targetElement.getBoundingClientRect().top + window.pageYOffset - 50;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }

  function defaultPlacemarkList() {
    const placemarkList: Array<ShopsTypeItem> = [];

    shopByCitiesList.forEach((city) => {
      city.shops.forEach((shop) => {
        placemarkList.push(shop);
      });
    });

    return placemarkList;
  }

  useEffect(() => {
    if (!activeShop) return;
    scrollTo(mapRef.current);
  }, [activeShop]);

  useEffect(() => {
    activePlacemark.length > 0
      ? setPlacemarkList(activePlacemark)
      : setPlacemarkList(defaultPlacemarkList());
  }, [activePlacemark]);

  useEffect(() => {
    setThemePage('');

    setActiveStoreName({
      value: 'Гипермаркет',
      label: 'Гипермаркет',
    });
    setMenuStore(headerMenuHypermarkets);

    return () => {
      setActiveStoreName({
        value: '',
        label: '',
      });
      setMenuStore([]);
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [headerMenuHypermarkets]);

  return (
    <Wrapper>
      <Breadcrumbs breadcrumbs={BREADCRUMB_LIST} />
      <Hero title={page?.title ?? ''} />
      <Filter
        setActiveShop={(shop: ShopsTypeItem) => setActiveShop(shop)}
        setActivePlacemark={(list: Array<ShopsTypeItem>) =>
          setActivePlacemark(list)
        }
        setActiveCityName={(name: string) => setActiveCityName(name)}
      />
      <MapWrapper ref={mapRef}>
        <MapBlock
          activeShop={activeShop}
          placemarkList={placemarkList}
          setActiveShop={(shop: ShopsTypeItem) => setActiveShop(shop)}
          isActiveCity={activeCityName}
        />
      </MapWrapper>
      <ShopInYourCity
        setActiveShop={(shop: ShopsTypeItem) => setActiveShop(shop)}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding-bottom: 212px;

  &:before {
    position: absolute;
    content: '';
    top: 1300px;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${colors.gray50};
  }

  ${media.laptop(css`
    padding-bottom: 180px;

    &:before {
      top: 1066px;
    }
  `)}

  ${media.tablet(css`
    padding-bottom: 106px;

    &:before {
      top: 780px;
    }
  `)}

  ${media.mobile(css`
    padding-bottom: 114px;
  `)}
`;

const MapWrapper = styled.div`
  margin-bottom: 128px;

  ${media.laptop(css`
    margin-bottom: 80px;
  `)}

  ${media.tablet(css`
    margin-bottom: 72px;
  `)}

  ${media.mobile(css`
    margin-bottom: 50px;
  `)}
`;

export default Shops;
